/**
 * @group Views-mixins
 * This is a description of the component it includes DpButton component
 */
export default {
    data() {
        return {
            /**
             * @vuese
             * the data
             */
            imagePreview: {
                image: null,
                images: [],
                width: null,
                height: null,
                ratioX: null,
                ratioY: null,
                viewport: null
            }
        }
    },
    /**
     * @vuese
     * description
     */
    destroyed() {
        this.imagePreview = null
    },
    methods: {
        /**
         * @vuese
         * description
         */
        getCanvasConfig() {
            this.$log.warn('Method not implemented')
            return null
        },
        /**
         * @vuese
         * description
         */
        getPreviewCanvasImage() {
            return this.$refs.canvasImage ? this.$refs.canvasImage.getNode() : null
        },
        /**
         * @vuese
         * description
         */
        getPreviewGaugeCanvasImage() {
            return this.getPreviewCanvasImage()
        },
        /**
         * @vuese
         * description
         */
        getPixelRatio() {
            return 0.5
        },
        /**
         * @vuese
         * doesn't do anything
         */
        beforePreviewCreate() {
            // you can implement this method
        },
        /**
         * @vuese
         * doesn't do anything
         */
        previewCreated() {
            // you can implement this method
        },
        /**
         * @vuese
         * description
         */
        clearPreview() {
            this.imagePreview = {
                image: null,
                width: null,
                height: null,
                ratioX: null,
                ratioY: null,
                viewport: null
            }
        },
        /**
         * @vuese
         * description
         * @arg ration
         */
        reloadPreviewImages(ratio) {
            const canvasImage = this.getPreviewCanvasImage()
            return new Promise((resolve) => {
                canvasImage.toImage({
                    pixelRatio: ratio,
                    callback: (image) => resolve(image)
                })
            })
        },
        /**
         * @vuese
         * description
         * @arg imageChanged - boolean, default=true
         */
        reloadPreview(imageChanged = true) {
            this.$nextTick(() => {
                const canvasConfig = this.getCanvasConfig()
                const canvasImage = this.getPreviewCanvasImage()
                const gaugeCanvasImage = this.getPreviewGaugeCanvasImage()
                if (!canvasConfig || !canvasImage || !gaugeCanvasImage) {
                    return
                }

                const {x, y, width, height} = canvasImage.getClientRect()
                const vw = x >= 0 ? width : width + x
                const cw = x >= 0 ? canvasConfig.width - x : canvasConfig.width
                const vh = y >= 0 ? height : height + y
                const ch = y >= 0 ? canvasConfig.height - y : canvasConfig.height

                const gaugeClientRect = gaugeCanvasImage.getClientRect()
                const gaugeWidth = gaugeClientRect.width
                const gaugeHeight = gaugeClientRect.height

                const ratio = this.getPixelRatio()
                const viewport = {
                    x: (x >= 0 ? 0 : -1 * x) * ratio,
                    y: (y >= 0 ? 0 : -1 * y) * ratio,
                    width: Math.min(vw, cw) * ratio,
                    height: Math.min(vh, ch) * ratio,
                    feedback: false
                }
                if (imageChanged) {
                    this.beforePreviewCreate()
                    this.reloadPreviewImages(ratio).then((results) => {
                        let images
                        if (results instanceof Array) {
                            images = results
                        } else {
                            images = [results]
                        }
                        this.imagePreview = {
                            image: images[0],
                            images,
                            width: gaugeWidth,
                            height: gaugeHeight,
                            ratioX: ratio,
                            ratioY: ratio,
                            viewport
                        }
                        this.previewCreated()
                    })
                } else {
                    const prevRatioX = this.imagePreview.width / gaugeWidth
                    const prevRatioY = this.imagePreview.height / gaugeHeight

                    viewport.x *= prevRatioX
                    viewport.y *= prevRatioY
                    viewport.width *= prevRatioX
                    viewport.height *= prevRatioY

                    this.imagePreview.ratioX = ratio * prevRatioX
                    this.imagePreview.ratioY = ratio * prevRatioY
                    this.imagePreview.viewport = viewport
                }
            })
        },
        /**
         * @vuese
         * description
         * @arg feedback - default true \\
         * @arg feedbackAttributes - default null
         */
        reloadPreviewViewport(feedback = false, feedbackAttributes = null) {
            this.$nextTick(() => {
                const canvasConfig = this.getCanvasConfig()
                const canvasImage = this.getPreviewCanvasImage()
                if (!canvasConfig || !canvasImage) {
                    return
                }

                const {x, y, width, height} = canvasImage.getClientRect()
                const vw = x >= 0 ? width : width + x
                const cw = x >= 0 ? canvasConfig.width - x : canvasConfig.width
                const vh = y >= 0 ? height : height + y
                const ch = y >= 0 ? canvasConfig.height - y : canvasConfig.height

                this.imagePreview.viewport = {
                    x: (x >= 0 ? 0 : -1 * x) * this.imagePreview.ratioX,
                    y: (y >= 0 ? 0 : -1 * y) * this.imagePreview.ratioY,
                    width: Math.min(vw, cw) * this.imagePreview.ratioX,
                    height: Math.min(vh, ch) * this.imagePreview.ratioY,
                    feedback: feedback,
                    feedbackAttributes: feedbackAttributes
                }
            })
        }
    }
}
