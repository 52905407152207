import axios from 'axios'

/**
 * @group Services
 * describe the Services (it will not be shown in vuese yet)
 */
export default {
    // formcount: 0,
    // forms: new FormData(),
    // cur_time: Date.now(),

    uploadSegment(correlationId, fileId, segmentNum, segmentFile, otherMeta) {
        const formData = new FormData()
        formData.append('segment', JSON.stringify({
            correlation_id: correlationId,
            file_id: fileId,
            segment_num: segmentNum,
            other_meta: otherMeta,
        }))
        formData.append('file', segmentFile)
        // this.forms.append('segment', JSON.stringify({
        //   correlation_id: correlationId,
        //   file_id: fileId,
        //   segment_num: segmentNum}))
        // this.forms.append('file', segmentFile)
        // this.formcount++
        // console.log(this.formcount)
        return axios.post(`/api/v1/segment/upload`, formData)
        //)
        // if (this.formcount > 10){
        //   console.log("sending request")
        //   return setTimeout(axios.post(`/api/v1/segment/upload`, this.forms), 1000).finally(
        //       this.forms = newFormData(),
        //       this.formcount= 0,
        //       this.cur_time= Date.now()
        //   )
        //   }else if (this.cur_time - Date.now() > 100){
        //   console.log("sending request on timeout")
        //   return setTimeout(axios.post(`/api/v1/segment/upload`, this.forms), 1000).finally(
        //     this.forms = newFormData(),
        //     this.formcount= 0,
        //     this.cur_time= Date.now()
        //   )
        // }
    },
    generateSegment(correlationId, fileId, segmentNum, segments) {

        // const sleep = ms => new Promise(r => setTimeout(r, ms));
        // sleep(10000)
        const formData = new FormData()
        formData.append('segment', JSON.stringify({
            correlation_id: correlationId,
            file_id: fileId,
            segment_num: segmentNum,
            segments: segments,
        }))
        return axios.post(`/api/v1/segment/generate`, formData)
    },
    uploadSegmentDataURL(correlationId, fileId, segmentNum, segmentType, dataURL, otherMeta) {
        const bytes = atob(dataURL.split(',')[1])
        const ab = new ArrayBuffer(bytes.length)
        const ia = new Uint8Array(ab)
        for (let i = 0; i < bytes.length; i++) {
            ia[i] = bytes.charCodeAt(i)
        }
        const blob = new Blob([ab], {type: segmentType})
        return this.uploadSegment(correlationId, fileId, segmentNum, blob)
    },

    uploadSegmentDataGen(correlationId, fileId, segmentNum, segmentType, otherMeta) {
        const ab = new ArrayBuffer(bytes.length)
        const ia = new Uint8Array(ab)
        return this.generateSegment(correlationId, fileId, segmentNum, otherMeta)
    },

    // uploadProjectId(projectId) {
    //   return this.uploadProject(projectId)
    // },
    // uploadProject(projectId) {
    //   const formData = new FormData()
    //   formData.append('project', JSON.stringify({
    //     project_id: projectId,
    //   }))
    //   return axios.post(`/api/v1/segment/save`, formData)
    // }
}
