import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Help from '@/views/help.vue'
import Projects from '@/views/projects.vue'
import Project from '@/views/project.vue'

/**
 * @vuese
 * describe the router (it will not be shown in vuese yet)
 */
Vue.use(VueRouter)

function isAuthorized() {
    return store.getters.isAuthorized && store.getters.isUserGranted && !store.getters.isSubscriptionUnpaid
}

function getRedirectedFrom() {
    return store.getters.getRedirectedFrom
}

function setRedirectedFrom(route) {
    store.dispatch('setRedirectedFrom', route)
}

const validateAuthorization = (to, from, next) => {
    if (isAuthorized()) {
        next()
    } else {
        setRedirectedFrom({name: to.name, params: to.params})
        next({name: 'home'})
    }
}

const routes = [
    {
        path: '/',
        name: 'home',
        template: '<div></div>',
        beforeEnter(to, from, next) {
            if (isAuthorized() && !to.query.authorization_code) {
                let route = getRedirectedFrom()
                route = route !== null ? route : {name: 'projects'}
                setRedirectedFrom(null)
                next(route)
            } else {
                next()
            }
        }
    },
    {
        path: '/help',
        name: 'help',
        component: Help,
        meta: {
            footerColor: '#FFFFFF'
        }
    },
    {
        path: '/projects',
        name: 'projects',
        component: Projects,
        beforeEnter: validateAuthorization,
        meta: {
            footerColor: '#FFFFFF'
        }
    },
    {
        path: '/project/:projectId',
        name: 'project',
        component: Project,
        beforeEnter: validateAuthorization
    }
]

const router = new VueRouter({
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                offset: {x: 0, y: 96},
                selector: to.hash,
                behavior: 'smooth'
            }
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    }
})

router.afterEach((to, from) => {
    document.title = process.env.VUE_APP_NAME
})

export default router
