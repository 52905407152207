import axios from 'axios'

/**
 * @group Services
 * describe the Services (it will not be shown in vuese yet)
 */
export default {
    profile() {
        return axios.get('/api/v1/user/profile')
    },
    saveLocale(locale) {
        const data = {
            locale: locale.toUpperCase()
        }
        return axios.patch('/api/v1/user/profile', data)
    },
    logout() {
        return axios.get('/api/v1/user/logout')
    }
}
