<template>
  <b-modal
      v-model="display"
      :title="$t('title')"
      centered
      dialog-class="project-notice"
      no-close-on-backdrop
      title-class="windows-title"
      @ok.prevent="showCreationDialog"
  >
    <template v-slot:modal-header-close>
      <dp-icon name="close"></dp-icon>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <dp-button ghost @click="cancel">{{ $t("cancel") }}</dp-button>
      <dp-button @click="ok">{{ $t("create") }}</dp-button>
    </template>
    <template v-slot:default>
      <div>
        {{ $t("notice") + fileName + "?" }}
      </div>
      <b-overlay :opacity="0.3" :show="actionsDisabled" no-wrap></b-overlay>
      <!-- <project-update
        :active.sync="modal.creation"
        :project-name.sync="projectName"
        :project-id="projectId"
        v-on:updateFiles="updateFiles"
      >
      </project-update> -->
    </template>
  </b-modal>
</template>

<script>
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import DpIcon from "@/components/icon/dp-icon";
import DpButton from "@/components/button/dp-button";

/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: "project-update",
  components: {
    DpIcon,
    DpButton,
  },
  props: {
    modalNotice: {
      type: Boolean,
      default: false,
    },
    projectId: {
      required: true,
    },
    fileName: {
      required: true,
    },
  },
  data() {
    return {
      actionsDisabled: false,
    };
  },
  computed: {
    display: {
      get() {
        return this.modalNotice;
      },
      set(newValue) {
        this.$emit("update:modalNotice", newValue);
      },
    },
  },
  watch: {},
  methods: {
    showCreationDialog() {
      this.$emit("deleteCoreFiles");
      this.$emit("update:modalNotice", false);
      // this.$emit("showCreationDialog");
    },
    updateFiles() {
      this.$emit("loadPreviews");
    },
    upload() {
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Notice",
    "create": "Yes",
    "cancel": "No",
    "notice": "Would you like to delete file "
  },
  "ru": {
    "title": "Внимание",
    "create": "Да",
    "cancel": "Нет",
    "notice": "Вы действительно хотите удалить файл "
  }
}
</i18n>
