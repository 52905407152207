<template>
  <div class="project-depth-referencing d-flex w-100">
    <core-preview :depths="imagePreviewDepths"
                  :images="imagePreview.images"
                  :viewport="imagePreview.viewport"
                  @move-viewport="scrollImageFromPreview">
    </core-preview>

    <div ref="imageContainer" class="image-container flex-grow-1">
      <image-scale :scale="scale.x"></image-scale>

      <core-depth :active.sync="depths.current.active"
                  :pointer-position="depths.current.position"
                  :rules="depths.current.rules"
                  :value="depths.current.value"
                  @core-depth-apply="applyDepth">
      </core-depth>

      <v-stage ref="canvasStage" :config="canvasConfig" class="image-canvas"
               @click="onCanvasStageClick"
               @contextmenu="(e) => {e.evt.preventDefault()}">
        <v-layer ref="canvasLayer">
          <v-group v-if="imageDataLoaded" ref="canvasImageGroup">
            <v-group ref="canvasImage">
              <template v-for="image in images">
                <v-image :key="`canvas-image-part-${image.image_id}`"
                         :ref="`canvas-image-part-${image.image_id}`"
                         :config="image.config"/>
                <!-- rect need for correct scrolling -->
                <v-rect v-if="image.with_split"
                        :key="`split-image-rect-part-${image.image_id}`"
                        :config="image.splitRectConfig"/>
                <v-line v-if="image.with_split"
                        :key="`split-image-line-part-${image.image_id}`"
                        :config="image.splitLineConfig"/>
              </template>
            </v-group>

            <template v-for="imageId in Object.keys(depths.points)">
              <template v-for="(point, index) in depths.points[imageId]">
                <!-- depth point -->
                <v-line :key="`depth-bound-${imageId}-${index}`"
                        :config="point.boundConfig"/>
                <v-line :key="`depth-pointer-${imageId}-${index}`"
                        :config="point.pointerConfig"/>
                <v-text v-if="!depths.editable && point.visible"
                        :key="`depth-text-${imageId}-${index}`"
                        :config="point.textConfig"/>
                <v-rect v-if="depths.editable && point.editable && point.visible"
                        :key="`depth-edit-rect-${imageId}-${index}`"
                        :config="point.editableRectConfig"/>
                <v-text v-if="depths.editable && point.editable && point.visible"
                        :key="`depth-edit-text-${imageId}-${index}`"
                        :config="point.editableTextConfig"/>

                <!-- next/previous depth point arrows -->
                <v-path v-if="depths.editable && point.editable && point.visible"
                        :key="`depth-nav-path-${imageId}-${index}`"
                        :config="point.editableNavPathConfig"/>
              </template>
            </template>
          </v-group>
        </v-layer>
        <v-layer ref="canvasCursorLayer" :config="{listening: false}">
          <v-group ref="canvasCursorGroup">
            <v-line :config="cursorPosition.boundConfig"/>
            <v-line :config="cursorPosition.pointerConfig"/>
            <v-text :config="cursorPosition.depthConfig"/>
          </v-group>
        </v-layer>
      </v-stage>
      <b-overlay :opacity="0.3" :show="displayCanvasLoader" no-wrap></b-overlay>
    </div>

    <image-toolbar ref="imageToolbar"
                   :editing-tools="['pointer-depth', 'split']"
                   :redo-enabled="redoEnabled"
                   :undo-enabled="undoEnabled"
                   @hand="handImageLocal"
                   @redo="redoImage"
                   @split="splitImage"
                   @undo="undoImage"
                   @pointer-depth="editImageDepths"
                   @zoom-in="zoomImageInLocal"
                   @zoom-out="zoomImageOutLocal">
    </image-toolbar>

    <cores-download :active.sync="downloadDialog.active" :project-id="project.project_id"></cores-download>
  </div>
</template>

<script>
import {v4 as uuidv4} from 'uuid'
import CorePreview from '@/views/components/core-preview'
import CoreDepth from '@/views/components/core-depth'
import ImageToolbar from '@/views/components/image-toolbar'
import CoreColumnsMixin from '@/views/mixins/core-columns-mixin'
import ProjectService from '@/services/project-service'
import FileService from '@/services/file-service'
import ImageScale from '@/views/components/image-scale'
import MessageMixin from '@/mixins/message-mixin'
import CoresDownload from '@/views/components/cores-download'

/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: 'project-depth-referencing',
  components: {
    CoresDownload,
    CoreDepth,
    CorePreview,
    ImageScale,
    ImageToolbar
  },
  mixins: [CoreColumnsMixin, MessageMixin],
  data() {
    return {
      closestDepthNodes: [],
      reloadTask: null,
      reloadTimeout: 5000,
      displayCanvasLoader: false,
      downloadDialog: {
        active: false
      }
    }
  },
  watch: {
    coreColumns(value) {
      if (value && value.length > 0) {
        this.enableDownload()
      } else {
        this.disableDownload()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.resizeCanvas()
      window.addEventListener('resize', this.resizeCanvas)

      this.loadCoreColumns().then(() => this.$nextTick(() => this.resizeCanvas()))
    })
  },
  destroyed() {
    this.closestDepthNodes = null
    clearTimeout(this.reloadTask)
    window.removeEventListener('resize', this.resizeCanvas)
  },
  methods: {
    closeProject() {
      return this.saveImages(false)
    },
    goToNextStep() {
      if (this.reloadTask !== null) {
        const message = this.$t('errors.messages.DP-35')
        this.showEMessage(message)
        return Promise.reject(new Error(`Manual reject: ${message}`))
      }
      return this.saveImages(false)
    },
    afterGoToNextStep() {
      return ProjectService.hasCorrectBoundingBoxesStatus(this.project.project_id).then((result) => {
        const status = result.data.check_success
        if (!status) {
          const filenames = result.data.incorrect_file_names
          const more = filenames.length > 2 ? filenames.length - 2 : 0
          let message = this.$t('messages.incorrect-bb-status', {filenames: filenames.slice(0, 2)})
          if (more) {
            message = message + ' ' + this.valueMessage(more, this.$t('messages.incorrect-bb-status-more')).replace('{value}', more)
          }
          this.showWMessage(message, 15000)
        }
        return status
      })
    },
    download() {
      this.disableDownload()
      return this.saveImages().then(() => {
        this.clearHistory()
        this.enableDownload()
        this.downloadDialog.active = true
        return true
      })
    },
    enableDownload() {
      this.$emit('enable-download', true)
    },
    disableDownload() {
      this.$emit('enable-download', false)
    },
    saveImages(reloadCores = true) {
      if (!this.needSaveImage()) {
        return Promise.resolve(false)
      }

      const newSplits = []
      const newSplitFileIds = []
      Object.keys(this.coreColumnSplits).flatMap((fileId) => this.coreColumnSplits[fileId]).forEach((split) => {
        if (split.new_split) {
          newSplits.push(split)
          if (!newSplitFileIds.includes(split.file_id)) {
            newSplitFileIds.push(split.file_id)
          }
        }
      })
      if (newSplits.length === 0) {
        return Promise.resolve(false)
      }

      return ProjectService.saveCoreColumnSplits(this.project.project_id, newSplits).then(() => {
        newSplits.forEach((split) => this.$delete(split, 'new_split'))
        if (!reloadCores) {
          return true
        }
        const splitCores = newSplitFileIds.map((fileId) => {
          return FileService.cores(fileId).then((result) => {
            return {
              file_id: fileId,
              cores: result.data
            }
          })
        })
        return Promise.all(splitCores).then((results) => {
          const fileCores = this.arrayToMap(results, 'file_id')
          this.images.forEach((image, index) => {
            if (!newSplitFileIds.includes(image.file_id)) {
              return
            }

            const imageCores = fileCores[image.file_id].cores.filter((core) => {
              return core.anchor_y < (image.config.cropY + image.config.cropHeight) &&
                  core.anchor_y >= image.config.cropY
            })
            image.cores = this.arrayToMap(imageCores, 'core_id')

            const points = this.coresToDepthPoints(image)
            this.$set(this.images[index], 'cores', image.cores)
            this.$set(this.images[index], 'top_depth', points[0].value)
            this.$set(this.images[index], 'bottom_depth', points[points.length - 1].value)
            this.$set(this.depths.points, image.image_id, points)
          })
          return true
        })
      })
    },
    loadCoreColumns() {
      const projectId = this.project.project_id
      return ProjectService.isCoreColumnsReady(projectId).then((result) => {
        if (result.data.ready) {
          this.reloadTask = null
          return Promise.all([ProjectService.coreColumns(projectId), ProjectService.coreColumnSplits(projectId)]).then((results) => {
            if (results[0].data && results[0].data.length > 0) {
              this.$set(this, 'coreColumns', results[0].data.sort((i1, i2) => i1.top_depth - i2.top_depth))
              if (results[1].data && results[1].data.length > 0) {
                this.$set(this, 'coreColumnSplits', this.arrayToMapOfArrays(results[1].data, 'file_id'))
              }
              return this.loadCanvasImages()
            } else {
              this.showEMessage(this.$t('messages.no-core-columns'))
              return false
            }
          })
        } else {
          this.reloadTask = setTimeout(() => {
            this.loadCoreColumns()
          }, this.reloadTimeout)
          return false
        }
      })
    },
    loadCanvasImages() {
      this.beforeImagesLoading()
      this.resetCursorPosition()

      const coreColumnData = this.coreColumns.map((coreColumn) => {
        const fileId = coreColumn.file_id
        return Promise.all([FileService.file(fileId), FileService.cores(fileId)]).then((results) => {
          return {
            fileId: fileId,
            coreColumn: coreColumn,
            file: results[0].data,
            cores: results[1].data
          }
        })
      })
      return Promise.all(coreColumnData).then((results) => {
        const loadedImages = results.map((result) => {
          const htmlImage = new Image()
          htmlImage.src = URL.createObjectURL(result.file)
          return new Promise((resolve) => {
            htmlImage.onload = () => {
              URL.revokeObjectURL(htmlImage.src)
              resolve({
                fileId: result.fileId,
                coreColumn: result.coreColumn,
                image: htmlImage,
                cores: result.cores
              })
            }
            htmlImage.onerror = () => {
              URL.revokeObjectURL(htmlImage.src)
              resolve(null)
            }
          })
        })
        return this.loadImagesWithCores(loadedImages).then(() => {
          this.afterImagesLoading()
          return true
        })
      })
    },
    resizeCanvas() {
      const imageContainer = this.$refs.imageContainer
      this.$set(this.canvasConfig, 'width', imageContainer ? imageContainer.clientWidth : 0)
      this.$set(this.canvasConfig, 'height', imageContainer ? imageContainer.clientHeight : 0)
      this.$set(this.depths.current, 'active', false)
      this.repositioningImages()
      this.repositioningExtraLayers()
      this.resetCursorPosition()
      this.reloadPreview(false)
    },
    setImageScale(scaleX, scaleY) {
      const pointerPosition = this.getCanvasStage().getPointerPosition()
      const imageClientRect = this.getCanvasImage().getClientRect()
      const height = imageClientRect.height / this.scale.y
      const deltaY = (pointerPosition.y - imageClientRect.y) / this.scale.y

      this.$set(this, 'scale', {x: scaleX, y: scaleY})
      this.$set(this.depths.current, 'active', false)
      this.repositioningImages()
      this.repositioningExtraLayers()
      this.resetCursorPosition()

      let newY = pointerPosition.y - deltaY * scaleY - 22 // it is depth point text offset
      if (newY > 0) {
        newY = 0
      }
      if (newY + height * scaleY < this.canvasConfig.height - this.splitHeight) {
        newY = this.canvasConfig.height - this.splitHeight - height * scaleY
      }
      this.getCanvasImage().y(newY)
    },
    getHistoryImageData() {
      return {
        scale: this.clone(this.scale),
        images: this.cloneImages(this.images),
        splits: this.clone(this.coreColumnSplits),
        ignoredSplitsNum: this.ignoredSplitsNum,
        points: this.clone(this.depths.points)
      }
    },
    loadHistoryImageData(imageData) {
      this.$set(this, 'scale', this.clone(imageData.scale))
      this.$set(this, 'images', this.cloneImages(imageData.images))
      this.$set(this, 'coreColumnSplits', this.clone(imageData.splits))
      this.$set(this, 'ignoredSplitsNum', imageData.ignoredSplitsNum)
      this.$set(this.depths, 'points', this.clone(imageData.points))
      return this.$nextTick().then(() => {
        const imageClientRect = this.getCanvasImage().getClientRect()
        let newY = imageClientRect.y
        if (newY > this.canvasConfig.height - this.splitHeight * 2) {
          newY = this.canvasConfig.height - this.splitHeight * 2
        }
        if (newY + imageClientRect.height < this.splitHeight) {
          newY = this.splitHeight - imageClientRect.height
        }
        if (newY !== imageClientRect.y) {
          this.getCanvasImage().y(newY)
        }
        return true
      })
    },
    handImageLocal() {
      this.turnOffDepthEvents()
      this.turnOffCursorLine()
      this.handImage()
      this.turnOnImageScrolling()
      this.turnOnCursorLine('rgba(255, 255, 255, 0.5)', false)
    },
    zoomImageInLocal() {
      this.turnOffDepthEvents()
      this.turnOffCursorLine()
      this.zoomImageIn()
    },
    zoomImageOutLocal() {
      this.turnOffDepthEvents()
      this.turnOffCursorLine()
      this.zoomImageOut()
    },
    editImageDepths() {
      if (this.needSaveImage()) {
        this.displayCanvasLoader = true
        this.$refs.imageToolbar.setup()
        this.$refs.imageToolbar.disable()
        this.saveImages().then(() => {
          this.resetAndEditImageDepths()
          this.$refs.imageToolbar.activateTool('pointer-depth')
        }).finally(() => {
          this.displayCanvasLoader = false
          this.$refs.imageToolbar.enable()
        })
      } else {
        this.resetAndEditImageDepths()
      }
    },
    resetAndEditImageDepths() {
      this.clearHistory()
      this.resetCanvasImage()
      this.turnOffCursorLine()
      this.turnOnDepthEvents()
      this.scrollImageToTop()
    },
    changeLineYPoints(points, delta) {
      return points.map((point, index) => {
        if (index % 2 !== 0) {
          return point + delta
        } else {
          return point
        }
      })
    },
    splitImage() {
      this.resetCanvasImage()
      this.turnOffCursorLine()
      this.turnOffDepthEvents()
      this.turnOnImageScrolling()
      this.turnOnCursorLine('#E4312C', true)

      this.$refs.canvasImage.getNode().on('click tap', () => {
        const cursorPosition = this.$refs.canvasStage.getNode().getPointerPosition()
        const imageRect = this.$refs.canvasImage.getNode().getClientRect()
        const splitY = Math.round(cursorPosition.y - imageRect.y + this.splitHeight)

        let splitImage = null
        let splitImageIndex = null
        let splitIgnoredType = false
        let splitImageInitialHeight = 0
        let splitImageCropHeight = 0
        const minImageCropHeight = 20
        for (let index = 0; index < this.images.length; index++) {
          const image = this.images[index]
          const imageFromY = image.offset * this.scale.y
          const imageToY = (image.offset + image.config.height) * this.scale.y
          if (Math.abs(imageToY - splitY) < 5 && !image.last_part) {
            splitImage = image
            splitImageIndex = index
            splitIgnoredType = true
          } else if (splitY > imageFromY && splitY < imageToY) {
            splitImageInitialHeight = image.config.height
            splitImageCropHeight = Math.round(splitY / this.scale.y - image.offset)
            if (splitImageCropHeight < minImageCropHeight || (splitImageInitialHeight - splitImageCropHeight) < minImageCropHeight) {
              break
            }
            splitImage = image
            splitImageIndex = index
          } else if (splitImage) {
            image.config.y += this.splitHeight
            image.offset += this.splitHeight / this.scale.y
            if (image.with_split) {
              image.splitLineConfig.points = this.changeLineYPoints(image.splitLineConfig.points, this.splitHeight)
              image.splitRectConfig.y += this.splitHeight
            }
            this.$set(this.images, index, image)
            this.$set(this.depths.points, image.image_id, this.coresToDepthPoints(image))
          }
        }

        if (splitImage && splitIgnoredType) {
          const currentY = splitImage.offset * this.scale.y
          splitImage.with_split = true
          splitImage.last_part = true
          splitImage.splitLineConfig = this.createSplitLineConfig(splitImage.config, currentY)
          splitImage.splitRectConfig = this.createSplitRectConfig(splitImage.config, currentY)

          const splitImagePoints = this.coresToDepthPoints(splitImage)
          splitImage.top_depth = splitImagePoints[0].value
          splitImage.bottom_depth = splitImagePoints[splitImagePoints.length - 1].value

          this.$set(this.images, splitImageIndex, splitImage)
          this.$set(this.depths.points, splitImage.image_id, splitImagePoints)

          let splits = this.coreColumnSplits[splitImage.file_id]
          if (!splits) {
            splits = []
          }
          splits.push({
            file_id: splitImage.file_id,
            anchor_y: splitImage.config.cropY + splitImage.config.cropHeight,
            depth: splitImage.bottom_depth,
            new_split: true
          })
          this.$set(this.coreColumnSplits, splitImage.file_id, splits)
          this.ignoredSplitsNum++

          this.saveHistory(this.getHistoryImageData())
          this.reloadPreview()
        } else if (splitImage) {
          const newImage = {
            image_id: uuidv4(),
            file_id: splitImage.file_id,
            config: {},
            splitConfig: {},
            with_split: splitImage.with_split,
            first_part: true,
            last_part: splitImage.last_part
          }
          this.images.splice(splitImageIndex + 1, 0, newImage)

          splitImage.config.cropX = 0
          splitImage.config.cropWidth = splitImage.config.width
          splitImage.config.cropHeight = splitImageCropHeight
          splitImage.config.height = splitImage.config.cropHeight

          newImage.cores = this.clone(splitImage.cores)
          newImage.config = this.cloneImageConfig(splitImage.config)
          newImage.config.cropY = splitImage.config.cropY + splitImage.config.cropHeight
          newImage.config.cropHeight = splitImageInitialHeight - splitImage.config.cropHeight
          newImage.config.height = newImage.config.cropHeight
          newImage.config.y = this.canvasConfig.height / 2 + splitImage.offset * this.scale.y + splitImage.config.height * this.scale.y + this.splitHeight
          newImage.offset = splitImage.offset + splitImage.config.height + this.splitHeight / this.scale.y

          if (splitImage.with_split) {
            newImage.splitLineConfig = splitImage.splitLineConfig
            newImage.splitLineConfig.points = this.changeLineYPoints(newImage.splitLineConfig.points, this.splitHeight)
            newImage.splitRectConfig = splitImage.splitRectConfig
            newImage.splitRectConfig.y += this.splitHeight
          }

          const currentY = splitImage.offset * this.scale.y
          splitImage.with_split = true
          splitImage.last_part = true
          splitImage.splitLineConfig = this.createSplitLineConfig(splitImage.config, currentY)
          splitImage.splitRectConfig = this.createSplitRectConfig(splitImage.config, currentY)

          this.$set(this.images, splitImageIndex, splitImage)
          this.$set(this.images, splitImageIndex + 1, newImage)

          const splitImageCores = Object.keys(splitImage.cores).map((coreId) => splitImage.cores[coreId]).filter((core) => {
            return core.anchor_y < newImage.config.cropY
          }).sort((core1, core2) => {
            return core1.top_depth - core2.top_depth
          })
          const lastCore = splitImageCores[splitImageCores.length - 1]
          const coreBottomDepth = lastCore.bottom_depth
          const heightDelta = lastCore.anchor_y + lastCore.height - newImage.config.cropY
          const depthUnitPerPixel = (lastCore.bottom_depth - lastCore.top_depth) / lastCore.height
          if (lastCore.anchor_y + lastCore.height > newImage.config.cropY) {
            lastCore.height -= heightDelta
            lastCore.bottom_depth -= heightDelta * depthUnitPerPixel
          }
          splitImage.cores = this.arrayToMap(splitImageCores, 'core_id')
          const splitImagePoints = this.coresToDepthPoints(splitImage)
          this.$set(this.images[splitImageIndex], 'cores', splitImage.cores)
          this.$set(this.images[splitImageIndex], 'top_depth', splitImagePoints[0].value)
          this.$set(this.images[splitImageIndex], 'bottom_depth', splitImagePoints[splitImagePoints.length - 1].value)
          this.$set(this.depths.points, splitImage.image_id, splitImagePoints)

          const newImageCores = Object.keys(newImage.cores).map((coreId) => newImage.cores[coreId]).filter((core) => {
            return core.anchor_y >= newImage.config.cropY
          }).sort((core1, core2) => {
            return core1.top_depth - core2.top_depth
          })
          if (newImageCores.length === 0 || newImageCores[0].anchor_y > newImage.config.cropY) {
            const newCore = {
              core_id: uuidv4(),
              core_number: 0,
              anchor_x: lastCore.anchor_x,
              anchor_y: newImage.config.cropY,
              width: lastCore.width,
              height: heightDelta,
              top_depth: lastCore.bottom_depth,
              bottom_depth: coreBottomDepth
            }
            newImageCores.splice(0, 0, newCore)
          }
          newImage.cores = this.arrayToMap(newImageCores, 'core_id')
          const newImagePoints = this.coresToDepthPoints(newImage)
          this.$set(this.images[splitImageIndex + 1], 'cores', newImage.cores)
          this.$set(this.images[splitImageIndex + 1], 'top_depth', newImagePoints[0].value)
          this.$set(this.images[splitImageIndex + 1], 'bottom_depth', newImagePoints[newImagePoints.length - 1].value)
          this.$set(this.depths.points, newImage.image_id, newImagePoints)

          let splits = this.coreColumnSplits[splitImage.file_id]
          if (!splits) {
            splits = []
          }
          splits.push({
            file_id: splitImage.file_id,
            anchor_y: newImage.config.cropY,
            depth: +newImagePoints[0].value.toFixed(2),
            new_split: true
          })
          this.$set(this.coreColumnSplits, splitImage.file_id, splits)

          this.saveHistory(this.getHistoryImageData())
          this.reloadPreview()
        }
      })
    },
    onImageScrolling() {
      this.depths.current.active = false
    },
    onCanvasStageClick() {
      this.depths.current.active = false
    },
    completeDepthPoint(point, imageX, imageWidth) {
      point.boundConfig = this.getDepthBoundConfig(point, imageX, imageWidth)
      point.pointerConfig = this.getDepthPointerConfig(point, imageX)
      point.textConfig = this.getDepthTextConfig(point, imageX, false)
      if (point.visible && point.editable && this.needEditableDepthPoint()) {
        point.editableRectConfig = this.getDepthRectConfig(point, imageX, true)
        point.editableTextConfig = this.getDepthTextConfig(point, imageX, true)
        point.editableNavPathConfig = this.getDepthNavPathConfig(point)
      }
      return point
    },
    getEditableDepthCanvasNodes() {
      return this.$refs.canvasImageGroup.getNode().find((node) => {
        return node.id().includes('depth') && ['top', 'bottom'].includes(node.getAttr('depthType')) && node.getAttr('depthEditable')
      })
    },
    getNavPathDepthCanvasNodes() {
      return this.$refs.canvasImageGroup.getNode().find((node) => {
        return node.id().includes('depth-nav-path')
      })
    },
    navigateToNextDepthPoint(direction, depthValue) {
      this.depths.current.active = false

      const indexDelta = direction === 'top' ? -1 : +1
      let nextPointIndex = this.closestDepthNodes.findIndex((el) => el.attrs.depthValue === depthValue) + indexDelta
      while (this.closestDepthNodes[nextPointIndex].attrs.depthValue === depthValue) {
        nextPointIndex = nextPointIndex + indexDelta
      }

      const canvasImage = this.getCanvasImage()
      const height = canvasImage.getClientRect().height
      let newY = -this.closestDepthNodes[nextPointIndex].attrs.y + this.canvasConfig.height / 2
      if (newY + height < this.canvasConfig.height - this.splitHeight) {
        newY = (this.canvasConfig.height - this.splitHeight) - height
      }
      if (newY > 0) {
        newY = 0
      }
      canvasImage.y(newY)
      this.$refs.canvasLayer.getNode().batchDraw()

      this.repositioningExtraLayers()
      this.reloadPreviewViewport()
    },
    getDepthNavPathConfig(point) {
      const direction = point.type === 'bottom' ? 'top' : 'bottom'

      let path
      if (direction === 'bottom') {
        path = 'M23.9879 32.4672C23.6241 32.4672 23.2361 32.3217 22.9693 32.0307L8.41834 17.4797C7.86055 16.9219 7.86055 ' +
            '16.0004 8.41834 15.4183C8.97613 14.8606 9.89769 14.8606 10.4797 15.4183L23.9879 28.9507L37.5203 15.4183C38.0781 ' +
            '14.8606 38.9996 14.8606 39.5817 15.4183C40.1395 15.9761 40.1395 16.8977 39.5817 17.4797L25.0307 32.0307C24.7397 32.3217 ' +
            '24.3517 32.4672 23.9879 32.4672Z'
      } else if (direction === 'top') {
        path = 'M23.9879 14.9996C23.6241 14.9996 23.2361 15.1451 22.9693 15.4361L8.41834 29.9871C7.86055 30.5449 7.86055 ' +
            '31.4664 8.41834 32.0485C8.97613 32.6062 9.89769 32.6062 10.4797 32.0485L23.9879 18.5161L37.5203 32.0485C38.0781 ' +
            '32.6062 38.9996 32.6062 39.5817 32.0485C40.1395 31.4907 40.1395 30.5691 39.5817 29.9871L25.0307 15.4361C24.7397 ' +
            '15.1451 24.3517 14.9996 23.9879 14.9996Z'
      }

      const textConfig = point.editableTextConfig
      return {
        id: `depth-nav-path-${direction}-${point.coreId}`,
        depthValue: point.value,
        navDirection: direction,
        data: path,
        fill: '#42B1E5',
        x: textConfig.x + textConfig.width / 2 - 20,
        y: textConfig.y + (direction === 'top' ? -1 : 1) * 30,
        scaleX: 0.8,
        scaleY: 0.8,
        hitStrokeWidth: 24
      }
    },
    turnOnDepthEvents() {
      this.depths.editable = true

      this.$nextTick(() => {
        const editableDepthCanvasNodes = this.getEditableDepthCanvasNodes()
        editableDepthCanvasNodes.each((node) => {
          node.off()
          this.setCursorStyle(node, 'pointer')

          node.on('click tap', (event) => {
            event.cancelBubble = true

            const fileId = node.getAttr('fileId')
            const coreId = node.getAttr('coreId')
            const depthType = node.getAttr('depthType')
            const depthValue = node.getAttr('depthValue')
            const current = this.depths.current
            if (current.active && current.fileId === fileId && current.coreId === coreId && current.type === depthType) {
              current.active = false
              return
            }

            const imageIndex = this.images.findIndex((image) => image.file_id === fileId && Object.keys(image.cores).includes(coreId))
            const {jointImages, previousImage, nextImage} = this.getJointImages(imageIndex)
            const jointImageDepth = jointImages[jointImages.length - 1].bottom_depth - jointImages[0].top_depth

            const useFirstImage = this.images[0].image_id === jointImages[0].image_id
            const useLastImage = this.images[this.images.length - 1].image_id === jointImages[jointImages.length - 1].image_id

            const rules = ['required']
            if (depthType === 'top') {
              if (!useLastImage) {
                rules.push(`max_value:${nextImage.top_depth - jointImageDepth}`)
              }
              if (!useFirstImage) {
                rules.push(`min_value:${previousImage.bottom_depth}`)
              }
            }
            if (depthType === 'bottom') {
              if (!useLastImage) {
                rules.push(`max_value:${nextImage.top_depth}`)
              }
              if (useFirstImage) {
                rules.push(`min_value:${jointImageDepth}`)
              } else {
                rules.push(`min_value:${previousImage.bottom_depth + jointImageDepth}`)
              }
            }

            const absolutePosition = node.getAbsolutePosition(this.$refs.canvasStage.getNode())
            this.$set(this.depths, 'current', {
              fileId: fileId,
              coreId: coreId,
              active: true,
              type: depthType,
              value: depthValue,
              rules: rules.join('|'),
              position: {
                x: absolutePosition.x + node.width() / 2,
                y: absolutePosition.y + node.height() / 2,
                offsetX: node.width() / 2,
                offsetY: node.height() / 2
              }
            })
          })
        })

        this.getNavPathDepthCanvasNodes().each((node) => {
          node.off()
          this.setCursorStyle(node, 'pointer')

          node.on('click tap', () => {
            const direction = node.getAttr('navDirection')
            const depthValue = node.getAttr('depthValue')
            this.navigateToNextDepthPoint(direction, depthValue)
          })
        })
        this.$set(this, 'closestDepthNodes', [...editableDepthCanvasNodes].sort((node1, node2) => node1.attrs.y - node2.attrs.y))
      })
    },
    turnOffDepthEvents() {
      this.$set(this.depths, 'editable', false)
      this.$set(this.depths, 'current', {
        active: false,
        value: 0,
        rules: null,
        position: null
      })
      this.$set(this, 'closestDepthNodes', [])
    },
    applyDepth(newDepthValue) {
      const current = this.depths.current

      const imageIndex = this.images.findIndex((image) => image.file_id === current.fileId && Object.keys(image.cores).includes(current.coreId))
      const jointImages = this.getJointImages(imageIndex).jointImages

      const prevDepthValue = current.type === 'top' ? jointImages[0].top_depth : jointImages[jointImages.length - 1].bottom_depth
      const depthDelta = newDepthValue - prevDepthValue

      const promises = jointImages.map((image) => {
        const recalculated = this.recalculateCoreDepths(image.cores, depthDelta)
        return FileService.changeCoreDepths(image.file_id, recalculated).then((result) => {
          result.data.forEach((core) => this.$set(image.cores, core.core_id, core))
          const points = this.coresToDepthPoints(image)
          this.$set(this.depths.points, image.image_id, points)
          this.$set(image, 'top_depth', points[0].value)
          this.$set(image, 'bottom_depth', points[points.length - 1].value)
          return true
        })
      })

      Promise.all(promises).then(() => {
        this.$nextTick(() => {
          // refresh depth events because of points were changed
          this.turnOnDepthEvents()
        })
      })
    },
    recalculateCoreDepths(imageCores, depthDelta) {
      return Object.keys(imageCores).map((id) => {
        const core = imageCores[id]
        return {
          core_id: core.core_id,
          top_depth: this.roundDepth(core.top_depth + depthDelta),
          bottom_depth: this.roundDepth(core.bottom_depth + depthDelta)
        }
      })
    },
    roundDepth(value) {
      return Math.round((value + Number.EPSILON) * 100) / 100
    }
  }
}
</script>

<style lang="scss">
.project-depth-referencing {
  .image-container {
    position: relative;

    .image-scale {
      position: absolute;
      margin-left: 8px;
      bottom: 0;
      z-index: 1;
    }

    .core-depth {
      z-index: 2;
    }

    .image-canvas {
      position: absolute;
      z-index: 0;
    }
  }

  .image-toolbar {
    z-index: 1;
  }
}
</style>

<i18n>
{
  "en": {
    "messages": {
      "no-core-columns": "No core found in images or it is oriented horizontally, contact help@petroleum.digital",
      "incorrect-bb-status": "The following images were not processed as no core found there or it is oriented horizontally, contact help@petroleum.digital: {filenames}.",
      "incorrect-bb-status-more": [
        "There is 1 more image with the same issue",
        "There are {value} more images with the same issue",
        "There are {value} more images with the same issue",
        "There are {value} more images with the same issue"
      ]
    }
  },
  "ru": {
    "messages": {
      "no-core-columns": "Керн на изображениях не обнаружен или расположен в ящиках горизонтально, напишите на help@petroleum.digital",
      "incorrect-bb-status": "Следующие изображения не обработаны, т.к. на них не обнаружен керн или он расположен в ящиках горизонтально, напишите на help@petroleum.digital: {filenames}.",
      "incorrect-bb-status-more": [
        "Есть ещё 1 изображение с такой же проблемой",
        "Есть ещё {value} изображение с такой же проблемой",
        "Есть ещё {value} изображения с такой же проблемой",
        "Есть ещё {value} изображений с такой же проблемой"
      ]
    }
  }
}
</i18n>
