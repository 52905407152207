<template>
  <div v-if="filename" class="image-filename">{{ filename }}</div>
</template>

<script>
/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: 'image-filename',
  props: {
    filename: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.image-filename {
  padding: 10px;
  background: $--color-base-01;
  box-shadow: 5px 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: $--font-weight-regular !important;
  font-size: $--font-size-small !important;
  line-height: 18px;
  max-width: calc(100% / 3);
}
</style>
