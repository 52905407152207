import axios from 'axios'
import store from '@/store'

/**
 * @group Services
 * describe the Services (it will not be shown in vuese yet)
 */

export default {
    authCheck(authorizationCode) {
        const data = {
            authorization_code: authorizationCode
        }
        return axios.post('/api/v1/auth/check', data)
    },
    authLocation() {
        return axios.get('/api/v1/auth/redirect')
    },
    profileLocation() {
        return axios.get('/api/v1/auth/profile')
    },
    refreshToken() {
        const data = {
            refresh_token: store.getters.getRefreshToken
        }
        return axios.post('/api/v1/token/refresh', data)
    },
    redirect(location, currentRoute) {
        if (currentRoute && currentRoute.name !== 'home') {
            store.dispatch('setRedirectedFrom', {name: currentRoute.name, params: currentRoute.params})
        }
        window.location.replace(location)
    }
}
