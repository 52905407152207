<template>
  <b-container class="projects">
    <!-- <h2 class="header-bottom-margin">{{ $t("title") }}</h2> -->
    <!-- <b-row cols="1" cols-sm="2" cols-md="3" cols-lg="4" cols-xl="4"> -->

    <div class="d-flex justify-content-between flex-wrap">
      <div class="position_grow">
        <div class="d-flex justify-content-between flex-wrap height">
          <div class="mt-3">
            <dp-button
                class="download-btn d-xl-inline-flex new_btn"
                primary
                @click="showCreationDialog"
            >
              {{ $t("actions.create") }}
              <template v-slot:icon>
                <dp-icon fill="red" name="new-item"/>
              </template>
            </dp-button>
          </div>
          <div class="search_grow mt-3">
            <b-input-group class="height">
              <b-form-input
                  v-model="search_project"
                  :placeholder="$t('search.project')"
                  class="dp_search_input dp_search height"
                  type="search"
                  v-bind:class="{
                  dp_search_active: isActive,
                  dp_search_error: isError,
                }"
              >
              </b-form-input>
              <b-input-group-append
                  class="append"
                  v-bind:class="{
                  dp_search_active_BG: isActive,
                  dp_search_error_BG: isError,
                }"
              >
                <dp-icon fill="#DADADA" name="glass"/>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <form>
          <div class="__select" data-state="" @click="showSelect">
            <div :data-default="$t('search.date')" class="__select__title">
              {{ $t("search.date") }}
            </div>
            <div class="__select__content">
              <input
                  id="singleSelect0"
                  checked
                  class="__select__input"
                  name="singleSelect"
                  type="radio"
              />
              <label class="__select__label" for="singleSelect0">{{
                  $t("search.date")
                }}</label>
              <input
                  id="singleSelect1"
                  class="__select__input"
                  name="singleSelect"
                  type="radio"
              />
              <label class="__select__label" for="singleSelect1">{{
                  $t("search.date")
                }}</label>
              <input
                  id="singleSelect1"
                  class="__select__input"
                  name="singleSelect"
                  type="radio"
              />
              <label class="__select__label" for="singleSelect1">{{
                  $t("search.alphabet")
                }}</label>
            </div>
          </div>
        </form>
        <!-- <b-form-select
          v-model="selected"
          @change="sorted"
          id="select"
          class="dp-drop"
        >
          <b-form-select-option :value="search_date">{{
            $t("search.date")
          }}</b-form-select-option>
          <b-form-select-option value="search_alphabet">{{
            $t("search.alphabet")
          }}</b-form-select-option>
        </b-form-select> -->
      </div>
    </div>
    <b-row
        class="cards justify-content-start"
        cols="1"
        cols-lg="4"
        cols-md="3"
        cols-sm="2"
        cols-xl="6"
    >
      <b-col v-for="project in new_projects_arr" :key="project.project_id">
        <b-card
            :id="project.project_id"
            class="clickable"
            @click="openProject(project)"
        >
          <b-card-title>
            <div class="d-flex">
              <div :title="project.project_name" class="text-truncate">
                {{ project.project_name }}
              </div>
              <b-dropdown
                  class="ml-auto my-auto"
                  no-caret
                  right
                  toggle-tag="div"
                  variant="link"
              >
                <template v-slot:button-content>
                  <dp-icon name="context-menu"></dp-icon>
                </template>
                <b-dropdown-item
                    @click.prevent.stop="showRenamingDialog(project)"
                >
                  {{ $t("actions.rename") }}
                </b-dropdown-item>
                <b-dropdown-item
                    @click.prevent.stop="showDeletionDialog(project)"
                >
                  {{ $t("actions.delete") }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-card-title>
          <b-card-sub-title>
            {{ formatDate(project.creation_date) }}
          </b-card-sub-title>
          <b-card-text class="mt-3">
            <b-img
                :rounded="true"
                :src="getProjectPreview(project)"
                fluid
            ></b-img>
          </b-card-text>
        </b-card>
      </b-col>
      <!-- <b-col>
        <b-card
          v-if="isAuthorized"
          @click="showCreationDialog"
          class="clickable"
        >
          <div class="empty-card-body">
            <div
              class="
                d-flex
                flex-column
                align-items-center
                justify-content-center
                h-100
              "
            >
              <dp-icon
                name="new-item"
                class="mx-auto"
                width="48"
                height="48"
              ></dp-icon>
              <div class="create-project-title">{{ $t("actions.create") }}</div>
            </div>
          </div>
        </b-card>
      </b-col> -->
    </b-row>

    <project-creation :active.sync="modal.creation"></project-creation>

    <project-renaming
        :active.sync="modal.renaming"
        :project-id="currentProject.project_id"
        :project-name.sync="currentProject.project_name"
    >
    </project-renaming>

    <dp-decision-dialog
        id="deletion"
        :active.sync="modal.deletion"
        @agree="deleteProject(currentProject)"
    >
      {{ $t("delete-question", {name: currentProject.project_name}) }}
    </dp-decision-dialog>
  </b-container>
</template>

<script>
import DpButton from "@/components/button/dp-button";
import moment from "moment";
import DpIcon from "@/components/icon/dp-icon";
import DpDecisionDialog from "@/components/dp-decision-dialog";
import ProjectRenaming from "@/views/components/project-renaming";
import ProjectService from "@/services/project-service";
import ProjectCreation from "@/views/components/project-creation";

/**
 * @group Views
 * Root view
 */
export default {
  name: "projects",
  components: {
    DpIcon,
    DpButton,
    DpDecisionDialog,
    ProjectCreation,
    ProjectRenaming,
  },
  data() {
    return {
      /**
       * @vuese
       * description
       */
      modal: {
        creation: false,
        renaming: false,
        deletion: false,
      },
      /**
       * @vuese
       * description
       */
      projects: [],
      new_projects_arr: [],
      isActive: false,
      isError: false,
      value_selected: "",
      search_date: "search_date",
      selected: "search_date",
      /**
       * @vuese
       * description
       */
      previews: {},
      /**
       * @vuese
       * description
       */
      currentProject: {},
      search_project: "",
      placeholder: "",
    };
  },
  computed: {
    /**
     * @vuese
     * description
     */
    isAuthorized() {
      return (
          // this.$store.getters.isAuthorized &&
          // this.$store.getters.isUserGranted &&
          // !this.$store.getters.isSubscriptionUnpaid
          true
      );
    },
  },
  /**
   * @vuese
   * description
   */
  mounted() {
    this.loadProjects();
  },
  watch: {
    search_project() {
      this.new_projects_arr = [];
      this.isActive = false;
      this.isError = true;
      this.projects.forEach((pr) => {
        if (
            pr.project_name.toUpperCase().includes(this.search_project) ||
            pr.project_name.toLowerCase().includes(this.search_project) ||
            pr.project_name.includes(this.search_project)
        ) {
          if (!this.new_projects_arr.includes(pr)) {
            this.new_projects_arr.push(pr);
            this.isActive = true;
            this.isError = false;
            //console.log("color", this.isActive);
          }
        }
      });
      if (this.search_project === "") {
        this.isActive = false;
        this.isError = false;
      }
      this.new_projects_arr === this.projects;
    },
  },
  /**
   * @vuese
   * description
   */
  destroyed() {
    Object.keys(this.previews).forEach((id) => {
      URL.revokeObjectURL(this.previews[id]);
    });
    this.previews = null;
  },
  methods: {
    /**
     * @vuese
     * description
     */
    loadProjects() {
      ProjectService.userProjects().then((result) => {
        this.$set(this, "projects", result.data);
        this.$set(this, "new_projects_arr", result.data);
        if (result.data) {
          result.data.forEach((project) => {
            ProjectService.preview(project.project_id).then((preview) => {
              this.$set(
                  this.previews,
                  project.project_id,
                  URL.createObjectURL(preview.data)
              );
            });
          });
        }
      });
    },
    sorted() {
      const focusSelect = document.getElementById("select");
      if (this.selected === "search_date") {
        this.sortedDate();
        //console.log("sorting focus", focusSelect);
      } else if (this.selected === "search_alphabet") {
        this.sortedAlphabet();
        //console.log("sorting alphabet");
      }
    },
    sortedAlphabet() {
      //console.log(this.selected);
      this.new_projects_arr.sort(function (a, b) {
        var nameA = a.project_name.toLowerCase(),
            nameB = b.project_name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      this.title_sorted = this.$t("search.alphabet");
      //console.log("alph", this.title_sorted);
    },
    sortedDate() {
      this.new_projects_arr.sort(function (a, b) {
        var dateA = new Date(a.creation_date),
            dateB = new Date(b.creation_date);
        return dateB - dateA;
      });
      this.title_sorted = this.$t("search.date");
      //console.log("date", this.title_sorted);
    },
    /**
     * @vuese
     * description
     * @arg projects
     */
    openProject(project) {
      this.$router.push({
        name: "project",
        params: {
          projectId: project.project_id,
        },
      });
      localStorage.setItem("project_id", project.project_id);
    },
    /**
     * @vuese
     * description
     * @arg project
     */
    deleteProject(project) {
      ProjectService.delete(project.project_id).then(() => {
        const filtered = this.new_projects_arr.filter(
            (p) => p.project_id !== project.project_id
        );
        this.$set(this, "new_projects_arr", filtered);
        URL.revokeObjectURL(this.previews[project.project_id]);
        this.$delete(this.new_projects_arr, this.project);
        this.$delete(this.previews, project.project_id);
      });
    },
    /**
     * @vuese
     * description
     * @arg project
     */
    getProjectPreview(project) {
      return this.previews[project.project_id];
    },

    /**
     * @vuese
     * description
     */
    showCreationDialog() {
      this.modal.creation = true;
    },
    /**
     * @vuese
     * description
     * @arg project
     */
    showRenamingDialog(project) {
      this.currentProject = project;
      this.modal.renaming = true;
    },
    /**
     * @vuese
     * description
     * @arg project
     */
    showDeletionDialog(project) {
      this.currentProject = project;
      this.modal.deletion = true;
    },
    /**
     * @vuese
     * description
     * @arg date
     */
    formatDate(date) {
      const value = date ? new Date(date) : null;
      return value && moment(value).isValid
          ? moment(value).format("DD/MM/YYYY")
          : null;
    },

    showSelect() {
      const selectSingle = document.querySelector(".__select");
      const selectSingle_title = selectSingle.querySelector(".__select__title");
      const selectSingle_labels =
          selectSingle.querySelectorAll(".__select__label");
      // let locale = localStorage.getItem("locale");
      // Toggle menu
      if ("active" === selectSingle.getAttribute("data-state")) {
        selectSingle.setAttribute("data-state", "");
      } else {
        selectSingle.setAttribute("data-state", "active");
      }

      // Close when click to option
      for (let i = 0; i < selectSingle_labels.length; i++) {
        selectSingle_labels[i].addEventListener("click", (evt) => {
          selectSingle_title.textContent = evt.target.textContent;
          //console.log("11111", selectSingle_title.textContent);
          if (selectSingle_title.textContent === this.$t("search.alphabet")) {
            this.sortedAlphabet();
          } else if (
              selectSingle_title.textContent === this.$t("search.date")
          ) {
            this.sortedDate();
            selectSingle_title.textContent === this.$t("search.date");
          }
          selectSingle.setAttribute("data-state", "");
        });
      }

      // if(locale === 'ru'){

      // }else if(locale === "en"){

      // }
    },
  },
};
</script>

<style lang="scss">
.height {
  height: 100% !important;
}

.new_btn {
  height: 100% !important;

  .dp-button {
    max-height: 40px !important;
  }
}

.position_grow {
  flex-grow: 0.3;
}

.search_grow {
  flex-grow: 0.7;
  height: 40px;
}

.dp_search {
  border: 2px solid #e8e8e8 !important;
  border-radius: 8px 0px 0px 8px !important;

  &:focus {
    box-shadow: none !important;
  }
}

.my-height {
  height: 40px;
}

.dp_search_input {
  border-right: none !important;
  border-radius: none !important;

  &:focus {
    box-shadow: none !important;
  }

  .input-group-append {
    align-items: center;
  }
}

.append {
  border: 2px solid #e8e8e8 !important;
  border-radius: 0px 8px 8px 0px !important;
  border-left: none !important;
}

.dp_search_active {
  border: 2px solid #42b1e5 !important;
  // border-right: 2px solid#42b1e5 !important;
  // border-radius: 8px 8px 8px 8px !important;
}

.dp_search_error {
  border: 2px solid #e4312c !important;
}

.dp_search_active_BG {
  border: 2px solid #42b1e5 !important;
  background: #42b1e5;
}

.dp_search_error_BG {
  border: 2px solid #e4312c !important;
  background: #e4312c !important;
}

.dp-drop {
  background: url("../assets/img/chevron-down.svg") right 0.75rem center no-repeat !important;
  border: 2px solid #e8e8e8 !important;
  border-radius: 8px !important;
  width: 180px !important;
  height: 40px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    border: 2px solid #42b1e5 !important;
    box-shadow: none !important;
  }

  .form-control {
    border: none !important;
  }

  input {
    height: 34px;
  }
}

.dropdown_select {
  border: 2px solid #e8e8e8 !important;
  border-radius: 4px !important;

  &:hover {
    background: #fafafa;
    border-radius: 4px;
  }
}

.input-group-append {
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
}

.none_hover .btn {
  border: 0px solid #42b1e5 !important;
  border-radius: 8px;

  &:hover {
    text-decoration: none;
  }
}

.projects.container {
  max-width: none !important;
  margin: 0;
  padding: 40px 55px 12px 110px;
  background-color: $--color-base-01;
  text-align: -webkit-center;

  .cards {
    .col {
      padding-top: 24px;
      min-width: 312px;
      max-width: 312px;
    }
  }

  .card {
    width: 282px;
    height: 350px;
    border: none;
    border-radius: 8px;
    box-shadow: -5px 0px 20px rgba(0, 0, 0, 0.1);

    .empty-card-body {
      width: 250px;
      height: 318px;
    }

    .card-title {
      font-weight: $--font-weight-semi-bold !important;
      font-size: $--font-size-paragraph-base !important;
      line-height: 24px !important;
      margin-bottom: 4px !important;
    }

    .card-subtitle {
      font-weight: $--font-weight-regular !important;
      font-size: $--font-size-extra-small !important;
      line-height: 24px;
      color: $--color-primary;
      opacity: 0.7;
      text-align: end;
    }

    .card-body {
      padding: 16px;
    }

    .create-project-title {
      font-weight: $--font-weight-semi-bold;
      font-size: $--font-size-paragraph-base !important;
      line-height: 24px;
      margin-top: 35px;
    }

    img.rounded {
      border-radius: 8px !important;
    }
  }
}

// For select component

.__select {
  position: relative;
  width: 180px;
  height: 40px;
  margin: 0 auto;

  &[data-state="active"] {
    .__select__title {
      &::before {
        transform: translate(-3px, -50%) rotate(-45deg);
      }

      &::after {
        transform: translate(3px, -50%) rotate(45deg);
      }
    }

    .__select__content {
      opacity: 1;
    }

    .__select__label + .__select__input + .__select__label {
      max-height: 40px;
      border-top-width: 1px;
    }
  }
}

.__select__title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px 16px;

  border: 2px solid #e8e8e8;
  border-radius: 8px;

  cursor: pointer;

  &::before,
  &::after {
    content: "";

    position: absolute;
    top: 50%;
    right: 16px;

    display: block;
    width: 10px;
    height: 2px;

    transition: all 0.3s ease-out;

    background-color: #e8e8e8;

    transform: translate(-3px, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(3px, -50%) rotate(-45deg);
  }

  &:hover {
    border-color: #42b1e5;

    &::before,
    &::after {
      background-color: #42b1e5;
    }
  }
}

.__select__content {
  position: absolute;
  top: 40px;
  left: 3px;

  display: flex;
  flex-direction: column;
  width: calc(100% - 6px);

  background-color: #ffffff;

  border: 2px solid #e8e8e8;
  border-radius: 4px;
  margin-top: 3px;
  // border-top: none;
  // border-bottom-left-radius: 5px;
  // border-bottom-right-radius: 5px;

  transition: all 0.3s ease-out;

  opacity: 0;
  z-index: 8;
}

.__select__input {
  display: none;

  &:checked + label {
    // background-color: #dedede;
  }

  &:disabled + label {
    opacity: 0.6;
    pointer-events: none;
  }
}

.__select__label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  max-height: 0;
  padding: 0 16px;

  transition: all 0.2s ease-out;

  cursor: pointer;

  overflow: hidden;

  & + input + & {
    // border-top: 0 solid #c7ccd160;
  }

  &:hover {
    background-color: #42b1e5 !important;
    border-radius: 4px;
    color: #ffffff;
  }
}
</style>

<i18n>
{
  "en": {
    "title": "My projects",
    "actions": {
      "create": "New project",
      "delete": "Delete project",
      "rename": "Rename project"
    },
    "delete-question": "Are you sure you want to delete project {name}?",
    "search": {
      "sorted": "Sorting",
      "project": "Project search",
      "alphabet": "Name",
      "date": "Date modified"
    }
  },
  "ru": {
    "title": "Мои проекты",
    "actions": {
      "create": "Новый проект",
      "delete": "Удалить",
      "rename": "Переименовать"
    },
    "delete-question": "Вы действительно хотите удалить проект {name}?",
    "search": {
      "sorted": "Сортировка",
      "project": "Поиск проекта",
      "alphabet": "По алфавиту",
      "date": "По дате"
    }
  }
}
</i18n>
