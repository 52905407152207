import i18n from '@/plugins/i18n'

/**
 * @group Services
 * describe the Services (it will not be shown in vuese yet)
 */
export default {
    /**
     * @vuese
     * the method
     */
    getErrorMessage(error) {
        if (error.response && error.response.data && error.response.data.code) {
            let params = error.response.data.params
            if (params instanceof Array) {
                params = params.join(', ')
            }
            return i18n.t(`errors.messages.${error.response.data.code}`, {
                message: error.response.data.message,
                params: params
            })
        } else if (error.response && error.response.data && error.response.data.message) {
            return i18n.t('errors.messages.DP-1', {message: error.response.data.message})
        } else if (error.response && error.response.data) {
            return i18n.t('errors.messages.DP-1', {message: error.response.data})
        } else if (error.response && error.response.statusText) {
            return i18n.t('errors.messages.DP-1', {message: `HTTP ${error.response.status} ${error.response.statusText}`})
        } else {
            return i18n.t('errors.messages.DP-0')
        }
    }
}
