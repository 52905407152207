/**
 * @group Views-mixins
 * This is a description of the component it includes DpButton component
 */
export default {
    data() {
        return {
            /**
             * @vuese
             * the data
             */
            imageHistory: {
                items: [],
                step: 0,
            },
        }
    },
    computed: {
        /**
         * @vuese
         * description
         */
        undoEnabled() {
            return this.imageHistory.step > 0
        },
        /**
         * @vuese
         * description
         */
        redoEnabled() {
            return this.imageHistory.step < this.imageHistory.items.length - 1
        },
        /**
         * @vuese
         * description
         */
        saveEnabled() {
            return (this.imageHistory.step < this.imageHistory.items.length - 1 || this.imageHistory.step > 0)
        },
        /**
         * @vuese
         * description
         */
        historyStepLimit() {
            return this.isIE() ? 2 : 25
        },
    },
    /**
     * @vuese
     * description
     */
    destroyed() {
        this.imageHistory = null
    },
    methods: {
        /**
         * @vuese
         * description
         */
        needSaveImage() {
            return this.imageHistory.items.slice(0, this.imageHistory.step + 1).find((item) => item.persistable)
        },
        /**
         * @vuese
         * description
         */
        clearHistory() {
            this.imageHistory.items = []
            this.imageHistory.step = 0
        },
        /**
         * @vuese
         * description
         * @arg imageData
         */
        setupHistory(imageData) {
            this.clearHistory()
            this.imageHistory.items.push({
                persistable: false,
                imageData: Object.assign({}, imageData),
            })
        },
        /**
         * @vuese
         * description
         * @arg imageData \\
         * @arg persistable - boolean default true
         */
        saveHistory(imageData, persistable = true) {
            if (this.imageHistory.items.length === this.historyStepLimit && this.imageHistory.step === this.imageHistory.items.length - 1) {
                if (this.imageHistory.items[0].persistable) {
                    this.imageHistory.items[1].persistable = true
                }
                this.imageHistory.items = this.imageHistory.items.slice(1)
            } else {
                this.imageHistory.step += 1
                this.imageHistory.items = this.imageHistory.items.slice(0, this.imageHistory.step)
            }
            this.imageHistory.items.push({
                persistable: persistable,
                imageData: Object.assign({}, imageData),
            })
        },
        /**
         * @vuese
         * description
         */
        undoHistory() {
            this.imageHistory.step -= 1
            return Object.assign({}, this.imageHistory.items[this.imageHistory.step].imageData)
        },
        /**
         * @vuese
         * description
         */
        redoHistory() {
            this.imageHistory.step += 1
            return Object.assign({}, this.imageHistory.items[this.imageHistory.step].imageData)
        },
    },
}
