<template>
  <b-modal v-model="display" :title="title" centered
           modal-class="lithology-type-modal"
           no-close-on-backdrop title-class="windows-title text-truncate"
           @ok.prevent="save">
    <template v-slot:modal-header-close>
      <dp-icon name="close"></dp-icon>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <dp-button ghost @click="cancel">{{ $t('actions.cancel') }}</dp-button>
      <dp-button primary @click="ok">{{ $t('actions.save') }}</dp-button>
    </template>
    <template v-slot:default>
      <b-form @submit.prevent="save">
        <validation-provider ref="name-validator"
                             v-slot="validationContext"
                             name="name"
                             rules="required|max:250">
          <b-form-group id="name-group" :invalid-feedback="validationContext.errors[0]">
            <b-form-input id="name"
                          v-model="innerType.name"
                          :state="getValidationState(validationContext)">
            </b-form-input>
          </b-form-group>
        </validation-provider>
      </b-form>
      <lithology-type-settings :disabled-settings="disabledSettings"
                               :error-enabled="settingsError"
                               :settings.sync="typeSettings"/>
    </template>
  </b-modal>
</template>

<script>
import DpIcon from '@/components/icon/dp-icon'
import DpButton from '@/components/button/dp-button'
import ValidationMixin from '@/mixins/validation-mixin'
import LithologyTypeSettings from '@/views/components/lithology/lithology-type-settings'

/**
 * @group Views-components-lithology
 * This is a description of the component
 */
export default {
  name: 'lithology-type',
  components: {LithologyTypeSettings, DpButton, DpIcon},
  mixins: [ValidationMixin],
  props: {
    modelName: {
      type: String,
      default: ''
    },
    type: {
      type: Object,
      default: () => {
        return {
          id: null,
          custom_name: null,
          names: null,
          color: null,
          pattern: null,
          source_type: null
        }
      }
    },
    disabledSettings: {
      type: Array,
      default: () => {
        return []
      }
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerType: {},
      settingsError: false
    }
  },
  computed: {
    display: {
      get() {
        return this.active
      },
      set(newValue) {
        this.$emit('update:active', newValue)
      }
    },
    title() {
      if (this.innerType.id) {
        if (this.modelName && this.innerType.source_type === 'MODEL') {
          return `${this.innerType.name} (${this.modelName})`
        } else {
          return this.innerType.name
        }
      } else {
        return this.$t('title')
      }
    },
    typeSettings: {
      get() {
        return {
          id: this.innerType.id,
          color: this.innerType.color,
          pattern: this.innerType.pattern
        }
      },
      set(newValue) {
        this.innerType.color = newValue.color
        this.innerType.pattern = newValue.pattern
      }
    }
  },
  watch: {
    active(value) {
      if (value) {
        if (this.type && this.type.id) {
          let name = this.type.custom_name ? this.type.custom_name : null
          name = !name && this.type.names ? this.type.names[this.$i18n.locale.toUpperCase()] : name
          this.innerType = {
            id: this.type.id,
            name: name,
            color: this.type.color,
            pattern: this.type.pattern
          }
        } else {
          this.innerType = {
            id: null,
            name: null,
            color: null,
            pattern: null
          }
        }
      }
    }
  },
  destroyed() {
    this.innerType = null
  },
  methods: {
    save() {
      this.$refs['name-validator'].validate().then((result) => {
        if (result.valid) {
          if (!this.innerType.color || !this.innerType.pattern) {
            this.settingsError = true
            return
          }

          this.$emit('save-type', this.innerType)
          this.display = false
        }
      })
    }
  }
}
</script>

<style lang="scss">
.lithology-type-modal {
  .modal-dialog {
    max-width: 628px;

    .modal-body {
      padding: 16px 0 24px !important;
    }

    form {
      margin-bottom: 24px;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "title": "New class",
    "color-title": "Choose color",
    "pattern-title": "Choose pattern",
    "actions": {
      "save": "Save",
      "cancel": "Cancel"
    }
  },
  "ru": {
    "title": "Новый класс",
    "color-title": "Выберите цвет",
    "pattern-title": "Выберите паттерн",
    "actions": {
      "save": "Сохранить",
      "cancel": "Отмена"
    }
  }
}
</i18n>
