<template>
  <div class="project-classification d-flex w-100">
    <core-preview
        :depths="imagePreviewDepths"
        :images="imagePreview.images"
        :lithology-enabled="lithologyEnabled"
        :probabilities="imagePreviewProbabilities"
        :viewport="imagePreview.viewport"
        @move-viewport="scrollImageFromPreview"
    />

    <div ref="imageContainer" class="image-container flex-grow-1">
      <image-scale :scale="scale.x"/>
      <lithology-classes
          :active.sync="lithologyRegion.classes.enabled"
          :classes="lithologyRegion.classes.value"
          :column-id="lithologyRegion.column_id"
          :pointer-position="lithologyRegion.classes.position"
          :rect-id="lithologyRegion.rect_id"
          :types="lithologyTypesByType"
          @update:classes="updateLithologyRegionClasses"
      />

      <v-stage
          ref="canvasStage"
          :config="canvasConfig"
          class="image-canvas"
          @click="onCanvasStageClick"
          @contextmenu="
          (e) => {
            e.evt.preventDefault();
          }
        "
      >
        <v-layer ref="canvasLayer">
          <v-group v-if="imageDataLoaded" ref="canvasImageGroup">
            <v-rect
                v-if="backgroundConfig.width && backgroundConfig.height"
                :config="backgroundConfig"
            />

            <v-group ref="canvasImage">
              <template v-for="image in images">
                <v-image
                    :key="`canvas-image-part-${image.image_id}`"
                    :ref="`canvas-image-part-${image.image_id}`"
                    :config="image.config"
                />

                <v-rect
                    v-if="image.with_split"
                    :key="`split-image-rect-part-${image.image_id}`"
                    :config="image.splitRectConfig"
                />
                <v-line
                    v-if="image.with_split"
                    :key="`split-image-line-part-${image.image_id}`"
                    :ref="`split-image-line-part-${image.image_id}`"
                    :config="image.splitLineConfig"
                />
              </template>
              <template v-for="columnId in Object.keys(lithologyRects)">
                <template
                    v-for="rectId in Object.keys(lithologyRects[columnId])"
                >
                  <!-- Отвечает за оьтобрадение картинки паттернов -->
                  <v-rect
                      :key="`canvas-lithology-rect-${rectId}`"
                      :config="lithologyRects[columnId][rectId].config"
                  />
                </template>
              </template>
            </v-group>

            <template v-for="imageId in Object.keys(depths.points)">
              <template v-for="(point, index) in depths.points[imageId]">
                <v-line
                    :key="`depth-bound-${imageId}-${index}`"
                    :config="point.boundConfig"
                />
                <v-line
                    :key="`depth-pointer-${imageId}-${index}`"
                    :config="point.pointerConfig"
                />
                <v-text
                    v-if="point.visible"
                    :key="`depth-text-${imageId}-${index}`"
                    :config="point.textConfig"
                />
              </template>
            </template>
          </v-group>
        </v-layer>
        <v-layer ref="canvasCursorLayer" :config="{ listening: false }">
          <v-group ref="canvasCursorGroup">
            <v-line :config="cursorPosition.boundConfig"/>
          </v-group>
        </v-layer>
        <v-layer ref="canvasToolLayer">
          <v-group ref="canvasToolGroup">
            <v-line
                v-if="drawing.lineConfig.points"
                :config="drawing.lineConfig"
            />
            <v-rect v-if="drawing.rectConfig.y" :config="drawing.rectConfig"/>

            <v-rect
                v-if="lithologyRegion.rectConfig.x"
                ref="canvasLithologyRegionRect"
                :config="lithologyRegion.rectConfig"
                @click="enableLithologyRegionTransformer"
                @mouseenter="setStageCursor('pointer')"
                @mouseleave="setStageCursor('default')"
                @tap="enableLithologyRegionTransformer"
                @wheel="scrollImage"
            />
            <v-circle
                v-if="lithologyRegion.circleConfig.x"
                :config="lithologyRegion.circleConfig"
                @click="displayLithologyClasses"
                @mouseenter="setStageCursor('pointer')"
                @mouseleave="setStageCursor(getGrabCursor())"
                @tap="displayLithologyClasses"
                @wheel="scrollImage"
            />
            <v-path
                v-if="lithologyRegion.pathConfig.x"
                :config="lithologyRegion.pathConfig"
            />

            <v-transformer
                v-if="lithologyRegion.transformer.enabled"
                ref="canvasLithologyRegionTransformer"
                :config="lithologyRegion.transformer.config"
            />
          </v-group>
        </v-layer>
      </v-stage>

      <classification-models
          v-if="display.models && classificationModels.length > 0"
          :model-id="project.classification_model_id"
          :models="classificationModels"
          @apply-model="applySelectedModel"
      />

      <classification-model-types
          v-if="display.modelTypes"
          :active.sync="display.modelTypes"
          :model="selectedModel"
          @apply-model="saveClassificationModel"
          @cancel-model="cancelSelectedModel"
      />

      <b-overlay :opacity="0.3" :show="display.canvasLoader" no-wrap/>
    </div>

    <image-toolbar
        ref="imageToolbar"
        :editing-tools="toolbarEditingTools"
        :models-enabled="true"
        :redo-enabled="redoEnabled"
        :save-enabled="saveEnabled"
        :undo-enabled="undoEnabled"
        @hand="handImageLocal"
        @redo="redoImage"
        @save="saveImage"
        @undo="undoImage"
        @pointer-border="borderLithologyType"
        @brush-lithology="brushLithologyType"
        @interval-tool="intervalLithologyType"
        @eraser-lithology="eraserLithologyType"
        @zoom-in="zoomImageInLocal"
        @zoom-out="zoomImageOutLocal"
        @display-models="displayModels"
    >
      <template v-slot:external-editing-tools>
        <lithology-types
            v-if="display.projectTypes"
            :active-type.sync="activeLithologyType"
            :model-name="projectModelName"
            :project-id="project.project_id"
            :types="lithologyTypes"
            :used-type-ids="usedLithologyTypeIds"
            @add-type="addLithologyType"
            @swap-type="swapLithologyType"
            @delete-type="deleteLithologyType"
        />
      </template>
    </image-toolbar>

    <dp-decision-dialog
        :active.sync="decisionDialog.active"
        :question="decisionDialog.question"
        @agree="decisionDialog.agree"
        @disagree="decisionDialog.disagree"
    />

    <lithology-download
        :active.sync="downloadDialog.active"
        @download-lithology="downloadLithology"
    />
  </div>
</template>

<script>
import CorePreview from "@/views/components/core-preview";
import ImageToolbar from "@/views/components/image-toolbar";
import LithologyTypes from "@/views/components/lithology/lithology-types";
import ClassificationModels from "@/views/components/lithology/classification-models";
import LookupService from "@/services/lookup-service";
import CoreColumnsMixin from "@/views/mixins/core-columns-mixin";
import ProjectLithologyMixin from "@/views/mixins/project-lithology-mixin";
import ImageScale from "@/views/components/image-scale";
import ProjectService from "@/services/project-service";
import FileService from "@/services/file-service";
import LithologyTypeService from "@/services/lithology-type-service";
import SegmentService from "@/services/segment-service";
import ClassificationModelTypes from "@/views/components/lithology/classification-model-types";
import DpDecisionDialog from "@/components/dp-decision-dialog";
import LithologyDownload from "@/views/components/lithology-download";
import LithologyClasses from "@/views/components/lithology/lithology-classes";
import {v4 as uuidv4} from "uuid";

/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: "project-classification",
  components: {
    LithologyClasses,
    LithologyDownload,
    DpDecisionDialog,
    ClassificationModelTypes,
    ClassificationModels,
    LithologyTypes,
    CorePreview,
    ImageScale,
    ImageToolbar,
  },
  mixins: [CoreColumnsMixin, ProjectLithologyMixin],
  data() {
    return {
      backgroundConfig: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        fill: "#F5F5F5",
        listening: false,
      },
      imageColumns: [],
      lithologyCores: {},
      lithologyRects: {},
      lithologyRectsByType: {},
      deletedLithology: [],
      lithologyRegion: {
        column_id: null,
        column_index: null,
        rect_id: null,
        from_y: 0,
        to_y: 0,
        rectConfig: {},
        circleConfig: {},
        pathConfig: {},
        classes: {
          enabled: false,
          position: {},
          value: [],
        },
        transformer: {
          enabled: false,
          updated: false,
          origRects: [],
          config: {},
        },
      },
      drawing: {
        lineConfig: {},
        rectConfig: {},
      },
      classificationModels: [],
      selectedModel: null,
      lithologyTypes: [],
      deletedLithologyTypes: [],
      activeLithologyType: null,
      display: {
        canvasLoader: false,
        models: false,
        modelTypes: false,
        projectTypes: false,
      },
      editingTools: [
        "pointer-border",
        "brush-lithology",
        "interval-tool",
        "eraser-lithology",
      ],
      decisionDialog: {
        active: false,
        question: null,
        agree: () => {
        },
        disagree: () => {
        },
      },
      downloadDialog: {
        active: false,
      },
      reloadTask: null,
      reloadTimeout: 5000,
      forSave: false,
    };
  },
  computed: {
    coreWidth() {
      if (this.images.length > 0) {
        return this.images[0].config.width;
      } else {
        return 0;
      }
    },
    scaledCoreWidth() {
      return this.coreWidth * this.scale.x;
    },
    lithologyWidth() {
      return this.project.lithology_column_width
          ? this.project.lithology_column_width
          : 300;
    },
    scaledLithologyWidth() {
      return this.lithologyWidth * this.scale.x;
    },
    lithologyEnabled() {
      return Object.keys(this.lithologyRects).length > 0;
    },
    lithologyTypesByType() {
      return this.arrayToMap(this.lithologyTypes, "id");
    },
    usedLithologyTypeIds() {
      return Object.keys(this.lithologyRectsByType);
    },
    invisibleLithologyType() {
      return this.lithologyTypes.find(
          (type) => type.source_type === "INVISIBLE"
      );
    },
    imagePreviewProbabilities() {
      const probabilities = [];
      if (
          this.imagePreview.images &&
          this.imagePreview.images.length > 0 &&
          this.imagePreview.ratioY &&
          Object.keys(this.lithologyRects).length > 0
      ) {
        Object.keys(this.lithologyRects).forEach((columnId) => {
          const imageColumn = this.imageColumns.find(
              (column) => column.column_id === columnId
          );
          const splitsNum = this.getSplitsNumBeforeImage(
              imageColumn.image_ids[0]
          );
          const imageSplitHeight =
              (splitsNum * this.splitHeight) / this.scale.y;
          const previewSplitHeight =
              splitsNum * this.splitHeight * this.getPixelRatio();

          Object.keys(this.lithologyRects[columnId]).forEach((rectId) => {
            const rect = this.lithologyRects[columnId][rectId];
            if (!rect.invisible) {
              probabilities.push({
                from_y:
                    (rect.image_y - imageSplitHeight) *
                    this.scale.y *
                    this.imagePreview.ratioY +
                    previewSplitHeight -
                    this.splitHeight * this.imagePreview.ratioY,
                to_y:
                    (rect.image_y + rect.image_height - imageSplitHeight) *
                    this.scale.y *
                    this.imagePreview.ratioY +
                    previewSplitHeight -
                    this.splitHeight * this.imagePreview.ratioY,
                value: rect.probability,
              });
            }
          });
        });
      }
      return probabilities;
    },
    hasProjectModelId() {
      return !(
          this.project.classification_model_id === null ||
          this.project.classification_model_id === undefined
      );
    },
    projectModelName() {
      const modelId = this.project.classification_model_id;
      if (modelId || modelId === 0) {
        return this.classificationModels.find((m) => m.id === modelId).names[
            this.$i18n.locale.toUpperCase()
            ];
      } else {
        return null;
      }
    },
    toolbarEditingTools() {
      return this.hasProjectModelId ? this.editingTools : [];
    },
  },
  watch: {
    lithologyEnabled(value) {
      if (value) {
        this.enableDownload();
      } else {
        this.disableDownload();
      }
    },
    activeLithologyType(value) {
      if (value && this.lithologyRegion.transformer.enabled) {
        this.updateLithologyRegionType(value);
      }
    },
    "lithologyRegion.transformer.enabled"(value) {
      this.$store.dispatch("setCloseOnClickOutside", !value);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.resizeCanvas();
      window.addEventListener("resize", this.resizeCanvas);

      this.loadClassificationCores().then(() => {
        return this.$nextTick().then(() => {
          this.resizeCanvas();
          return this.loadClassificationResults(true);
        });
      });
    });
  },
  destroyed() {
    this.imageColumns = null;
    this.lithologyCores = null;
    this.lithologyRects = null;
    this.lithologyRectsByType = null;
    this.deletedLithology = null;
    this.lithologyRegion = null;
    this.drawing = null;
    this.classificationModels = null;
    this.selectedModel = null;
    this.lithologyTypes = null;
    this.deletedLithologyTypes = null;
    this.activeLithologyType = null;

    clearTimeout(this.reloadTask);

    window.removeEventListener("resize", this.resizeCanvas);
  },
  methods: {
    closeProject() {
      return this.saveImages(false);
    },
    download() {
      this.disableDownload();
      return this.saveImages().then(() => {
        this.clearHistory();
        this.enableDownload();
        this.downloadDialog.active = true;
        return true;
      });
    },
    enableDownload() {
      this.$emit("enable-download", true);
    },
    disableDownload() {
      this.$emit("enable-download", false);
    },
    downloadLithology(column, legend, registry, templates, generation) {
      let download;
      if (column || generation) {
        if (generation) {
          download = this.generateSegments().then((segmentation) => {
            // console.log("segmentation", segmentation);
            // console.log("segmentation", segmentation.depth);
            return ProjectService.downloadLithology(
                this.project.project_id,
                column,
                legend,
                registry,
                this.$i18n.locale,
                segmentation,
                templates,
                generation,
            );
          });
        } else {
          download = this.saveImageSegments().then((segmentation) => {
            // console.log("segmentation", segmentation);
            // console.log("segmentation", segmentation.depth);
            return ProjectService.downloadLithology(
                this.project.project_id,
                column,
                legend,
                registry,
                this.$i18n.locale,
                segmentation,
                templates,
                generation,
            );
          });
        }
      } else {
        //console.log("segmentation", templates);
        download = ProjectService.downloadLithology(
            this.project.project_id,
            column,
            legend,
            registry,
            this.$i18n.locale,
            null,
            templates,
            generation,
        );
      }

      download.finally(() => {
        this.downloadDialog.active = false;
      });
    },
    saveImageSegments() {
      this.resetLithologyRegion();
      this.resetCursorPosition();
      this.resetDrawing();
      this.scrollImageToTop();

      const segments = {};
      let height = 2 * this.splitHeight;
      this.images.forEach((image) => {
        if (image.with_split) {
          this.$refs[`split-image-line-part-${image.image_id}`][0]
              .getNode()
              .hide();
        }

        height +=
            image.config.height * this.scale.y +
            (image.with_split ? this.splitHeight : 0);
        let offsetY = image.offset * this.scale.y;

        const cores = Object.keys(image.cores)
            .map((coreId) => image.cores[coreId])
            .sort((c1, c2) => c1.top_depth - c2.top_depth);
        cores.forEach((core, coreIndex) => {
          const fileId = core.src_file_id;
          const previousFileDifferent =
              cores[coreIndex - 1] && cores[coreIndex - 1].src_file_id !== fileId;
          const nextFileDifferent =
              cores[coreIndex + 1] && cores[coreIndex + 1].src_file_id !== fileId;

          let y = offsetY;
          let height = core.height * this.scale.y;
          if (coreIndex === 0 || previousFileDifferent) {
            y -= this.splitHeight / 2;
            height += this.splitHeight / 2;
          }
          if (coreIndex === cores.length - 1 || nextFileDifferent) {
            height += this.splitHeight / 2;
          }

          if (!segments[fileId]) {
            segments[fileId] = [];
          }
          segments[fileId].push({y, height, file_id: fileId});

          offsetY += core.height * this.scale.y;
        });
      });

      this.backgroundConfig.x =
          this.canvasConfig.width / 2 - this.scaledCoreWidth - 150;
      this.backgroundConfig.y = 0;
      this.backgroundConfig.width =
          this.scaledLithologyWidth + this.scaledCoreWidth + 300;
      this.backgroundConfig.height = height;

      const uploads = [];
      const correlationId = uuidv4();

      return this.$nextTick().then(() => {
        const segmentation = [];
        Object.keys(segments).forEach((fileId) => {
          const fileSegments = segments[fileId];  // src_file_id
          segmentation.push({
            correlation_id: correlationId,
            file_id: fileId,
            segments: fileSegments.length,
          });
          fileSegments.forEach((segment, index) => {
            const url = this.$refs.canvasImageGroup.getNode().toDataURL({
              mimeType: "image/png",
              pixelRatio: 1 / Math.abs(this.scale.x),
              x: this.backgroundConfig.x,
              y: segment.y,
              width: this.backgroundConfig.width,
              height: segment.height,
            });
            // function sleep(milliseconds) {
            //   const date = Date.now();
            //   let currentDate = null;
            //   do {
            //     currentDate = Date.now();
            //   } while (currentDate - date < milliseconds);
            // }

            uploads.push(
                SegmentService.uploadSegmentDataURL(
                    correlationId,
                    fileId,
                    index,
                    "image/png",
                    url
                )
            );
          });
        });

        return Promise.all(uploads)
            .then(() => segmentation) // this one is sent in downloadLithology request
            .finally(() => {
              this.images.forEach((image) => {
                if (image.with_split) {
                  this.$refs[`split-image-line-part-${image.image_id}`][0]
                      .getNode()
                      .show();
                }
              });

              this.backgroundConfig.x = 0;
              this.backgroundConfig.y = 0;
              this.backgroundConfig.width = 0;
              this.backgroundConfig.height = 0;
            });
      });
    },


    /**
     * @vuese
     * segments sending for generation by dc-service
     */
    generateSegments() {
      const segments = {};
      let height = 2 * this.splitHeight;
      var points_dict = {}
      var fileIds = []
      this.images.forEach((image) => {
        // console.log("image.points", image)
        const points = this.coresToDepthPoints(image)
            .map(item => item).sort(
                (item1, item2) => item1.top_depth - item2.top_depth)
        let offset = 0 // the image is positioned in browser plot in different way
        let iniOffset = 0
        points.forEach((point, pointIndex) => {
          let y = point.y
          /// this one is just copy-paste with changes from cores iteration below
          const previousFileDifferent =
              point[pointIndex - 1] && point[pointIndex - 1].coreId !== point.coreId;
          const nextFileDifferent =
              point[pointIndex + 1] && point[pointIndex + 1].coreId !== point.coreId;
          //
          if (pointIndex === 0 || previousFileDifferent) {
            const yScale = this.scale.y
            y = y * yScale;
            y -= this.splitHeight / 2;
            iniOffset = y
            offset = image.offset - y
          }
          ///

          if (!(point.coreId in points_dict)) {
            points_dict[point.coreId] = {
              "coreId": [],
              "fileId": [],
              "y": [],
              "value": [],
              "height": [],
              "offset": [],
              "ini_offset": [],
            }
          }
          points_dict[point.coreId]["coreId"].push(point.coreId)
          points_dict[point.coreId]["fileId"].push(point.fileId)
          points_dict[point.coreId]["y"].push(y)
          points_dict[point.coreId]["value"].push(point.value)
          points_dict[point.coreId]["offset"].push(offset)
          points_dict[point.coreId]["ini_offset"].push(iniOffset)
        });

        if (image.with_split) {
          this.$refs[`split-image-line-part-${image.image_id}`][0]
              .getNode()
              .hide();
        }

        height +=
            image.config.height * this.scale.y +
            (image.with_split ? this.splitHeight : 0);
        let offsetY = image.offset * this.scale.y;

        const cores = Object.keys(image.cores)
            .map((coreId) => image.cores[coreId])
            .sort((c1, c2) => c1.top_depth - c2.top_depth);
        // console.log("cores", cores)
        cores.forEach((core, coreIndex) => {
          const fileId = core.src_file_id;
          const coreId = core.core_id;
          const coreIdPointsDict = points_dict[coreId]
          const previousFileDifferent =
              cores[coreIndex - 1] && cores[coreIndex - 1].src_file_id !== fileId;
          const nextFileDifferent =
              cores[coreIndex + 1] && cores[coreIndex + 1].src_file_id !== fileId;

          let y = offsetY;
          let height = core.height * this.scale.y;
          if (coreIndex === 0 || previousFileDifferent) {
            y -= this.splitHeight / 2;
            height += this.splitHeight / 2;
          }
          if (coreIndex === cores.length - 1 || nextFileDifferent) {
            height += this.splitHeight / 2;
          }

          if (!("height" in coreIdPointsDict) || !("core_height" in coreIdPointsDict)) {
            coreIdPointsDict["height"] = []
            coreIdPointsDict["core_height"] = []
            coreIdPointsDict["split_height"] = []
          }
          coreIdPointsDict['height'].push(height)
          coreIdPointsDict['core_height'].push(core.height)
          coreIdPointsDict['split_height'].push(this.splitHeight / 2)
          if (!segments[fileId]) {
            segments[fileId] = [];
            fileIds.push(fileId)
          }
          segments[fileId].push({y, height, file_id: fileId, coreIdPointsDict});
          offsetY += core.height * this.scale.y;
        });
      }); // end for each image

      const correlationId = uuidv4();
      // const sleep = ms => new Promise(r => setTimeout(r, ms));

      // async function runPromisesInSequence(correlationId,
      // fileId,
      // idx,
      // fileSegments) {
      //   return await SegmentService.generateSegment(
      //         correlationId,
      //         fileId,
      //         idx,
      //         fileSegments
      //     );
      // }
      // getAllFiles().then( (files) => {
      //   return files.reduce((p, theFile) => {
      //     return p.then(() => {
      //       return transferFile(theFile); //function returns a promise
      //     });
      //   }, Promise.resolve()).then(()=>{
      //     console.log("All files transferred");
      //   });
      // }).catch((error)=>{
      //   console.log(error);
      // });

      const segmentation = [];
      const segments_list = [];
      let file_id = ""
      return this.$nextTick().then( () => {
        return fileIds.reduce((p, fileId) => { // this will do a concurrent job
          return p.then(() => {
            const fileSegments = segments[fileId];  // src_file_id
            let prevFileId = ''
            let counter = 0
            let segm_file_id = ''
            fileSegments.forEach((file, index) => {
              segm_file_id = file.coreIdPointsDict.fileId[0]
              if (segm_file_id !== prevFileId) {
                counter += 1
              }
              prevFileId = segm_file_id
            })
            const segmentationData = {
              correlation_id: correlationId,
              file_id: fileId,
              segments: counter //fileSegments.length,
            }
            segmentation.push(segmentationData);
            let idx = 0
            if (file_id === fileId) {
              idx = index
            }
            const segmentData = SegmentService.generateSegment(
                correlationId,
                fileId,
                idx,
                fileSegments
            )
            segments_list.push(segmentData)
            return segmentData; //function returns a promise
          });
        }, Promise.resolve())
      }).then(()=>{
        return Promise.all(segments_list)
            .then(() => segmentation)  // this one is sent in downloadLithology request
            .finally(() => {
              this.images.forEach((image) => {
                if (image.with_split) {
                  this.$refs[`split-image-line-part-${image.image_id}`][0]
                      .getNode()
                      .show();
                }
              });

              this.backgroundConfig.x = 0;
              this.backgroundConfig.y = 0;
              this.backgroundConfig.width = 0;
              this.backgroundConfig.height = 0;
            });
      }).catch((error)=>{
        console.log(error);
      });
      // then(() => {
      //   const segmentation = [];
      //   const segments_list = [];
      //   let file_id = ""
      //   Object.keys(segments).forEach((fileId, index) => {
      //     const fileSegments = segments[fileId];  // src_file_id
      //     let prevFileId = ''
      //     let counter = 0
      //     let segm_file_id = ''
      //     fileSegments.forEach((file, index) => {
      //       segm_file_id = file.coreIdPointsDict.fileId[0]
      //       if (segm_file_id !== prevFileId) {
      //         counter += 1
      //       }
      //       prevFileId = segm_file_id
      //     })
      //     const segmentationData = {
      //       correlation_id: correlationId,
      //       file_id: fileId,
      //       segments: counter //fileSegments.length,
      //     }
      //     segmentation.push(segmentationData);
      //     let idx = 0
      //     if (file_id === fileId) {
      //       idx = index
      //     }
      //     const segmentData = runPromisesInSequence(correlationId,
      //         fileId,
      //         idx,
      //         fileSegments)
      //     segments_list.push(segmentData)
      //   });
      //
      //   //
      //   // runPromisesInSequence(segments_list)
      //
      //   return Promise.all(segments_list)
      //       .then(() => segmentation)  // this one is sent in downloadLithology request
      //       .finally(() => {
      //         this.images.forEach((image) => {
      //           if (image.with_split) {
      //             this.$refs[`split-image-line-part-${image.image_id}`][0]
      //                 .getNode()
      //                 .show();
      //           }
      //         });
      //
      //         this.backgroundConfig.x = 0;
      //         this.backgroundConfig.y = 0;
      //         this.backgroundConfig.width = 0;
      //         this.backgroundConfig.height = 0;
      //       });
      // });
    },

    saveImages(reloadLithology = true) {
      if (!this.needSaveImage()) {
        return Promise.resolve(false);
      }

      const projectId = this.project.project_id;
      const lithologyTypesPromises = [];
      const lithologyElements = [];

      lithologyTypesPromises.push(
          ...this.deletedLithologyTypes.map((type) =>
              LithologyTypeService.deleteLithologyType(type.id)
          )
      );
      // console.log(this.lithologyTypes);
      this.lithologyTypes.forEach((type) => {
        let promise;
        if (type.created) {
          promise = LithologyTypeService.addLithologyType(
              projectId,
              type.id,
              type.custom_name,
              type.color,
              type.pattern
          );
        } else if (type.updated) {
          promise = LithologyTypeService.swapLithologyType(
              type.id,
              type.custom_name,
              type.color,
              type.pattern
          );
        }
        if (promise) {
          lithologyTypesPromises.push(promise);
        }
      });

      this.deletedLithology.forEach((id) => {
        if (id) {
          lithologyElements.push({
            lithology_id: id,
            custom_probability: -1,
          });
        }
      });

      Object.keys(this.lithologyRects).forEach((columnId) => {
        Object.keys(this.lithologyRects[columnId]).forEach((rectId) => {
          const rect = this.lithologyRects[columnId][rectId];
          if (rect.created) {
            lithologyElements.push({
              file_id: rect.file_id,
              from_y: Math.round(rect.from_y),
              to_y: Math.round(rect.to_y),
              type_id: rect.type_id,
              custom_probability: 1,
            });
          } else if (rect.updated) {
            lithologyElements.push({
              lithology_id: rect.lithology_id,
              file_id: rect.file_id,
              from_y: Math.round(rect.from_y),
              to_y: Math.round(rect.to_y),
              type_id: rect.type_id,
              custom_probability: rect.probability === 1 ? 1 : 0,
            });
          }
        });
      });

      return Promise.all(lithologyTypesPromises)
          .then(() => {
            return ProjectService.saveLithologyElements(
                projectId,
                lithologyElements
            );
          })
          .then((result) => {
            const lithology = result.data;
            if (reloadLithology) {
              this.$set(this, "deletedLithologyTypes", []);
              this.$set(this, "deletedLithology", []);

              this.display.canvasLoader = true;
              this.$refs.imageToolbar.disable();
              return this.loadLithologyTypes()
                  .then(() => this.loadLithologyCanvasRects(lithology))
                  .finally(() => {
                    this.display.canvasLoader = false;
                    this.$refs.imageToolbar.enable();
                    return true;
                  });
            } else {
              return true;
            }
          });
    },
    saveImagesFromTool(reloadLithology = true) {
      this.forSave = true;
      if (!this.needSaveImage()) {
        return Promise.resolve(false);
      }

      const projectId = this.project.project_id;
      const lithologyTypesPromises = [];
      const lithologyElements = [];

      lithologyTypesPromises.push(
          ...this.deletedLithologyTypes.map((type) =>
              LithologyTypeService.deleteLithologyType(type.id)
          )
      );
      // console.log(this.lithologyTypes);
      this.lithologyTypes.forEach((type) => {
        let promise;
        if (type.created) {
          promise = LithologyTypeService.addLithologyType(
              projectId,
              type.id,
              type.custom_name,
              type.color,
              type.pattern
          );
        } else if (type.updated) {
          promise = LithologyTypeService.swapLithologyType(
              type.id,
              type.custom_name,
              type.color,
              type.pattern
          );
        }
        if (promise) {
          lithologyTypesPromises.push(promise);
        }
      });

      this.deletedLithology.forEach((id) => {
        if (id) {
          lithologyElements.push({
            lithology_id: id,
            custom_probability: -1,
          });
        }
      });

      Object.keys(this.lithologyRects).forEach((columnId) => {
        Object.keys(this.lithologyRects[columnId]).forEach((rectId) => {
          const rect = this.lithologyRects[columnId][rectId];
          if (rect.created) {
            lithologyElements.push({
              file_id: rect.file_id,
              from_y: Math.round(rect.from_y),
              to_y: Math.round(rect.to_y),
              type_id: rect.type_id,
              custom_probability: 1,
            });
          } else if (rect.updated) {
            lithologyElements.push({
              lithology_id: rect.lithology_id,
              file_id: rect.file_id,
              from_y: Math.round(rect.from_y),
              to_y: Math.round(rect.to_y),
              type_id: rect.type_id,
              custom_probability: rect.probability === 1 ? 1 : 0,
            });
          }
        });
      });

      return Promise.all(lithologyTypesPromises)
          .then(() => {
            return ProjectService.saveLithologyElements(
                projectId,
                lithologyElements
            );
          })
          .then((result) => {
            const lithology = result.data;
            if (reloadLithology) {
              this.$set(this, "deletedLithologyTypes", []);
              this.$set(this, "deletedLithology", []);

              this.display.canvasLoader = true;
              this.$refs.imageToolbar.disable();
              return this.loadLithologyTypes()
                  .then(() => this.loadLithologyCanvasRects(lithology))
                  .finally(() => {
                    this.display.canvasLoader = false;
                    this.$refs.imageToolbar.enable();
                    return true;
                  });
            } else {
              return true;
            }
          });
    },
    displayModels(display) {
      this.display.models = display;
    },
    displayToolbarModels(display) {
      this.$refs.imageToolbar.initModels(display);
    },
    applySelectedModel(model) {
      this.selectedModel = model;
      if (this.selectedModel && this.hasProjectModelId) {
        this.decisionDialog.question = this.$t("questions.apply-new-model");
        this.decisionDialog.agree = this.agreeApplySelectedModel;
        this.decisionDialog.disagree = this.disagreeApplySelectedModel;
        this.decisionDialog.active = true;
      } else if (this.selectedModel) {
        this.agreeApplySelectedModel();
      }
    },
    agreeApplySelectedModel() {
      this.display.modelTypes = true;
    },
    disagreeApplySelectedModel() {
      this.cancelSelectedModel();
    },
    cancelSelectedModel() {
      this.selectedModel = null;
    },
    saveClassificationModel(modelId, types, changedTypes) {
      this.selectedModel = null;
      ProjectService.saveClassificationModel(
          this.project.project_id,
          modelId,
          types,
          changedTypes
      ).then((result) => {
        this.$emit("update:project", result.data);
        this.setupLithology();
        this.reloadPreview();
        this.showWMessage(this.$t("messages.classification-started"));
        this.$nextTick(() => this.loadClassificationResults());
      });
    },
    loadClassificationModels() {
      return LookupService.classificationModels().then((result) => {
        this.$set(this, "classificationModels", result.data);
        return true;
      });
    },
    loadClassificationResults(needUserMessage = false) {
      if (!this.hasProjectModelId) {
        this.displayToolbarModels(true);
        return Promise.resolve(false);
      }

      this.display.canvasLoader = true;
      this.$refs.imageToolbar.disable();
      return ProjectService.isClassificationReady(this.project.project_id)
          .then((result) => {
            if (result.data.ready) {
              this.reloadTask = null;
              return this.loadLithologyTypes()
                  .then(() => {
                    return this.loadLithologyRects();
                  })
                  .then(() => {
                    this.display.canvasLoader = false;
                    this.$refs.imageToolbar.enable();
                    this.displayToolbarModels(false);
                    return true;
                  });
            } else {
              if (needUserMessage) {
                this.showWMessage(this.$t("messages.classification-processing"));
              }
              this.displayToolbarModels(true);
              this.reloadTask = setTimeout(() => {
                this.loadClassificationResults();
              }, this.reloadTimeout);
              return false;
            }
          })
          .catch(() => {
            this.display.canvasLoader = false;
            this.$refs.imageToolbar.enable();
            return false;
          });
    },
    setupLithology() {
      this.resetLithologyRegion();

      this.$set(this, "lithologyCores", {});
      this.$set(this, "lithologyTypes", []);
      this.$set(this, "deletedLithologyTypes", []);
      this.$set(this, "lithologyRects", {});
      this.$set(this, "lithologyRectsByType", {});
      this.$set(this, "deletedLithology", []);

      let currentY = this.splitHeight;
      this.images.forEach((image, index) => {
        image.first_rect_id = null;
        image.config.x = this.canvasConfig.width / 2;
        image.config.offset.x = image.config.width / 2;
        image.config.offsetX = image.config.width / 2;

        if (image.with_split) {
          image.splitLineConfig = this.createSplitLineConfig(
              image.config,
              currentY
          );
          image.splitRectConfig = this.createSplitRectConfig(
              image.config,
              currentY
          );
        }

        this.$set(this.images, index, image);
        this.$set(
            this.depths.points,
            image.image_id,
            this.recalculateDepthPointConfigs(
                this.depths.points[image.image_id],
                image.config
            )
        );

        currentY = this.nextImageY(image.config, currentY, image.with_split);
      });
    },
    loadLithologyTypes() {
      return ProjectService.lithologyTypes(this.project.project_id).then(
          (result) => {
            this.$set(this, "lithologyTypes", result.data);
            return true;
          }
      );
    },
    addLithologyType(type) {
      type.created = true;
      this.lithologyTypes.push(type);
      this.saveHistory(this.getHistoryImageData());
    },
    swapLithologyType(type) {
      const index = this.lithologyTypes.findIndex((t) => t.id === type.id);
      if (index !== -1) {
        const previousType = this.lithologyTypes[index];
        const rectsMustBeChanged =
            previousType.color !== type.color ||
            previousType.pattern !== type.pattern;

        type.updated = true;
        this.$set(this.lithologyTypes, index, type);

        if (
            rectsMustBeChanged &&
            this.lithologyRectsByType[type.id] &&
            Object.keys(this.lithologyRectsByType[type.id]).length > 0
        ) {
          this.getLithologyFillPattern(this.project.project_id, type).then(
              (image) => {
                Object.keys(this.lithologyRectsByType[type.id]).forEach(
                    (columnId) => {
                      const rectIds = this.lithologyRectsByType[type.id][columnId];
                      rectIds.forEach((rectId) => {
                        const rect = this.lithologyRects[columnId][rectId];
                        rect.config.fillPatternImage = image;
                      });
                      this.saveHistory(this.getHistoryImageData());
                      this.reloadPreview();
                    }
                );
              }
          );
        } else {
          this.saveHistory(this.getHistoryImageData());
        }
      }
    },
    deleteLithologyType(type) {
      this.$set(
          this,
          "lithologyTypes",
          this.lithologyTypes.filter((t) => t.id !== type.id)
      );
      if (!type.created) {
        this.$set(
            this.deletedLithologyTypes,
            this.deletedLithologyTypes.length,
            type
        );
      }

      if (
          this.lithologyRectsByType[type.id] &&
          Object.keys(this.lithologyRectsByType[type.id]).length > 0
      ) {
        Object.keys(this.lithologyRectsByType[type.id]).forEach((columnId) => {
          const rectIds = this.lithologyRectsByType[type.id][columnId];
          rectIds.forEach((rectId) => {
            const rect = this.lithologyRects[columnId][rectId];
            rect.updated = true;
            rect.type_id = this.invisibleLithologyType.id;
            rect.invisible = true;
            rect.config.fillPatternImage = null;
            rect.config.fill = this.invisibleLithologyType.color;
            rect.classes = [
              {
                lithology_class_id: null,
                class_id: null,
                type_id: this.invisibleLithologyType.id,
                probability: 0,
                custom_probability: 1,
                selected: true,
              },
            ];
          });
          this.$delete(this.lithologyRectsByType[type.id], columnId);
        });
        this.$delete(this.lithologyRectsByType, type.id);
        this.reloadPreview();
      }
      this.saveHistory(this.getHistoryImageData());
    },
    loadClassificationCores() {
      const projectId = this.project.project_id;
      return ProjectService.isPreClassificationReady(projectId)
          .then((result) => {
            if (result.data.ready) {
              this.reloadTask = null;
              return Promise.all([
                ProjectService.coreColumns(projectId),
                ProjectService.coreColumnSplits(projectId),
              ]).then((results) => {
                if (results[0].data && results[0].data.length > 0) {
                  this.$set(
                      this,
                      "coreColumns",
                      results[0].data.sort((i1, i2) => i1.top_depth - i2.top_depth)
                  );
                  if (results[1].data && results[1].data.length > 0) {
                    this.$set(
                        this,
                        "coreColumnSplits",
                        this.arrayToMapOfArrays(results[1].data, "file_id")
                    );
                  }
                  return this.loadCanvasImages();
                } else {
                  this.showEMessage(this.$t("messages.no-core-columns"));
                  return false;
                }
              });
            } else {
              this.reloadTask = setTimeout(() => {
                this.loadClassificationCores();
              }, this.reloadTimeout);
              return false;
            }
          })
          .then((result) => {
            return result ? this.loadClassificationModels() : result;
          });
    },
    loadCanvasImages() {
      this.beforeImagesLoading();
      this.setupImageColumns();

      const coreColumnData = this.coreColumns.map((coreColumn) => {
        const fileId = coreColumn.file_id;
        return Promise.all([
          FileService.file(fileId),
          FileService.extendedCores(fileId),
        ]).then((results) => {
          return {
            fileId: fileId,
            coreColumn: coreColumn,
            file: results[0].data,
            cores: results[1].data,
          };
        });
      });
      return Promise.all(coreColumnData).then((results) => {
        const loadedImages = results.map((result) => {
          const htmlImage = new Image();
          htmlImage.src = URL.createObjectURL(result.file);
          return new Promise((resolve) => {
            htmlImage.onload = () => {
              URL.revokeObjectURL(htmlImage.src);
              resolve({
                fileId: result.fileId,
                coreColumn: result.coreColumn,
                image: htmlImage,
                cores: result.cores,
              });
            };
            htmlImage.onerror = () => {
              URL.revokeObjectURL(htmlImage.src);
              resolve(null);
            };
          });
        });
        return this.loadImagesWithCores(loadedImages).then(() => {
          this.loadImageColumns();
          this.afterImagesLoading();
          return true;
        });
      });
    },
    setupImageColumns() {
      this.$set(this, "imageColumns", []);
    },
    loadImageColumns() {
      const columns = [];
      let column = null;
      this.images.forEach((image) => {
        if (image.first_part) {
          column = {
            column_id: uuidv4(),
            image_ids: [image.image_id],
            file_ids: [image.file_id],
            offset: image.offset,
            cropY: image.config.cropY,
            height: image.config.height,
            first_rect_id: null,
          };
        } else {
          column.image_ids.push(image.image_id);
          if (!column.file_ids.includes(image.file_id)) {
            column.file_ids.push(image.file_id);
          }
          column.height += image.config.height;
        }

        if (image.last_part) {
          columns.push(column);
        }
      });
      this.$set(this, "imageColumns", columns);
    },
    loadLithologyRects() {
      const projectId = this.project.project_id;
      return Promise.all([
        ProjectService.lithologyElements(projectId),
        ProjectService.cores(projectId),
      ]).then((results) => {
        const elementsLoaded = results[0].data && results[0].data.length > 0;
        const coresLoaded = results[1].data && results[1].data.length > 0;
        if (elementsLoaded && coresLoaded) {
          this.$set(
              this,
              "lithologyCores",
              this.arrayToMapOfArrays(results[1].data, "src_file_id")
          );
          return this.loadLithologyCanvasRects(results[0].data);
        } else {
          this.showEMessage(this.$t("messages.no-core-columns"));
          return false;
        }
      });
    },
    loadLithologyCanvasRects(lithology) {
      this.clearHistory();
      this.$refs.imageToolbar.disable();

      const lithologyByFile = this.arrayToMapOfArrays(lithology, "file_id");

      const lithologyRects = {};
      const lithologyRectsByType = {};
      const patternPromises = [];
      const clonedImages = [];
      const clonedImageDepthPoints = {};
      this.imageColumns.forEach((column) => {
        let elementOffset = 0;
        let processedCoresHeight = 0;
        let previousRectId = null;
        let nextRectId = uuidv4();
        const firstRectId = nextRectId;
        column.file_ids.forEach((fileId) => {
          const fileCores = this.lithologyCores[fileId].sort(
              (c1, c2) => c1.top_depth - c2.top_depth
          );
          for (let fCI = 0; fCI < fileCores.length; fCI++) {
            const core = fileCores[fCI];
            if (column.cropY > processedCoresHeight) {
              processedCoresHeight += core.height;
              elementOffset += core.height;
              continue;
            }
            if (column.cropY + column.height <= processedCoresHeight) {
              break;
            }
            processedCoresHeight += core.height;

            const elements = lithologyByFile[core.file_id].sort(
                (el1, el2) => el1.from_y - el2.from_y
            );
            elements.forEach((el) => {
              const y =
                  column.offset + elementOffset + el.from_y - column.cropY;
              const height = el.to_y - el.from_y;

              const activeClass = el.classes.find((cl) => cl.selected);
              //TODO NEW CLASS NOT FOUND!!!!!!
              const invisible =
                  activeClass.type_id === this.invisibleLithologyType.id;
              const type = this.lithologyTypesByType[activeClass.type_id];
              const lithologyRect = {
                rect_id: nextRectId,
                previous_rect_id: previousRectId,
                next_rect_id: uuidv4(),
                column_id: column.column_id,
                lithology_id: el.lithology_id,
                file_id: el.file_id,
                type_id: activeClass.type_id,
                invisible: invisible,
                probability: Math.max(
                    activeClass.probability,
                    activeClass.custom_probability
                ),
                from_y: el.from_y,
                to_y: el.to_y,
                image_y: y,
                image_height: height,
                classes: el.classes,
                offset: elementOffset,
                config: {
                  x: this.canvasConfig.width / 2,
                  y: y * this.scale.y,
                  width: this.lithologyWidth,
                  height: height,
                  scaleX: this.scale.x,
                  scaleY: this.scale.y,
                  fill: invisible ? type.color : null,
                  fillPatternImage: null,
                },
              };
              previousRectId = nextRectId;
              nextRectId = lithologyRect.next_rect_id;
              this.addLithologyRect(
                  lithologyRects,
                  lithologyRect.column_id,
                  lithologyRect
              );
              this.addLithologyRectByType(
                  lithologyRectsByType,
                  activeClass.type_id,
                  lithologyRect.column_id,
                  lithologyRect.rect_id
              );

              let promise = Promise.resolve(true);
              if (!invisible) {
                promise = this.getLithologyFillPattern(
                    this.project.project_id,
                    type
                ).then((result) => {
                  lithologyRect.config.fillPatternImage = result;
                  return true;
                });
              }
              patternPromises.push(promise);
            });
            elementOffset += elements[elements.length - 1].to_y;
          }
        });
        // last rect for column
        lithologyRects[column.column_id][previousRectId].next_rect_id = null;

        column.first_rect_id = firstRectId;
      });

      let currentY = this.splitHeight;
      this.images.forEach((image) => {
        const clonedImage = this.clone(image);
        clonedImage.config.image = image.config.image;
        clonedImage.config.x =
            this.canvasConfig.width / 2 -
            (clonedImage.config.width / 2) * this.scale.x;
        clonedImage.config.offset.x = clonedImage.config.width / 2;
        clonedImage.config.offsetX = clonedImage.config.width / 2;

        if (image.with_split) {
          const width = this.scaledCoreWidth + this.scaledLithologyWidth;
          clonedImage.splitLineConfig = this.createSplitLineConfig(
              clonedImage.config,
              currentY,
              width
          );
          clonedImage.splitRectConfig = this.createSplitRectConfig(
              clonedImage.config,
              currentY,
              width
          );
        }

        clonedImages.push(clonedImage);
        clonedImageDepthPoints[clonedImage.image_id] =
            this.recalculateDepthPointConfigs(
                this.clone(this.depths.points[clonedImage.image_id]),
                clonedImage.config,
                this.scaledLithologyWidth
            );

        currentY = this.nextImageY(image.config, currentY, image.with_split);
      });

      return Promise.all(patternPromises).then(() => {
        this.$set(this, "images", clonedImages);
        this.$set(this.depths, "points", clonedImageDepthPoints);
        this.$set(this, "lithologyRects", lithologyRects);
        this.$set(this, "lithologyRectsByType", lithologyRectsByType);
        // console.log(this.lithologyRects);
        return this.$nextTick().then(() => {
          this.$refs.imageToolbar.setup();
          if (this.forSave === false) {
            this.repositioningExtraLayers();
            this.setupHistory(this.getHistoryImageData());
            this.reloadPreview();
            this.scrollImageToTop();
          }
          if (this.forSave === true) {
            {
              this.repositioningExtraLayers();
              this.setupHistory(this.getHistoryImageData());
              this.reloadPreview();
              this.forSave = false;
            }
          }
          return true;
        });
      });
    },
    addLithologyRect(lithologyRects, columnId, rect) {
      if (!lithologyRects[columnId]) {
        lithologyRects[columnId] = {};
      }
      lithologyRects[columnId][rect.rect_id] = rect;
    },
    addLithologyRectByType(lithologyRectsByType, typeId, columnId, rectId) {
      if (!lithologyRectsByType[typeId]) {
        lithologyRectsByType[typeId] = {};
      }
      if (!lithologyRectsByType[typeId][columnId]) {
        lithologyRectsByType[typeId][columnId] = [];
      }
      lithologyRectsByType[typeId][columnId].push(rectId);
    },
    addDataLithologyRectByType(typeId, columnId, rectId) {
      if (!this.lithologyRectsByType[typeId]) {
        this.$set(this.lithologyRectsByType, typeId, {});
      }
      if (!this.lithologyRectsByType[typeId][columnId]) {
        this.$set(this.lithologyRectsByType[typeId], columnId, []);
      }
      this.lithologyRectsByType[typeId][columnId].push(rectId);
    },
    repositioningImages() {
      let currentY = this.splitHeight;
      let currentOffset = this.splitHeight / this.scale.y;
      let imageColumn = null;
      let imageColumnIndex = 0;
      this.images.forEach((image) => {
        if (image.config) {
          image.config.scaleX = this.scale.x;
          image.config.scaleY = this.scale.y;

          image.config.x = this.canvasConfig.width / 2;
          image.config.y = this.canvasConfig.height / 2 + currentY;
          // need set offset.y and offsetY for correct depth positioning - may be bug in konva
          image.config.offset.y =
              this.canvasConfig.height / 2 / image.config.scaleY;
          image.config.offsetY = image.config.offset.y;
          image.offset = currentOffset;

          if (image.first_part) {
            imageColumn = this.imageColumns[imageColumnIndex++];
            imageColumn.offset = image.offset;
          }

          if (this.lithologyEnabled) {
            image.config.x =
                this.canvasConfig.width / 2 -
                (image.config.width / 2) * this.scale.x;
            image.config.offset.x = image.config.width / 2;
            image.config.offsetX = image.config.width / 2;

            if (image.last_part) {
              const columnId = imageColumn.column_id;
              const columnLithologyRects = this.lithologyRects[columnId];
              Object.keys(columnLithologyRects).forEach((rectId) => {
                const rect = this.lithologyRects[columnId][rectId];
                rect.image_y =
                    imageColumn.offset +
                    rect.offset +
                    rect.from_y -
                    imageColumn.cropY;
                rect.config.x = this.canvasConfig.width / 2;
                rect.config.y = rect.image_y * this.scale.y;
                rect.config.scaleX = this.scale.x;
                rect.config.scaleY = this.scale.y;
              });
            }
          }

          if (image.with_split) {
            const width = this.lithologyEnabled
                ? this.scaledCoreWidth + this.scaledLithologyWidth
                : this.scaledCoreWidth;
            image.splitLineConfig = this.createSplitLineConfig(
                image.config,
                currentY,
                width
            );
            image.splitRectConfig = this.createSplitRectConfig(
                image.config,
                currentY,
                width
            );
          }

          const extraWidth = this.lithologyEnabled
              ? this.scaledLithologyWidth
              : 0;
          this.$set(
              this.depths.points,
              image.image_id,
              this.coresToDepthPoints(image, extraWidth)
          );

          currentY = this.nextImageY(image.config, currentY, image.with_split);
          currentOffset = this.nextImageOffset(
              image.config,
              currentOffset,
              image.with_split
          );
        }
      });
      this.resetLithologyRegion();
    },
    repositioningExtraLayers() {
      this.$nextTick(() => {
        if (this.getCanvasImage()) {
          const y = this.getCanvasImage().y();

          this.$refs.canvasCursorGroup.getNode().y(y);
          this.$refs.canvasCursorLayer.getNode().batchDraw();

          this.$refs.canvasToolGroup.getNode().y(y);
          this.$refs.canvasToolLayer.getNode().batchDraw();
        }
      });
    },
    getHistoryImageData() {
      return {
        scale: this.clone(this.scale),
        images: this.clone(this.images), // don't save html image, we don't edit it
        imageColumns: this.clone(this.imageColumns),
        points: this.clone(this.depths.points),
        lithologyTypes: this.clone(this.lithologyTypes),
        deletedLithologyTypes: this.clone(this.deletedLithologyTypes),
        lithologyRects: this.clone(this.lithologyRects),
        lithologyRectsByType: this.clone(this.lithologyRectsByType),
        deletedLithology: this.clone(this.deletedLithology),
      };
    },
    loadHistoryImageData(imageData) {
      const clonedImages = this.clone(imageData.images);
      clonedImages.forEach((image, index) => {
        // restore html image from current state
        image.config.image = this.images[index].config.image;
      });

      this.$set(this, "scale", this.clone(imageData.scale));
      this.$set(this, "images", clonedImages);
      this.$set(this, "imageColumns", this.clone(imageData.imageColumns));
      this.$set(this, "lithologyTypes", this.clone(imageData.lithologyTypes));
      this.$set(
          this,
          "deletedLithologyTypes",
          this.clone(imageData.deletedLithologyTypes)
      );
      if (
          this.activeLithologyType &&
          !this.lithologyTypesByType[this.activeLithologyType.id]
      ) {
        this.$set(this, "activeLithologyType", null);
      }

      this.resetLithologyRegion();

      const promises = [];
      const clonedLithologyRects = this.clone(imageData.lithologyRects);
      Object.keys(clonedLithologyRects).forEach((columnId) => {
        Object.keys(clonedLithologyRects[columnId]).forEach((rectId) => {
          const rect = clonedLithologyRects[columnId][rectId];
          if (rect.invisible) {
            promises.push(Promise.resolve(true));
          } else {
            const type = this.lithologyTypesByType[rect.type_id];
            const promise = this.getLithologyFillPattern(
                this.project.project_id,
                type
            ).then((result) => {
              rect.config.fillPatternImage = result;
              return true;
            });
            promises.push(promise);
          }
        });
      });
      return Promise.all(promises)
          .then(() => {
            this.$set(this, "lithologyRects", clonedLithologyRects);
            this.$set(
                this,
                "lithologyRectsByType",
                this.clone(imageData.lithologyRectsByType)
            );
            this.$set(
                this,
                "deletedLithology",
                this.clone(imageData.deletedLithology)
            );
            this.$set(this.depths, "points", this.clone(imageData.points));
            return this.$nextTick();
          })
          .then(() => {
            const imageClientRect = this.getCanvasImage().getClientRect();
            let newY = imageClientRect.y;
            if (newY > 0) {
              newY = 0;
            }
            if (
                newY + imageClientRect.height <
                this.canvasConfig.height - this.splitHeight
            ) {
              newY =
                  this.canvasConfig.height -
                  this.splitHeight -
                  imageClientRect.height;
            }
            if (newY !== imageClientRect.y) {
              this.getCanvasImage().y(newY);
            }
            return true;
          });
    },
    needEditableDepthPoint() {
      return false;
    },
    handImageLocal() {
      this.turnOffImageTools();
      this.handImage();
      this.turnOnImageScrolling();
      this.turnOnSingleLithologyRegion();
    },
    zoomImageInLocal() {
      this.turnOffImageTools();
      this.zoomImageIn();
    },
    zoomImageOutLocal() {
      this.turnOffImageTools();
      this.zoomImageOut();
    },
    borderLithologyType() {
      this.turnOffImageTools();
      this.resetCanvasImage();
      // this.turnOnDrawingTool("brush");
      this.turnOnImageScrolling();
      this.turnOnComplexLithologyRegion("brush");
      // this.turnOnLithologyTypes();
    },
    brushLithologyType() {
      this.turnOffImageTools();
      this.resetCanvasImage();
      this.turnOnDrawingTool("brush");
      this.turnOnCursorLine("#E4312C", true, true, false, false);
      this.turnOnImageScrolling();
      this.turnOnLithologyTypes();
    },
    intervalLithologyType() {
      this.turnOffImageTools();
      this.resetCanvasImage();
      this.turnOnIntervalTool();
      this.turnOnCursorLine("#E4312C", true, true, false, false);
      this.turnOnImageScrolling();
      this.turnOnLithologyTypes();
    },
    eraserLithologyType() {
      this.turnOffImageTools();
      this.resetCanvasImage();
      this.turnOnDrawingTool("eraser");
      this.turnOnCursorLine("#E4312C", true, true, false, false);
      this.turnOnImageScrolling();
    },
    onDragMoveImage() {
      this.hideLithologyClasses();
      this.repositioningExtraLayers();
    },
    onImageScrolling() {
      this.hideLithologyClasses();
      this.disableLithologyRegionTransformer();
    },
    onCanvasStageClick() {
      this.hideLithologyClasses();
      this.disableLithologyRegionTransformer();
    },
    turnOffImageTools() {
      this.turnOffCursorLine();
      this.turnOffLithologyRegion();
      this.turnOffLithologyTypes();
      this.turnOffDrawing();
    },
    turnOnLithologyTypes(type = null) {
      this.activeLithologyType = type;
      this.display.projectTypes = true;
    },
    turnOffLithologyTypes() {
      this.activeLithologyType = null;
      this.display.projectTypes = false;
    },
    turnOnSingleLithologyRegion() {
      if (!this.lithologyEnabled) {
        return;
      }
      this.$refs.canvasImage.getNode().on("touchmove mousemove", () => {
        const position = this.$refs.canvasStage.getNode().getPointerPosition();
        const imageRect = this.$refs.canvasImage.getNode().getClientRect();

        const y = position.y - imageRect.y + this.splitHeight;
        const cursorImageY = y / this.scale.y;
        if (
            this.lithologyRegion.from_y <= cursorImageY &&
            cursorImageY <= this.lithologyRegion.to_y
        ) {
          return;
        }

        this.resetLithologyRegion();

        const imageColumnIndex = this.imageColumns.findIndex(
            (column) =>
                column.height + column.offset >= cursorImageY &&
                cursorImageY >= column.offset
        );

        if (imageColumnIndex === -1) {
          return;
        }
        const imageColumn = this.imageColumns[imageColumnIndex];

        const rectId = Object.keys(
            this.lithologyRects[imageColumn.column_id]
        ).find((rectId) => {
          const r = this.lithologyRects[imageColumn.column_id][rectId];
          return (
              r.image_y <= cursorImageY &&
              cursorImageY <= r.image_y + r.image_height
          );
        });
        const rect = this.lithologyRects[imageColumn.column_id][rectId];
        if (!rect || rect.invisible) {
          return;
        }

        this.$set(this.lithologyRegion, "column_id", imageColumn.column_id);
        this.$set(this.lithologyRegion, "column_index", imageColumnIndex);
        this.$set(this.lithologyRegion, "rect_id", rect.rect_id);
        this.$set(this.lithologyRegion, "from_y", rect.image_y);
        this.$set(
            this.lithologyRegion,
            "to_y",
            rect.image_y + rect.image_height
        );
        this.$set(this.lithologyRegion.classes, "value", rect.classes);
        this.$set(this.lithologyRegion, "rectConfig", {
          x: rect.config.x - 4,
          y: rect.config.y - 4,
          width: rect.config.width * this.scale.x + 8,
          height: rect.config.height * this.scale.y + 8,
          cornerRadius: 10,
          stroke: "#42B1E5",
          strokeWidth: 3,
          scaleX: 1,
          scaleY: 1,
          scale: {x: 1, y: 1},
          listening: false,
        });
        this.$set(this.lithologyRegion, "circleConfig", {
          x: rect.config.x + 24,
          y: rect.config.y + rect.config.height * this.scale.y - 24,
          radius: 16,
          fill: "#FFFFFF",
        });
        this.$set(this.lithologyRegion, "pathConfig", {
          x: rect.config.x + 13,
          y: rect.config.y + rect.config.height * this.scale.y - 35,
          data:
              "M11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 " +
              "11C22 17.0751 17.0751 22 11 22ZM11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 " +
              "2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM12.0036 " +
              "12.9983H13.003V14.9983H9.00295V12.9983H10.003V10.9983H9.00295V8.99835H12.0036V12.9983ZM11.0003 " +
              "7.99835C10.4479 7.99835 10 7.55063 10 6.99835C10 6.44606 10.4479 5.99835 11.0003 5.99835C11.5528 " +
              "5.99835 12.0007 6.44606 12.0007 6.99835C12.0007 7.55063 11.5528 7.99835 11.0003 7.99835Z",
          fill: "#42B1E5",
          listening: false,
        });
      });
    },
    turnOnComplexLithologyRegion() {
      if (!this.lithologyEnabled) {
        return;
      }
      this.$refs.canvasImage.getNode().on("touchmove mousemove", () => {
        if (this.lithologyRegion.transformer.enabled) {
          return;
        }

        const position = this.$refs.canvasStage.getNode().getPointerPosition();
        const imageRect = this.$refs.canvasImage.getNode().getClientRect();

        const y = position.y - imageRect.y + this.splitHeight;
        const cursorImageY = y / this.scale.y;
        if (
            this.lithologyRegion.from_y <= cursorImageY &&
            cursorImageY <= this.lithologyRegion.to_y
        ) {
          return;
        }

        this.resetLithologyRegion();

        const imageColumnIndex = this.imageColumns.findIndex(
            (column) =>
                column.height + column.offset >= cursorImageY &&
                cursorImageY >= column.offset
        );
        if (imageColumnIndex === -1) {
          return;
        }
        const imageColumn = this.imageColumns[imageColumnIndex];
        const rects = [];
        const origRects = [];
        let cursorRect = null;

        let currentRectId = imageColumn.first_rect_id;
        while (currentRectId) {
          const rect =
              this.lithologyRects[imageColumn.column_id][currentRectId];
          if (
              rect.image_y <= cursorImageY &&
              cursorImageY <= rect.image_y + rect.image_height
          ) {
            cursorRect = rect;
          }
          if (cursorRect && cursorRect.invisible) {
            break;
          }
          if (cursorRect && cursorRect.type_id === rect.type_id) {
            rects.push(rect);
            origRects.push({
              rectId: rect.rect_id,
              y: rect.config.y,
              height: rect.config.height,
              scaledHeight: rect.config.height * this.scale.y,
            });
          } else if (cursorRect && cursorRect.type_id !== rect.type_id) {
            break;
          }

          currentRectId = rect.next_rect_id;
        }
        if (!cursorRect || cursorRect.invisible) {
          return;
        }

        let previousRect =
            this.lithologyRects[imageColumn.column_id][
                cursorRect.previous_rect_id
                ];

        while (previousRect && previousRect.type_id === cursorRect.type_id) {
          rects.splice(0, 0, previousRect);
          origRects.splice(0, 0, {
            rectId: previousRect.rect_id,
            y: previousRect.config.y,
            height: previousRect.config.height,
            scaledHeight:
                previousRect.config.height * previousRect.config.scaleY,
          });
          previousRect =
              this.lithologyRects[imageColumn.column_id][
                  previousRect.previous_rect_id
                  ];
        }

        const startRect = rects[0];
        const endRect = rects[rects.length - 1];

        this.$set(this.lithologyRegion, "column_id", imageColumn.column_id);
        this.$set(this.lithologyRegion, "column_index", imageColumnIndex);
        this.$set(this.lithologyRegion, "rect_id", cursorRect.rect_id);
        this.$set(this.lithologyRegion, "from_y", startRect.image_y);
        this.$set(
            this.lithologyRegion,
            "to_y",
            endRect.image_y - startRect.image_y + endRect.image_height
        );
        this.$set(this.lithologyRegion.transformer, "origRects", origRects);
        this.$set(this.lithologyRegion, "rectConfig", {
          x: startRect.config.x - 4,
          y: startRect.config.y - 4,
          width: startRect.config.width * this.scale.x + 8,
          height:
              endRect.config.y -
              startRect.config.y +
              endRect.config.height * this.scale.y +
              8,
          cornerRadius: 10,
          stroke: "#42B1E5",
          strokeWidth: 3,
          strokeScaleEnabled: false,
          scaleX: 1,
          scaleY: 1,
          scale: {x: 1, y: 1},
          // fill: ,
        });
      });
    },
    turnOffLithologyRegion() {
      this.$refs.canvasImage.getNode().off();
      this.resetLithologyRegion();
    },
    resetLithologyRegion() {
      this.disableLithologyRegionTransformer();
      this.$set(this, "lithologyRegion", {
        column_id: null,
        column_index: null,
        rect_id: null,
        from_y: 0,
        to_y: 0,
        rectConfig: {},
        circleConfig: {},
        pathConfig: {},
        classes: {
          enabled: false,
          position: {},
          value: [],
        },
        transformer: {
          enabled: false,
          updated: false,
          origRects: [],
          config: {},
        },
      });
    },
    disableLithologyRegionTransformer() {
      if (!this.lithologyRegion.transformer.enabled) {
        return;
      }

      this.turnOffLithologyTypes();
      this.$refs.imageToolbar.displayEditingTools(false);
      if (this.lithologyRegion.transformer.updated) {
        const columnId = this.lithologyRegion.column_id;
        const columnIndex = this.lithologyRegion.column_index;
        const imageColumn = this.imageColumns[columnIndex];

        let firstRectId = null;
        let lastRectId = null;
        this.lithologyRegion.transformer.origRects.forEach((origRect) => {
          const rect = this.lithologyRects[columnId][origRect.rectId];
          let deleted = false;
          if (
              rect.config.y !== origRect.y ||
              rect.config.height !== origRect.height
          ) {
            if (rect.config.height === 0 || rect.from_y === rect.to_y) {
              deleted = true;
              this.deleteLithologyRect(imageColumn, rect);
            } else {
              rect.updated = true;
            }
          }
          firstRectId =
              !deleted && firstRectId === null ? rect.rect_id : firstRectId;
          lastRectId = !deleted ? rect.rect_id : lastRectId;
        });

        // previous rects
        const firstRect = this.lithologyRects[columnId][firstRectId];
        let rect = this.lithologyRects[columnId][firstRect.previous_rect_id];
        while (rect) {
          if (rect.config.height === 0 || rect.from_y === rect.to_y) {
            this.deleteLithologyRect(imageColumn, rect);
          } else {
            firstRectId = rect.rect_id;
          }
          rect = this.lithologyRects[columnId][rect.previous_rect_id];
        }
        this.$set(this.imageColumns[columnIndex], "first_rect_id", firstRectId);

        // next rects
        const lastRect = this.lithologyRects[columnId][lastRectId];
        rect = this.lithologyRects[columnId][lastRect.next_rect_id];
        while (rect) {
          if (rect.config.height === 0 || rect.from_y === rect.to_y) {
            this.deleteLithologyRect(imageColumn, rect);
          }
          rect = this.lithologyRects[columnId][rect.next_rect_id];
        }

        this.checkImageLithologyRects(this.imageColumns[columnIndex]);

        this.saveHistory(this.getHistoryImageData());
        this.reloadPreview();
      }
      this.$set(this.lithologyRegion, "from_y", 0);
      this.$set(this.lithologyRegion, "to_y", 0);
      this.$set(this.lithologyRegion, "rectConfig", {});
      this.$set(this.lithologyRegion, "transformer", {
        enabled: false,
        updated: false,
        origRects: [],
        config: {},
      });
      if (this.$refs.canvasLithologyRegionRect) {
        this.$refs.canvasLithologyRegionRect.getNode().off("transform");
      }
    },
    enableLithologyRegionTransformer(event) {
      event.cancelBubble = true;

      const secondaryColor = "#42B1E5";
      this.$set(this.lithologyRegion.transformer, "config", {
        anchorSize: 8,
        anchorStroke: secondaryColor,
        anchorFill: secondaryColor,
        anchorCornerRadius: 4,
        enabledAnchors: ["top-center", "bottom-center"],
        borderEnabled: false,
        rotateEnabled: false,
        keepRatio: false,
        ignoreStroke: true,
        boundBoxFunc: (oldBoundBox, newBoundBox) => {
          const imageIds =
              this.imageColumns[this.lithologyRegion.column_index].image_ids;
          const firstImageClientRect = this.$refs[
              `canvas-image-part-${imageIds[0]}`
              ][0]
              .getNode()
              .getClientRect();
          const lastImageClientRect = this.$refs[
              `canvas-image-part-${imageIds[imageIds.length - 1]}`
              ][0]
              .getNode()
              .getClientRect();
          const minY = firstImageClientRect.y - 4;
          const maxY = lastImageClientRect.y + lastImageClientRect.height + 4;

          const box = newBoundBox;
          if (box.y < minY) {
            box.height -= minY - box.y;
            box.y = minY;
          }
          if (box.y + box.height > maxY) {
            box.height = maxY - box.y;
          }

          const minHeight = 10 * this.scale.y + 8;
          if (box.height < minHeight) {
            const delta = minHeight - box.height;
            if (box.y >= minY + delta) {
              box.y -= delta;
              box.height = 18;
            } else {
              box.y = oldBoundBox.y;
              box.height = oldBoundBox.height;
            }
          }
          return box;
        },
      });
      this.$set(this.lithologyRegion.transformer, "enabled", true);

      const typeId =
          this.lithologyRects[this.lithologyRegion.column_id][
              this.lithologyRegion.rect_id
              ].type_id;
      this.turnOnLithologyTypes(this.lithologyTypesByType[typeId]);
      this.$refs.imageToolbar.displayEditingTools();

      this.$nextTick(() => {
        this.$refs.canvasLithologyRegionTransformer
            .getNode()
            .nodes([this.$refs.canvasLithologyRegionRect.getNode()]);

        this.$nextTick(() => {
          this.modifyLithologyRegionTransformerAnchors();
        });

        let previousInvisibleRect = null;
        let nextInvisibleRect = null;
        this.$refs.canvasLithologyRegionRect
            .getNode()
            .on("transform", (event) => {
              this.$refs.canvasLithologyRegionTransformer.getNode().update();
              this.$nextTick(() => {
                this.modifyLithologyRegionTransformerAnchors();
              });

              this.$set(this.lithologyRegion.transformer, "updated", true);

              const lithologyRegionRect = event.target;
              lithologyRegionRect.setAttrs({
                height:
                    lithologyRegionRect.height() * lithologyRegionRect.scaleY(),
                scaleY: 1,
              });
              const newFromY = lithologyRegionRect.y() + 4;
              const newToY =
                  lithologyRegionRect.y() + lithologyRegionRect.height() - 4;

              const columnId = this.lithologyRegion.column_id;
              const imageColumn =
                  this.imageColumns[this.lithologyRegion.column_index];
              const origRects = this.lithologyRegion.transformer.origRects;
              const origFromY = this.lithologyRegion.rectConfig.y + 4;
              const origToY =
                  this.lithologyRegion.rectConfig.y +
                  this.lithologyRegion.rectConfig.height -
                  4;

              let processedFirstNotEmpty = false;
              let lastNotEmptyRect = null;
              let deltaY = newFromY - origFromY;
              let currentHeight = newToY - newFromY;
              let rect = null;
              for (let i = 0; i < origRects.length; i++) {
                const origRect = origRects[i];
                rect = this.lithologyRects[columnId][origRect.rectId];

                if (currentHeight === 0) {
                  this.modifyLithologyRect(imageColumn, rect, 0, 0);
                  continue;
                }

                if (deltaY < 0 && i === 0) {
                  this.modifyLithologyRect(
                      imageColumn,
                      rect,
                      origRect.y + deltaY,
                      Math.min(origRect.scaledHeight - deltaY, currentHeight)
                  );
                } else if (deltaY >= 0) {
                  const rectDelta = Math.min(deltaY, origRect.scaledHeight);
                  this.modifyLithologyRect(
                      imageColumn,
                      rect,
                      origRect.y + rectDelta,
                      Math.min(origRect.scaledHeight - rectDelta, currentHeight)
                  );
                  deltaY -= rectDelta;
                }

                if (rect.config.height > 0 && !processedFirstNotEmpty) {
                  this.modifyLithologyRect(
                      imageColumn,
                      rect,
                      newFromY,
                      rect.config.y + rect.config.height * this.scale.y - newFromY
                  );
                  processedFirstNotEmpty = true;
                  lastNotEmptyRect = rect;
                } else if (rect.config.height > 0) {
                  lastNotEmptyRect = rect;
                }

                currentHeight -= rect.config.height * this.scale.y;
              }
              if (currentHeight > 0 && rect) {
                this.modifyLithologyRect(
                    imageColumn,
                    rect,
                    rect.config.y,
                    rect.config.height * this.scale.y + currentHeight
                );
                if (rect.config.height > 0) {
                  lastNotEmptyRect = rect;
                }
              }
              if (
                  lastNotEmptyRect &&
                  lastNotEmptyRect.config.y +
                  lastNotEmptyRect.config.height * this.scale.y !==
                  newToY
              ) {
                const delta =
                    newToY -
                    (lastNotEmptyRect.config.y +
                        lastNotEmptyRect.config.height * this.scale.y);
                const y = lastNotEmptyRect.config.y + delta;
                this.modifyLithologyRect(
                    imageColumn,
                    lastNotEmptyRect,
                    y,
                    newToY - y
                );
              }

              const firstOrigRect = origRects[0];
              const firstRect =
                  this.lithologyRects[columnId][firstOrigRect.rectId];
              const previousRectId = firstRect.previous_rect_id;
              if (
                  newFromY !== origFromY &&
                  previousRectId &&
                  !previousInvisibleRect
              ) {
                let previousRect = this.lithologyRects[columnId][previousRectId];
                while (previousRect && newFromY < previousRect.config.y) {
                  this.modifyLithologyRect(
                      imageColumn,
                      previousRect,
                      previousRect.config.y,
                      0,
                      true
                  );
                  previousRect =
                      this.lithologyRects[columnId][previousRect.previous_rect_id];
                }
                if (previousRect) {
                  const scaledHeight =
                      previousRect.config.height * this.scale.y +
                      newFromY -
                      (previousRect.config.y +
                          previousRect.config.height * this.scale.y);
                  this.modifyLithologyRect(
                      imageColumn,
                      previousRect,
                      previousRect.config.y,
                      scaledHeight,
                      true
                  );
                  if (previousRect.previous_rect_id) {
                    // need because transform raised not for every pixel, so we can loose height when move down after move up
                    let prevFromY = previousRect.config.y;
                    let rectToCheck =
                        this.lithologyRects[columnId][
                            previousRect.previous_rect_id
                            ];
                    while (rectToCheck && rectToCheck.updated) {
                      this.modifyLithologyRect(
                          imageColumn,
                          rectToCheck,
                          rectToCheck.config.y,
                          prevFromY - rectToCheck.config.y,
                          true
                      );

                      prevFromY = rectToCheck.config.y;
                      rectToCheck =
                          this.lithologyRects[columnId][
                              rectToCheck.previous_rect_id
                              ];
                    }
                  }
                }
              } else if (newFromY !== origFromY && previousInvisibleRect) {
                this.modifyLithologyRect(
                    imageColumn,
                    previousInvisibleRect,
                    origFromY,
                    newFromY - origFromY
                );
              } else if (newFromY > origFromY) {
                previousInvisibleRect = this.createCustomLithologyRect(
                    columnId,
                    firstRect.file_id,
                    this.invisibleLithologyType
                );
                previousInvisibleRect.config.x = firstRect.config.x;
                previousInvisibleRect.offset = firstRect.offset;
                previousInvisibleRect.next_rect_id = firstRect.rect_id;
                firstRect.previous_rect_id = previousInvisibleRect.rect_id;
                this.modifyLithologyRect(
                    imageColumn,
                    previousInvisibleRect,
                    origFromY,
                    newFromY - origFromY
                );
                this.$set(
                    this.lithologyRects[columnId],
                    previousInvisibleRect.rect_id,
                    previousInvisibleRect
                );
                this.addDataLithologyRectByType(
                    this.invisibleLithologyType.id,
                    columnId,
                    previousInvisibleRect.rect_id
                );
              }

              const lastOrigRect = origRects[origRects.length - 1];
              const lastRect = this.lithologyRects[columnId][lastOrigRect.rectId];
              const nextRectId = lastRect.next_rect_id;
              if (newToY !== origToY && nextRectId && !nextInvisibleRect) {
                let nextRect = this.lithologyRects[columnId][nextRectId];
                while (
                    nextRect &&
                    newToY >
                    nextRect.config.y + nextRect.config.height * this.scale.y
                    ) {
                  this.modifyLithologyRect(
                      imageColumn,
                      nextRect,
                      nextRect.config.y + nextRect.config.height * this.scale.y,
                      0,
                      true
                  );
                  nextRect = this.lithologyRects[columnId][nextRect.next_rect_id];
                }
                if (nextRect) {
                  this.modifyLithologyRect(
                      imageColumn,
                      nextRect,
                      newToY,
                      nextRect.config.height * this.scale.y -
                      newToY +
                      nextRect.config.y,
                      true
                  );
                  if (nextRect.next_rect_id) {
                    // need because transform raised not for every pixel, so we can loose height when move up after move down
                    let prevToY =
                        nextRect.config.y + nextRect.config.height * this.scale.y;
                    let rectToCheck =
                        this.lithologyRects[columnId][nextRect.next_rect_id];
                    while (rectToCheck && rectToCheck.updated) {
                      const height =
                          rectToCheck.config.y +
                          rectToCheck.config.height * this.scale.y -
                          prevToY;
                      this.modifyLithologyRect(
                          imageColumn,
                          rectToCheck,
                          prevToY,
                          height,
                          true
                      );

                      prevToY =
                          rectToCheck.config.y +
                          rectToCheck.config.height * this.scale.y;
                      rectToCheck =
                          this.lithologyRects[columnId][rectToCheck.next_rect_id];
                    }
                  }
                }
              } else if (newToY !== origToY && nextInvisibleRect) {
                this.modifyLithologyRect(
                    imageColumn,
                    nextInvisibleRect,
                    newToY,
                    origToY - newToY
                );
              } else if (newToY < origToY) {
                nextInvisibleRect = this.createCustomLithologyRect(
                    columnId,
                    lastRect.file_id,
                    this.invisibleLithologyType
                );
                nextInvisibleRect.config.x = lastRect.config.x;
                nextInvisibleRect.offset = lastRect.offset;
                nextInvisibleRect.previous_rect_id = lastRect.rect_id;
                lastRect.next_rect_id = nextInvisibleRect.rect_id;
                this.modifyLithologyRect(
                    imageColumn,
                    nextInvisibleRect,
                    newToY,
                    origToY - newToY
                );
                this.$set(
                    this.lithologyRects[columnId],
                    nextInvisibleRect.rect_id,
                    nextInvisibleRect
                );
                this.addDataLithologyRectByType(
                    this.invisibleLithologyType.id,
                    columnId,
                    nextInvisibleRect.rect_id
                );
              }
            });
      });
    },
    deleteLithologyRect(column, rect) {
      const columnId = column.column_id;
      const filteredRects = this.lithologyRectsByType[rect.type_id][
          columnId
          ].filter((id) => id !== rect.rect_id);
      if (filteredRects.length > 0) {
        this.$set(
            this.lithologyRectsByType[rect.type_id],
            columnId,
            filteredRects
        );
      } else {
        this.$delete(this.lithologyRectsByType[rect.type_id], columnId);
        if (Object.keys(this.lithologyRectsByType[rect.type_id]).length === 0) {
          this.$delete(this.lithologyRectsByType, rect.type_id);
        }
      }

      if (rect.previous_rect_id) {
        this.lithologyRects[columnId][rect.previous_rect_id].next_rect_id =
            rect.next_rect_id;
      }
      if (rect.next_rect_id) {
        this.lithologyRects[columnId][rect.next_rect_id].previous_rect_id =
            rect.previous_rect_id;
      }

      if (column.first_rect_id === rect.rect_id) {
        column.first_rect_id = rect.next_rect_id;
      }

      this.deletedLithology.push(rect.lithology_id);
      this.$delete(this.lithologyRects[columnId], rect.rect_id);
    },
    modifyLithologyRect(column, rect, y, scaledHeight, setUpdated = false) {
      rect.config.y = y;
      rect.config.height = scaledHeight / this.scale.y;
      rect.image_y = rect.config.y / this.scale.y;
      rect.image_height = rect.config.height;
      rect.from_y = Math.round(
          rect.image_y - column.offset + column.cropY - rect.offset
      );
      rect.to_y = Math.round(rect.from_y + rect.image_height);
      if (setUpdated) {
        rect.updated = true;
      }
    },
    checkImageLithologyRects(column) {
      const lithologyCores = {};
      const allSortedFileCores = [];
      column.file_ids.forEach((fileId) => {
        const fileCores = this.lithologyCores[fileId].sort(
            (c1, c2) => c1.top_depth - c2.top_depth
        );
        Object.assign(lithologyCores, this.arrayToMap(fileCores, "file_id"));
        allSortedFileCores.push(...fileCores);
      });

      let firstLithologyCore = null;
      let firstLithologyCoreOffset = 0;
      let lastLithologyCore = null;
      let lastLithologyCoreOffset = 0;
      let processedCoresHeight = 0;
      for (let i = 0; i < allSortedFileCores.length; i++) {
        const core = allSortedFileCores[i];
        if (column.cropY > processedCoresHeight) {
          processedCoresHeight += core.height;
          continue;
        } else if (column.cropY + column.height <= processedCoresHeight) {
          lastLithologyCore = allSortedFileCores[i - 1];
          lastLithologyCoreOffset =
              processedCoresHeight - allSortedFileCores[i - 1].height;
          break;
        }
        if (!firstLithologyCore) {
          firstLithologyCore = core;
          firstLithologyCoreOffset = processedCoresHeight;
        }
        processedCoresHeight += core.height;
      }

      let rect = this.lithologyRects[column.column_id][column.first_rect_id];
      while (rect) {
        // detect rect for previous lithology core: split rect
        if (rect.from_y < 0) {
          const delta = -rect.from_y;
          if (rect.previous_rect_id) {
            const previousRect =
                this.lithologyRects[column.column_id][rect.previous_rect_id];
            if (delta < 10) {
              previousRect.updated = true;
              previousRect.to_y += delta;
              previousRect.image_height =
                  previousRect.to_y - previousRect.from_y;
              previousRect.config.height = previousRect.image_height;
            } else {
              const newRect = this.createCustomLithologyRect(
                  column.column_id,
                  previousRect.file_id,
                  this.lithologyTypesByType[rect.type_id]
              );
              newRect.offset = previousRect.offset;
              newRect.from_y =
                  lithologyCores[previousRect.file_id].height - delta;
              newRect.to_y = newRect.from_y + delta;
              newRect.image_y =
                  column.offset - column.cropY + newRect.offset + newRect.from_y;
              newRect.image_height = newRect.to_y - newRect.from_y;
              newRect.config.x = previousRect.config.x;
              newRect.config.y = newRect.image_y * this.scale.y;
              newRect.config.height = newRect.image_height;
              newRect.config.fillPatternImage = rect.config.fillPatternImage;

              newRect.previous_rect_id = rect.previous_rect_id;
              newRect.next_rect_id = rect.rect_id;
              rect.previous_rect_id = newRect.rect_id;
              previousRect.next_rect_id = newRect.rect_id;

              this.$set(
                  this.lithologyRects[column.column_id],
                  newRect.rect_id,
                  newRect
              );
              this.addDataLithologyRectByType(
                  newRect.type_id,
                  column.column_id,
                  newRect.rect_id
              );
            }
          } else if (firstLithologyCore.file_id !== rect.file_id) {
            const newRect = this.createCustomLithologyRect(
                column.column_id,
                firstLithologyCore.file_id,
                this.lithologyTypesByType[rect.type_id]
            );
            newRect.offset = firstLithologyCoreOffset;
            newRect.from_y = 0;
            newRect.to_y = delta;
            newRect.image_y =
                column.offset - column.cropY + newRect.offset + newRect.from_y;
            newRect.image_height = newRect.to_y - newRect.from_y;
            newRect.config.x = rect.config.x;
            newRect.config.y = newRect.image_y * this.scale.y;
            newRect.config.height = newRect.image_height;
            newRect.config.fillPatternImage = rect.config.fillPatternImage;

            newRect.next_rect_id = rect.rect_id;
            rect.previous_rect_id = newRect.rect_id;
            column.first_rect_id = newRect.rect_id;

            this.$set(
                this.lithologyRects[column.column_id],
                newRect.rect_id,
                newRect
            );
            this.addDataLithologyRectByType(
                newRect.type_id,
                column.column_id,
                newRect.rect_id
            );
          }
          rect.updated = true;
          rect.from_y = 0;
          rect.image_y =
              column.offset - column.cropY + rect.offset + rect.from_y;
          rect.image_height = rect.to_y - rect.from_y;
          rect.config.y = rect.image_y * this.scale.y;
          rect.config.height = rect.image_height;
        }

        // detect rect for next lithology core: split rect
        if (rect.to_y > lithologyCores[rect.file_id].height) {
          const delta = rect.to_y - lithologyCores[rect.file_id].height;
          if (rect.next_rect_id) {
            const nextRect =
                this.lithologyRects[column.column_id][rect.next_rect_id];
            if (delta < 10) {
              nextRect.updated = true;
              nextRect.from_y -= delta;
              nextRect.image_y -= delta;
              nextRect.image_height = nextRect.to_y - nextRect.from_y;
              nextRect.config.y = nextRect.image_y * this.scale.y;
              nextRect.config.height = nextRect.image_height;
            } else {
              const newRect = this.createCustomLithologyRect(
                  column.column_id,
                  nextRect.file_id,
                  this.lithologyTypesByType[rect.type_id]
              );
              newRect.offset = nextRect.offset;
              newRect.from_y = 0;
              newRect.to_y = delta;
              newRect.image_y =
                  column.offset - column.cropY + newRect.offset + newRect.from_y;
              newRect.image_height = newRect.to_y - newRect.from_y;
              newRect.config.x = nextRect.config.x;
              newRect.config.y = newRect.image_y * this.scale.y;
              newRect.config.height = newRect.image_height;
              newRect.config.fillPatternImage = rect.config.fillPatternImage;

              newRect.previous_rect_id = rect.rect_id;
              newRect.next_rect_id = rect.next_rect_id;
              rect.next_rect_id = newRect.rect_id;
              nextRect.previous_rect_id = newRect.rect_id;

              this.$set(
                  this.lithologyRects[column.column_id],
                  newRect.rect_id,
                  newRect
              );
              this.addDataLithologyRectByType(
                  newRect.type_id,
                  column.column_id,
                  newRect.rect_id
              );
            }
          } else if (
              lastLithologyCore &&
              lastLithologyCore.file_id !== rect.file_id
          ) {
            const newRect = this.createCustomLithologyRect(
                column.column_id,
                lastLithologyCore.file_id,
                this.lithologyTypesByType[rect.type_id]
            );
            newRect.offset = lastLithologyCoreOffset;
            newRect.from_y = 0;
            newRect.to_y = delta;
            newRect.image_y =
                column.offset - column.cropY + newRect.offset + newRect.from_y;
            newRect.image_height = newRect.to_y - newRect.from_y;
            newRect.config.x = rect.config.x;
            newRect.config.y = newRect.image_y * this.scale.y;
            newRect.config.height = newRect.image_height;
            newRect.config.fillPatternImage = rect.config.fillPatternImage;

            newRect.previous_rect_id = rect.rect_id;
            rect.next_rect_id = newRect.rect_id;

            this.$set(
                this.lithologyRects[column.column_id],
                newRect.rect_id,
                newRect
            );
            this.addDataLithologyRectByType(
                newRect.type_id,
                column.column_id,
                newRect.rect_id
            );
          }
          rect.updated = true;
          rect.to_y = lithologyCores[rect.file_id].height;
          rect.image_height = rect.to_y - rect.from_y;
          rect.config.height = rect.image_height;
        }

        // detect small rect: attach to closest rect and delete
        if (rect.to_y - rect.from_y < 10) {
          let closestRectId = null;
          if (rect.from_y === 0) {
            closestRectId = rect.next_rect_id;
          }
          if (rect.to_y === lithologyCores[rect.file_id].height) {
            closestRectId = rect.previous_rect_id;
          }
          if (!closestRectId) {
            if (
                this.lithologyRects[column.column_id][rect.previous_rect_id]
                    .type_id === rect.type_id
            ) {
              closestRectId = rect.previous_rect_id;
            } else {
              closestRectId = rect.next_rect_id;
            }
          }
          const closestRect =
              this.lithologyRects[column.column_id][closestRectId];
          if (closestRect) {
            if (closestRect.rect_id === rect.previous_rect_id) {
              closestRect.to_y = rect.to_y;
              closestRect.image_height += rect.image_height;
              closestRect.config.height += rect.config.height;
            } else {
              closestRect.from_y = rect.from_y;
              closestRect.image_y = rect.image_y;
              closestRect.image_height += rect.image_height;
              closestRect.config.y = rect.config.y;
              closestRect.config.height += rect.config.height;
            }
            this.deleteLithologyRect(column, rect);
          } else {
            this.$log.error("Closest rect for small rect (<10px) not found");
          }
        }

        rect = this.lithologyRects[column.column_id][rect.next_rect_id];
      }
    },
    modifyLithologyRegionTransformerAnchors() {
      const transformer = this.$refs.canvasLithologyRegionTransformer.getNode();
      const anchors = [
        transformer.findOne(".top-center"),
        transformer.findOne(".bottom-center"),
      ];

      anchors.forEach((anchor) => {
        anchor.width(Math.max(this.lithologyRegion.rectConfig.width / 4, 8));
        anchor.offsetX(anchor.width() / 2);
      });
    },
    updateLithologyRegionType(type) {
      const columnId = this.lithologyRegion.column_id;
      const rectIds = this.lithologyRegion.transformer.origRects.map(
          (rect) => rect.rectId
      );

      const oldTypeId = this.lithologyRects[columnId][rectIds[0]].type_id;
      if (oldTypeId === type.id) {
        return;
      }

      // remove rects from previous type
      const filteredRects = this.lithologyRectsByType[oldTypeId][
          columnId
          ].filter((id) => !rectIds.includes(id));
      if (filteredRects.length > 0) {
        this.$set(
            this.lithologyRectsByType[oldTypeId],
            columnId,
            filteredRects
        );
      } else {
        this.$delete(this.lithologyRectsByType[oldTypeId], columnId);
        if (Object.keys(this.lithologyRectsByType[oldTypeId]).length === 0) {
          this.$delete(this.lithologyRectsByType, oldTypeId);
        }
      }

      // change type of rects
      const promises = rectIds.map((rectId) => {
        const rect = this.lithologyRects[columnId][rectId];
        rect.type_id = type.id;
        rect.updated = true;
        rect.probability = 1;

        let typeExists = false;
        rect.classes.forEach((cls) => {
          if (cls.type_id === type.id) {
            typeExists = true;
            cls.selected = true;
            cls.custom_probability = 1;
          } else {
            cls.selected = false;
            cls.custom_probability = 0;
          }
        });
        if (!typeExists) {
          rect.classes = [
            {
              lithology_class_id: null,
              class_id: null,
              type_id: type.id,
              probability: 0,
              custom_probability: 1,
              selected: true,
            },
          ];
        }

        this.addDataLithologyRectByType(type.id, columnId, rectId);

        return this.getLithologyFillPattern(this.project.project_id, type).then(
            (result) => {
              rect.config.fillPatternImage = result;
              return true;
            }
        );
      });
      Promise.all(promises).then(() => {
        this.lithologyRegion.transformer.updated = false;
        this.saveHistory(this.getHistoryImageData());
        this.reloadPreview();
      });
    },
    updateLithologyRegionClasses(classes, typeId, columnId, rectId) {
      const rect = this.lithologyRects[columnId][rectId];
      if (rect && rect.type_id !== typeId) {
        rect.updated = true;
        rect.probability = 1;
        rect.classes = classes;

        // remove rect from previous type
        const filteredRects = this.lithologyRectsByType[rect.type_id][
            columnId
            ].filter((id) => id !== rectId);
        if (filteredRects.length > 0) {
          this.$set(
              this.lithologyRectsByType[rect.type_id],
              columnId,
              filteredRects
          );
        } else {
          this.$delete(this.lithologyRectsByType[rect.type_id], columnId);
          if (
              Object.keys(this.lithologyRectsByType[rect.type_id]).length === 0
          ) {
            this.$delete(this.lithologyRectsByType, rect.type_id);
          }
        }

        // change rect type
        rect.type_id = typeId;
        this.addDataLithologyRectByType(typeId, columnId, rectId);

        const type = this.lithologyTypesByType[typeId];
        this.getLithologyFillPattern(this.project.project_id, type).then(
            (result) => {
              rect.config.fillPatternImage = result;
              this.saveHistory(this.getHistoryImageData());
              this.reloadPreview();
            }
        );
      }
    },
    hideLithologyClasses() {
      this.$set(this.lithologyRegion.classes, "enabled", false);
    },
    displayLithologyClasses(event) {
      event.cancelBubble = true;
      const position = event.target.getAbsolutePosition(
          this.$refs.canvasStage.getNode()
      );
      const offset = this.lithologyRegion.circleConfig.radius;
      this.$set(this.lithologyRegion.classes, "enabled", true);
      this.$set(this.lithologyRegion.classes, "position", {
        x: position.x,
        y: position.y,
        offsetX: offset,
        offsetY: offset,
      });
    },
    resetDrawing() {
      this.$set(this, "drawing", {
        lineConfig: {},
        rectConfig: {},
      });
    },
    turnOffDrawing() {
      this.$refs.canvasImage.getNode().off();
      this.resetDrawing();
    },
    turnOnIntervalTool() {
      const canvasImage = this.$refs.canvasImage.getNode();
      this.setCursorStyle(canvasImage, "pointer");

      canvasImage.on("click tap", () => {
        if (!this.activeLithologyType) {
          this.showWMessage(this.$t("messages.no-active-lithology-type"));
          return;
        }

        const pointerPosition = this.$refs.canvasStage
            .getNode()
            .getPointerPosition();
        const imageRect = this.$refs.canvasImage.getNode().getClientRect();
        const pointerPositionY =
            pointerPosition.y - imageRect.y + this.splitHeight;

        if (Object.keys(this.drawing.lineConfig).length > 0) {
          const y = this.drawing.lineConfig.points[1];
          let height;
          if (
              pointerPositionY >= this.drawing.lineConfig.minY &&
              pointerPositionY <= this.drawing.lineConfig.maxY
          ) {
            height = pointerPositionY - y;
          } else if (pointerPositionY > this.drawing.lineConfig.maxY) {
            height = this.drawing.lineConfig.maxY - y;
          } else if (pointerPositionY < this.drawing.lineConfig.minY) {
            height = this.drawing.lineConfig.minY - y;
          }

          this.$set(this.drawing, "lineConfig", {});

          this.addCustomLithologyRect(y, height, this.activeLithologyType).then(
              () => {
                this.saveHistory(this.getHistoryImageData());
                this.reloadPreview();
              }
          );
        } else {
          const imagePointerPositionY = pointerPositionY / this.scale.y;
          const imageColumn = this.imageColumns.find(
              (column) =>
                  column.height + column.offset >= imagePointerPositionY &&
                  imagePointerPositionY >= column.offset
          );
          if (!imageColumn) {
            return;
          }

          this.$set(this.drawing, "lineConfig", {
            points: [
              imageRect.x + 2,
              pointerPositionY,
              imageRect.x +
              2 +
              this.scaledCoreWidth +
              this.scaledLithologyWidth,
              pointerPositionY,
            ],
            minY: imageColumn.offset * this.scale.y,
            maxY: (imageColumn.offset + imageColumn.height) * this.scale.y,
            stroke: this.activeLithologyType.color,
            strokeWidth: 4,
            dash: [10, 10],
            dashEnabled: true,
            listening: false,
          });
        }
      });
    },
    turnOnDrawingTool(tool) {
      const canvasImage = this.$refs.canvasImage.getNode();
      this.setCursorStyle(canvasImage, "pointer");

      let activated = false;
      canvasImage.on("touchstart mousedown", () => {
        activated = !activated;
        if (activated) {
          if (tool === "brush" && !this.activeLithologyType) {
            activated = false;
            this.showWMessage(this.$t("messages.no-active-lithology-type"));
            return;
          }

          const pointerPosition = this.$refs.canvasStage
              .getNode()
              .getPointerPosition();
          const imageRect = this.$refs.canvasImage.getNode().getClientRect();
          const color =
              tool === "brush"
                  ? this.activeLithologyType.color
                  : this.invisibleLithologyType.color;
          const pointerPositionY =
              pointerPosition.y - imageRect.y + this.splitHeight;

          const imagePointerPositionY = pointerPositionY / this.scale.y;
          const imageColumn = this.imageColumns.find(
              (column) =>
                  column.height + column.offset >= imagePointerPositionY &&
                  imagePointerPositionY >= column.offset
          );
          if (!imageColumn) {
            activated = false;
            return;
          }

          this.$set(this.drawing, "rectConfig", {
            x: imageRect.x + this.scaledCoreWidth + 1,
            y: pointerPositionY,
            minY: imageColumn.offset * this.scale.y,
            maxY: (imageColumn.offset + imageColumn.height) * this.scale.y,
            width: this.lithologyWidth,
            height: 0,
            scaleX: this.scale.x,
            scaleY: this.scale.y,
            fill: color,
            fillPatternImage: null,
            listening: false,
          });

          if (tool === "brush") {
            this.getLithologyFillPattern(
                this.project.project_id,
                this.activeLithologyType
            ).then((result) => {
              this.drawing.rectConfig.fill = null;
              this.drawing.rectConfig.fillPatternImage = result;
            });
          }
        }
      });
      canvasImage.on("touchend mouseup mouseleave", () => {
        if (activated) {
          const y = this.drawing.rectConfig.y;
          const height = this.drawing.rectConfig.height * this.scale.y;
          const type =
              tool === "brush"
                  ? this.activeLithologyType
                  : this.invisibleLithologyType;

          this.$set(this.drawing, "rectConfig", {});
          activated = false;

          this.addCustomLithologyRect(y, height, type).then(() => {
            this.saveHistory(this.getHistoryImageData());
            this.reloadPreview();
          });
        }
      });

      canvasImage.on("touchmove mousemove", () => {
        if (!activated) {
          return;
        }
        const pointerPosition = this.$refs.canvasStage
            .getNode()
            .getPointerPosition();
        const imageRect = this.$refs.canvasImage.getNode().getClientRect();
        const pointerPositionY =
            pointerPosition.y - imageRect.y + this.splitHeight;
        if (
            pointerPositionY >= this.drawing.rectConfig.minY &&
            pointerPositionY <= this.drawing.rectConfig.maxY
        ) {
          this.drawing.rectConfig.height =
              (pointerPositionY - this.drawing.rectConfig.y) / this.scale.y;
        } else if (pointerPositionY > this.drawing.lineConfig.maxY) {
          this.drawing.rectConfig.height =
              (this.drawing.lineConfig.maxY - this.drawing.rectConfig.y) /
              this.scale.y;
        } else if (pointerPositionY < this.drawing.lineConfig.minY) {
          this.drawing.rectConfig.height =
              (this.drawing.lineConfig.minY - this.drawing.rectConfig.y) /
              this.scale.y;
        }
        this.$refs.canvasToolLayer.getNode().batchDraw();
      });
    },
    addCustomLithologyRect(origY, origHeight, type) {
      let y = origY;
      let height = origHeight;
      if (height < 0) {
        height *= -1;
        y -= height;
      }
      if (height / this.scale.y < 10) {
        height = 10 * this.scale.y;
      }

      const imageFromY = y / this.scale.y;
      const imageToY = (y + height) / this.scale.y;

      const imageColumn = this.imageColumns.find((column) => {
        return (
            column.height + column.offset >= imageFromY &&
            imageFromY >= column.offset &&
            column.height + column.offset >= imageToY &&
            imageToY >= column.offset
        );
      });
      if (!imageColumn) {
        return Promise.resolve(false);
      }

      let firstRect = null;
      let deleteFirstRect = false;
      let lastRect = null;
      let deleteLastRect = false;
      let fromY = null;
      let toY = null;
      let currentCustomRect = null;
      const customRects = [];
      const deletedRects = [];

      let rectId = imageColumn.first_rect_id;
      while (rectId) {
        const rect = this.lithologyRects[imageColumn.column_id][rectId];

        if (
            rect.image_y <= imageFromY &&
            imageFromY <= rect.image_y + rect.image_height
        ) {
          fromY =
              imageFromY - imageColumn.offset - rect.offset + imageColumn.cropY;
          firstRect = this.clone(rect);
          firstRect.updated = true;
          firstRect.to_y = fromY;
          firstRect.image_height = firstRect.to_y - firstRect.from_y;
          firstRect.config.height = firstRect.image_height;
          firstRect.config.fillPatternImage = rect.config.fillPatternImage;

          let delta = 0;
          if (firstRect.to_y - firstRect.from_y < 10) {
            deleteFirstRect = true;
            delta = firstRect.to_y - firstRect.from_y;
            fromY -= delta;
          }

          currentCustomRect = this.createCustomLithologyRect(
              imageColumn.column_id,
              firstRect.file_id,
              type
          );
          currentCustomRect.from_y = fromY;
          currentCustomRect.image_y = imageFromY - delta;
          currentCustomRect.offset = firstRect.offset;
          currentCustomRect.config.x = firstRect.config.x;
          currentCustomRect.config.y = y - delta * this.scale.y;
        }

        if (firstRect) {
          if (currentCustomRect.file_id !== rect.file_id) {
            currentCustomRect.to_y =
                this.lithologyRects[imageColumn.column_id][
                    rect.previous_rect_id
                    ].to_y;
            currentCustomRect.image_height =
                currentCustomRect.to_y - currentCustomRect.from_y;
            currentCustomRect.config.height = currentCustomRect.image_height;
            customRects.push(currentCustomRect);

            const newImageY =
                currentCustomRect.image_y + currentCustomRect.image_height;
            currentCustomRect = this.createCustomLithologyRect(
                imageColumn.column_id,
                rect.file_id,
                type
            );
            currentCustomRect.from_y = rect.from_y;
            currentCustomRect.image_y = newImageY;
            currentCustomRect.offset = rect.offset;
            currentCustomRect.config.x = rect.config.x;
            currentCustomRect.config.y = newImageY * this.scale.y;
          }

          if (
              rect.image_y <= imageToY &&
              imageToY <= rect.image_y + rect.image_height
          ) {
            toY =
                imageToY - imageColumn.offset - rect.offset + imageColumn.cropY;
            lastRect = this.clone(rect);
            lastRect.lithology_id =
                lastRect.rect_id === firstRect.rect_id
                    ? null
                    : lastRect.lithology_id;
            lastRect.created = lastRect.rect_id === firstRect.rect_id;
            lastRect.updated = lastRect.rect_id !== firstRect.rect_id;
            lastRect.rect_id =
                lastRect.rect_id === firstRect.rect_id
                    ? uuidv4()
                    : lastRect.rect_id;
            lastRect.from_y = toY;
            lastRect.image_y = imageToY;
            lastRect.image_height = lastRect.to_y - lastRect.from_y;
            lastRect.config.y = lastRect.image_y * this.scale.y;
            lastRect.config.height = lastRect.image_height;
            lastRect.config.fillPatternImage = rect.config.fillPatternImage;

            if (lastRect.to_y - lastRect.from_y < 10) {
              deleteLastRect = true;
              toY = lastRect.to_y;
            }

            currentCustomRect.to_y = toY;
            currentCustomRect.image_height =
                currentCustomRect.to_y - currentCustomRect.from_y;
            currentCustomRect.config.height = currentCustomRect.image_height;
            customRects.push(currentCustomRect);

            break;
          } else if (firstRect.rect_id !== rectId) {
            deletedRects.push(rectId);
          }
        }

        rectId = rect.next_rect_id;
      }
      if (!firstRect || !lastRect) {
        return Promise.resolve(false);
      }

      if (deleteFirstRect) {
        deletedRects.push(firstRect.rect_id);
      }
      if (deleteLastRect) {
        deletedRects.push(lastRect.rect_id);
      }

      deletedRects.forEach((rectId) => {
        const rect = this.lithologyRects[imageColumn.column_id][rectId];
        if (rect) {
          this.$delete(this.lithologyRects[imageColumn.column_id], rectId);

          if (
              this.lithologyRectsByType[rect.type_id] &&
              this.lithologyRectsByType[rect.type_id][imageColumn.column_id]
          ) {
            const filteredRects = this.lithologyRectsByType[rect.type_id][
                imageColumn.column_id
                ].filter((id) => id !== rectId);
            if (filteredRects.length > 0) {
              this.$set(
                  this.lithologyRectsByType[rect.type_id],
                  imageColumn.column_id,
                  filteredRects
              );
            } else {
              this.$delete(
                  this.lithologyRectsByType[rect.type_id],
                  imageColumn.column_id
              );
              if (
                  Object.keys(this.lithologyRectsByType[rect.type_id]).length ===
                  0
              ) {
                this.$delete(this.lithologyRectsByType, rect.type_id);
              }
            }
          }
          this.deletedLithology.push(rect.lithology_id);
        }
      });

      if (deleteFirstRect) {
        customRects[0].previous_rect_id = firstRect.previous_rect_id;
        if (firstRect.previous_rect_id) {
          this.lithologyRects[imageColumn.column_id][
              firstRect.previous_rect_id
              ].next_rect_id = customRects[0].rect_id;
        }
        imageColumn.first_rect_id =
            firstRect.rect_id === imageColumn.first_rect_id
                ? customRects[0].rect_id
                : imageColumn.first_rect_id;
      } else {
        customRects[0].previous_rect_id = firstRect.rect_id;
        firstRect.next_rect_id = customRects[0].rect_id;
        this.$set(
            this.lithologyRects[imageColumn.column_id],
            firstRect.rect_id,
            firstRect
        );
      }
      if (deleteLastRect) {
        customRects[customRects.length - 1].next_rect_id =
            lastRect.next_rect_id;
        if (lastRect.next_rect_id) {
          this.lithologyRects[imageColumn.column_id][
              lastRect.next_rect_id
              ].previous_rect_id = customRects[customRects.length - 1].rect_id;
        }
      } else {
        customRects[customRects.length - 1].next_rect_id = lastRect.rect_id;
        lastRect.previous_rect_id = customRects[customRects.length - 1].rect_id;
        if (lastRect.next_rect_id) {
          // update next rect because last rect can be copied from first
          this.lithologyRects[imageColumn.column_id][
              lastRect.next_rect_id
              ].previous_rect_id = lastRect.rect_id;
        }
        this.$set(
            this.lithologyRects[imageColumn.column_id],
            lastRect.rect_id,
            lastRect
        );
      }

      customRects.forEach((rect, index) => {
        rect.config.fill = type.color;

        if (!rect.previous_rect_id && index > 0) {
          rect.previous_rect_id = customRects[index - 1].rect_id;
        }
        if (!rect.next_rect_id && index < customRects.length - 1) {
          rect.next_rect_id = customRects[index + 1].rect_id;
        }

        this.$set(
            this.lithologyRects[imageColumn.column_id],
            rect.rect_id,
            rect
        );
        this.addDataLithologyRectByType(
            type.id,
            imageColumn.column_id,
            rect.rect_id
        );
      });

      const promise =
          type.id === this.invisibleLithologyType.id
              ? Promise.resolve(null)
              : this.getLithologyFillPattern(this.project.project_id, type);
      return promise.then((result) => {
        if (result) {
          customRects.forEach((rect) => {
            rect.config.fill = null;
            rect.config.fillPatternImage = result;
          });
        }
        return true;
      });
    },
    createCustomLithologyRect(columnId, fileId, type) {
      const invisible = type.id === this.invisibleLithologyType.id;
      return {
        rect_id: uuidv4(),
        previous_rect_id: null,
        next_rect_id: null,
        column_id: columnId,
        file_id: fileId,
        created: true,
        lithology_id: null,
        type_id: type.id,
        invisible: invisible,
        probability: 1,
        from_y: 0,
        to_y: 0,
        image_y: 0,
        image_height: 0,
        classes: [
          {
            lithology_class_id: null,
            class_id: null,
            type_id: type.id,
            probability: 0,
            custom_probability: 1,
            selected: true,
          },
        ],
        offset: 0,
        config: {
          x: this.canvasConfig.width / 2,
          y: 0,
          width: this.lithologyWidth,
          height: 0,
          scaleX: this.scale.x,
          scaleY: this.scale.y,
          fill: invisible ? type.color : null,
          fillPatternImage: null,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.project-classification {
  .image-container {
    position: relative;

    .image-scale {
      position: absolute;
      margin-left: 8px;
      bottom: 0;
      z-index: 1;
    }

    .lithology-classes {
      z-index: 2;
    }

    .image-canvas {
      position: absolute;
      z-index: 0;
    }

    .classification-models {
      position: absolute;
      right: 8px;
      z-index: 2;
    }

    .lithology-types {
      position: absolute;
      right: 8px;
      z-index: 2;
    }
  }

  .image-toolbar {
    z-index: 1;
  }
}
</style>

<i18n>
{
  "en": {
    "messages": {
      "no-core-columns": "No core found in images or it is oriented horizontally, contact help@petroleum.digital",
      "no-active-lithology-type": "Please choose class",
      "classification-started": "Images was sent to classification service. Processing may take several minutes, you may leave the page",
      "classification-processing": "Classification of images in progress. Processing may take several minutes, you may leave the page"
    },
    "questions": {
      "apply-new-model": "Current results and changes will be lost. It will be impossible to download them after applying a new model. Would you like to continue?"
    }
  },
  "ru": {
    "messages": {
      "no-core-columns": "Керн на изображениях не обнаружен или расположен в ящиках горизонтально, напишите на help@petroleum.digital",
      "no-active-lithology-type": "Пожалуйста, выберите класс",
      "classification-started": "Изображения отправлены на классификацию. Обработка может занять несколько минут, вы можете покинуть страницу",
      "classification-processing": "Идет классификация изображений. Обработка может занять несколько минут, вы можете покинуть страницу"
    },
    "questions": {
      "apply-new-model": "Текущие результаты и изменения будут утеряны. Они будут недоступны для скачивания после применения новой модели. Продолжить?"
    }
  }
}
</i18n>
