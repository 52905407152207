/**
 * @group Store-modules
 * describe the store (it will not be shown in vuese yet)
 */
const state = {
    patterns: {
        names: [],
        icons: {}
    },
    colors: [
        '#D30D14',
        '#E4312C',
        '#CD4945',
        '#F48206',
        '#D7816A',
        '#FFC37C',
        '#FFEFAB',
        '#00FF00',
        '#4EBC50',
        '#4FD7A6',
        '#CED74F',
        '#FFFF00',
        '#0DBDD3',
        '#3D7D9A',
        '#5DABCD',
        '#B4E1FF',
        '#C4D0C3'
    ]
}

const getters = {
    getPatternNames: (state) => state.patterns.names,
    getPatternIcons: (state) => Object.keys(state.patterns.icons).map((k) => state.patterns.icons[k]),
    getPatternIcon: (state) => (name, fill) => state.patterns.icons[`${name}-${fill}`],
    getColors: (state) => state.colors
}

const mutations = {
    setPatternNames(state, patternNames) {
        state.patterns.names = patternNames
    },
    setPatternIcon(state, pattern) {
        state.patterns.icons[`${pattern.name}-${pattern.fill}`] = pattern
    }
}

const actions = {
    setPatternNames(context, patternNames) {
        context.commit('setPatternNames', patternNames)
    },
    setPatternIcon(context, pattern) {
        context.commit('setPatternIcon', pattern)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
