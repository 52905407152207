import axios from 'axios'

/**
 * @group Services
 * describe the Services (it will not be shown in vuese yet)
 */
export default {
    imageToJpeg(width, height, file) {
        const formData = new FormData()
        formData.append('width', width)
        formData.append('height', height)
        formData.append('file', file)
        return axios.post('/api/v1/image/to-jpeg', formData, {
            responseType: 'blob'
        })
    },
    fileInfo(fileId) {
        return axios.get(`/api/v1/file/${fileId}`)
    },
    filePreview(fileId) {
        return axios.get(`/api/v1/file/${fileId}/preview`, {
            responseType: 'blob'
        })
    },
    file(fileId) {
        return axios.get(`/api/v1/file/${fileId}/download`, {
            responseType: 'blob'
        })
    },
    swap(fileId, file) {
        const formData = new FormData()
        formData.append('file', file)
        return axios.post(`/api/v1/file/${fileId}/swap`, formData)
    },
    swapDataURL(fileId, fileName, fileType, dataURL) {
        const bytes = atob(dataURL.split(',')[1])
        const ab = new ArrayBuffer(bytes.length)
        const ia = new Uint8Array(ab)
        for (let i = 0; i < bytes.length; i++) {
            ia[i] = bytes.charCodeAt(i)
        }
        const blob = new Blob([ab], {type: fileType})
        return this.swap(fileId, blob)
    },
    changeDepths(fileId, topDepth, bottomDepth) {
        const data = {
            top: topDepth,
            bottom: bottomDepth
        }
        return axios.patch(`/api/v1/file/${fileId}/depth`, data)
    },
    cores(fileId) {
        return axios.get(`/api/v1/file/${fileId}/cores`)
    },
    extendedCores(fileId) {
        return axios.get(`/api/v1/file/${fileId}/cores-ext`)
    },
    changeCoreDepths(fileId, coreDepths) {
        return axios.patch(`/api/v1/file/${fileId}/cores`, coreDepths)
    }
}
