import i18n from '@/plugins/i18n'

/**
 * @group Mixins
 * This is a description of the mixin
 */
export default {
    methods: {
        /**
         * @vuese
         * method
         * @arg message
         */
        showEMessage(message) {
            this.showMessage(message, 'danger')
        },
        /**
         * @vuese
         * method
         * @arg message
         */
        showERootMessage(message) {
            this.showRootMessage(message, 'danger')
        },
        /**
         * @vuese
         * method
         * @arg message \\
         * @arg autoHideDelay default = 5000ms?
         */
        showWMessage(message, autoHideDelay = 5000) {
            this.showMessage(message, 'warning', autoHideDelay)
        },
        /**
         * @vuese
         * method
         * @arg message \\
         * @arg autoHideDelay default = 5000ms?
         */
        showWRootMessage(message, autoHideDelay = 5000) {
            this.showRootMessage(message, 'warning', autoHideDelay)
        },
        /**
         * @vuese
         * method
         * @arg message \\
         * @arg autoHideDelay default = 5000ms?
         */
        showSMessage(message, autoHideDelay = 5000) {
            this.showMessage(message, 'success', autoHideDelay)
        },
        /**
         * @vuese
         * method
         * @arg message \\
         * @arg autoHideDelay default = 5000ms?
         */
        showSRootMessage(message, autoHideDelay = 5000) {
            this.showRootMessage(message, 'success', autoHideDelay)
        },
        /**
         * @vuese
         * method
         * @arg message \\
         * @arg variant \\
         * @arg autoHideDelay default = 5000ms?
         */
        showMessage(message, variant, autoHideDelay = 5000) {
            this.$bvToast.toast(message, this.getToastOptions(variant, autoHideDelay))
        },
        /**
         * @vuese
         * method
         * @arg message \\
         * @arg variant \\
         * @arg autoHideDelay default = 5000ms?
         */
        showRootMessage(message, variant, autoHideDelay = 5000) {
            this.$root.$bvToast.toast(message, this.getToastOptions(variant, autoHideDelay))
        },
        /**
         * @vuese
         * method
         * @arg variant \\
         * @arg autoHideDelay default = 5000ms?
         */
        getToastOptions(variant, autoHideDelay = 5000) {
            return {
                title: this.getToastTitle(variant),
                solid: true,
                variant: variant,
                noAutoHide: variant === 'danger',
                autoHideDelay: autoHideDelay,
                toaster: 'b-toaster-bottom-right',
                appendToast: true
            }
        },
        /**
         * @vuese
         * method
         * @arg variant
         */
        getToastTitle(variant) {
            if (variant === 'danger') {
                return i18n.t('toast.error')
            } else if (variant === 'warning') {
                return i18n.t('toast.warning')
            } else {
                return i18n.t('toast.success')
            }
        }
    }
}
