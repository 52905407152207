<template>
  <div :style="{backgroundColor: color, width: width, height: height, opacity: opacity}" class="lithology-type-preview"
       v-html="patternIcon.content">
  </div>
</template>

<script>
import LookupService from '@/services/lookup-service'

/**
 * @group Views-components-lithology
 * This is a description of the component
 */
export default {
  name: 'lithology-type-preview',
  props: {
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    },
    pattern: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    opacity: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      patternIcon: {
        name: null,
        fill: null,
        content: null
      }
    }
  },
  watch: {
    $props: {
      handler() {
        this.loadPreview()
      },
      deep: true
    }
  },
  mounted() {
    this.loadPreview()
  },
  destroyed() {
    this.patternIcon = null
  },
  methods: {
    loadPreview() {
      const patternName = this.pattern
      const patternFill = this.calcPatternFill(this.color)

      if (this.patternIcon.name === patternName && this.patternIcon.fill === patternFill) {
        return
      }

      let patternIcon = this.$store.getters.getPatternIcon(patternName, patternFill)
      if (patternIcon) {
        this.$set(this, 'patternIcon', patternIcon)
      } else {
        LookupService.patternIcon(patternName, patternFill).then((result) => {
          patternIcon = {
            name: patternName,
            fill: patternFill,
            content: result.data
          }
          this.$store.dispatch('setPatternIcon', patternIcon)
          this.$set(this, 'patternIcon', patternIcon)
        })
      }
    },
    calcPatternFill(color) {
      const rgb = this.hexToRgb(color)
      if ((rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > 186) {
        return 'black'
      } else {
        return 'white'
      }
    },
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null
    }
  }
}
</script>

<style lang="scss">
.lithology-type-preview {
  border-radius: 4px;

  svg {
    margin-top: -2px;
  }
}
</style>
