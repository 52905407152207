/**
 * @group Mixins
 * This is a description of the mixin
 */
export default {
    methods: {
        /**
         * @vuese
         * method
         * @arg dirty \\
         * @arg validated \\
         * @arg valid default = null
         */
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null
        }
    }
}
