<template>
  <b-navbar class="dp-header mx-0" fixed="top">
    <b-navbar-brand :to="{name: 'home'}">
      <b-img height="40" src="@/assets/img/logo.svg"></b-img>
    </b-navbar-brand>

    <b-navbar-nav>
      <b-nav-item v-if="isAuthorized && isUserGranted && !isSubscriptionUnpaid" :to="{name: 'projects'}"
                  class="menu-default">
        <b-icon :sensitive="false" class="d-md-none" icon="briefcase"></b-icon>
        <span class="d-none d-md-inline">{{ $t('my-projects') }}</span>
      </b-nav-item>
      <b-nav-item v-if="isAuthorized && showSubscriptionInfo" class="menu-default"
                  @click="extendSubscription">
        <b-icon :sensitive="false" class="d-md-none" icon="envelope"></b-icon>
        <span class="d-none d-md-inline">{{ $t('extend-subscription') }}</span>
      </b-nav-item>
      <b-nav-item :to="{name: 'help'}" class="menu-default">
        <dp-icon :sensitive="false" class="mr-2" name="question"></dp-icon>
        <span class="d-none d-md-inline">{{ $t('help') }}</span>
      </b-nav-item>
    </b-navbar-nav>

    <b-navbar-nav class="ml-auto">
      <b-nav-item-dropdown class="locale" no-caret>
        <template v-slot:button-content>
          <span>{{ $i18n.locale.toUpperCase() }}</span>
          <dp-icon :sensitive="false" class="ml-1" height="12" name="chevron-down" width="12"></dp-icon>
        </template>
        <b-dropdown-item @click="changeLocale('EN')">EN</b-dropdown-item>
        <b-dropdown-item @click="changeLocale('RU')">RU</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <span class="divider"></span>

    <b-navbar-nav v-if="hasUserInfo">
      <b-nav-item-dropdown class="user-info" no-caret>
        <template v-slot:button-content>
          <div class="d-flex flex-column">
            <div class="menu-default">
              <span>{{ userName }}</span>
              <dp-icon :sensitive="false" class="ml-1" height="12" name="chevron-down"
                       width="12"></dp-icon>
            </div>
            <div v-if="showSubscriptionInfo" class="subscription-days">
              {{ subscriptionDaysLeftDesc }}
            </div>
          </div>
        </template>
        <b-dropdown-item v-if="isAuthorized && isUserGranted && isProfileEnabled" @click="profile">
          {{ $t('profile') }}
        </b-dropdown-item>
        <b-dropdown-item @click="logout">{{ $t('logout') }}</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-navbar-nav v-else>
      <dp-button class="login-btn" @click="login">{{ $t('login') }}</dp-button>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import i18n from '@/plugins/i18n'
import {localize} from 'vee-validate'
import UserService from '@/services/user-service'
import AuthService from '@/services/auth-service'
import DpIcon from '@/components/icon/dp-icon'
import DpButton from '@/components/button/dp-button'
import MessageMixin from '@/mixins/message-mixin'

/**
 * @group Components
 * Standard components
 */
export default {
  name: 'dp-header',
  components: {
    DpIcon,
    DpButton
  },
  mixins: [MessageMixin],
  computed: {
    user() {
      return this.$store.getters.getProfile
    },
    userName() {
      return this.user.name ? this.user.name : this.user.email
    },
    showSubscriptionInfo() {
      return this.subscriptionDaysLeft <= 30 || this.isSubscriptionUnpaid
    },
    subscriptionDaysLeft() {
      return this.$store.getters.getDaysLeft
    },
    subscriptionDaysLeftDesc() {
      if (this.isSubscriptionUnpaid) {
        return this.$t('no-subscription')
      }
      const days = this.subscriptionDaysLeft
      return this.valueMessage(days, this.$t('subscription-days')).replace('{value}', days)
    },
    hasUserInfo() {
      return this.user && this.user.email
    },
    isUserGranted() {
      return this.$store.getters.isUserGranted
    },
    isSubscriptionUnpaid() {
      return this.$store.getters.isSubscriptionUnpaid
    },
    isAuthorized() {
      return this.$store.getters.isAuthorized
    },
    isProfileEnabled() {
      return process.env.VUE_APP_USE_AD_AUTH === 'false'
    }
  },
  methods: {
    changeLocale(locale) {
      if (i18n.locale === locale.toLowerCase()) {
        return
      }

      i18n.locale = locale.toLowerCase()
      localize(locale.toLowerCase())

      if (this.isAuthorized && this.hasUserInfo) {
        UserService.saveLocale(locale).then(() => {
          this.$log.debug(`User locale saved: ${locale}`)
        })
      }
    },
    login() {
      AuthService.authLocation().then((result) => {
        AuthService.redirect(result.data.location)
      })
    },
    logout() {
      AuthService.authLocation().then((result) => {
        UserService.logout().finally(() => {
          this.$store.dispatch('clearAuth')
          this.$store.dispatch('setUser', {})
          AuthService.redirect(result.data.location)
        })
      })
    },
    profile() {
      AuthService.profileLocation().then((result) => {
        window.open(result.data.location, '_blank')
      })
    },
    extendSubscription(e) {
      this.$emit('extend-subscription', e)
    }
  }
}
</script>

<style lang="scss">
.dp-header {
  background-color: $--color-base-01;
  height: 65px;
  width: 100%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  padding: 0 24px !important;

  .menu-default {
    .nav-link {
      color: $--color-primary !important;
      padding: 8px 16px !important;
    }
  }

  .navbar-brand {
    margin-right: 32px;
  }

  .locale.b-nav-dropdown {
    .nav-link {
      background-color: $--color-base-05;
      color: $--color-primary !important;
      font-size: $--font-size-small;
      font-weight: $--font-weight-light;
      font-style: normal;
    }
  }

  .divider {
    border-right: 1px solid $--color-base-02;
    padding-right: 24px;
    height: 24px;
    margin: auto 0;
  }

  .login-btn {
    margin-left: 24px;
  }

  .user-info.b-nav-dropdown {
    .nav-link {
      padding-left: 24px !important;
      font-style: normal;
      color: $--color-primary !important;

      .subscription-days {
        font-weight: $--font-weight-bold;
        font-size: $--font-size-small;
        line-height: 21px;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "my-projects": "My projects",
    "help": "Help",
    "login": "Log in",
    "logout": "Log out",
    "profile": "Profile",
    "extend-subscription": "Extend subscription",
    "no-subscription": "no active subscription",
    "subscription-days": [
      "1 day left",
      "{value} days left",
      "{value} days left",
      "{value} days left"
    ]
  },
  "ru": {
    "my-projects": "Мои проекты",
    "help": "Справка",
    "login": "Войти",
    "logout": "Выйти",
    "profile": "Профиль",
    "extend-subscription": "Продлить подписку",
    "no-subscription": "нет активной подписки",
    "subscription-days": [
      "остался 1 день",
      "остался {value} день",
      "осталось {value} дня",
      "осталось {value} дней"
    ]
  }
}
</i18n>
