import axios from 'axios'

/**
 * @group Services
 * describe the Services (it will not be shown in vuese yet)
 */
export default {
    userProjects() {
        return axios.get('/api/v1/projects')
    },
    project(projectId) {
        return axios.get(`/api/v1/project/${projectId}`)
    },
    preview(projectId) {
        return axios.get(`/api/v1/project/${projectId}/preview`, {
            responseType: 'blob'
        })
    },
    images(projectId) {
        return axios.get(`/api/v1/project/${projectId}/images`)
    },
    masks(projectId) {
        return axios.get(`/api/v1/project/${projectId}/masks`)
    },
    cores(projectId) {
        return axios.get(`/api/v1/project/${projectId}/cores`)
    },
    coreColumns(projectId) {
        return axios.get(`/api/v1/project/${projectId}/core-columns`)
    },
    isCoreColumnsReady(projectId) {
        return axios.get(`/api/v1/project/${projectId}/core-columns-ready`)
    },
    isPreClassificationReady(projectId) {
        return axios.get(`/api/v1/project/${projectId}/pre-classification-ready`)
    },
    isClassificationReady(projectId) {
        return axios.get(`/api/v1/project/${projectId}/classification-ready`)
    },
    hasDepthsIntersections(projectId) {
        return axios.get(`/api/v1/project/${projectId}/check/depths-intersections`)
    },
    hasCoreDepthsIntersections(projectId) {
        return axios.get(`/api/v1/project/${projectId}/check/core-depths-intersections`)
    },
    hasCorrectBoundingBoxesStatus(projectId) {
        return axios.get(`/api/v1/project/${projectId}/check/bounding-boxes-status`)
    },
    coreColumnColor(projectId) {
        return axios.get(`/api/v1/project/${projectId}/core-column-color`)
    },
    upload(projectId, projectFiles) {
        const formData = new FormData()
        projectFiles.forEach((f) => {
            formData.append('file', f.content)
        })
        return axios.post(`/api/v1/project/${projectId}/upload`, formData)
    },
    create(projectName, projectFiles) {
        const formData = new FormData()
        formData.append('project_name', projectName)
        projectFiles.forEach((f) => {
            formData.append('file', f.content)
        })
        return axios.post('/api/v1/project', formData)
    },
    rename(projectId, projectName) {
        const data = {
            project_id: projectId,
            project_name: projectName,
        }
        return axios.put('/api/v1/project', data)
    },
    deleteFile(projectID, fileIds) {
        // const formData = new FormData()
        // formData.append('fileIds', fileIds)
        // console.log("test", fileIds)
        return axios.post(`api/v1/project/${projectID}/delete-project-files`, {fileIds: fileIds})
    },
    delete(projectId) {
        return axios.delete(`/api/v1/project/${projectId}`)
    },
    nextStep(projectId) {
        return axios.patch(`/api/v1/project/${projectId}/step-next`)
    },
    previousStep(projectId) {
        return axios.patch(`/api/v1/project/${projectId}/step-prev`)
    },
    downloadFile(url, params, method = 'GET', data = null) {
        return axios({
            method: method,
            url: url,
            responseType: 'blob',
            params: params,
            data: data
        }).then((result) => {
            let fileName = result.headers['content-disposition'].split(';')[1].trim().split('=')[1]
            fileName = decodeURIComponent(fileName.replace(/utf-8''/g, ''))
            if (navigator && navigator.msSaveBlob) { // for IE
                navigator.msSaveBlob(result.data, fileName)
            } else { // for Non-IE (chrome, firefox etc.)
                let link = document.createElement('a')
                link.href = URL.createObjectURL(result.data)
                link.download = fileName
                link.click()
                URL.revokeObjectURL(link.href)
                link = null
            }

            return true
        })
    },
    // downloadCores(projectId, registry = true, templates) {
    //   return this.downloadFile(`/api/v1/project/${projectId}/download/cores`, { registry, templates })
    // },
    downloadCores(projectId, registry = true, locale, templates) {
        const data = {
            registry,
            locale: locale.toUpperCase(),
            templates
        }
        return this.downloadFile(`/api/v1/project/${projectId}/download/cores`, null, 'POST', data)
        //return axios.post(`/api/v1/project/${projectId}/download/cores`, { registry: registry, locale: locale, templates: templates })
    },
    // downloadCoreColumns(projectId, registry = true, templates) {
    //   return this.downloadFile(`/api/v1/project/${projectId}/download/core-columns`, { registry, templates })
    // },
    downloadCoreColumns(projectId, registry = true, locale, templates) {
        const data = {
            registry,
            locale: locale.toUpperCase(),
            templates
        }
        return this.downloadFile(`/api/v1/project/${projectId}/download/core-columns`, null, 'POST', data)
        //return axios.post(`/api/v1/project/${projectId}/download/core-columns`, { registry: registry, locale: locale, templates: templates })
    },
    downloadLithology(projectId, column = true, legend = true,
                      registry = true, locale = 'EN', segmentation = null, templates,
                      generation = true) {
        const data = {
            column,
            legend,
            registry,
            locale: locale.toUpperCase(),
            segmentation,
            templates,
            generation,
        }
        return this.downloadFile(`/api/v1/project/${projectId}/download/lithology`, null, 'POST', data)
    },
    saveCoringDirection(projectId, coringDirection) {
        const data = {
            coring_direction: coringDirection
        }
        return axios.patch(`/api/v1/project/${projectId}/set-coring-direction`, data)
    },
    saveCoreColumnHeight(projectId, coreColumnHeight) {
        const data = {
            core_column_height: coreColumnHeight
        }
        return axios.patch(`/api/v1/project/${projectId}/set-core-column-height`, data)
    },
    coreColumnSplits(projectId) {
        return axios.get(`/api/v1/project/${projectId}/splits`)
    },
    saveCoreColumnSplits(projectId, splits) {
        return axios.post(`/api/v1/project/${projectId}/splits`, splits)
    },
    saveClassificationModel(projectId, modelId, types, changedTypes) {
        const data = {
            model_id: modelId,
            classes: types,
            changed_classes: changedTypes.map((t) => {
                return {
                    id: t.id,
                    name: t.name,
                    color: t.color,
                    pattern: t.pattern
                }
            })
        }
        return axios.post(`/api/v1/project/${projectId}/set-classification-model`, data)
    },
    lithologyTypes(projectId) {
        return axios.get(`/api/v1/project/${projectId}/lithology-types`)
    },
    lithologyFillPattern(projectId, color, pattern) {
        return axios.get(`/api/v1/project/${projectId}/lithology-image`, {
            responseType: 'blob',
            params: {name: pattern, bg_color: color}
        })
    },
    lithologyElements(projectId) {
        return axios.get(`/api/v1/project/${projectId}/lithology`)
    },
    saveLithologyElements(projectId, lithologyElements) {
        return axios.post(`/api/v1/project/${projectId}/lithology`, lithologyElements)
    },
    getClassificationTemplates() {
        return axios.get(`/api/v1/project/reports/classification_report_templates`)
    },
    getCoreTemplates() {
        return axios.get(`/api/v1/project/reports/core_report_templates`)
    }
}
