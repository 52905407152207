<template>
  <div v-show="display" :class="componentClasses" :style="componentStyles" class="core-depth">
    <div v-if="pointerPosition" :class="arrowClasses"></div>
    <div class="title mb-1">{{ $t('title') }}</div>
    <validation-observer ref="validation-observer" v-slot="{ handleSubmit }">
      <b-form inline @submit.prevent="handleSubmit(apply)">
        <validation-provider v-slot="validationContext"
                             :rules="rules"
                             name="depth">
          <b-form-group id="depth-group">
            <b-form-input id="depth"
                          v-model="innerValue"
                          v-mask="mask"
                          :state="validationContext.touched ? validationContext.valid : null"
                          @change="changeDepth">
            </b-form-input>
          </b-form-group>
        </validation-provider>
        <dp-button class="ml-auto" secondary type="submit">{{ $t('actions.apply') }}</dp-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import DpButton from '@/components/button/dp-button'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import i18n from '@/plugins/i18n'

/**
 * @group Views-components
 * This is a description of the component it includes DpButton component
 */
export default {
  name: 'core-depth',
  components: {
    DpButton
  },
  props: {
    /**
     * title
     */
    value: {
      type: Number,
      required: true
    },
    /**
     * title
     */
    rules: {
      type: String,
      default: 'required'
    },
    /**
     * title
     */
    active: {
      type: Boolean,
      default: true
    },
    /**
     * title
     */
    pointerPosition: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data() {
    return {
      /**
       * @vuese
       * the data
       */
      mask: createNumberMask({
        prefix: '',
        suffix: '',
        allowDecimal: true,
        requireDecimal: true,
        includeThousandsSeparator: false,
        allowNegative: false
      }),
      /**
       * @vuese
       * the data
       */
      innerValue: this.value.toFixed(2),
      /**
       * @vuese
       * the data
       */
      arrowPosition: 'top'
    }
  },
  computed: {
    /**
     * @vuese
     * title
     */
    display: {
      get() {
        return this.active
      },
      set(newValue) {
        this.$emit('update:active', newValue)
      }
    },
    /**
     * @vuese
     * title
     */
    componentClasses() {
      const classes = []
      classes.push(this.$i18n.locale.toLowerCase())
      if (this.pointerPosition) {
        classes.push('position-absolute')
      }
      return classes
    },
    /**
     * @vuese
     * title
     */
    componentStyles() {
      const centerX = this.pointerPosition ? this.pointerPosition.x : 0
      const centerY = this.pointerPosition ? this.pointerPosition.y : 0
      const offsetX = this.pointerPosition ? this.pointerPosition.offsetX : 0
      const offsetY = this.pointerPosition ? this.pointerPosition.offsetY : 0

      const elWidth = i18n.locale.toLowerCase() === 'ru' ? 293 : 242
      const elHeight = 81
      const x = centerX - elWidth / 2
      const y = centerY + offsetY + 18 + 8
      const parentWidth = this.$el && this.$el.parentNode ? this.$el.parentNode.clientWidth : 0
      const paddingWidth = 36
      const parentHeight = this.$el && this.$el.parentNode ? this.$el.parentNode.clientHeight : 0
      const position = {
        left: '',
        top: ''
      }
      if (elWidth < centerX && elHeight / 2 < centerY) {
        // component is on the left side
        this.setArrowPosition('right')
        position.left = this.pointerPosition ? `${centerX - elWidth - offsetX - 18 - 8}px` : 0
        position.top = centerY - elHeight / 2 + 'px'
      } else if (elHeight + centerY < parentHeight) {
        // component is on the bottom side
        this.setArrowPosition('top')
        position.left = this.pointerPosition ? `${x}px` : 0
        position.top = this.pointerPosition ? `${y}px` : 0
      } else if (elHeight / 2 < centerY && elWidth + centerX + paddingWidth < parentWidth) {
        // component is on the right side
        this.setArrowPosition('left')
        position.left = this.pointerPosition ? `${centerX + offsetX + 18 + 8}px` : 0
        position.top = centerY - elHeight / 2 + 'px'
      } else if (centerY - elHeight - offsetY > 0) {
        // component is on the top side
        this.setArrowPosition('bottom')
        position.left = this.pointerPosition ? `${x}px` : 0
        position.top = this.pointerPosition ? `${centerY - elHeight - offsetY - 18 - 8}px` : 0
      }
      return position
    },
    /**
     * @vuese
     * title
     */
    arrowClasses() {
      return ['arrow', this.arrowPosition]
    }
  },
  /**
   * @vuese
   * title
   */
  watch: {
    value(newValue) {
      this.innerValue = newValue.toFixed(2)
    }
  },
  methods: {
    /**
     * @vuese
     * title
     * @arg value - arrow position
     */
    setArrowPosition(value) {
      this.arrowPosition = value
    },
    /**
     * @vuese
     * title
     * @arg newValue - arrow position
     */
    changeDepth(newValue) {
      if (newValue) {
        this.innerValue = parseFloat(newValue).toFixed(2)
      } else {
        this.innerValue = null
      }
    },
    /**
     * @vuese
     * emmits core-depth-apply action with float this.innerValue
     * resets validation-observer
     */
    apply() {
      this.$emit('core-depth-apply', parseFloat(this.innerValue))
      this.display = false
      this.$refs['validation-observer'].reset()
    }
  }
}
</script>

<style lang="scss" scoped>
.core-depth {
  width: 242px;
  height: 81px;
  background-color: $--color-base-01;
  box-shadow: 5px 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px;
  z-index: 1;

  &.ru {
    width: 293px;
  }

  .arrow {
    position: absolute;
    width: 0;
    height: 0;

    &.right {
      right: -18px;
      top: calc(50% - 18px);
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
      border-left: 18px solid $--color-base-01;
    }

    &.left {
      left: -18px;
      top: calc(50% - 18px);
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
      border-right: 18px solid $--color-base-01;
    }

    &.top {
      top: -18px;
      left: calc(50% - 18px);
      border-right: 18px solid transparent;
      border-left: 18px solid transparent;
      border-bottom: 18px solid $--color-base-01;
    }

    &.bottom {
      bottom: -18px;
      left: calc(50% - 18px);
      border-right: 18px solid transparent;
      border-left: 18px solid transparent;
      border-top: 18px solid $--color-base-01;
    }
  }

  .title {
    font-weight: $--font-weight-bold;
    font-size: $--font-size-small;
    line-height: 21px;
  }

  input.form-control {
    width: 130px;
  }

  .dp-button {
    margin-left: 12px !important;
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Depth",
    "actions": {
      "apply": "Apply"
    }
  },
  "ru": {
    "title": "Глубина",
    "actions": {
      "apply": "Применить"
    }
  }
}
</i18n>
