/**
 * @group Store-modules
 * describe the store (it will not be shown in vuese yet)
 */
const state = {
    profile: {
        name: null,
        email: null,
        subscriptions: []
    },
    granted: false,
    unpaid: false,
    daysLeft: null
}

const getters = {
    getProfile: (state) => state.profile,
    getDaysLeft: (state) => state.daysLeft,
    isUserGranted: (state) => state.granted,
    isSubscriptionUnpaid: (state) => state.unpaid
}

const mutations = {
    setUser(state, user) {
        state.profile = user

        if (user && user.email) {
            const activeSubscriptions = user.subscriptions ? user.subscriptions.filter((s) => s.application_name === 'deep-core' && s.active) : []

            state.unpaid = !activeSubscriptions || activeSubscriptions.length === 0
            state.granted = !state.unpaid

            if (activeSubscriptions && activeSubscriptions.length > 0) {
                const days = activeSubscriptions.map((s) => s.days_left)
                state.daysLeft = Math.max(...days)
            } else {
                state.daysLeft = 0
            }
        } else {
            state.granted = false
            state.unpaid = false
            state.daysLeft = null
        }
    },
    setGranted(state, granted) {
        state.granted = granted
    },
    setUnpaid(state, unpaid) {
        state.unpaid = unpaid
    }
}

const actions = {
    setUser(context, user) {
        context.commit('setUser', user)
    },
    setGranted(context, granted) {
        context.commit('setGranted', granted)
    },
    setUnpaid(context, unpaid) {
        context.commit('setUnpaid', unpaid)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
