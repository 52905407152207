<template>
  <div class="lithology-type-settings">
    <div :class="displayColorError ? 'error' : null" class="lithology-type-color">
      <div class="title">
        <span>{{ $t('color-title') }}</span>
        <dp-icon v-if="displayColorError" :sensitive="false" name="info"></dp-icon>
      </div>
      <vue-custom-scrollbar :settings="scrollYSettings" class="color-container scrollable-container"
                            tagname="div">
        <div v-for="color in colors" :key="color"
             :class="{'active' : isActiveColor(color), 'disabled': isDisabledColor(color)}"
             class="color"
             @click="!isDisabledColor(color) && (settingsColor = color)">
          <div :style="{background: color, opacity: isDisabledColor(color) ? 0.1 : 1}"></div>
        </div>
      </vue-custom-scrollbar>
    </div>
    <div :class="displayPatternError ? 'error' : null" class="lithology-type-pattern">
      <div class="title">
        <span>{{ $t('pattern-title') }}</span>
        <dp-icon v-if="displayPatternError" :sensitive="false" name="info"></dp-icon>
      </div>
      <vue-custom-scrollbar :settings="scrollYSettings" class="pattern-container scrollable-container"
                            tagname="div">
        <div v-for="pattern in patterns" :key="pattern"
             :class="{'active' : isActivePattern(pattern), 'disabled': isDisabledPattern(pattern)}"
             class="pattern"
             @click="!isDisabledPattern(pattern) && (settingsPattern = pattern)">
          <lithology-type-preview :height="24" :opacity="isDisabledPattern(pattern) ? 0.2 : 1"
                                  :pattern="pattern"
                                  :width="24" color="#E8E8E8"/>
        </div>
      </vue-custom-scrollbar>
    </div>
  </div>
</template>

<script>
import VueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import DpIcon from '@/components/icon/dp-icon'
import LithologyTypePreview from '@/views/components/lithology/lithology-type-preview'
import ScrollMixin from '@/mixins/scroll-mixin'
import LookupService from '@/services/lookup-service'

/**
 * @group Views-components-lithology
 * This is a description of the component
 */
export default {
  name: 'lithology-type-settings',
  components: {DpIcon, LithologyTypePreview, VueCustomScrollbar},
  mixins: [ScrollMixin],
  props: {
    settings: {
      type: Object,
      default: () => {
        return {
          id: null,
          color: null,
          pattern: null
        }
      }
    },
    disabledSettings: {
      type: Array,
      default: () => {
        return []
      }
    },
    errorEnabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    colors() {
      return this.$store.getters.getColors
    },
    patterns() {
      return this.$store.getters.getPatternNames
    },
    settingsColor: {
      get() {
        return this.settings.color
      },
      set(newValue) {
        this.$emit('update:settings', {
          id: this.settings.id,
          color: newValue,
          pattern: this.settings.pattern
        })
      }
    },
    settingsPattern: {
      get() {
        return this.settings.pattern
      },
      set(newValue) {
        this.$emit('update:settings', {
          id: this.settings.id,
          color: this.settings.color,
          pattern: newValue
        })
      }
    },
    disabledColors() {
      if (this.disabledSettings && this.disabledSettings.length > 0 && this.settingsPattern) {
        return this.disabledSettings.filter((s) => {
          return s.pattern.toUpperCase() === this.settingsPattern.toUpperCase() && s.id !== this.settings.id
        }).map((s) => s.color.toUpperCase())
      } else {
        return []
      }
    },
    disabledPatterns() {
      if (this.disabledSettings && this.disabledSettings.length > 0 && this.settingsColor) {
        return this.disabledSettings.filter((s) => {
          return s.color.toUpperCase() === this.settingsColor.toUpperCase() && s.id !== this.settings.id
        }).map((s) => s.pattern.toUpperCase())
      } else {
        return []
      }
    },
    displayColorError() {
      return this.errorEnabled && !this.settingsColor
    },
    displayPatternError() {
      return this.errorEnabled && !this.settingsPattern
    }
  },
  mounted() {
    if (this.patterns.length === 0) {
      LookupService.patternNames().then((result) => {
        return this.$store.dispatch('setPatternNames', result.data.names)
      })
    }
  },
  methods: {
    isActiveColor(color) {
      return this.settingsColor && this.settingsColor.toUpperCase() === color.toUpperCase()
    },
    isActivePattern(pattern) {
      return this.settingsPattern && this.settingsPattern.toUpperCase() === pattern.toUpperCase()
    },
    isDisabledColor(color) {
      return this.disabledColors.includes(color.toUpperCase())
    },
    isDisabledPattern(pattern) {
      return this.disabledPatterns.includes(pattern.toUpperCase())
    }
  }
}
</script>

<style lang="scss">
.lithology-type-settings {
  display: flex;
  flex-wrap: nowrap;

  .lithology-type-color, .lithology-type-pattern {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
      color: $--color-primary;
      font-weight: $--font-weight-semi-bold;
      font-size: $--font-size-base;
      line-height: 24px;
    }

    &.error {
      .title {
        color: $--color-red-300;

        svg {
          fill: $--color-red-300;
        }
      }
    }
  }

  .color-container, .pattern-container {
    width: 232px;
    max-height: 128px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    margin-left: -4px;

    .color, .pattern {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      > div {
        width: 24px;
        height: 24px;
        border-radius: 4px;
      }

      &.disabled {
        cursor: not-allowed;
      }

      &.active {
        border: 1px solid $--color-secondary;
        box-sizing: border-box;
        border-radius: 4px;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "color-title": "Choose color",
    "pattern-title": "Choose pattern"
  },
  "ru": {
    "color-title": "Выберите цвет",
    "pattern-title": "Выберите паттерн"
  }
}
</i18n>
