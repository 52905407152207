<template>
  <b-modal
      v-model="display"
      :hide-header-close="actionsDisabled"
      :title="$t('title')"
      centered
      modal-class="cores-download-modal"
      no-close-on-backdrop
      no-close-on-esc
      title-class="windows-title"
      @ok.prevent="download"
  >
    <template v-slot:modal-header-close>
      <dp-icon name="close"></dp-icon>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <dp-button :disabled="actionsDisabled" ghost @click="cancel">{{
          $t("cancel")
        }}
      </dp-button>
      <dp-button :disabled="actionsDisabled" secondary @click="ok">
        {{ $t("download") }}
      </dp-button
      >
    </template>
    <template v-slot:default>
      <div class="d-flex justify-content-between">
        <div>
          <div class="subtitle">{{ $t("subtitle") }}</div>
          <div class="options">
            <div
                v-for="option in Object.keys(downloadOptions)"
                :key="option"
                class="d-flex flex-column"
            >
              <div class="option">
                <dp-radio
                    :value.sync="downloadOptions[option].checked"
                    class="option-radio"
                >
                </dp-radio>
                <div
                    class="option-title clickable"
                    @click="
                    downloadOptions[option].checked =
                      !downloadOptions[option].checked
                  "
                >
                  {{ $t(`options.${option}`) }}
                </div>
              </div>

              <div class="option">
                <dp-checkbox
                    :disabled="!downloadOptions[option].checked"
                    :value.sync="downloadOptions[option].registry"
                    class="option-checkbox"
                >
                </dp-checkbox>
                <div
                    class="option-title clickable"
                    @click="switchRegistry(option)"
                >
                  {{ $t(`options.registry`) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="subtitle">{{ $t(`template`) }}</div>
          <!-- <b-dropdown
            no-caret
            class="model-dropdown"
            @shown="updateModelItemsScrollbar"
          >
            <template v-slot:button-content>
              <div class="d-flex flex-row pr-3 pl-3">
                <div
                  v-if="selectedModel"
                  :title="getModelName(selectedModel)"
                  class="model-name text-truncate"
                >
                  {{ getModelName(selectedModel) }}
                </div>
                <div class="ml-auto mt-0">
                  <dp-icon
                    name="chevron-down"
                    width="24"
                    height="24"
                    :sensitive="false"
                  >
                  </dp-icon>
                </div>
              </div>
            </template>
            <vue-custom-scrollbar
              ref="model-items-scrollbar"
              class="dropdown-model-items scrollable-container"
              tagname="div"
              :settings="scrollYSettings"
            >
              <b-form-checkbox
                v-model="selectedModel"
                v-for="option in Object.keys(templateOptions)"
                :key="option"
                :value.sync="templateOptions[option]"
                class="mb-3 dp-checkbox-form"
                >{{ $t(`${option}`) }}
              </b-form-checkbox>
            </vue-custom-scrollbar>
          </b-dropdown> -->
          <b-dropdown
              :disabled="disabled == 1"
              class="model-dropdown"
              no-caret
              @shown="updateModelItemsScrollbar"
          >
            <template v-slot:button-content>
              <div class="d-flex flex-row pr-3 pl-3">
                <div :title="selectedFormat" class="model-name text-truncate">
                  {{ selectedFormat.join() }}
                </div>
                <div class="ml-auto mt-0">
                  <dp-icon
                      :sensitive="false"
                      height="24"
                      name="chevron-down"
                      width="24"
                  >
                  </dp-icon>
                </div>
              </div>
            </template>
            <vue-custom-scrollbar
                ref="model-items-scrollbar"
                :settings="scrollYSettings"
                class="dropdown-model-items scrollable-container"
                tagname="div"
            >
              <b-form-group class="mb-0">
                <template v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                      id="templateOptions"
                      v-model="selected"
                      :aria-describedby="ariaDescribedby"
                      :options="newTemplateOptions"
                      aria-label="Individual templateOptions"
                      class="mb-0 dp-checkbox-form"
                      name="templateOptions"
                      stacked
                      text-field="captions"
                      value-field="id"
                  ></b-form-checkbox-group>
                </template>
              </b-form-group>
            </vue-custom-scrollbar>
          </b-dropdown>
        </div>
      </div>
      <b-overlay :opacity="0.3" :show="actionsDisabled" no-wrap></b-overlay>
    </template>
  </b-modal>
</template>

<script>
import VueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import DpButton from "@/components/button/dp-button";
import DpIcon from "@/components/icon/dp-icon";
import ProjectService from "@/services/project-service";
import DpCheckbox from "@/components/dp-checkbox";
import DpRadio from "@/components/dp-radio";
import ScrollMixin from "@/mixins/scroll-mixin";

/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: "cores-download",
  components: {DpRadio, DpCheckbox, DpButton, DpIcon, VueCustomScrollbar},
  mixins: [ScrollMixin],
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      actionsDisabled: false,
      downloadOptions: {
        cores: {
          checked: true,
          registry: true,
        },
        columns: {
          checked: false,
          registry: false,
        },
      },
      // templateOptions: {
      //   DeepCore: true,
      //   MineVisioProject: true,
      //   MiMGO: true,
      // },
      templateOptions: [{id: "", captions: ""}],
      newTemplateOptions: [],
      selected: [],
      indeterminate: false,
      selectedFormat: [],
      len: 1,
      disabled: 0,
    };
  },
  computed: {
    display: {
      get() {
        return this.active;
      },
      set(newValue) {
        this.$emit("update:active", newValue);
      },
    },
  },
  watch: {
    "$i18n.locale": {
      handler() {
        this.selected = [];
        this.selectedFormat = [];
        this.templateOptions = [];
        this.newTemplateOptions = [];
        ProjectService.getCoreTemplates().then((result) => {
          this.templateOptions = result.data.core_report_templates;
          let obj;
          this.templateOptions.filter((i) => {
            obj = {
              id: i.id,
              captions: i.captions[this.$i18n.locale.toUpperCase()],
            };
            this.newTemplateOptions.push(obj);

            if (i.default === true) {
              this.selected.push(i.id),
                  this.selectedFormat.push(
                      i.captions[this.$i18n.locale.toUpperCase()]
                  );
            }
          });
        });
      },
    },
    selected() {
      if (this.selected.length > this.len) {
        this.len = this.selected.length;
        this.selected.filter((a) => {
          this.templateOptions.filter((b) => {
            if (a === b.id) {
              if (
                  this.selectedFormat.includes(
                      b.captions[this.$i18n.locale.toUpperCase()]
                  )
              ) {
                return;
              } else {
                this.selectedFormat.push(
                    b.captions[this.$i18n.locale.toUpperCase()]
                );
              }
            }
          });
        });
      }
      if (this.selected.length < this.len) {
        (this.selectedFormat = []), (this.len = this.selected.length);
        this.selected.filter((a) => {
          this.templateOptions.filter((b) => {
            if (a === b.id) {
              if (
                  this.selectedFormat.includes(
                      b.captions[this.$i18n.locale.toUpperCase()]
                  )
              ) {
                return;
              } else {
                this.selectedFormat.push(
                    b.captions[this.$i18n.locale.toUpperCase()]
                );
              }
            }
          });
        });
      }
    },
    active(value) {
      if (value) {
        this.downloadOptions.cores.checked = true;
        this.downloadOptions.cores.registry = true;
        this.downloadOptions.columns.checked = false;
        this.downloadOptions.columns.registry = false;
        this.disabled = 0;
      }
    },
    "downloadOptions.cores.checked"(value) {
      if (!value) {
        this.downloadOptions.cores.registry = false;
      }
      this.downloadOptions.columns.checked = !value;
      this.downloadOptions.columns.registry = !value;
    },
    "downloadOptions.columns.checked"(value) {
      if (!value) {
        this.downloadOptions.columns.registry = false;
      }
      this.downloadOptions.cores.checked = !value;
      this.downloadOptions.cores.registry = !value;
    },
  },
  methods: {
    switchRegistry(option) {
      if (this.downloadOptions[option].checked) {
        this.downloadOptions[option].registry =
            !this.downloadOptions[option].registry;
      }
    },
    download() {
      this.actionsDisabled = true;
      //let locale = this.$i18n.locale.toUpperCase();
      let promise;
      if (this.downloadOptions.cores.checked) {
        promise = ProjectService.downloadCores(
            this.projectId,
            this.downloadOptions.cores.registry,
            this.$i18n.locale.toUpperCase(),
            this.selected
        );
      } else {
        promise = ProjectService.downloadCoreColumns(
            this.projectId,
            this.downloadOptions.columns.registry,
            this.$i18n.locale.toUpperCase(),
            this.selected
        );
      }

      promise.finally(() => {
        this.actionsDisabled = false;
        this.display = false;
      });
    },
    updateModelItemsScrollbar() {
      this.$nextTick(() => {
        this.$refs["model-items-scrollbar"].update();
      });
    },
  },
  mounted() {
    ProjectService.getCoreTemplates().then((result) => {
      this.templateOptions = result.data.core_report_templates;
      let obj;
      this.templateOptions.filter((i) => {
        obj = {
          id: i.id,
          captions: i.captions[this.$i18n.locale.toUpperCase()],
        };
        this.newTemplateOptions.push(obj);

        if (i.default === true) {
          this.selected.push(i.id),
              this.selectedFormat.push(
                  i.captions[this.$i18n.locale.toUpperCase()]
              );
        }
      });
    });
  },
};
</script>

<style lang="scss">
.cores-download-modal {
  .modal-dialog {
    max-width: 598px;
    max-height: 370px;

    .modal-body {
      padding: 20px 0 85px !important;
    }
  }

  .subtitle {
    font-weight: $--font-weight-semi-bold;
    font-size: $--font-size-base;
    line-height: 24px;
  }

  .options {
    margin-top: 16px;

    .option {
      display: flex;
      align-items: center;
      margin-top: 0;
      margin-bottom: 8px;
      height: 24px;

      .option-title {
        font-weight: $--font-weight-medium;
        font-size: $--font-size-small !important;
        line-height: 24px;
      }

      .option-checkbox {
        margin-left: 28px;
        margin-right: 8px;
      }

      .option-radio {
        margin-left: 4px;
        margin-right: 8px;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Download results",
    "subtitle": "Choose data",
    "download": "Download",
    "cancel": "Cancel",
    "options": {
      "cores": "Separated columns (.jpg)",
      "columns": "Stitched core (.png)",
      "registry": "Image depth referencing (.xslx)"
    },
    "template": "Templates"
  },
  "ru": {
    "title": "Экспорт результатов",
    "subtitle": "Выберите данные",
    "download": "Скачать",
    "cancel": "Отмена",
    "options": {
      "cores": "Отдельные колонки (.jpg)",
      "columns": "Объединённый керн (.png)",
      "registry": "Привязка изображений по глубине (.xslx)"
    },
    "template": "Шаблоны"
  }
}
</i18n>
