<template>
  <b-modal v-model="display" :title="$t('title')" centered
           no-close-on-backdrop title-class="windows-title"
           @ok.prevent="rename">
    <template v-slot:modal-header-close>
      <dp-icon name="close"></dp-icon>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <dp-button ghost @click="cancel">{{ $t('cancel') }}</dp-button>
      <dp-button :disabled="actionsDisabled" @click="ok">{{ $t('rename') }}</dp-button>
    </template>
    <template v-slot:default>
      <b-form @submit.prevent="rename">
        <validation-provider ref="project-name-validator"
                             v-slot="validationContext"
                             name="project-name"
                             rules="required|max:250">
          <b-form-group id="project-name-group"
                        :invalid-feedback="validationContext.errors[0]"
                        :label="$t('project-name-label')"
                        label-for="project-name">
            <b-form-input id="project-name"
                          v-model="innerProjectName"
                          :disabled="actionsDisabled"
                          :state="getValidationState(validationContext)">
            </b-form-input>
          </b-form-group>
        </validation-provider>
      </b-form>
    </template>
  </b-modal>
</template>

<script>
import DpIcon from '@/components/icon/dp-icon'
import DpButton from '@/components/button/dp-button'
import ValidationMixin from '@/mixins/validation-mixin'
import ProjectService from '@/services/project-service'

/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: 'project-renaming',
  components: {
    DpIcon,
    DpButton
  },
  mixins: [ValidationMixin],
  props: {
    active: {
      type: Boolean,
      default: false
    },
    projectId: {
      required: true
    },
    projectName: {
      required: true
    }
  },
  data() {
    return {
      actionsDisabled: false,
      innerProjectName: null
    }
  },
  computed: {
    display: {
      get() {
        return this.active
      },
      set(newValue) {
        this.$emit('update:active', newValue)
      }
    }
  },
  watch: {
    projectName(value) {
      this.innerProjectName = value
    }
  },
  methods: {
    rename() {
      this.actionsDisabled = true

      this.$refs['project-name-validator'].validate().then((result) => {
        if (result.valid) {
          if (this.projectName === this.innerProjectName) {
            this.$emit('update:active', false)
            this.actionsDisabled = false
          } else {
            ProjectService.rename(this.projectId, this.innerProjectName).then(() => {
              this.$emit('update:projectName', this.innerProjectName)
              this.$emit('update:active', false)
            }).finally(() => {
              this.actionsDisabled = false
            })
          }
        } else {
          this.actionsDisabled = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<i18n>
{
  "en": {
    "title": "Rename project",
    "rename": "Rename",
    "cancel": "Cancel",
    "project-name-label": "Project name"
  },
  "ru": {
    "title": "Переименовать проект",
    "rename": "Переименовать",
    "cancel": "Отмена",
    "project-name-label": "Наименование проекта"
  }
}
</i18n>
