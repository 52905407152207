var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project-preview d-flex w-100"},[_c('image-previews',{ref:"imagePreviews",attrs:{"active-image":_vm.image,"images":_vm.images,"steps":_vm.step},on:{"update:activeImage":function($event){_vm.image=$event},"update:active-image":function($event){_vm.image=$event},"loadImages":_vm.loadImages}}),_c('div',{ref:"imageContainer",staticClass:"image-container flex-grow-1"},[_c('image-preview',{staticClass:"preview",attrs:{"image":_vm.imagePreview.image,"scale":_vm.image.config.scaleX,"viewport":_vm.imagePreview.viewport}}),_c('image-filename',{staticClass:"filename",attrs:{"filename":_vm.image.file_name}}),_c('v-stage',{ref:"canvasStage",staticClass:"image-canvas",attrs:{"config":_vm.canvasConfig},on:{"contextmenu":(e) => {
          e.evt.preventDefault();
        }}},[_c('v-layer',{ref:"canvasLayer"},[(_vm.imageLoaded)?_c('v-image',{ref:"canvasImage",attrs:{"config":_vm.image.config}}):_vm._e(),(_vm.image.crop.enabled)?_c('v-group',{ref:"canvasCropGroup",attrs:{"config":_vm.image.crop.groupConfig}},[_c('v-image',{attrs:{"config":_vm.image.crop.imageConfig}})],1):_vm._e(),(_vm.image.crop.enabled)?_c('v-rect',{ref:"canvasCropRect",attrs:{"config":_vm.image.crop.rectConfig}}):_vm._e(),(_vm.image.crop.enabled)?_c('v-transformer',{ref:"canvasTransformer",attrs:{"config":_vm.image.crop.transformerConfig}}):_vm._e()],1)],1)],1),_c('image-toolbar',{ref:"imageToolbar",attrs:{"bottom-depth":_vm.image.bottom_depth,"core-column-height":_vm.projectOptions.coreColumnHeight,"coring-direction":_vm.projectOptions.coringDirection,"depths-enabled":true,"editing-tools":[
      'rotate-right',
      'rotate-left',
      'reflect-v',
      'reflect-h',
      'crop',
    ],"redo-enabled":_vm.redoEnabled,"top-depth":_vm.image.top_depth,"undo-enabled":_vm.undoEnabled},on:{"update:coreColumnHeight":function($event){return _vm.$set(_vm.projectOptions, "coreColumnHeight", $event)},"update:core-column-height":function($event){return _vm.$set(_vm.projectOptions, "coreColumnHeight", $event)},"update:coringDirection":function($event){return _vm.$set(_vm.projectOptions, "coringDirection", $event)},"update:coring-direction":function($event){return _vm.$set(_vm.projectOptions, "coringDirection", $event)},"crop":_vm.startCropImage,"hand":function($event){return _vm.handImage(true)},"redo":_vm.redoImage,"undo":_vm.undoImage,"rotate-right":_vm.rotateImageRight,"rotate-left":_vm.rotateImageLeft,"reflect-v":_vm.reflectImageVertically,"reflect-h":_vm.reflectImageHorizontally,"crop-apply":_vm.applyCropImage,"crop-cancel":_vm.cancelCropImage,"crop-rotation":_vm.changeCropImageRotation,"zoom-in":_vm.zoomImageIn,"zoom-out":_vm.zoomImageOut,"core-column-height-apply":_vm.saveCoreColumnHeight,"coring-direction-apply":_vm.saveCoringDirection,"depths-apply":_vm.saveDepths,"switch-editing-tools":function($event){_vm.projectOptions.accepted = true},"switch-project-options":function($event){_vm.projectOptions.accepted = true}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }