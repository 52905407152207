<template>
  <b-modal v-model="display" :title="dialogTitle" centered hide-header-close
           no-close-on-backdrop title-class="windows-title"
           @cancel="disagree"
           @ok="agree">
    <template v-slot:modal-footer="{ ok, cancel }">
      <dp-button ghost @click="cancel">{{ $t('no') }}</dp-button>
      <dp-button @click="ok">{{ $t('yes') }}</dp-button>
    </template>
    <template v-if="question" v-slot:default>
      {{ question }}
    </template>
    <template v-else v-slot:default>
      <slot></slot>
    </template>
  </b-modal>
</template>

<script>
import DpButton from '@/components/button/dp-button'

/**
 * @group Components
 * Standard components
 */
export default {
  name: 'dp-decision-dialog',
  components: {
    DpButton
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    question: {
      type: String
    }
  },
  computed: {
    display: {
      get() {
        return this.active
      },
      set(newValue) {
        this.$emit('update:active', newValue)
      }
    },
    dialogTitle() {
      if (this.title) {
        return this.title
      } else {
        return this.$t('title')
      }
    }
  },
  methods: {
    agree() {
      this.$emit('agree')
    },
    disagree() {
      this.$emit('disagree')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<i18n>
{
  "en": {
    "title": "Notice",
    "yes": "Yes",
    "no": "No"
  },
  "ru": {
    "title": "Внимание",
    "yes": "Да",
    "no": "Нет"
  }
}
</i18n>
