<template>
  <b-container class="help">
    <b-row>
      <b-col class="content">
        <h2 class="header-bottom-margin">{{ $t('title') }}</h2>
        <div>
          <h3 id="short-desc" ref="short-desc">{{ $t('short-desc') }}</h3>
          <p>{{ $t('short-desc-1') }}</p>
          <h4 id="quick-start" ref="quick-start">{{ $t('quick-start') }}</h4>
          <p>{{ $t('quick-start-1') }}</p>
          <p>{{ $t('quick-start-2') }}</p>
          <p>{{ $t('quick-start-3') }}</p>
          <b-img-lazy :src="getImageSrc(1)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-1') }}</p>
          <p>{{ $t('quick-start-4') }}</p>
          <p>{{ $t('quick-start-5') }}</p>
          <b-img-lazy :src="getImageSrc(2)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-2') }}</p>
          <p>{{ $t('quick-start-6') }}</p>
          <b-img-lazy :src="getImageSrc(3)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-3') }}</p>
          <p>{{ $t('quick-start-7') }}</p>
          <b-img-lazy :src="getImageSrc(4)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-4') }}</p>
          <p>{{ $t('quick-start-8') }}</p>
          <b-img-lazy :src="getImageSrc(5)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-5') }}</p>
          <p>{{ $t('quick-start-9') }}</p>
          <p>{{ $t('quick-start-10') }}</p>
          <b-img-lazy :src="getImageSrc(6)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-6') }}</p>
          <p>{{ $t('quick-start-11') }}</p>
          <h4 id="system-req" ref="system-req">{{ $t('system-req') }}</h4>
          <p>{{ $t('system-req-1') }}</p>
          <p>{{ $t('system-req-2') }}</p>
          <p>{{ $t('system-req-3') }}</p>
          <h4 id="work-speed" ref="work-speed">{{ $t('work-speed') }}</h4>
          <p>{{ $t('work-speed-1') }}</p>
          <h4 id="data-req" ref="data-req">{{ $t('data-req') }}</h4>
          <p>{{ $t('data-req-1') }}</p>
          <p>{{ $t('data-req-2') }}</p>
          <b-img-lazy :src="getImageSrc(7)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-7') }}</p>
          <p>{{ $t('data-req-3') }}</p>
          <p>{{ $t('data-req-4') }}</p>
          <h4 id="create-models" ref="create-models">{{ $t('create-models') }}</h4>
          <p>{{ $t('create-models-1') }}</p>
          <p>{{ $t('create-models-2') }}</p>
          <p>{{ $t('create-models-3') }}</p>
          <p>{{ $t('create-models-4') }}</p>
          <h4 id="feedback" ref="feedback">{{ $t('feedback') }}</h4>
          <p>{{ $t('feedback-1') }}</p>
          <p>{{ $t('feedback-2') }}</p>
          <p>{{ $t('feedback-3') }}</p>
          <p>{{ $t('feedback-4') }}</p>
          <p>{{ $t('feedback-5') }}</p>

          <h3 id="details" ref="details">{{ $t('details') }}</h3>
          <h4 id="projects" ref="projects">{{ $t('projects') }}</h4>
          <p>{{ $t('projects-1') }}</p>
          <h4 id="create-project" ref="create-project">{{ $t('create-project') }}</h4>
          <p>{{ $t('create-project-1') }}</p>
          <b-img-lazy :src="getImageSrc(8)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-8') }}</p>
          <h4 id="upload-img" ref="upload-img">{{ $t('upload-img') }}</h4>
          <p>{{ $t('upload-img-1') }}</p>
          <b-img-lazy :src="getImageSrc(9)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-9') }}</p>
          <p>{{ $t('upload-img-2') }}</p>
          <p>{{ $t('upload-img-2-1') }}</p>
          <p>{{ $t('upload-img-3') }}</p>
          <h4 id="edit-project" ref="edit-project">{{ $t('edit-project') }}</h4>
          <p>{{ $t('edit-project-1') }}</p>
          <b-img-lazy :src="getImageSrc(10)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-10') }}</p>

          <h3 id="interface" ref="interface">{{ $t('interface') }}</h3>
          <p>{{ $t('interface-1') }}</p>
          <p>{{ $t('interface-2') }}</p>
          <p>{{ $t('interface-3') }}</p>
          <h4 id="work-space" ref="work-space">{{ $t('work-space') }}</h4>
          <p>{{ $t('work-space-1') }}</p>
          <ul>
            <li>{{ $t('work-space-2') }}</li>
            <li>{{ $t('work-space-3') }}</li>
            <li>{{ $t('work-space-4') }}</li>
            <li>{{ $t('work-space-5') }}</li>
            <li>{{ $t('work-space-6') }}</li>
            <li>{{ $t('work-space-7') }}</li>
            <li>{{ $t('work-space-8') }}</li>
            <li>{{ $t('work-space-9') }}</li>
          </ul>
          <b-img-lazy :src="getImageSrc(11)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-11') }}</p>
          <h4 id="user-notification" ref="user-notification">{{ $t('user-notification') }}</h4>
          <p>{{ $t('user-notification-1') }}</p>
          <p>{{ $t('user-notification-2') }}</p>
          <h4 id="warnings-errors" ref="warnings-errors">{{ $t('warnings-errors') }}</h4>
          <p>{{ $t('warnings-errors-1') }}</p>
          <h4 id="tools" ref="tools">{{ $t('tools') }}</h4>
          <p>{{ $t('tools-1') }}</p>
          <b-img-lazy :src="getImageSrc(12)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-12') }}</p>

          <h3 id="step-1" ref="step-1">{{ $t('step-1') }}</h3>
          <h4 id="step-1-tools" ref="step-1-tools">{{ $t('step-1-tools') }}</h4>
          <b-img-lazy :src="getImageSrc(13)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-13') }}</p>
          <p>{{ $t('step-1-1') }}</p>
          <h4 id="step-1-depths" ref="step-1-depths">{{ $t('step-1-depths') }}</h4>
          <p>{{ $t('step-1-2') }}</p>
          <h4 id="step-1-global-params" ref="step-1-global-params">
            {{ $t('step-1-global-params') }}
          </h4>
          <b-img-lazy :src="getImageSrc(14)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-14') }}</p>
          <p>{{ $t('step-1-3') }}</p>
          <p>{{ $t('step-1-4') }}</p>
          <p>{{ $t('step-1-5') }}</p>
          <h4 id="step-1-notifications" ref="step-1-notifications">{{ $t('step-1-notifications') }}</h4>
          <p>{{ $t('step-1-6') }}</p>
          <h4 id="step-1-errors" ref="step-1-errors">{{ $t('step-1-errors') }}</h4>
          <p>{{ $t('step-1-7') }}</p>

          <h3 id="step-2" ref="step-2">{{ $t('step-2') }}</h3>
          <p>{{ $t('step-2-1') }}</p>
          <h4 id="step-2-data" ref="step-2-data">{{ $t('step-2-data') }}</h4>
          <p>{{ $t('step-2-2') }}</p>
          <p>{{ $t('step-2-3') }}</p>
          <p>{{ $t('step-2-4') }}</p>
          <h4 id="step-2-notifications" ref="step-2-notifications">{{ $t('step-2-notifications') }}</h4>
          <p>{{ $t('step-2-5') }}</p>
          <h4 id="step-2-tools" ref="step-2-tools">{{ $t('step-2-tools') }}</h4>
          <p>{{ $t('step-2-6') }}</p>
          <b-img-lazy :src="getImageSrc(15)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-15') }}</p>
          <h4 id="step-2-edit-mask" ref="step-2-edit-mask">{{ $t('step-2-edit-mask') }}</h4>
          <p>{{ $t('step-2-7') }}</p>
          <b-img-lazy :src="getImageSrc(16)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-16') }}</p>
          <h4 id="step-2-edit-depths" ref="step-2-edit-depths">{{ $t('step-2-edit-depths') }}</h4>
          <p>{{ $t('step-2-8') }}</p>
          <b-img-lazy :src="getImageSrc(17)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-17') }}</p>
          <p>{{ $t('step-2-9') }}</p>
          <h4 id="step-2-edit-notifications" ref="step-2-edit-notifications">{{ $t('step-2-edit-notifications') }}</h4>
          <p>{{ $t('step-2-10') }}</p>
          <b-img-lazy :src="getImageSrc(18)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-18') }}</p>
          <h4 id="step-2-edit-errors" ref="step-2-edit-errors">{{ $t('step-2-edit-errors') }}</h4>
          <p>{{ $t('step-2-11') }}</p>

          <h3 id="step-3" ref="step-3">{{ $t('step-3') }}</h3>
          <p>{{ $t('step-3-1') }}</p>
          <h4 id="step-3-data" ref="step-3-data">{{ $t('step-3-data') }}</h4>
          <p>{{ $t('step-3-2') }}</p>
          <h4 id="step-3-tools" ref="step-3-tools">{{ $t('step-3-tools') }}</h4>
          <p>{{ $t('step-3-3') }}</p>
          <b-img-lazy :src="getImageSrc(19)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-19') }}</p>
          <h4 id="step-3-depths" ref="step-3-depths">{{ $t('step-3-depths') }}</h4>
          <p>{{ $t('step-3-4') }}</p>
          <b-img-lazy :src="getImageSrc(20)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-20') }}</p>
          <p>{{ $t('step-3-5') }}</p>
          <b-img-lazy :src="getImageSrc(21)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-21') }}</p>
          <h4 id="step-3-download" ref="step-3-download">{{ $t('step-3-download') }}</h4>
          <p>{{ $t('step-3-6') }}</p>
          <p>{{ $t('step-3-7') }}</p>
          <b-img-lazy :src="getImageSrc(22)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-22') }}</p>
          <p>{{ $t('step-3-8') }}</p>
          <p>{{ $t('step-3-9') }}</p>
          <p>{{ $t('step-3-10') }}</p>
          <b-img-lazy :src="getImageSrc(23)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-23') }}</p>
          <h4 id="step-3-edit-warnings" ref="step-3-edit-warnings">{{ $t('step-3-edit-warnings') }}</h4>
          <p>{{ $t('step-3-11') }}</p>
          <h4 id="step-3-edit-errors" ref="step-3-edit-errors">{{ $t('step-3-edit-errors') }}</h4>
          <p>{{ $t('step-3-12') }}</p>

          <h3 id="step-4" ref="step-4">{{ $t('step-4') }}</h3>
          <p>{{ $t('step-4-1') }}</p>
          <p>{{ $t('step-4-2') }}</p>
          <h4 id="step-4-model" ref="step-4-model">{{ $t('step-4-model') }}</h4>
          <p>{{ $t('step-4-3') }}</p>
          <b-img-lazy :src="getImageSrc(24)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-24') }}</p>
          <p>{{ $t('step-4-4') }}</p>
          <b-img-lazy :src="getImageSrc(25)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-25') }}</p>
          <p>{{ $t('step-4-5') }}</p>
          <p>{{ $t('step-4-6') }}</p>
          <p>{{ $t('step-4-7') }}</p>
          <b-img-lazy :src="getImageSrc(26)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-26') }}</p>
          <h4 id="step-4-data" ref="step-4-model">{{ $t('step-4-data') }}</h4>
          <p>{{ $t('step-4-8') }}</p>
          <p>{{ $t('step-4-9') }}</p>
          <b-img-lazy :src="getImageSrc(27)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-27') }}</p>
          <h4 id="step-4-notifications" ref="step-4-notifications">{{ $t('step-4-notifications') }}</h4>
          <p>{{ $t('step-4-10') }}</p>
          <h4 id="step-4-tools" ref="step-4-tools">{{ $t('step-4-tools') }}</h4>
          <p>{{ $t('step-4-11') }}</p>
          <b-img-lazy :src="getImageSrc(28)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-28') }}</p>
          <h4 id="step-4-edit" ref="step-4-edit">{{ $t('step-4-edit') }}</h4>
          <p>{{ $t('step-4-12') }}</p>
          <b-img-lazy :src="getImageSrc(29)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-29') }}</p>
          <p>{{ $t('step-4-13') }}</p>
          <b-img-lazy :src="getImageSrc(30)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-30') }}</p>
          <h4 id="step-4-classes" ref="step-4-classes">{{ $t('step-4-classes') }}</h4>
          <p>{{ $t('step-4-14') }}</p>
          <b-img-lazy :src="getImageSrc(31)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-31') }}</p>
          <p>{{ $t('step-4-15') }}</p>
          <p>{{ $t('step-4-16') }}</p>
          <p>{{ $t('step-4-17') }}</p>
          <b-img-lazy :src="getImageSrc(32)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-32') }}</p>
          <p>{{ $t('step-4-18') }}</p>
          <p>{{ $t('step-4-19') }}</p>
          <p>{{ $t('step-4-19-2') }}</p>
          <b-img-lazy :src="getImageSrc(33)" width="800"></b-img-lazy>
          <p class="picture">{{ $t('pic-33') }}</p>
          <h4 id="step-4-warnings" ref="step-4-warnings">{{ $t('step-4-warnings') }}</h4>
          <p>{{ $t('step-4-20') }}</p>
          <h4 id="step-4-errors" ref="step-4-errors">{{ $t('step-4-errors') }}</h4>
          <p>{{ $t('step-4-21') }}</p>
        </div>
      </b-col>
      <b-col class="menu">
        <b-navbar v-b-scrollspy="{offset: 96}" class="flex-column">
          <b-nav :class="subMenuItemActive ? 'sub-menu-item-active' : null" pills vertical>
            <b-nav-item :to="{hash: 'short-desc'}" class="menu-item">
              {{ $t('short-desc') }}
            </b-nav-item>
            <b-nav pills vertical>
              <b-nav-item :to="{hash: 'quick-start'}" class="sub-item">
                {{ $t('quick-start') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'system-req'}" class="sub-item">{{ $t('system-req') }}</b-nav-item>
              <b-nav-item :to="{hash: 'work-speed'}" class="sub-item">{{ $t('work-speed') }}</b-nav-item>
              <b-nav-item :to="{hash: 'data-req'}" class="sub-item">{{ $t('data-req') }}</b-nav-item>
              <b-nav-item :to="{hash: 'create-models'}" class="sub-item">
                {{ $t('create-models') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'feedback'}" class="sub-item">{{ $t('feedback') }}</b-nav-item>
            </b-nav>

            <b-nav-item :to="{hash: 'details'}" class="menu-item">
              {{ $t('details') }}
            </b-nav-item>
            <b-nav pills vertical>
              <b-nav-item :to="{hash: 'projects'}" class="sub-item">{{ $t('projects') }}</b-nav-item>
              <b-nav-item :to="{hash: 'create-project'}" class="sub-item">
                {{ $t('create-project') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'upload-img'}" class="sub-item">{{ $t('upload-img') }}</b-nav-item>
              <b-nav-item :to="{hash: 'edit-project'}" class="sub-item">
                {{ $t('edit-project') }}
              </b-nav-item>
            </b-nav>

            <b-nav-item :to="{hash: 'interface'}" class="menu-item">
              {{ $t('interface') }}
            </b-nav-item>
            <b-nav pills vertical>
              <b-nav-item :to="{hash: 'work-space'}" class="sub-item">{{ $t('work-space') }}</b-nav-item>
              <b-nav-item :to="{hash: 'user-notification'}" class="sub-item">
                {{ $t('user-notification') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'warnings-errors'}" class="sub-item">
                {{ $t('warnings-errors') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'tools'}" class="sub-item">{{ $t('tools') }}</b-nav-item>
            </b-nav>

            <b-nav-item :to="{hash: 'step-1'}" class="menu-item">
              {{ $t('step-1') }}
            </b-nav-item>
            <b-nav pills vertical>
              <b-nav-item :to="{hash: 'step-1-tools'}" class="sub-item">
                {{ $t('step-1-tools') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-1-depths'}" class="sub-item">
                {{ $t('step-1-depths') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-1-global-params'}" class="sub-item">
                {{ $t('step-1-global-params') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-1-notifications'}" class="sub-item">
                {{ $t('step-1-notifications') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-1-errors'}" class="sub-item">
                {{ $t('step-1-errors') }}
              </b-nav-item>
            </b-nav>

            <b-nav-item :to="{hash: 'step-2'}" class="menu-item">
              {{ $t('step-2') }}
            </b-nav-item>
            <b-nav pills vertical>
              <b-nav-item :to="{hash: 'step-2-data'}" class="sub-item">
                {{ $t('step-2-data') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-2-notifications'}" class="sub-item">
                {{ $t('step-2-notifications') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-2-tools'}" class="sub-item">
                {{ $t('step-2-tools') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-2-edit-mask'}" class="sub-item">
                {{ $t('step-2-edit-mask') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-2-edit-depths'}" class="sub-item">
                {{ $t('step-2-edit-depths') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-2-edit-notifications'}" class="sub-item">
                {{ $t('step-2-edit-notifications') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-2-edit-errors'}" class="sub-item">
                {{ $t('step-2-edit-errors') }}
              </b-nav-item>
            </b-nav>

            <b-nav-item :to="{hash: 'step-3'}" class="menu-item">
              {{ $t('step-3') }}
            </b-nav-item>
            <b-nav pills vertical>
              <b-nav-item :to="{hash: 'step-3-data'}" class="sub-item">
                {{ $t('step-3-data') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-3-tools'}" class="sub-item">
                {{ $t('step-3-tools') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-3-depths'}" class="sub-item">
                {{ $t('step-3-depths') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-3-download'}" class="sub-item">
                {{ $t('step-3-download') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-3-edit-warnings'}" class="sub-item">
                {{ $t('step-3-edit-warnings') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-3-edit-errors'}" class="sub-item">
                {{ $t('step-3-edit-errors') }}
              </b-nav-item>
            </b-nav>

            <b-nav-item :to="{hash: 'step-4'}" class="menu-item">
              {{ $t('step-4') }}
            </b-nav-item>
            <b-nav pills vertical>
              <b-nav-item :to="{hash: 'step-4-model'}" class="sub-item">
                {{ $t('step-4-model') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-4-data'}" class="sub-item">
                {{ $t('step-4-data') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-4-notifications'}" class="sub-item">
                {{ $t('step-4-notifications') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-4-tools'}" class="sub-item">
                {{ $t('step-4-tools') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-4-edit'}" class="sub-item">
                {{ $t('step-4-edit') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-4-classes'}" class="sub-item">
                {{ $t('step-4-classes') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-4-warnings'}" class="sub-item">
                {{ $t('step-4-warnings') }}
              </b-nav-item>
              <b-nav-item :to="{hash: 'step-4-errors'}" class="sub-item">
                {{ $t('step-4-errors') }}
              </b-nav-item>
            </b-nav>
          </b-nav>
        </b-navbar>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/**
 * @group Views
 * Root view
 */
export default {
  name: 'help',
  data() {
    return {
      /**
       * @vuese
       * the data
       */
      publicPath: process.env.BASE_URL,
      /**
       * @vuese
       * the data
       */
      subMenuItemActive: false
    }
  },
  /**
   * @vuese
   * description
   */
  created() {
    this.$root.$on('bv::scrollspy::activate', this.activateElement)
  },
  /**
   * @vuese
   * description
   */
  destroyed() {
    this.$root.$off('bv::scrollspy::activate')
  },
  methods: {
    /**
     * @vuese
     * description
     * @arg elementId
     */
    activateElement(elementId) {
      const el = this.$refs[elementId.replace('#', '')]
      this.subMenuItemActive = el && el.tagName === 'H4'
    },
    /**
     * @vuese
     * description
     * @arg imageNumber
     */
    getImageSrc(imageNumber) {
      return `${this.publicPath}/help/${this.$i18n.locale.toLowerCase()}/image${('00' + imageNumber).slice(-3)}.png`
    }
  }
}
</script>

<style lang="scss">
.help.container {
  max-width: none !important;
  margin: 0;
  padding: 40px 110px 12px 110px;
  background-color: $--color-base-01;

  .content {
    max-width: calc(100% - 384px - 32px);

    h2 {
      margin-bottom: 24px !important;
    }

    h3, h4 {
      margin-bottom: 16px !important;
    }

    p {
      margin-bottom: 32px !important;
    }

    ul {
      margin-top: -24px;
      margin-bottom: 32px;
      padding-left: 16px;
      list-style-type: none;
      list-style-position: inside;

      li {
        margin-top: 8px;
        font-size: $--font-size-paragraph-base;
        font-weight: $--font-weight-medium;
        line-height: 27px;

        &:before {
          content: '\2013\00a0';
        }
      }
    }

    .picture {
      margin-top: 4px !important;
      font-size: $--font-size-small !important;
      font-weight: $--font-weight-regular;
      line-height: 18px;
    }
  }

  .menu {
    max-width: 384px;
    margin-left: 32px;
    padding: 0;
    position: fixed;
    top: 105px;
    right: 110px;

    .navbar {
      padding: 0;

      .nav.sub-menu-item-active {
        .nav-item.menu-item.active .nav-link.active {
          color: $--color-primary;
          background-color: $--color-base-01;
        }
      }

      .nav-item:not(.active) + .nav.nav-pills {
        display: none;
      }

      .nav-item.active + .nav.nav-pills {
        display: flex;
      }

      .nav.nav-pills {
        width: 100%;

        .nav-item {
          font-size: $--font-size-base;
          font-weight: $--font-weight-semi-bold;
          line-height: 24px;

          &.sub-item {
            margin-left: 24px;
          }
        }
      }
    }
  }
}
</style>

<!-- eslint-disable -->
<i18n>
{
  "en": {
    "title": "DeepCore Help Center",
    "short-desc": "Summary",
    "short-desc-1": "DeepCore designed to speed up and ease full-bore core description. It can automatically segment core out of a core box image and perform depth referencing. Each core centimeter can be automatically classified. That allows to receive a high precision digital description in a minute and analyze it. Convolutional neural networks and other modern data analysis techniques process the images and produce probabilities of a prediction.",
    "quick-start": "Quick start. Workflow description",
    "quick-start-1": "A common workflow described here. Detailed description can be found in the detailed description section. You can go there via contextual menu on the right. You can also search the page with CTRL+F hotkey or selecting the appropriate option in the browser menu.",
    "quick-start-2": "To get the result, a user needs to create a project and go through 4 steps. At each step, there are several automated checks. Each check results either with moving to the next step or an error window with an appropriate description of an error.",
    "quick-start-3": "You can create a project for core description in the \"My Projects\" menu by clicking on the \"Create a New Project\" button (1).",
    "pic-1": "Figure 1. Project overview.",
    "quick-start-4": "A window for image uploading appears—upload core image. The core should be vertically oriented. Orientation of core can be changed on the first step.",
    "quick-start-5": "Learn more about downloading and creating a project in the \"Detailed description\" and \"Data requirements section\".",
    "pic-2": "Figure 2. Project creation. Image uploading.",
    "quick-start-6": "The first step of a project aimed to check depth referencing extracted from image name and edit (crop, tilt, turn) some images if some errors are found.",
    "pic-3": "Figure 3. Step 1. Image preview step.",
    "quick-start-7": "The next step detects the core in the image and performs depth referencing for each column. Each step automatically saved in the background. When all images segmented – user informed by e-mail, it is unnecessary to stay on the page. A user can create another project while waiting for the e-mail. When the detection finished, the user can edit the results. A user can check the image processing status by checking if notification icon (1) appeared.",
    "pic-4": "Figure 4. Step 2. Editing the results of core and depth detection.",
    "quick-start-8": "The third step is for the final depth referencing checkup. A user can split the core, change the depth of splits and download the results. The files are downloaded as a zip archive. A user can choose which type of data he wants to download – a stitched core by boxes and splits or separated core columns as they appear in the box.",
    "pic-5": "Figure 5. Step 3. Depth referencing.",
    "quick-start-9": "The last step is to detect the classes for the whole well into the types chosen by the user from the proposed models. You can use both available models and request new models by sending us a message to  help@petroleum.digital with the subject \"DeepCore - models\".",
    "quick-start-10": "The description can be downloaded in both graphical and table formats with depth referencing.",
    "pic-6": "Figure 6. Step 4. Classification",
    "quick-start-11": "A more detailed description of the steps and editing tools presented can be found in the \"Detailed description\" section. If there is no answer to your question in this manual - do not hesitate to ask by e-mail help@petroleum.digital with the subject \"DeepCore - help\". You can offer new functionality the same way with the subject \"DeepCore - functionality\".",
    "system-req": "System requirements",
    "system-req-1": "The app barely uses the user's resources. You can use it even from a tablet.",
    "system-req-2": "The app is optimized for modern browsers (Safari, Edge, Chrome, Firefox). It can work with Internet Explorer in limited functionality. In case there are problems with scale (e.g. elements are too large) – try to adjust it in the browser (Ctrl + Mouse Wheel) or go to browser settings – \"Scale\".",
    "system-req-3": "If there some problems with viewing the results in a system image viewer - we recommend using open-source software such as ImageGlass, XnViewMP, FastStone Image viewer.",
    "work-speed": "Speed",
    "work-speed-1": "The system allows to extraction of core from a box (about one box per second, regardless of the core quantity) and the results of the core classification on different types selected by the user (about 4 m per second). The overall speed and response of the system depend on the load of the server.",
    "data-req": "Data requirements",
    "data-req-1": "Existing models are trained on data with a minimum core column width of approximately 10 cm or 400 px (equals 100 dpi) and 6.2 cm or 250 px (equals 100dpi).",
    "data-req-2": "It is desirable to use core boxes images without strong edge distortion (core columns should lie even). The core in the image should be positioned vertically.",
    "pic-7": "Figure 7. Examples of recommended data formats (grey area means core, white means box).",
    "data-req-3": "Following extensions are supported - JPEG, PNG, TIFF. To convert images from one format to another, you can use the recommended software in the section \"Short description\" - \"System requirements\". They can convert or edit multiple images at the same time. It is also possible to turn around a batch of images before uploading with the batch processing tool available in the system. Most systems are capable of doing that by selecting the images and right-clicking on them, choose \"turn clockwise\"counterclockwise\"",
    "data-req-4": "If the images are stored in CorelDraw (.cdr), .xlsx or .doc - e-mail us to help@petroleum.digital with the subject \"DeepCore – preprocessor\", we will provide you with special preprocessors to automatically extract such data. If the data is stored in another similar format, we may investigate this issue and consider writing an additional preprocessor for your data.",
    "create-models": "Creating new models",
    "create-models-1": "To create new models, contact us by e-mail help@petroleum.digital with the subject \"DeepCore - models\".",
    "create-models-2": "It is possible to supplement models based on user-labelled data.",
    "create-models-3": "If you need to create a new model, which differs from the available - a well-prepared database is required. As a rule, the core is described in intervals (several meters of core - one type of rock or other characteristics). Our algorithms allow you to get a detailed description to create the algorithm centimetre accuracy of the description needed. The transition from meter to centimetre scale is possible with post-processing performed by us. We support users with data preparation and provide the necessary tools to simplify labelling.",
    "create-models-4": "The average time to prepare data and train a new model is one and a half weeks if you have an interval-scale description. However, due to the frequent presence of other responsibilities, the preparation of data and model can take up to two or three weeks.",
    "feedback": "Feedback",
    "feedback-1": "You can always ask a question by e-mailing us at help@petroleum.digital .",
    "feedback-2": "If it is necessary to develop a new model, write \"DeepCore - model\" in a subject.",
    "feedback-3": "In case of emergencies or lack of information in the manual – \"DeepCore - help\".",
    "feedback-4": "If you want to offer new functionality or improvement - \"DeepCore - functionality\".",
    "feedback-5": "If you need a preprocessor for data stored in CorelDraw .cdr, .xlsx or .doc or some other extension - \"DeepCore - preprocessor\"",
    "details": "Detailed workflow description",
    "projects": "Projects",
    "projects-1": "The element of the system to work with is the project. The project stores information for one well. Several projects can have similar names. You can create as many projects as you need.",
    "create-project": "Creating a project",
    "create-project-1": "On the project page, it is possible to create new projects or open the previous one if it exists. To create a new project - click the button \"Create new project\" (1).",
    "pic-8": "Figure 8. Adding a project.",
    "upload-img": "Uploading images",
    "upload-img-1": "Once a user clicked on the \"Create new project\" button, he will see a window for image upload: acceptable image formats JPEG, PNG, TIFF. The core axis should be vertically oriented. The orientation of separate images can be corrected at the first step. You can upload images of separate columns or core boxes.",
    "pic-9": "Figure 9. Downloading images into the project.",
    "upload-img-2": "To simplify the depth referencing, it is recommended to mark the depths in the file name like this: \"upper depth - lower depth\". Some examples: \"Well 98, Burgan oilfield, interval 1988.23-1993.5” \"sampling interval 1980-2000\", \"1988,23-1993,5\", \"1988-1993\", \"10-15,3\".",
    "upload-img-2-1": "It is better to do a quick preparation of images and check if the depths are in the image names and are the images oriented correctly. You can batch-process them in ways described in Data requirements. In most cases – it takes from 1 to 5 minutes to check the orientation and up to 10 minutes to prepare the image names depending on which kind of data you use (the maximum values are for the preparation of large datasets with over 100 images).",
    "upload-img-3": "If the images are stored in format other of .jpg, .png or .tiff. You can use special freeware tools recommended in the \"Short Description\" - \"System requirements\" to convert multiple images with several easy steps. In case of any problems or if you need to get images out of .cdr, .doc, or .xls files – send us e-mail to help@petroleum.digital for further assistance with subject \"DeepCore - preprocessor\".",
    "edit-project": "Project edit",
    "edit-project-1": "The project can be removed or renamed by clicking on the project settings button (1).",
    "pic-10": "Figure 10. Additional project settings",
    "interface": "Project workflow overview",
    "interface-1": "Once a project is created, and images are uploaded, a user starts working on the project. ",
    "interface-2": "A user passes through 4 steps in a project. The first step is Preview for screening and editing of uploaded results. The second - Core selection for the detection of a core in the image and binding the core columns with depths user can edit. The third is Depth referencing allows a user to download the separated and stitched columns (cropped-out of a core image box), check the depth and break down the column to additional intervals. The fourth step aims to classify images using available models, review and edit the results, and download all the necessary information (legend, graphical report, and table report).",
    "interface-3": "All changes on the step are automatically saved when you exit editing mode or switch to another image or the next step. When you take a step back, the results of the work in the current step erased.",
    "work-space": "Workspace overview",
    "work-space-1": "Each step has several common areas that are present at all steps.",
    "work-space-2": "(1) Data preview ",
    "work-space-3": "(2) Image editing",
    "work-space-4": "(3) Image navigation",
    "work-space-5": "(4) Toolbox",
    "work-space-6": "(5) Current step display ",
    "work-space-7": "(6) Exit button",
    "work-space-8": "(7) The next and previous step buttons",
    "work-space-9": "(8) Results download button (active on step 3 and 4)",
    "pic-11": "Figure 11. Workspace overview",
    "user-notification": "User alert",
    "user-notification-1": "When the segmentation (step 2) or classification (step 4) is completed, the user receives an alert by e-mail about the completion of the work on a step. It will be good to set up a message filter at the e-mail service to put all messages from the system in a separate folder.",
    "user-notification-2": "If you need some assistance with filtering setup – please, do not hesitate to ask via e-mail to help@petroleum.digital with the subject \"DeepCore – help\".",
    "warnings-errors": "Warning and error messages",
    "warnings-errors-1": "If there are errors or warnings when checking inputs and other conditions, a message occurs in the bottom right corner with an error (red) or a warning (yellow) message with a description. Further discussion of errors will be available for each step in a separate section.",
    "tools": "Toolbox",
    "tools-1": "There are several tools for navigating to work with images – hand for navigating (1), zoom-in (2) and zoom-out (3), editing tools (4) (differ at each step), global settings button (5) (available on the first step) for setting maximum core column height for all boxes. There are buttons undo (6) and redo (7) to cancel user's changes at the current editing process in the same window.",
    "pic-12": "Figure 12. Available Tools.",
    "step-1": "Step 1. Preview",
    "step-1-tools": "Tools",
    "pic-13": "Figure 13.",
    "step-1-1": "Multiple editing tools are available during image view: turn image clockwise (1) and counterclockwise (2), flip image horizontally (3) and vertically (4), crop (5) and align (straighten) image (5, 6).",
    "step-1-depths": "Depths editing",
    "step-1-2": "While in editing mode, it is possible to add or change the depths referencing (7) for a current photo. The next step will be available after depth referencing of all images. To confirm the changes, you need to press the \"apply\" button (8).",
    "step-1-global-params": "Project global parameters",
    "pic-14": "Figure 14. Global project options available on the step view of uploaded images.",
    "step-1-3": "To accurately bind extracted core columns to depth, it is necessary to set the core height of the core in the box (commonly from 0.75 to 1 meter) (1). Besides, you need to select the direction of the core layout (by default - right-to-left) (2).",
    "step-1-4": "These settings are available in the global project settings (3) and are only active on the first step.",
    "step-1-5": "It should be considered that the real height of the core may slightly differ from the stated (usually within 10 cm). This is because roulette measures the length of the box (end to end) instead of the core.",
    "step-1-notifications": "Warnings and notifications",
    "step-1-6": "A warning may appear after going to the next step with a notification about depth crossing. If there are more than 4 images mentioned, this can often be due to global settings set incorrectly or some images have overlapping depths in the names. If you have incorrect global settings, change them by switching to the previous step. Otherwise, pay attention to images with intersecting depths during the second step.",
    "step-1-errors": "Errors",
    "step-1-7": "An error that will block moving to the next step appears if there are some problems with depth detection on an image. A red pop-up will appear with the image name where the depth was not detected. After fixing such issues, the next step will available.",
    "step-2": "Step 2. Core selection",
    "step-2-1": "After pressing the \"Next step\" button on step 1, depth detection starts. This process is performing in the background. A user can exit the window and wait until the e-mail with notification arrives at his mailbox.",
    "step-2-data": "Data produced",
    "step-2-2": "The result of segmentation is a mask with depths.",
    "step-2-3": "The mask is used to extract the core further. The mask must be accurate enough (there is a gap between the core columns, the core is detected correctly). If there are multiple coring intervals inside a single image, it is possible to divide them using an eraser.",
    "step-2-4": "At the same time, the depths are applied on top of the mask. If the image has multiple coring intervals from quite different depths, it is possible to make changes with a depth editing tool.",
    "step-2-notifications": "Notification",
    "step-2-5": "At the end of the segmentation process, the user will receive an e-mail about this. You do not have to stay on the page until the segmentation process is complete.",
    "step-2-tools": "Editing tools",
    "step-2-6": "The user can edit the mask. To do this, you can use the tools \"Brush\" (1) and \"Eraser\"  (2)to add or remove the area. The size of the tools can be configured with a slider (3) or manually entered in the corresponding window (4) during the editing process. Depth editing is possible in the image. To activate this mode, you must select the \"Edit depth\" tool (5).",
    "pic-15": "Figure 15. Tools for data edit on the \"Core selection\" step.",
    "step-2-edit-mask": "Mask editing",
    "step-2-7": "The mask editing mode is activated automatically when selecting the \"Brush\" or \"Eraser\" tool. If there are two coring intervals, you may erase a small area of the mask by dividing the core into two parts. To apply the edited mask, choose the \"Edit depth\" tool. After applying the results – it is not possible to undo them.",
    "pic-16": "Figure 16. Mask editing example.",
    "step-2-edit-depths": "Depths editing",
    "step-2-8": "A user can edit the depths on top of the mask. Choose the \"Edit depth\" tool (5). Depth becomes blue. To change the depth, a user needs to press on it. An input window will appear.",
    "pic-17": "Figure 17. Usage example of \"Edit depth\" tool.",
    "step-2-9": "The following intuition is used in depth editing - all the depths below the chosen one change their values within a single image at a given interval (in global settings). Values above the selected depth remain the same.",
    "step-2-edit-notifications": "Warnings and notifications",
    "step-2-10": "If problems during depth referencing appear in the data preview window may appear in information alerts. User can know what the problem is by hovering it. A massage with a description appear. Images with a red warning will not be used further to build the core column on the third step. Images with a yellow badge will be used, but they should be checked as they may cause some problems.",
    "pic-18": "Figure 18. Warnings and errors can occur when the core is segmented in the data preview window",
    "step-2-edit-errors": "Errors",
    "step-2-11": "The same as in step 1, depths will be checked for an intersection. If any depth intersection detected, the user would not be allowed to go further until he fixes the files mentioned in the error.",
    "step-3": "Step 3. Depth referencing",
    "step-3-1": "When a user moves from step 2 to step 3, the resulting masks are processed, and the depths are matched to the image according to the mask and depths.",
    "step-3-data": "Data produced",
    "step-3-2": "All columns are stitched into one with depth referencing. If there are breaks between intervals (e.g., if one coring interval ends up with 203 and the other starts with 204), they will be marked by a catenary curve and counted as separate intervals. This curve will be used to export data.",
    "step-3-tools": "Editing tools",
    "step-3-3": "The user can make changes to the final depths of the stitched columns in the image. If you want to change a separate depth, you need to choose the \"Edit depth\" tool. If some areas needed to be broken down into several coring intervals - the tool \"split\" would help a user do so.",
    "pic-19": "Figure 19. Tools available in \"Depth referencing\" step",
    "step-3-depths": "Editing depths",
    "step-3-4": "The stitched core can be divided into two parts by splitting the interval. Within these parts, further depth editing can be performed.",
    "pic-20": "Figure 20. Example of cutting the core in two splits.",
    "step-3-5": "After selecting the \"Edit depth\" tool, the depths can be changed for the whole coring interval. To undo the split, click the cancellation button before entering the depth edit mode or re-enter this step from the previous one.",
    "pic-21": "Figure 21. Editing depths.",
    "step-3-download": "Data download",
    "step-3-6": "The user can download the data as individual core columns and the stitched parts with a given split. Data is downloaded by the \"Download\" button (1).",
    "step-3-7": "After clicking the button, a window will be displayed to select data types (2).",
    "pic-22": "Figure 22. Choosing the types of data to download.",
    "step-3-8": "The data can be downloaded as separate columns (3) or as stitched by a box with splits (4).",
    "step-3-9": "If a user chooses to download the separate columns - the core box image would be separated into core columns by areas found on the segmented image.",
    "step-3-10": "In addition to the images, the user can download the depth reference for each type of data (5). The data is downloaded in a zip archive. Separate images are numbered according to the initial image name and the layout direction. Their names are matched to the depths in table (1) (\"final_name\" column) and the initial full-size image (\"ini_image\" column).",
    "pic-23": "Figure 23. Example of downloaded data.",
    "step-3-edit-warnings": "Warnings and notifications",
    "step-3-11": "A warning may appear after moving from the second to the third step if there are images with missing or incorrect masks (red icon in the preview). Such images will not be processed.",
    "step-3-edit-errors": "Errors",
    "step-3-12": "If the final image is not fully prepared to move to the next step, an error message will appear after pressing the \"Next step\" button. Wait until the image prepared.",
    "step-4": "Step 4. Classification",
    "step-4-1": "The results are saved when moving to 4th step.",
    "step-4-2": "A class is a predicted unit by the algorithm. The class can be represented by the type of rock, texture, lithotype (type of rock with a structure), core condition, cracks and many other parameters that can be predicted if the appropriate model is available. To start the classification, you need to choose a model.",
    "step-4-model": "Choosing a model and starting a classification",
    "step-4-3": "To select a model, you need to press the model selection button on the toolbar (1).",
    "pic-24": "Figure 24. Choosing a model to start the classification process.",
    "step-4-4": "The model selection window (2) will open. In the drop-out menu (1) you can choose a model suitable for the current task. In the info window – you can see the purpose of each model. If there is no such model - send us an e-mail to help@petroleum.digital with the subject \"DeepCore - model\".",
    "pic-25": "Figure 25. Model choice",
    "step-4-5": "After selecting the model, click on the \"Apply\" button.",
    "step-4-6": "A window appears to customize the type of the finite classes that could be defined in the model - if any. A user can cancel certain classes if he wishes by unflagging it.",
    "step-4-7": "You can change the color and pattern, filling the appropriate class. You can also do this when you are working with the results of interpretation.",
    "pic-26": "Figure 26. Change color and pattern for the class",
    "step-4-data": "Data produced",
    "step-4-8": "Once a user selected the model, the image classification process starts. For each part of an image, a probability is calculated. There may be cases when the predicted class is not correct as it is not persisting in the model. In such cases – the user can specify a manual class.",
    "step-4-9": "In the preview window on the left, a line describing the probability of predicted classes presented. It will help a user to concentrate on cases that are not surely detected by the algorithm.",
    "pic-27": "Figure 27. Data preview window.",
    "step-4-notifications": "Alerts",
    "step-4-10": "At the end of the classification process, the user will receive a message about that event by e-mail. It is not required to stay on the page until the classification process is complete",
    "step-4-tools": "Editing tools",
    "step-4-11": "The user can make the necessary changes to the results. While a \"Hand\" tool selected – the user can view the probabilities detected for each detected interval. With the \"Select or edit border\" tool, he can change the boundaries of specific classes and change predicted classes in a whole equally detected interval. The \"Brush\" tool allows a user to change an area from the left mouse pressing (or screen touching) to release it. The \"Interval tool enables\" a user to select a large interval from the chosen point (left mouse click) to the second selected point. \"Eraser\" allows erasing the interval the same way as the \"Brush\" tool. ",
    "step-4-edit": "Editing predicted classes",
    "step-4-12": "When a user selects the \"Hand\" tool, an icon will appear on each of the predicted classes (i). A window with probabilities distributed to each class for that predicted interval appears after clicking it. To change the predicted classes within the whole predicted with the same class interval, select the \"Select or edit border\" tool. If a user chooses the predicted interval of the same class, he can change the entire interval by clicking on the appropriate class in the class window.",
    "pic-29": "Figure 29. Editing prediction results and viewing the exact probability distribution",
    "step-4-13": "If a user wants to change the appearance or name of the class, he can do this by clicking on the class edit sign.",
    "pic-30": "Figure 30. Class appearance modification after model application.",
    "step-4-classes": "Adding and deleting your own classes",
    "step-4-14": "In the class window, a user can add the own class he needs to solve his problem. The user can choose any combination of color and pattern available. If there are no patterns suitable for you, send us an e-mail to help@petroleum.digital with the subject \"DeepCore - functionality\" with an example of the necessary pattern, we will add it.",
    "pic-31": "Figure 31. Creating and removing the custom class by selecting the color and pattern.",
    "step-4-15": "The class you created could be edited in the ways described above.",
    "step-4-16": "The user can also remove the class created by clicking on the trash button.",
    "step-4-download": "Data download",
    "step-4-17": "Once the necessary changes have been made, the user can download the results. All changes are saved automatically. To download the resulting data, you need to click on the \"Download\" button. A menu appears to select the data types that can be downloaded.",
    "pic-32": "Figure 32. Selecting the types of data you unload on the fourth step.",
    "step-4-18": "The data is downloaded as a zip archive.",
    "step-4-19": "A user can choose different types of report. Graphic report - the results, stitched the same way as on the third step. It contains core photo, depth, and column with classes. A table report is table data, with a label and class name (\"label\", \"class\") matched to depth (the top and the bottom depth), the name of the original image (\"ini_image\"), the core part to which it refers (\"image_part\"), and the corresponding name of the core part if the core were downloaded as separate columns on the third step. A legend is a legend with a pattern and class name, including custom classes.",
    "step-4-19-2": "We can design particular data processors to transform the downloaded data to the required format. Write an e-mail to help@petroleum.digital with the subject \"DeepCore – preprocessor\" to get one.",
    "pic-33": "Figure 33. Examples of downloaded data on the fourth step.",
    "step-4-warnings": "Warnings and notifications",
    "step-4-20": "After selecting a new model and pressing the \"Apply\" button, a user notified that the image had been sent to the classification. A similar notification is displayed if you enter the project before the classification process is complete.",
    "step-4-errors": "Errors",
    "step-4-21": "If a user hasn't selected an active class when a user chooses an editing tool, he will see a message alerting him about that."
  },
  "ru": {
    "title": "Справочный центр DeepCore",
    "short-desc": "Краткое описание",
    "short-desc-1": "Данный продукт предназначен для ускорения работы пользователя при работе с керновым материалом. С использованием искусственных сверточных нейронных сетей и других современных алгоритмов на изображении возможно в автоматическом режиме выделить керн, а также определить, к какому классу принадлежит каждый сантиметр керна. Это позволяет выйти на качественно новый уровень работы с керном и упростить создание цифровой базы данных.",
    "quick-start": "Быстрый старт. Описание общего рабочего процесса.",
    "quick-start-1": "Здесь приведён общий процесс использования системы. Более подробное описание приведено в разделе “Подробное описание”, к нему можно перейти в контекстном меню справа. Также можно выполнить поиск по странице, нажав Ctrl+F или выбрав соответствующую строку в меню браузера.",
    "quick-start-2": "Для того чтобы получить конечный результат надо создать проект и пройти через 4 шага. На каждом шаге происходит ряд проверок. По результату – пользователь допускается до следующего шага или выводится окно ошибки, описывающее в чем может быть проблема.",
    "quick-start-3": "Создать проект можно в меню “Мои проекты”, нажав на кнопку “Создать новый проект” (1).",
    "pic-1": "Рисунок 1. Окно обзора проектов.",
    "quick-start-4": "В появившееся диалоговое окно загрузите изображения керна. Керн на изображении должен быть ориентирован вертикально, ориентацию керна можно изменить на следующем шаге.",
    "quick-start-5": "Подробнее про загрузку и создание проекта – в разделе “Подробное описание”.",
    "pic-2": "Рисунок 2. Окно создания проекта.",
    "quick-start-6": "Первый шаг работы с проектом, позволяет отредактировать изображения, а также изменить глубинную привязку фотографий. Если этого не требуется, то для перехода на следующий шаг необходимо нажать кнопку “вперед” (1).",
    "pic-3": "Рисунок 3. Окно просмотра изображений",
    "quick-start-7": "На следующем шаге происходит обнаружение керна на изображении и его привязка по глубинам. Это происходит за счет создания маски изображения. В случае возникновения проблем в процессе обнаружения – система оповещает об этом пользователя с помощью информационного сообщения в окне предпросмотра результатов (1). Анализ происходит в фоновом режиме, пользователю не обязательно находиться на странице. По завершении пользователю приходит оповещение на почту, указанную при регистрации.",
    "pic-4": "Рисунок 4. Окно редактирования результатов детектирования керна и привязки керна.",
    "quick-start-8": "На третьем шаге можно дополнительно уточнить привязку керна и выполнить дополнительное разбиение на отдельные интервалы. Возможно выгрузить получившиеся данные с привязкой изображений по глубине. Выгрузка файлов происходит в виде zip-архива, где хранятся изображения и привязка по глубинам в виде файла .xlsx",
    "pic-5": "Рисунок 5.",
    "quick-start-9": "На последнем шаге происходит классификация изображений на типы, выбранные пользователем из предложенных моделей. Можно использовать как доступные модели, так и запросить новые модели, сообщив об этом по адресу help@petroleum.digital  с темой сообщения “DeepCore - модели”",
    "quick-start-10": "Описание можно выгрузить как в графическом, так и в текстовом формате в виде таблицы с привязкой по глубине.",
    "pic-6": "Рисунок 6.",
    "quick-start-11": "Более подробное описание представленных шагов и инструментов для редактирования можно найти в разделе “Подробное описание”. В случае, если в инструкции нет ответа на вопрос – всегда можно обратиться к нам по адресу help@petroleum.digital  с темой сообщения “DeepCore - помощь” и задать свой вопрос или описать свою проблему. Кроме того, вы всегда можете предложить новый функционал по тому же адресу с темой “DeepCore - функционал”.",
    "system-req": "Требования к системе",
    "system-req-1": "Приложение почти не использует ресурсы пользователя. Пользоваться им можно даже с планшета.",
    "system-req-2": "Приложение оптимизированно под многие современные браузеры (Safari, Edge, Chrome, Firefox). В случае работы в браузерах, работа над которыми прекращена - функциональность может быть ограничена (например, в Internet Explorer 11 невозможно использовать кнопку отмены действия более одного раза). В случае проблем с отображением страницы (например, элементы слишком большие) – попробуйте изменить масштаб страницы (Ctrl + колесо мыши или зайдите в настройки браузера – меню \"Масштаб\")",
    "system-req-3": "В случае возникновения проблем с просмотром результатов стандартными средствами системы - рекомендуем использовать просмоторщики с открытым исходным кодом такие как ImageGlass, XnViewMP, FastStone Image viewer.",
    "work-speed": "Скорость работы",
    "work-speed-1": "Система позволяет с высокой скоростью получать как результаты детектирования керна в ящиках (порядка одного ящика в секунду вне зависимости от количества керна в ящиках), так и результаты классификации керна на различные типы, выбранные пользователем (порядка 4 м в секунду). Общая скорость работы и отклика системы зависит от загруженности сервера.",
    "data-req": "Требования к данным",
    "data-req-1": "На данный момент существующие модели обучены на данных с минимальной шириной столбца керна равной примерно 10 см или 400 px (соответствует 100 dpi, в пересчёте на ширину столбца) и 6,2 см или 250 px (также 100 dpi, в пересчёте).",
    "data-req-2": "Желательны изображения ящиков керна без сильных искажений на краях (ячейки, в которых лежит керн, должны быть ровными). Керн на изображении должен быть расположен вертикально.",
    "pic-7": "Рисунок 7. Примеры приемлемых и неприемлемых данных (серая область – керн, белая -ящик).",
    "data-req-3": "Допустимый формат загрузки данных - JPEG, PNG, TIFF. Для конвертации изображений можно использовать просмотрщики, рекомендованные в разделе “Краткое описание” -> “Требованиях к системе”. В них есть возможность одновременно конвертации множества изображений.",
    "data-req-4": "В случае если изображения хранятся в формате CorelDraw, .xlsx, .doc – напишите нам по адресу help@petroleum.digital с темой сообщения \"DeepCore – препроцессор\". Мы предоставим препроцессор для преобразования таких данных в jpeg. В случае если данные хранятся в другом подобном формате мы можем исследовать эту проблему и рассмотрим возможность написать дополнительный препроцессор для ваших данных",
    "create-models": "Создание новых моделей",
    "create-models-1": "Для создания новых моделей вам надо обратиться к нам по адресу help@petroleum.digital  с темой сообщения “DeepCore - модели”",
    "create-models-2": "По запросу возможно дообучение моделей на основе размеченных пользователем данных.",
    "create-models-3": "В случае необходимости создания новой модели, отличной от доступных - необходимо иметь качественно подготовленную базу данных. Как правило – керн описан поинтервально (несколько метров керна = тот или иной тип породы или другая характеристика). Т. к. наши алгоритмы позволяют получить детальное описание, для обучения алгоритма необходима сантиметровая точность описания. Переход от метровой к сантиметровой точности возможен при помощи постобработки, выполняемой Digital Petroleum. С нашей стороны происходит постоянная поддержка пользователей как по вопросам подготовки данных, так и по вопросам обеспечения необходимыми инструментами для упрощения разметки данных.",
    "create-models-4": "Среднее время на подготовку данных и новой модели при наличии поинтервального описания составляет полторы недели, однако, ввиду частого наличия других обязанностей как у компании, так и у пользователей - подготовка данных может занимать до двух-трёх недель.",
    "feedback": "Обратная связь",
    "feedback-1": "Вы всегда можете обратиться по любым вопросам по адресу help@petroleum.digital",
    "feedback-2": "В случае необходимости разработать новую модель - “DeepCore - модель”.",
    "feedback-3": "В случае возникновения нештатных ситуаций или нехватки информации в инструкции - с темой сообщения “DeepCore - помощь”.",
    "feedback-4": "Если хотите предложить новый функционал или улучшение - “DeepCore - функционал”",
    "feedback-5": "Если необходим препроцессор для данных, хранящихся в форматах CorelDraw, .xlsx, .doc или другой - “DeepCore - препроцессор”",
    "details": "Подробное описание рабочего процесса",
    "projects": "Проекты",
    "projects-1": "Система основана на проектах. В проекте хранится информация по одной скважине. Название проектов может быть одинаковым. Можно создавать множество проектов.",
    "create-project": "Создание проекта",
    "create-project-1": "На странице проектов возможно создавать новые проекты нажатием кнопки “создать новый проект” (1).",
    "pic-8": "Рисунок 8. Добавление проекта.",
    "upload-img": "Загрузка изображений",
    "upload-img-1": "После нажатия кнопки “создать новый проект” будет выведено диалоговое окно загрузки изображений. Необходимо задать имя проекта (1) и загрузить файлы с изображением керна (2). Допустимые форматы изображений JPEG, PNG, TIFF. Ось керна на изображении должна быть ориентирована вертикально. Ориентацию изображений возможно будет исправить на первом шаге.",
    "pic-9": "Рисунок 9. Загрузка изображений в проект.",
    "upload-img-2": "Для упрощения работы в системе желательно, чтобы в имени файла изображения были указаны глубины в формате “верхняя глубина – нижняя глубина”. Примеры наименования: “Скважина 98, месторождение Обское, интервал 1988.23-1993.5, долбление 1980-2000”, “1988,23-1993,5”, “1988-1993”.",
    "upload-img-2-1": "Перед загрузкой изображений рекомендуется быстро их проверить (все ли изображения ориентированы верно, везде ли есть глубины). Такого рода проверка не займёт более 5 минут (при проверке 100 изображений).",
    "upload-img-3": "Для конвертации изображений можно использовать просмотрщики, рекомендованные в разделе \"Краткое описание\" -> \"Требованиях к системе\". В них есть возможность одновременно конвертации множества изображений. В случае каких -либо проблем или в случае необходимости конвертации большого количества изображений из форматов .cdr, .doc, или .xls – напишите нам по адресу help@petroleum.digital с темой сообщения \"DeepCore – препроцессор\"",
    "edit-project": "Изменение проектов",
    "edit-project-1": "Проект можно удалить или переименовать, нажав на кнопку настройки проекта (1).",
    "pic-10": "Рисунок 10. Дополнительные настройки проекта",
    "interface": "Обзор общего окружения для работы над проектом",
    "interface-1": "После создания и загрузки изображения пользователь начинает работу с проектом.",
    "interface-2": "Работа с проектом заключается в выполнении 4 шагов. Первый шаг предполагает просмотр и редактирование изображений, второй – обнаружение керна на изображении и привязку столбиков керна по глубине, третий – уточнение привязки и разбивка на дополнительные интервалы. Последний – классификацию изображений и редактирование результатов классификации.",
    "interface-3": "Все изменения на шаге автоматически сохраняются при выходе из режима редактирования или переходе на другое изображение или на следующий шаг. При переходе на шаг назад – результаты работы в текущем шаге стираются.",
    "work-space": "Обзор рабочей области",
    "work-space-1": "В каждом шаге есть несколько стандартных областей, которые присутствуют на всех шагах:",
    "work-space-2": "(1) окно предпросмотра данных",
    "work-space-3": "(2) окно работы с изображением",
    "work-space-4": "(3) окно навигации по изображению",
    "work-space-5": "(4) окно инструментов",
    "work-space-6": "(5) окно отображения текущего шага",
    "work-space-7": "(6) кнопка выхода из проекта",
    "work-space-8": "(7) кнопка перехода на следующий и предыдущий шаг",
    "work-space-9": "(8) кнопка загрузки результатов (активна на 3 и 4 шаге)",
    "pic-11": "Рисунок 11. Обзор рабочего пространства",
    "user-notification": "Оповещение пользователя",
    "user-notification-1": "По завершении работ по сегментации (шаг 2) или классификации (шаг 4) пользователю на почту приходит оповещение о завершении работы на шаге. После этого пользователь может редактировать результаты. Рекомендуется настроить фильтр сообщений у почтового сервиса для того, чтобы помещать все сообщения от системы в отдельную папку.",
    "user-notification-2": "Если вам необходима помощь в настройке фильтра – обратитесь к нам  по адресу help@petroleum.digital с темой сообщения “DeepCore – помощь”",
    "warnings-errors": "Предупреждения и ошибки",
    "warnings-errors-1": "В случае возникновения ошибок или предупреждений при проверке вводимых данных и других условий в нижнем правом углу возникает сообщение с ошибкой (красный цвет) или предупреждением (желтый цвет). В случае возникновения ошибки – пользователю необходимо предпринять действия к её устранению прежде, чем приступать к дальнейшим шагам. Предупреждения оповещают пользователя о проблемах, которые могут возникнуть в дальнейшем.",
    "tools": "Инструменты",
    "tools-1": "Для работы с изображениями существует несколько инструментов для навигации – “Рука” для перемещения по изображению (1), “Лупа” для приближения (2) и отдаления (3), “Карандаш” для редактирования (4) (отличается на каждом этапе). На первом шаге работы с проектом доступна кнопка глобальных настроек (\"Шестерёнка\") (5), она позволяет задать единую максимальную высоту колонки керна, общую для всех ящиков. В этом же окне присутствуют кнопки вперед (6) и назад (7) для отмены и повторения действий пользователя. ",
    "pic-12": "Рисунок 12. Доступные инструменты.",
    "step-1": "Шаг 1. Просмотр загруженных изображений",
    "step-1-tools": "Инструменты",
    "pic-13": "Рисунок 13. Доступные инструменты на шаге просмотра изображений",
    "step-1-1": "При просмотре изображений доступно несколько инструментов: поворот изображений по часовой (1) и против часовой стрелки (2), отражение изображения по вертикали (3) и горизонтали (4), обрезка изображения (5) и выравнивание с последующей обрезкой (5, 6).",
    "step-1-depths": "Редактирование глубин",
    "step-1-2": "В режиме редактирования возможно добавить или изменить глубины (7) привязки текущей фотографии. Без привязки к глубинам всех фотографий - невозможно перейти на следующий шаг. Для подтверждения изменений необходимо нажать кнопку “применить” (8).",
    "step-1-global-params": "Глобальные параметры проекта",
    "pic-14": "Рисунок 14. Глобальные параметры проекта, доступные на шаге просмотра загруженных изображений.",
    "step-1-3": "Для точной автоматической привязки столбцов керна по глубине необходимо установить стандартную высоту керна в ящике (как правило она равна 0.75 – 1 метр) (1). Кроме того – необходимо задать направление укладки керна (по умолчанию – справа-налево) (2).",
    "step-1-4": "Эти настройки доступны в глобальных параметрах проекта (3) и активны только на первом шаге.",
    "step-1-5": "Необходимо учитывать, что реальная высота керна может несколько отличаться от заявленной (как правило - в пределах 10 см). Это связано с тем, что как правило рулеткой замеряется длина коробки (от борта до борта), а не керна.",
    "step-1-notifications": "Предупреждения и уведомления",
    "step-1-6": "При переходе на следующий шаг - в случае наличия пересечения глубин вы будете об этом уведомлены. Если таких изображений много — это часто может быть связано с тем, что глобальные настройки выставлены неверно или некоторые изображения имеют пересекающиеся глубины в названиях. В случае некорректных глобальных настроек – измените их, перейдя на предыдущий шаг. В противном случае – обратите внимание на изображения с пересекающимися глубинами.",
    "step-1-errors": "Ошибки",
    "step-1-7": "При переходе на следующий шаг проверяется наличие глубин привязки изображений. В случае отсутствия глубин на каком-либо изображении – пользователю выводится предупреждение об этом через всплывающее окно красного цвета в нижней правой части экрана, переход на следующий шаг будет невозможен до исправления ошибки.",
    "step-2": "Шаг 2. Извлечение керна",
    "step-2-1": "После нажатия кнопки “вперёд” на шаге 1 начинается процесс сегментации изображений. Этот процесс происходит в фоновом режиме. По завершении пользователю приходит оповещение на почту. После этого можно приступать к редактированию.",
    "step-2-data": "Производимые данные",
    "step-2-2": "Результатом сегментации является маска с нанесёнными на неё глубинами.",
    "step-2-3": "Маска используется для дальнейшего извлечения керна. Важно чтобы маска была достаточно точной для извлечения (между столбиками керна есть промежутки, керн выделен правильно). В случае нахождения нескольких интервалов внутри одной фотографии – их можно разделить на несколько интервалов с помощью соответствующих инструментов.",
    "step-2-4": "В то же время глубины наносятся поверх маски. В случае, если на изображении есть несколько интервалов с сильно отличных глубин – возможно внести изменения с помощью инструмента редактирования глубин.",
    "step-2-notifications": "Оповещения",
    "step-2-5": "По завершении процесса сегментации пользователю на почту будет отправлено сообщение об этом событии. Не обязательно оставаться на странице до завершения процесса сегментации.",
    "step-2-tools": "Инструменты редактирования",
    "step-2-6": "Пользователь может редактировать маску. Для этого можно воспользоваться инструментами “Кисть” (1) и “Ластик” (2), чтобы добавить или удалить область. Размер инструментов может быть настроен с помощью ползунка (3) или введён вручную в соответствующем окне (4) в процессе редактирования. Редактирование глубин возможно на изображении. Для активации этого режима необходимо выбрать инструмент “Изменить глубину” (5).",
    "pic-15": "Рисунок 15. Инструменты для редактирования данных на шаге извлечения керна",
    "step-2-edit-mask": "Редактирование маски",
    "step-2-7": "Режим редактирования маски включается автоматически при выборе инструмента “Кисть” или “Ластик”. В случае наличия двух интервалов, не разделённых дощечкой, возможно стереть небольшую область маски, разделив керн на две части. При смене инструмента на инструмент “Изменить глубину” глубины будут пересчитаны и появится новая отметка глубины.",
    "pic-16": "Рисунок 16. Пример редактирования маски.",
    "step-2-edit-depths": "Редактирование глубин",
    "step-2-8": "Глубины, нанесённые поверх маски возможно отредактировать. Для этого необходимо выбрать инструмент “изменить глубину” (5). После этого активируется возможность выбора глубин, глубины приобретут синий цвет. Для изменения глубины надо нажать на глубину. Выведется окно ввода глубины.",
    "pic-17": "Рисунок 17. Пример использования инструмента “изменить глубину”.",
    "step-2-9": "При редактировании глубин применяется следующая логика – все глубины, находящиеся ниже выбранной, меняют свои значения в пределах одного изображения с заданным интервалом. Значения, находящиеся выше текущей глубины, остаются неизменными.",
    "step-2-edit-notifications": "Предупреждения и уведомления",
    "step-2-10": "В случае возможных проблем при сегментации изображения в окне предпросмотра данных могут появляться подсказки в виде информационных оповещений. При наведении – можно узнать в чём именно состоит проблема. Изображения с красным значком не будут использованы для дальнейшего построения колонки керна на третьем шаге. Изображения с желтым значком – будут использованы, но их следует проверить.",
    "pic-18": "Рисунок 18. Предупреждения и уведомления, возникающие при сегментации керна в окне предпросмотра данных.",
    "step-2-edit-errors": "Ошибки",
    "step-2-11": "В случае если на колонках керна обнаружены пересечения по глубине - пользователь не будет допущен до следующего шага. Будет выведено окно с названиями файлов, с которыми возникли проблемы.",
    "step-3": "Шаг 3. Привязка глубин",
    "step-3-1": "При переходе с шага 2 на шаг 3 – происходит обработка полученных масок и привязка глубин согласно нанесённых глубин на маску.",
    "step-3-data": "Производимые данные",
    "step-3-2": "По маске и глубинам происходит соединение всех столбиков керна в единый ствол. В случае наличия перерывов между интервалами – они будут отмечены на изображении линией разрыва. По этой линии в дальнейшем будут выгружаться данные. Благодаря этому пользователь получает удобный для дальнейшего анализа и импорта в другие приложения формат данных.",
    "step-3-tools": "Инструменты редактирования",
    "step-3-3": "Пользователь может внести изменения в конечную привязку элементов на изображении. Если необходимо изменить отдельную глубину– необходимо выбрать инструмент “Изменить глубину”. В случае если некоторые участки необходимо разбить на несколько отдельных частей керна – надо выбрать инструмент “Разделить”.",
    "pic-19": "Рисунок 19. Инструменты, доступные пользователю",
    "step-3-depths": "Редактирование глубин",
    "step-3-4": "Инструментом “Разделить” возможно разделить интервал на две части. В рамках этих частей будет происходить дальнейшее редактирование глубин",
    "pic-20": "Рисунок 20. Пример разрезания керна на две части.",
    "step-3-5": "После выбора инструмента \"Изменить глубину\" – активируется возможность изменения глубин. Глубины редактируются по следующей логике – все глубины ниже изменяемой вплоть до конца текущей части будут пересчитаны. Для отмены разреза – нажмите кнопку отмена действия или снова войдите на этот шаг с предыдущего.",
    "pic-21": "Рисунок 21. Редактирование глубин.",
    "step-3-download": "Выгрузка данных",
    "step-3-6": "Пользователь может выгрузить как отдельные столбцы керна, так и соединенные их части, с заданным разбиением. Выгрузка данных возможна при нажатии кнопки “Загрузка” (2).",
    "step-3-7": "После нажатия кнопки будет выведено окно с выбором типов данных.",
    "pic-22": "Рисунок 22. Выбор типов выгружаемых данных.",
    "step-3-8": "Данные можно выгрузить в виде отдельных колонок (1) и в объединенном виде (2).",
    "step-3-9": "В случае отдельных колонок - керн будет выгружен отдельными столбиками, как на отсегментированном изображении. В случае выбора выгрузки в объединенном виде – керн будет выгружен в виде объединенных частей керна, аналогично тому, как они объединены от разреза до разреза.",
    "step-3-10": "В дополнение к изображениям пользователь может выгрузить их привязку в табличном виде (5). Выгрузка данных происходит в zip-архиве. Изображения нумеруются соответственно их расположения по глубинам. Их имена привязываются к глубинам в таблице (1), прописывается принадлежность к полноразмерному изображению.",
    "pic-23": "Рисунок 23. Пример выгружаемых данных.",
    "step-3-edit-warnings": "Предупреждения и уведомления",
    "step-3-11": "При переходе с второго на третий шаг в случае наличия изображений с отсутствующими или некорректными масками (изображения в окне предпросмотра данных на втором шаге с красным значком).",
    "step-3-edit-errors": "Ошибки",
    "step-3-12": "В случае, если конечное изображение не было полностью подготовлено для перехода на следующий шаг – то при нажатии на кнопку “Вперед” будет выведена ошибка с соответствующим сообщением и не будет возможно перейти на следующий шаг.",
    "step-4": "Шаг 4. Классификация",
    "step-4-1": "В процессе перехода с 3 на 4 шаг происходит сохранение результатов.",
    "step-4-2": "Класс – предсказываемая алгоритмом единица. Классом может быть представлен тип породы, текстура, литотип (тип породы +текстура), состояние керна, трещины и многие другие параметры, которые возможно предсказать при наличии соответствующей модели. Для начала классификации необходимо выбрать модель.",
    "step-4-model": "Выбор модели и запуск классификации",
    "step-4-3": "Для выбора модели необходимо нажать на кнопку выбора модели на панели инструментов (1).",
    "pic-24": "Рисунок 24. Выбор модели для запуска процесса классификации.",
    "step-4-4": "Откроется окно выбора модели (2). В выпадающем окне (3) можно выбрать нужную для текущей задачи модель. После выбора будут отражены классы, доступные в этой модели. В случае, если необходимой модели нет – напишите нам по адресу help@petroleum.digital с темой “DeepCore - модель”. Выберите необходимую модель. В окне информации (3) указано для каких ситуаций та или иная модель может быть полезна.",
    "pic-25": "Рисунок 25. Выбор модели",
    "step-4-5": "После выбора модели необходимо нажать на кнопку “Применить”.",
    "step-4-6": "Появится окно настройки вида модели и классов, которые должны быть определены в модели – в случае отключения какого-либо (отменив флажки напротив соответствующих классов) из них – вероятности предсказания классов будут распределены между оставшимися классами.",
    "step-4-7": "Также можно изменить цвет и крапп заполняющий соответствующий класс. Это также можно сделать при работе с результатами интерпретации.",
    "pic-26": "Рисунок 26. Изменение цвета и краппа модели",
    "step-4-data": "Производимые данные",
    "step-4-8": "После выбора модели запустится процесс классификации изображений. Изображения отправляются на классификацию. В данный момент для всех моделей классификация происходит в масштабе 1:10 (класс определяется с интервалом в 10 см). Для каждого класса предсказывается вероятность принадлежности к тому или иному классу в пределах выбранных. Сумма предсказаний всегда равна 100%, поэтому могут быть случаи, когда предсказанный класс не является истинным, т.к. его не было в модели.",
    "step-4-9": "В окне предпросмотра данных слева появится линия, означающая вероятность предсказания класса. В случае если вероятность выше 60% - она окрашивается в зелёный, если – ниже 30% - в красный, промежуточные значения – желтый.",
    "pic-27": "Рисунок 27. Окно предпросмотра данных. Результаты классификация и вероятность предсказания.",
    "step-4-notifications": "Оповещения",
    "step-4-10": "По завершении процесса классификации пользователю на почту будет отправлено сообщение об этом событии. Не обязательно оставаться на странице до завершения процесса классификации.",
    "step-4-tools": "Инструменты редактирования",
    "step-4-11": "Пользователь может внести необходимые изменения в результаты. С помощью инструмента “Выбрать или изменить границы” можно уточнить границы определённых классов и изменить предсказанные классы целым блоком, просмотреть вероятность предсказания и изменить их на другой класс из списка, доступных в модели. Инструмент “Кисть” позволяет изменить участок от начала нажатия левой кнопки мыши (или прикосновение экрана) до момента отжимания (отпускания экрана). “Закрасить интервал” позволяет выбрать большой интервал от выбранной точки до второй выбранной точки. “Ластик” – позволяет стереть интервал на подобии инструмента “Кисть”.",
    "pic-28": "Рисунок 28. Инструменты редактирования, доступные на 4 шаге.",
    "step-4-edit": "Редактирование предсказанных классов",
    "step-4-12": "При выборе инструмента “Рука” на каждом из предсказанных интервале появится значок (i). При нажатии на него – появится окно с вероятностями, распределёнными по каждому из классов для данного интервала. Для изменения предсказанных классов в рамках целого интервала с идентичным классом выберите инструмент “Выбрать или изменить границы”. В случае нажатия на интервал – можно изменить его целиком, нажав на соответствующий класс в окне класса.",
    "pic-29": "Рисунок 29. Редактирование результатов предсказания и просмотр точного распределения вероятностей.",
    "step-4-13": "Если необходимо изменить вид или название класса – это возможно сделать, нажав на соответствующий знак в области выбора классов.",
    "pic-30": "Рисунок 30. Изменение вида класса после применения модели",
    "step-4-classes": "Добавление и удаление собственных классов",
    "step-4-14": "В окно классов пользователь может добавить собственный класс, необходимый ему для решения его задачи. Пользователь может выбрать любое сочетание цвета и краппа, доступное на выбор. Невозможно задать два одинаковых сочетания. Если какого-либо краппа не хватает – вы можете обратиться по адресу help@petroleum.digital с темой “DeepCore - функционал” с примером необходимого краппа и просьбой его добавить.",
    "pic-31": "Рисунок 31. Создание и удаление пользовательского класса, выбор цвета и краппа.",
    "step-4-15": "Созданный класс будет доступен для редактирования описанными выше способами.",
    "step-4-16": "Также пользователь может удалить созданный класс, нажав на кнопку удаления. В таком случае класс будет удалён со всей колонки керна.",
    "step-4-download": "Выгрузка данных",
    "step-4-17": "После внесения необходимых изменений (если такие требуются) – пользователь может выгрузить результаты. Все изменения сохраняются автоматически. Для того чтобы выгрузить данные – необходимо нажать на кнопку “Экспорт”. Появится диалоговое окно с выбором типов данных, которые можно выгрузить.",
    "pic-32": "Рисунок 32. Выбор типов выгружаемых данных на четвертом шаге.",
    "step-4-18": "Данные выгружаются в виде zip-архива.",
    "step-4-19": "Возможно выгрузить несколько типов данных. Графический отчет – графическое отображение результатов, нарезанное с шагом в 10000 пикселей (фотография керна, глубина, колонка с классами). Отчет в виде таблицы – табличные данные, с классом (“label”, “class”), привязанным по глубине (указывается верхняя и нижняя граница глубины, на которой он находится), имя изначального изображения (“ini_image”), к которому принадлежит данный класс, часть керна, к которой она относится (“image_part”) и соответствующее наименование части керна, если керн выгружался в качестве отдельных столбцов на третьем шаге. Условные обозначения – таблица условных обозначений (крапп и название класса, включая пользовательские классы).",
    "step-4-19-2": "Мы можем создать препроцессор, который будет преобразовывать выгруженные данные в необходимый формат. Обратитесь к нам для создания препроцессора по адресу help@petroleum.digital с темой “DeepCore – препроцессор”.",
    "pic-33": "Рисунок 33. Примеры выгружаемых данных на четвертом шаге.",
    "step-4-warnings": "Предупреждения и уведомления",
    "step-4-20": "После выбора новой модели и нажатия кнопки “Применить” – выводится уведомление о том, что изображение отправлено на классификацию. Схожее уведомление отображается в случае, если зайти в проект до завершения процесса классификации.",
    "step-4-errors": "Ошибки",
    "step-4-21": "Если не был выбран класс при выборе инструмента для редактирования будет показано сообщение, оповещающее об этом."
  }
}

</i18n>
<!-- eslint-enable -->
