<template>
  <div :class="[$i18n.locale.toLowerCase()]" class="classification-models">
    <div class="classification-models-title windows-title">
      {{ $t("title") }}
    </div>

    <b-form
        class="classification-models-form"
        @submit.prevent="applySelectedModel"
    >
      <b-dropdown
          class="model-dropdown"
          no-caret
          @shown="updateModelItemsScrollbar"
      >
        <template v-slot:button-content>
          <div class="d-flex flex-row">
            <div
                v-if="selectedModel"
                :title="getModelName(selectedModel)"
                class="model-name text-truncate"
            >
              {{ getModelName(selectedModel) }}
            </div>
            <div class="ml-auto mt-0">
              <dp-icon
                  :sensitive="false"
                  height="24"
                  name="chevron-down"
                  width="24"
              >
              </dp-icon>
            </div>
            <div
                v-b-popover.hover.bottom="{
                boundary: 'viewport',
                content: getModelDescription(selectedModel),
              }"
                class="ml-1 mt-0"
            >
              <dp-icon height="24" name="info" width="24" @click.native.stop="">
              </dp-icon>
            </div>
          </div>
        </template>
        <vue-custom-scrollbar
            ref="model-items-scrollbar"
            :settings="scrollYSettings"
            class="classification-model-items scrollable-container"
            tagname="div"
        >
          <b-dropdown-item
              v-for="model in models"
              :key="model.id"
              :title="getModelName(model)"
              @click="changeSelectedModel(model)"
          >
            {{ getModelName(model) }}
          </b-dropdown-item>
        </vue-custom-scrollbar>
      </b-dropdown>

      <dp-button secondary type="submit">{{ $t("action") }}</dp-button>
    </b-form>

    <vue-custom-scrollbar
        v-if="selectedModel"
        :settings="scrollYSettings"
        class="classification-model-types scrollable-container"
        tagname="div"
    >
      <div
          v-for="(type, index) in selectedModel.classes"
          :key="index"
          class="classification-model-type"
      >
        <lithology-type-preview
            :color="type.color"
            :height="24"
            :pattern="type.pattern"
            :width="24"
            class="type-preview"
        />
        <div :title="getModelTypeName(type)" class="type-title">
          {{ getModelTypeName(type) }}
        </div>
      </div>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import VueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import DpButton from "@/components/button/dp-button";
import DpIcon from "@/components/icon/dp-icon";
import LithologyTypePreview from "@/views/components/lithology/lithology-type-preview";
import ScrollMixin from "@/mixins/scroll-mixin";

/**
 * @group Views-components-lithology
 * This is a description of the component
 */
export default {
  name: "classification-models",
  components: {
    DpIcon,
    DpButton,
    LithologyTypePreview,
    VueCustomScrollbar,
  },
  mixins: [ScrollMixin],
  props: {
    models: {
      type: Array,
      default: () => {
        return [];
      },
    },
    modelId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedModel: null,
    };
  },
  watch: {
    modelId(value) {
      this.initSelectedModel(this.models, value);
    },
    models(value) {
      this.initSelectedModel(value, this.modelId);
    },
  },
  mounted() {
    this.initSelectedModel(this.models, this.modelId);
  },
  destroyed() {
    this.selectedModel = null;
  },
  methods: {
    initSelectedModel(models, modelId) {
      if (models.length > 0 && modelId != null) {
        this.selectedModel = models.find((model) => model.id === modelId);
      } else if (models.length > 0) {
        this.selectedModel = models[0];
      } else {
        this.selectedModel = null;
      }
    },
    changeSelectedModel(value) {
      this.selectedModel = value;
    },
    applySelectedModel() {
      this.$emit("apply-model", this.selectedModel);
    },
    getModelName(model) {
      return model ? model.names[this.$i18n.locale.toUpperCase()] : "";
    },
    getModelDescription(model) {
      return model ? model.descriptions[this.$i18n.locale.toUpperCase()] : "";
    },
    getModelTypeName(type) {
      return type ? type.names[this.$i18n.locale.toUpperCase()] : "";
    },
    updateModelItemsScrollbar() {
      this.$nextTick(() => {
        this.$refs["model-items-scrollbar"].update();
      });
    },
  },
};
</script>

<style lang="scss">
$widthclassificationru: 500px;
$widthclassificationen: $widthclassificationru - 50px;

// common characteristics
$widthclassificationdropdownmenu: calc(
    max($widthclassificationen, $widthclassificationru) -
    min($widthclassificationen, $widthclassificationru) / 4
);
$widthclassificationdropdowntoggle: calc(
    ($widthclassificationdropdownmenu) - $widthclassificationru / 7
    // 3.5 6 10
);

$widthmodelnameclassificationtext: calc(
    $widthclassificationdropdowntoggle
);

$maxwidthclassificationmodelitemsen: $widthclassificationen+2px;
$maxwidthclassificationmodelitemsru: $widthclassificationru+2px;

$maxheightclassificationmodelitemsru: 120px;
$maxheightclassificationmodelitemsen: 120px;

$modellistlineclamp: 3;


.classification-models {
  width: $widthclassificationen;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: $--color-base-01;
  box-shadow: -5px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  &.ru {
    width: $widthclassificationru;
  }

  &-title {
    margin-bottom: 16px;
  }

  &-form {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 16px;

    .model-dropdown {
      height: 40px;

      .model-name {
        width: $widthmodelnameclassificationtext;
        text-align: left;
      }

      .dropdown-toggle {
        width: $widthclassificationdropdowntoggle;
        margin: 0;
        padding: 7px 16px;
        background-color: $--color-base-01 !important;
        border: 2px solid $--color-base-02 !important;
        border-radius: 8px;
        color: $--color-primary !important;
        font-weight: $--font-weight-medium;
        font-size: $--font-size-base;
      }

      .dropdown-toggle[aria-expanded="true"] {
        border-color: $--color-secondary !important;
      }

      .dropdown-menu {
        background-color: $--color-base-01;
        border: 2px solid $--color-base-02;
        border-radius: 4px;
        box-shadow: none;
        padding: 8px;
        width: $widthclassificationdropdownmenu;
      }

      .dropdown-item {
        padding: 8px;
        border-radius: 4px;
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        color: $--color-primary;
        background-color: $--color-base-05;
        border: none;
        border-radius: 4px;
      }
    }

    .dp-button-group {
      margin-left: 12px;
    }
  }

  &.ru {
    .classification-model-items {
      max-width: $maxwidthclassificationmodelitemsru;
      max-height: $maxheightclassificationmodelitemsru;
    }
  }

  .classification-model-items {
    max-height: $maxheightclassificationmodelitemsen;
    max-width: $maxwidthclassificationmodelitemsen;

    &.scrollable-container.ps--active-y {
      margin-right: -4px;

      li {
        margin-right: 8px;
      }
    }

    &.scrollable-container:not(.ps--active-y) {
      margin-right: 0;

      li {
        margin-right: 0;
      }
    }

    .dropdown-item {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .classification-model-types {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 460px;

    .classification-model-type {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      min-height: 50px;
      padding: 8px 16px;
      box-sizing: border-box;

      &:not(:first-child) {
        margin-top: 4px;
      }

      .type-preview {
        margin-right: 8px;
      }

      .type-title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: $--font-weight-medium;
        line-height: 17px;
      }

      @supports (-webkit-line-clamp: $modellistlineclamp) {
        .type-title {
          display: -webkit-box;
          -webkit-line-clamp: $modellistlineclamp;
          -webkit-box-orient: vertical;
          white-space: unset;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Model",
    "action": "Apply"
  },
  "ru": {
    "title": "Модель",
    "action": "Применить"
  }
}
</i18n>
