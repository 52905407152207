import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/plugins/i18n'
import '@/plugins/axios'
import '@/plugins/logger'
import '@/plugins/bootstrap'
import '@/plugins/vee-validate'
import '@/plugins/konva'
import '@/plugins/mask'

import '@/assets/scss/index.scss'

import ToastMixin from '@/mixins/toast-mixin'
import BrowserMixin from '@/mixins/browser-mixin'

Vue.config.productionTip = false

// console.log(process.env)
console.log('Application environments:')
console.log('window.location.host:', window.location.host)
console.log('process.env.NODE_ENV:', process.env.NODE_ENV)
console.log('process.env.VUE_APP_PROTOCOL:', process.env.VUE_APP_PROTOCOL)
console.log('process.env.VUE_APP_HOST:', process.env.VUE_APP_HOST)
console.log('process.env.VUE_APP_CONTEXT:', process.env.VUE_APP_CONTEXT)
console.log('process.env.VUE_APP_PUBLIC_PATH:', process.env.VUE_APP_PUBLIC_PATH)
console.log('process.env.VUE_APP_USE_AD_AUTH:', process.env.VUE_APP_USE_AD_AUTH)
console.log('process.env.VUE_APP_UI_VERSION:', process.env.VUE_APP_UI_VERSION)

Vue.mixin(ToastMixin)
Vue.mixin(BrowserMixin)

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App)
}).$mount('#app')
