<template>
  <div class="project-core-selection d-flex w-100">
    <image-previews
        ref="imagePreviews"
        :active-image.sync="image"
        :images="images"
        :sort-by-depth="true"
    >
    </image-previews>

    <div ref="imageContainer" class="image-container flex-grow-1">

      <!-- control the current image selected from preview -->
      <image-preview
          :image="imagePreview.image"
          :scale="image.bgConfig.scaleX"
          :viewport="imagePreview.viewport"
          class="preview"
      >
      </image-preview>
      <image-filename
          :filename="image.file_name"
          class="filename"
      ></image-filename>
      <core-depth
          :active.sync="image.depth.current.active"
          :pointer-position="image.depth.current.position"
          :rules="image.depth.current.rules"
          :value="image.depth.current.value"
          class="depth"
          @core-depth-apply="applyDepth"
      >
      </core-depth>

      <v-stage
          ref="canvasStage"
          :config="canvasConfig"
          class="image-canvas"
          @contextmenu="
          (e) => {
            e.evt.preventDefault();
          }
        "
      >
        <v-layer ref="canvasLayer">
          <v-group
              v-if="imageLoaded"
              ref="canvasImageGroup"
              :config="image.groupConfig"
          >
            <v-image ref="canvasBgImage" :config="image.bgConfig"/>
            <v-image ref="canvasMaskImage" :config="image.maskConfig"/>
            <template v-for="coreId in Object.keys(image.cores)">
              <v-rect
                  v-if="image.depth.enabled"
                  :key="`top-depth-rect-${coreId}`"
                  :config="coreTopDepthRectConfig(image.cores[coreId])"
              />
              <v-text
                  v-if="image.depth.enabled"
                  :key="`top-depth-text-${coreId}`"
                  :config="coreTopDepthTextConfig(image.cores[coreId])"
              />
              <v-rect
                  v-if="image.depth.enabled"
                  :key="`bottom-depth-rect-${coreId}`"
                  :config="coreBottomDepthRectConfig(image.cores[coreId])"
              />
              <v-text
                  v-if="image.depth.enabled"
                  :key="`bottom-depth-text-${coreId}`"
                  :config="coreBottomDepthTextConfig(image.cores[coreId])"
              />
            </template>
          </v-group>
        </v-layer>
      </v-stage>
      <b-overlay :opacity="0.3" :show="displayCanvasLoader" no-wrap></b-overlay>
    </div>

    <image-toolbar
        v-if="Object.keys(this.images).length > 1"
        ref="imageToolbar"
        :editing-tools="['pointer-depth', 'brush', 'eraser', 'trash-bin']"
        :redo-enabled="redoEnabled"
        :undo-enabled="undoEnabled"
        @brush="brushImage"
        @eraser="eraserImage"
        @hand="handImageLocal"
        @redo="redoImage"
        @undo="undoImage"
        @pointer-depth="editImageDepths"
        @brush-stroke-width="changeContextLineWidth"
        @eraser-stroke-width="changeContextLineWidth"
        @trash-bin="deleteImageandMask"
        @zoom-in="zoomImageInLocal"
        @zoom-out="zoomImageOutLocal"
    >
    </image-toolbar>

    <image-toolbar
        v-if="Object.keys(this.images).length <= 1"
        ref="imageToolbar"
        :editing-tools="['pointer-depth', 'brush', 'eraser']"
        :redo-enabled="redoEnabled"
        :undo-enabled="undoEnabled"
        @brush="brushImage"
        @eraser="eraserImage"
        @hand="handImageLocal"
        @redo="redoImage"
        @undo="undoImage"
        @pointer-depth="editImageDepths"
        @brush-stroke-width="changeContextLineWidth"
        @eraser-stroke-width="changeContextLineWidth"
        @zoom-in="zoomImageInLocal"
        @zoom-out="zoomImageOutLocal"
    >
    </image-toolbar>

    <project-core-selection-notice
        :file-name.sync="image.file_name"
        :modalNotice.sync="modal.notice"
        :project-id="project.project_id"
        v-on:deleteCoreFiles="deleteCoreFiles"
        v-on:loadPreviews="loadPreviews"
    >
    </project-core-selection-notice>
  </div>
</template>

<script>
import ImageToolbar from "@/views/components/image-toolbar";
import ImagePreviews from "@/views/components/image-previews";
import ImagePreview from "@/views/components/image-preview";
import ImageFilename from "@/views/components/image-filename";
import ProjectService from "@/services/project-service";
import FileService from "@/services/file-service";
import LookupService from "@/services/lookup-service";
import CanvasImageMixin from "@/views/mixins/canvas-image-mixin";
import CoreDepth from "@/views/components/core-depth";
import MessageMixin from "@/mixins/message-mixin";
import ProjectCoreSelectionNotice from "./project-core-selection-notice.vue";

/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: "project-core-selection",
  components: {
    /**
     * @vuese
     * ..
     */
    CoreDepth,
    /**
     * @vuese
     * ..
     */
    ImageFilename,
    /**
     * @vuese
     * ..
     */
    ImagePreviews,
    /**
     * @vuese
     * ..
     */
    ImagePreview,
    /**
     * @vuese
     * ..
     */
    ImageToolbar,
    /**
     * @vuese
     * ..
     */
    ProjectCoreSelectionNotice,
  },
  mixins: [
    /**
     * @vuese
     * ..
     */
    CanvasImageMixin,
    /**
     * @vuese
     * ..
     */
    MessageMixin],
  props: {
    /**
     * @vuese
     * ..
     */
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      /**
       * @vuese
       * ..
       */
      images: {},
      /**
       * @vuese
       * ..
       */
      files: {},
      /**
       * @vuese
       * ..
       */
      fileCores: {},
      /**
       * @vuese
       * ..
       */
      srcfileid: null,
      /**
       * @vuese
       * ..
       */
      modal: {
        notice: false,
      },
      /**
       * @vuese
       * ..
       */
      image: {
        file_id: null,
        file_name: null,
        groupConfig: {},
        maskConfig: {},
        bgConfig: {},
        cContext: null,
        cores: {},
        depth: {
          enabled: false,
          editable: false,
          scale: null,
          current: {
            active: false,
            value: 0,
            rules: null,
            position: null,
          },
        },
      },
      /**
       * @vuese
       * ..
       */
      coreColumn: {
        opacity: 0.5,
        color: "#FFFFFF",
        depthCharWidth: 13,
      },
      /**
       * @vuese
       * ..
       */
      reloadTask: null,
      /**
       * @vuese
       * ..
       */
      reloadTimeout: 5000,
      /**
       * @vuese
       * ..
       */
      toolCursor: {
        minimal: () => {
          // eslint-disable-next-line
          return `url('data:image/svg+xml;charset=utf8,%3Csvg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M1 1H4V4H1V1ZM3 2H2V3H3V2Z" fill="white"/%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M0 0H5V5H0V0ZM1 1V4H4V1H1Z" fill="%23242424"/%3E%3C/svg%3E') 2.5 2.5, auto`;
        },
        standard: (width, height) => {
          // eslint-disable-next-line
          return `url('data:image/svg+xml;charset=utf8,%3Csvg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle cx="50%25" cy="50%25" r="${
              width / 2 - 2
          }" stroke="white"/%3E%3Ccircle cx="50%25" cy="50%25" r="${
              width / 2 - 1
          }" stroke="%23242424"/%3E%3C/svg%3E') ${width / 2} ${
              height / 2
          }, auto`;
        },
        maximum: () => {
          // eslint-disable-next-line
          return `url('data:image/svg+xml;charset=utf8,%3Csvg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle cx="50%25" cy="50%25" r="14" stroke="white" stroke-dasharray="5.6,5.6" stroke-linecap="round"/%3E%3Ccircle cx="50%25" cy="50%25" r="15" stroke="%23242424" stroke-dasharray="6,6" stroke-linecap="round"/%3E%3C/svg%3E') 16 16, auto`;
        },
      },
      /**
       * @vuese
       * ..
       */
      displayCanvasLoader: false,
    };
  },
  computed: {
    /**
     * @vuese
     * ..
     */
    imageLoaded() {
      return (
          this.image &&
          this.image.maskConfig &&
          Object.keys(this.image.maskConfig).length > 0 &&
          this.image.bgConfig &&
          Object.keys(this.image.bgConfig).length > 0 &&
          this.image.groupConfig &&
          Object.keys(this.image.groupConfig).length > 0
      );
    },
  },
  watch: {
    /**
     * @vuese
     * ..
     */
    image(newImage, oldImage) {
      if (newImage.file_id !== oldImage.file_id) {
        this.saveActiveImage(oldImage);
        if (!this.needSaveImage()) {
          this.reloadActiveImage(newImage);
        } else {
          this.displayCanvasLoader = false;
          this.$refs.imageToolbar.enable();
          this.reloadActiveImage(newImage);
        }
      }
    },
  },
  /**
   * @vuese
   * ..
   */
  mounted() {
    this.$nextTick(() => {
      this.resizeCanvas();
      window.addEventListener("resize", this.resizeCanvas);

      this.loadCoreColumnInfo()
          .then(() => {
            return this.loadImages();
          })
          .then(() => {
            return this.$nextTick(() => this.resizeCanvas());
          });
    });
  },
  /**
   * @vuese
   * dest
   */
  destroyed() {
    // Fire when the form is destroyed
    // @arg The argument is a boolean value representing xxx
    Object.keys(this.files).forEach((fileId) => this.deleteFile(fileId));

    this.images = null;
    this.files = null;
    this.fileCores = null;
    this.image = null;

    clearTimeout(this.reloadTask);

    window.removeEventListener("resize", this.resizeCanvas);
  },
  methods: {
    /**
     * @vuese
     * ..
     */
    closeProject() {
      return this.saveActiveImage(this.image, false);
    },
    /**
     * @vuese
     * ..
     */
    goToNextStep() {

      if (
          Object.keys(this.images).find((fileId) => this.images[fileId].wait_file)
      ) {
        const message = this.$t("errors.messages.DP-30");
        this.showEMessage(message);
        return Promise.reject(new Error(`Manual reject: ${message}`));
      }
      return ProjectService.hasCoreDepthsIntersections(
          this.project.project_id
      ).then((result) => {

        const status = result.data.check_success;
        if (status) {
          return this.saveActiveImage(this.image, false);
        } else { // if there is an intersection

          const incorrectFileNames = result.data.incorrect_file_names;
          const more = incorrectFileNames.length > 2 ? incorrectFileNames.length - 2 : 0;
          let message = this.$t("messages.core-depths-intersections", {
            filenames: incorrectFileNames.slice(0, 2),
          });
          if (more) {
            message =
                message +
                " " +
                this.valueMessage(
                    more,
                    this.$t("messages.depths-intersections-more")
                ).replace("{value}", more);
          }
          this.showEMessage(message);
          return Promise.reject(new Error(`Manual reject: ${message}`));
        }
      });
    },
    /**
     * @vuese
     * ..
     */
    afterGoToNextStep() {
      return ProjectService.hasDepthsIntersections(
          this.project.project_id
      ).then((result) => {
        const status = result.data.check_success;
        if (!status) {
          const filenames = result.data.incorrect_file_names;
          const more = filenames.length > 2 ? filenames.length - 2 : 0;
          let message = this.$t("messages.depths-intersections", {
            filenames: filenames.slice(0, 2),
          });
          if (more) {
            message =
                message +
                " " +
                this.valueMessage(
                    more,
                    this.$t("messages.depths-intersections-more")
                ).replace("{value}", more);
          }
          this.showWMessage(message, 15000);
        }
        return status;
      });
    },
    /**
     * @vuese
     * ..
     */
    loadImages() {
      let oldArrImg = this.images;
      const projectId = this.project.project_id;
      return Promise.all([
        ProjectService.images(projectId),
        ProjectService.masks(projectId),
      ]).then((results) => {
        if (!results[0].data || results[0].data.length === 0) {
          return false;
        }

        const masks = results[1].data
            ? results[1].data.reduce((masks, mask) => {
              masks[mask.file_id] = mask;
              return masks;
            }, {})
            : {};

        const srcFileIds = results[1].data
            ? results[1].data.map((mask) => mask.src_file_id)
            : [];
        const notReadySrcFiles = {};
        results[0].data.forEach((image) => {
          const fileId = image.file_id;
          if (!srcFileIds.includes(fileId)) {
            masks[fileId] = {
              file_id: fileId,
              file_name: image.file_name,
              src_file_id: fileId,
              top_depth: image.top_depth,
              bottom_depth: image.bottom_depth,
              wait_file: true,
            };
            notReadySrcFiles[fileId] = {};
          }
        });
        this.initReloadTask(notReadySrcFiles);
        this.$set(this, "images", masks);
        let newArrImg = this.images;
        if (Object.keys(newArrImg).length < Object.keys(oldArrImg).length) {
          var keys = Object.keys(newArrImg);
          this.image = newArrImg[keys[0]];
          this.reloadActiveImage(this.image);
        }
        return true;
      });
    },
    /**
     * @vuese
     * ..
     */
    initReloadTask(notReadySrcFiles) {
      if (Object.keys(notReadySrcFiles).length > 0) {
        this.reloadTask = setTimeout(() => {
          this.autoReloadMasks(notReadySrcFiles);
        }, this.reloadTimeout);
      } else {
        this.reloadTask = null;
      }
    },
    /**
     * @vuese
     * ..
     */
    autoReloadMasks(notReadySrcFiles) {
      const notReadySrcFileIds = Object.keys(notReadySrcFiles);
      ProjectService.masks(this.project.project_id).then((result) => {
        if (result.data) {
          result.data.forEach((mask) => {
            const fileId = mask.file_id;
            const srcFileId = mask.src_file_id;
            if (notReadySrcFileIds.includes(srcFileId)) {
              this.$delete(notReadySrcFiles, srcFileId);
              this.$delete(this.images, srcFileId);
              this.$set(this.images, fileId, mask);
              this.$refs.imagePreviews.loadPreview(fileId);
            }
          });
        }
        this.initReloadTask(notReadySrcFiles);
      });
    },
    /**
     * @vuese
     * ..
     */
    loadCoreColumnInfo() {
      return Promise.all([
        ProjectService.coreColumnColor(this.project.project_id),
        LookupService.coreColumnOpacity(),
      ]).then((results) => {
        this.coreColumn.color = results[0].data.color;
        this.coreColumn.opacity = results[1].data.opacity;
        return true;
      });
    },
    /**
     * @vuese
     * ..
     */
    saveActiveImage(image, refreshPreview = true) {
      if (!this.needSaveImage()) {
        return Promise.resolve(false);
      }
      const fileId = image.file_id;

      this.$refs.imagePreviews.startPreviewLoading(fileId);
      this.deleteFile(fileId);
      this.deleteFileCores(fileId);

      const canvasMaskImage = this.$refs.canvasMaskImage.getNode();
      canvasMaskImage.opacity(1);
      const url = canvasMaskImage.toDataURL({
        mimeType: "image/png",
        pixelRatio: 1 / Math.abs(image.maskConfig.scaleX),
      });
      canvasMaskImage.opacity(this.coreColumn.opacity);

      return FileService.swapDataURL(fileId, image.file_name, "image/png", url)
          .then((result) => {
            this.$set(this.images, fileId, result.data);
            if (refreshPreview) {
              return this.$refs.imagePreviews.loadPreview(fileId);
            } else {
              this.$refs.imagePreviews.endPreviewLoading(fileId);
              return true;
            }
          })
          .catch(() => {
            this.$set(this.images[fileId], "cores", {});
            this.$refs.imagePreviews.endPreviewLoading(fileId);
            return false;
          });
    },
    /**
     * @vuese
     * ..
     */
    setupImage(image) {
      this.$set(image, "groupConfig", {});
      this.$set(image, "bgConfig", {});
      this.$set(image, "maskConfig", {});
      this.$set(image, "cContext", null);
      this.$set(image, "cores", {});
      this.$set(image, "depth", {
        enabled: false,
        editable: false,
        scale: null,
        current: {
          active: false,
          value: 0,
          rules: null,
          position: null,
        },
      });
    },
    /**
     * @vuese
     * ..
     */
    reloadActiveImage(image, tool, callback) {
      this.clearHistory();
      this.clearPreview();
      this.setupImage(image);

      this.$refs.imageToolbar.disable();

      const fileId = image.file_id;
      const srcFileId = image.src_file_id;

      const file = this.files[fileId];
      const srcFile = this.files[srcFileId];

      if (file && srcFile) {
        this.reloadActiveImageFromFile(image, file, srcFile, tool, callback);
      } else {
        const promises = [];
        promises.push(
            file ? Promise.resolve({file: file}) : FileService.file(fileId)
        );
        promises.push(
            srcFile
                ? Promise.resolve({file: srcFile})
                : FileService.file(srcFileId)
        );

        Promise.all(promises).then((results) => {
          const file = results[0].file
              ? results[0].file
              : URL.createObjectURL(results[0].data);
          this.$set(this.files, fileId, file);
          const srcFile = results[1].file
              ? results[1].file
              : URL.createObjectURL(results[1].data);
          this.$set(this.files, srcFileId, srcFile);

          this.reloadActiveImageFromFile(image, file, srcFile, tool, callback);
        });
      }
      this.srcfileid = srcFileId;
    },
    /**
     * @vuese
     * ..
     */
    reloadActiveImageFromFile(image, maskFile, bgFile, tool, callback) {
      const maskHtmlImage = new Image();
      maskHtmlImage.src = maskFile;
      maskHtmlImage.onload = () => {
        const bgHtmlImage = new Image();
        bgHtmlImage.src = bgFile;
        bgHtmlImage.onload = () => {
          const bgImageConfig = this.createImageConfig(bgHtmlImage);
          const groupConfig = {
            x: this.canvasConfig.width / 2,
            y: this.canvasConfig.height / 2,
            offset: {
              x: (bgHtmlImage.width / 2) * bgImageConfig.scaleX,
              y: (bgHtmlImage.height / 2) * bgImageConfig.scaleY,
            },
          };
          this.$set(image, "groupConfig", groupConfig);

          bgImageConfig.x = groupConfig.offset.x;
          bgImageConfig.y = groupConfig.offset.y;
          bgImageConfig.offset = {
            x: groupConfig.offset.x / bgImageConfig.scaleX,
            y: groupConfig.offset.y / bgImageConfig.scaleY,
          };
          bgImageConfig.listening = false;
          this.$set(image, "bgConfig", bgImageConfig);

          const maskImageConfig = this.createImageConfig(maskHtmlImage);
          maskImageConfig.image = document.createElement("canvas");
          maskImageConfig.image.width = maskImageConfig.width;
          maskImageConfig.image.height = maskImageConfig.height;
          maskImageConfig.opacity = this.coreColumn.opacity;

          const cContext = maskImageConfig.image.getContext("2d", { willReadFrequently: true });
          cContext.drawImage(maskHtmlImage, 0, 0);

          maskImageConfig.x = groupConfig.offset.x;
          maskImageConfig.y = groupConfig.offset.y;
          maskImageConfig.offset = {
            x: groupConfig.offset.x / maskImageConfig.scaleX,
            y: groupConfig.offset.y / maskImageConfig.scaleY,
          };
          this.$set(image, "maskConfig", maskImageConfig);
          this.$set(image, "cContext", cContext);

          this.reloadActiveImageCores(image).finally(() => {
            this.$nextTick(() => {
              this.getCanvasImage().dragBoundFunc(this.getDragBoundFunc());
              this.$refs.imageToolbar.setup();
              if (tool) {
                this.$refs.imageToolbar.activateTool(tool);
              }
              this.setupHistory(this.getHistoryImageData());
              this.reloadPreview();

              if (callback) {
                callback();
              }
            });
          });
        };
      };
    },
    /**
     * @vuese
     * ..
     */
    reloadActiveImageCores(image) {
      const fileId = image.file_id;
      const cores = this.fileCores[fileId];
      if (cores && Object.keys(cores).length > 0) {
        this.$set(image, "cores", cores);
        this.calculateCoreDepthScale(image);
        this.$set(image.depth, "enabled", true);
        return Promise.resolve(true);
      } else {
        return FileService.cores(fileId).then((result) => {
          if (result.data && result.data.length > 0) {
            const cores = result.data.reduce((cores, core) => {
              cores[core.core_id] = core;
              return cores;
            }, {});
            this.$set(this.fileCores, fileId, cores);
            this.$set(image, "cores", cores);
            this.calculateCoreDepthScale(image);
            this.$set(image.depth, "enabled", true);
          }
          return true;
        });
      }
    },
    /**
     * @vuese
     * ..
     */
    calculateCoreDepthScale(image) {
      const scaleX = image.maskConfig.scaleX;
      const scales = Object.keys(image.cores).map((coreId) => {
        const core = image.cores[coreId];
        const cw = core.width * scaleX;

        const tw =
            core.top_depth.toFixed(2).length * this.coreColumn.depthCharWidth;
        const ts = cw / tw > 1 ? 1 : cw / tw;

        const bw =
            core.bottom_depth.toFixed(2).length * this.coreColumn.depthCharWidth;
        const bs = cw / bw > 1 ? 1 : cw / bw;

        return Math.min(ts, bs);
      });
      const scale = Math.min(...scales);
      this.$set(image.depth, "scale", Math.max(scale, 0.3));
    },
    /**
     * @vuese
     * ..
     */
    coreTopDepthTextConfig(core) {
      const scaleX = this.image.maskConfig.scaleX;
      const scaleY = this.image.maskConfig.scaleY;
      const coreDepthTextConfig = this.coreDepthTextConfig(core.top_depth);
      return {
        id: `top-depth-text-${core.core_id}`,
        coreId: core.core_id,
        coreDepthType: "top",
        coreDepthValue: core.top_depth,
        x:
            this.image.maskConfig.x -
            this.image.maskConfig.offset.x * scaleX +
            core.anchor_x * scaleX +
            (core.width / 2) * scaleX,
        y:
            this.image.maskConfig.y -
            this.image.maskConfig.offset.y * scaleY +
            core.anchor_y * scaleY +
            (coreDepthTextConfig.height / 2) * coreDepthTextConfig.scaleY +
            6,
        offsetX: coreDepthTextConfig.width / 2,
        offsetY: coreDepthTextConfig.height / 2,
        ...coreDepthTextConfig,
      };
    },
    /**
     * @vuese
     * ..
     */
    coreTopDepthRectConfig(core) {
      return {
        id: `top-depth-rect-${core.core_id}`,
        coreId: core.core_id,
        coreDepthType: "top",
        coreDepthValue: core.top_depth,
        ...this.coreDepthRectConfig(this.coreTopDepthTextConfig(core)),
      };
    },
    /**
     * @vuese
     * ..
     */
    coreBottomDepthTextConfig(core) {
      const scaleX = this.image.maskConfig.scaleX;
      const scaleY = this.image.maskConfig.scaleY;
      const coreDepthTextConfig = this.coreDepthTextConfig(core.bottom_depth);
      return {
        id: `bottom-depth-text-${core.core_id}`,
        coreId: core.core_id,
        coreDepthType: "bottom",
        coreDepthValue: core.bottom_depth,
        x:
            this.image.maskConfig.x -
            this.image.maskConfig.offset.x * scaleX +
            core.anchor_x * scaleX +
            (core.width / 2) * scaleX,
        y:
            this.image.maskConfig.y -
            this.image.maskConfig.offset.y * scaleY +
            core.anchor_y * scaleY +
            core.height * scaleY -
            (coreDepthTextConfig.height / 2) * coreDepthTextConfig.scaleY -
            6,
        offsetX: coreDepthTextConfig.width / 2,
        offsetY: coreDepthTextConfig.height / 2,
        ...coreDepthTextConfig,
      };
    },
    /**
     * @vuese
     * ..
     */
    coreBottomDepthRectConfig(core) {
      return {
        id: `bottom-depth-rect-${core.core_id}`,
        coreId: core.core_id,
        coreDepthType: "bottom",
        coreDepthValue: core.bottom_depth,
        ...this.coreDepthRectConfig(this.coreBottomDepthTextConfig(core)),
      };
    },
    /**
     * @vuese
     * ..
     */
    coreDepthTextConfig(depth) {
      const value = depth.toFixed(2);
      const width = value.length * this.coreColumn.depthCharWidth;
      const scale = this.image.depth.scale;
      return {
        scaleX: scale,
        scaleY: scale,
        width: width,
        height: 36,
        text: value,
        fontSize: 18,
        fontFamily: "Montserrat",
        fill: "#FFFFFF",
        align: "center",
        verticalAlign: "middle",
        listening: false,
      };
    },
    /**
     * @vuese
     * ..
     */
    coreDepthRectConfig(textConfig) {
      let fill = "rgba(36, 36, 36, 0.5)";
      let stroke = null;
      let strokeWidth = 0;
      if (this.image.depth.editable) {
        fill = "#42B1E5";
        stroke = "#FFFFFF";
        strokeWidth = 2;
      }

      return {
        x: textConfig.x,
        y: textConfig.y,
        offsetX: textConfig.offsetX,
        offsetY: textConfig.offsetY,
        scaleX: textConfig.scaleX,
        scaleY: textConfig.scaleY,
        height: textConfig.height,
        width: textConfig.width,
        fill: fill,
        stroke: stroke,
        strokeWidth: strokeWidth,
        cornerRadius: 10,
        listening: true,
      };
    },
    /**
     * @vuese
     * ..
     */
    disableDepthNodes() {
      this.image.depth.enabled = false;
      this.hideDepthNodes();
    },
    /**
     * @vuese
     * ..
     */
    enableDepthNodes() {
      this.image.depth.enabled = true;
      this.showDepthNodes();
    },
    /**
     * @vuese
     * ..
     */
    hideDepthNodes() {
      if (!this.$refs.canvasImageGroup) {
        return;
      }
      this.getDepthCanvasNodes().hide();
    },
    /**
     * @vuese
     * ..
     */
    showDepthNodes() {
      if (!this.image.depth.enabled || !this.$refs.canvasImageGroup) {
        return;
      }
      this.getDepthCanvasNodes().show().draw();
    },
    /**
     * @vuese
     * ..
     */
    getDepthCanvasNodes() {
      return this.$refs.canvasImageGroup
          .getNode()
          .find((node) => !!node.id().includes("depth"));
    },
    /**
     * @vuese
     * ..
     */
    editImageDepths() {
      if (this.needSaveImage()) {
        this.displayCanvasLoader = true;
        this.$refs.imageToolbar.setup();
        this.$refs.imageToolbar.disable();
        this.saveActiveImage(this.image)
            .then(() => {
              this.reloadActiveImage(this.image, "pointer-depth", () => {
                this.turnOffStageEvents();
                this.turnOffMaskEvents();
                this.turnOffGroupEvents();
                this.turnOnDepthEvents();
              });
            })
            .finally(() => {
              this.displayCanvasLoader = false;
              this.$refs.imageToolbar.enable();
            });
      } else {
        this.clearHistory();
        this.turnOffStageEvents();
        this.turnOffMaskEvents();
        this.turnOffGroupEvents();
        this.enableDepthNodes();
        this.$nextTick(() => {
          this.turnOnDepthEvents();
        });
      }
    },
    /**
     * @vuese
     * ..
     */
    turnOnDepthEvents() {
      this.image.depth.editable = true;

      this.getCanvasStage().on("click", () => {
        this.image.depth.current.active = false;
      });

      this.getDepthCanvasNodes().each((node) => {
        node.off();
        this.setCursorStyle(node, "pointer");

        node.on("click tap", (event) => {
          event.cancelBubble = true;

          const coreId = node.getAttr("coreId");
          const coreDepthType = node.getAttr("coreDepthType");
          const coreDepthValue = node.getAttr("coreDepthValue");
          const current = this.image.depth.current;
          if (
              current.active &&
              current.coreId === coreId &&
              current.type === coreDepthType
          ) {
            current.active = false;
            return;
          }

          const rules = ["required"];
          const core = this.image.cores[coreId];
          if (coreDepthType === "bottom") {
            rules.push(`min_value:${core.top_depth}`);
            rules.push(`is_not:${core.top_depth.toFixed(2)}`);
          }

          this.$set(this.image.depth, "current", {
            coreId: coreId,
            active: true,
            type: coreDepthType,
            value: coreDepthValue,
            rules: rules.join("|"),
            position: {
              ...node.getAbsolutePosition(this.$refs.canvasStage.getNode()),
              offsetX: (node.width() * node.scaleX()) / 2,
              offsetY: (node.height() * node.scaleY()) / 2,
            },
          });
        });
      });
    },
    /**
     * @vuese
     * ..
     */
    turnOffDepthEvents() {
      this.$set(this.image.depth, "editable", false);
      this.$set(this.image.depth, "current", {
        active: false,
        value: 0,
        rules: null,
        position: null,
      });
      this.getDepthCanvasNodes().off();
    },
    /**
     * @vuese
     * ..
     */
    applyDepth(newDepthValue) {
      const current = this.image.depth.current;
      const recalculated = this.recalculateCoreDepths(
          current.coreId,
          current.type,
          newDepthValue
      );
      FileService.changeCoreDepths(this.image.file_id, recalculated).then(
          (coreResult) => {
            FileService.fileInfo(this.image.file_id).then((fileResult) => {
              this.$set(this.image, "top_depth", fileResult.data.top_depth);
              this.$set(this.image, "bottom_depth", fileResult.data.bottom_depth);

              coreResult.data.forEach((core) =>
                  this.$set(this.image.cores, core.core_id, core)
              );
              this.$set(this.fileCores, this.image.file_id, this.image.cores);

              this.calculateCoreDepthScale(this.image);
            });
          }
      );
    },
    /**
     * @vuese
     * ..
     */
    recalculateCoreDepths(coreId, depthType, depthValue) {
      let recalculated = null;
      let prevBottomDepth = null;
      Object.keys(this.image.cores)
          .sort(
              (id1, id2) =>
                  this.image.cores[id1].core_number -
                  this.image.cores[id2].core_number
          )
          .forEach((id) => {
            const core = this.image.cores[id];
            if (core.core_id === coreId) {
              recalculated = [];
              const delta = core.bottom_depth - core.top_depth;
              const topDepth = depthType === "top" ? depthValue : core.top_depth;
              const bottomDepth =
                  depthType === "bottom" ? depthValue : depthValue + delta;
              recalculated.push({
                core_id: core.core_id,
                top_depth: this.roundDepth(topDepth),
                bottom_depth: this.roundDepth(bottomDepth),
              });
              prevBottomDepth = bottomDepth;
            } else if (recalculated) {
              const delta = core.bottom_depth - core.top_depth;
              recalculated.push({
                core_id: core.core_id,
                top_depth: this.roundDepth(prevBottomDepth),
                bottom_depth: this.roundDepth(prevBottomDepth + delta),
              });
              prevBottomDepth += delta;
            }
          });
      return recalculated;
    },
    /**
     * @vuese
     * ..
     */
    roundDepth(value) {
      return Math.round((value + Number.EPSILON) * 100) / 100;
    },
    /**
     * @vuese
     * ..
     */
    resizeCanvas() {
      const imageContainer = this.$refs.imageContainer;

      this.canvasConfig.width = imageContainer ? imageContainer.clientWidth : 0;
      this.canvasConfig.height = imageContainer
          ? imageContainer.clientHeight
          : 0;

      this.image.groupConfig = this.image.groupConfig
          ? this.image.groupConfig
          : {};
      this.image.groupConfig.x = this.canvasConfig.width / 2;
      this.image.groupConfig.y = this.canvasConfig.height / 2;

      this.image.depth.current.active = false;

      this.reloadPreview(false);
    },
    /**
     * @vuese
     * ..
     */
    handImageLocal() {
      this.turnOffStageEvents();
      this.turnOffMaskEvents();
      this.turnOffDepthEvents();
      this.enableDepthNodes();
      this.handImage(true);
    },
    /**
     * @vuese
     * ..
     */
    zoomImageInLocal() {
      this.turnOffStageEvents();
      this.turnOffMaskEvents();
      this.turnOffDepthEvents();
      this.enableDepthNodes();
      this.zoomImageIn();
    },
    /**
     * @vuese
     * ..
     */
    zoomImageOutLocal() {
      this.turnOffStageEvents();
      this.turnOffMaskEvents();
      this.turnOffDepthEvents();
      this.enableDepthNodes();
      this.zoomImageOut();
    },
    /**
     * @vuese
     * ..
     */
    turnOffMaskEvents() {
      if (this.$refs.canvasMaskImage) {
        this.$refs.canvasMaskImage.getNode().off();
      }
    },
    /**
     * @vuese
     * ..
     */
    turnOffStageEvents() {
      if (this.$refs.canvasStage) {
        this.$refs.canvasStage.getNode().off();
      }
    },
    /**
     * @vuese
     * ..
     */
    turnOffGroupEvents() {
      if (this.$refs.canvasImageGroup) {
        const canvasImageGroup = this.$refs.canvasImageGroup.getNode();
        canvasImageGroup.draggable(false);
        canvasImageGroup.off();
      }
    },
    /**
     * @vuese
     * ..
     */
    brushImage() {
      this.activateTool("brush");
    },
    /**
     * @vuese
     * ..
     */
    eraserImage() {
      this.activateTool("eraser");
    },
    /**
     * @vuese
     * ..
     */
    activateTool(tool) {
      if (!this.image.cContext) {
        return;
      }

      this.turnOffStageEvents();
      this.turnOffGroupEvents();
      this.turnOffMaskEvents();
      this.turnOffDepthEvents();
      this.setCursorStyle(
          this.$refs.canvasImageGroup.getNode(),
          this.getToolCursor()
      );
      this.disableDepthNodes();

      let paintMode = false;
      let lastPointerPosition = null;

      const canvasStage = this.$refs.canvasStage.getNode();
      canvasStage.on("touchstart mousedown", () => {
        paintMode = !paintMode;
      });
      canvasStage.on("touchend mouseup", () => {
        if (paintMode && lastPointerPosition) {
          this.saveHistory(this.getHistoryImageData());
          this.reloadPreview();
        }
        paintMode = false;
        lastPointerPosition = null;
      });

      const canvasMaskImage = this.$refs.canvasMaskImage.getNode();
      canvasMaskImage.on("touchmove mousemove", (event) => {
        if (!paintMode) {
          return;
        }
        if (!lastPointerPosition) {
          lastPointerPosition = event.target.getStage().getPointerPosition();
        }

        const line = {};
        const imageRect = event.target.getClientRect();
        const rateX = this.image.maskConfig.width / imageRect.width;
        const rateY = this.image.maskConfig.height / imageRect.height;

        let localPos = {
          x: lastPointerPosition.x - imageRect.x,
          y: lastPointerPosition.y - imageRect.y,
        };
        line.from = {
          x: localPos.x * rateX,
          y: localPos.y * rateY,
        };

        const pointerPosition = event.target.getStage().getPointerPosition();
        localPos = {
          x: pointerPosition.x - imageRect.x,
          y: pointerPosition.y - imageRect.y,
        };
        line.to = {
          x: localPos.x * rateX,
          y: localPos.y * rateY,
        };

        if (tool === "brush") {
          this.drawLine(line, this.coreColumn.color);
        }
        if (tool === "eraser") {
          this.drawLine(line);
        }

        lastPointerPosition = pointerPosition;
        event.target.getLayer().batchDraw();
      });
    },
    /**
     * @vuese
     * ..
     */
    drawLine(line, color = "#000000") {
      this.image.cContext.globalCompositeOperation = "source-over";
      this.image.cContext.strokeStyle = color;
      this.image.cContext.lineJoin = "round";
      this.image.cContext.lineCap = "round";

      this.image.cContext.beginPath();
      this.image.cContext.moveTo(line.from.x, line.from.y);
      this.image.cContext.lineTo(line.to.x, line.to.y);
      this.image.cContext.closePath();
      this.image.cContext.stroke();
    },
    /**
     * @vuese
     * ..
     */
    changeContextLineWidth(value) {
      if (!this.image.cContext) {
        return;
      }
      this.image.cContext.lineWidth = value;
      this.setCursorStyle(
          this.$refs.canvasImageGroup.getNode(),
          this.getToolCursor()
      );
    },
    /**
     * @vuese
     * ..
     */
    loadPreviews() {
      this.loadImages();
      this.$refs.imagePreviews.updateFiles();
    },
    /**
     * @vuese
     * ..
     */
    deleteCoreFiles() {
      let fId = [this.srcfileid];
      let projectId = this.project.project_id;
      return ProjectService.deleteFile(projectId, fId).then((result) => {
        this.loadPreviews();
        return result.data;
      });
    },
    /**
     * @vuese
     * ..
     */
    deleteImageandMask() {
      if (Object.keys(this.images).length > 1) {
        this.activateTool("trash-bin");
        this.modal.notice = true;
      }
    },
    /**
     * @vuese
     * ..
     */
    getToolCursor() {
      const scaledLineWidth =
          this.image.cContext.lineWidth * this.image.maskConfig.scaleX;
      let cursor = this.toolCursor.standard(scaledLineWidth, scaledLineWidth);
      if (scaledLineWidth <= 5) {
        cursor = this.toolCursor.minimal();
      } else if (
          this.image.cContext.lineWidth * this.image.maskConfig.scaleX >
          30
      ) {
        cursor = this.toolCursor.maximum();
      }
      return cursor;
    },
    /**
     * @vuese
     * ..
     */
    getCanvasImage() {
      return this.$refs.canvasImageGroup
          ? this.$refs.canvasImageGroup.getNode()
          : null;
    },
    /**
     * @vuese
     * ..
     */
    getPreviewCanvasImage() {
      return this.$refs.canvasImageGroup
          ? this.$refs.canvasImageGroup.getNode()
          : null;
    },
    /**
     * @vuese
     * ..
     */
    beforePreviewCreate() {
      this.hideDepthNodes();
    },
    /**
     * @vuese
     * ..
     */
    previewCreated() {
      this.showDepthNodes();
    },
    /**
     * @vuese
     * ..
     */
    getImageScale() {
      return {
        x: this.image.maskConfig.scaleX,
        y: this.image.maskConfig.scaleY,
      };
    },
    /**
     * @vuese
     * ..
     */
    setImageScale(scaleX, scaleY) {
      const canvasImageRect = this.getCanvasImage().getClientRect();
      const currentX = canvasImageRect.x;
      const currentY = canvasImageRect.y;
      const currentOffsetX = this.image.groupConfig.offset.x;
      const currentOffsetY = this.image.groupConfig.offset.y;
      const currentImageX = currentX + currentOffsetX;
      const currentImageY = currentY + currentOffsetY;

      const pointerPosition = this.getCanvasStage().getPointerPosition();
      const positionDeltaX =
          (pointerPosition.x - currentX) / Math.abs(this.image.bgConfig.scaleX);
      const positionDeltaY =
          (pointerPosition.y - currentY) / Math.abs(this.image.bgConfig.scaleY);

      this.image.maskConfig.scaleX = scaleX;
      this.image.maskConfig.scaleY = scaleY;
      this.image.bgConfig.scaleX = scaleX;
      this.image.bgConfig.scaleY = scaleY;

      this.image.groupConfig.offset = {
        x: (this.image.bgConfig.width / 2) * scaleX,
        y: (this.image.bgConfig.height / 2) * scaleY,
      };
      this.image.groupConfig.offsetX = this.image.groupConfig.offset.x;
      this.image.groupConfig.offsetY = this.image.groupConfig.offset.y;

      const nextTopLeftX = currentImageX - this.image.groupConfig.offsetX;
      const nextTopLeftY = currentImageY - this.image.groupConfig.offsetY;
      const nextBottomRightX =
          nextTopLeftX + this.image.bgConfig.width * Math.abs(scaleX);
      const nextBottomRightY =
          nextTopLeftY + this.image.bgConfig.height * Math.abs(scaleY);
      if (
          nextTopLeftX < 0 ||
          nextTopLeftY < 0 ||
          nextBottomRightX > this.canvasConfig.width ||
          nextBottomRightY > this.canvasConfig.height
      ) {
        this.image.groupConfig.x =
            pointerPosition.x -
            positionDeltaX * Math.abs(scaleX) +
            this.image.groupConfig.offsetX;
        this.image.groupConfig.y =
            pointerPosition.y -
            positionDeltaY * Math.abs(scaleY) +
            this.image.groupConfig.offsetY;
      } else {
        this.image.groupConfig.x = this.canvasConfig.width / 2;
        this.image.groupConfig.y = this.canvasConfig.height / 2;
      }

      this.image.bgConfig.x = this.image.groupConfig.offset.x;
      this.image.bgConfig.y = this.image.groupConfig.offset.y;
      this.image.bgConfig.offset = {
        x: this.image.groupConfig.offset.x / scaleX,
        y: this.image.groupConfig.offset.y / scaleY,
      };

      this.image.maskConfig.x = this.image.groupConfig.offset.x;
      this.image.maskConfig.y = this.image.groupConfig.offset.y;
      this.image.maskConfig.offset = {
        x: this.image.groupConfig.offset.x / scaleX,
        y: this.image.groupConfig.offset.y / scaleY,
      };

      this.calculateCoreDepthScale(this.image);
    },
    /**
     * @vuese
     * ..
     */
    getHistoryImageData() {
      return {
        groupConfig: this.clone(this.image.groupConfig),
        maskConfig: this.cloneImageConfig(this.image.maskConfig),
        bgConfig: this.cloneImageConfig(this.image.bgConfig),
        depth: this.clone(this.image.depth),
        cContextData: this.image.cContext.getImageData(
            0,
            0,
            this.image.maskConfig.width,
            this.image.maskConfig.height
        ),
      };
    },
    /**
     * @vuese
     * ..
     */
    loadHistoryImageData(imageData) {
      this.$set(this.image, "groupConfig", this.clone(imageData.groupConfig));
      this.$set(
          this.image,
          "maskConfig",
          this.cloneImageConfig(imageData.maskConfig)
      );
      this.$set(
          this.image,
          "bgConfig",
          this.cloneImageConfig(imageData.bgConfig)
      );
      this.$set(this.image, "depth", this.clone(imageData.depth));
      this.image.cContext.putImageData(imageData.cContextData, 0, 0);
      return Promise.resolve(true);
    },
    /**
     * @vuese
     * ..
     */
    deleteFile(fileId) {
      const file = this.files[fileId];
      if (file) {
        URL.revokeObjectURL(file);
      }
      this.$delete(this.files, fileId);
    },
    /**
     * @vuese
     * ..
     */
    deleteFileCores(fileId) {
      this.$delete(this.fileCores, fileId);
    },
  },
};
</script>

<style lang="scss">
.project-core-selection {
  .image-container {
    position: relative;

    .preview {
      position: absolute;
      margin-left: 8px;
      top: 0;
      z-index: 1;
    }

    .filename {
      position: absolute;
      margin-left: 8px;
      bottom: 0;
      z-index: 1;
    }

    .depth {
      z-index: 2;
    }

    .image-canvas {
      position: absolute;
      z-index: 0;
    }
  }

  .image-toolbar {
    z-index: 1;
  }
}
</style>

<i18n>
{
  "en": {
    "messages": {
      "depths-intersections": "The following images have depth intersection: {filenames}.",
      "depths-intersections-more": [
        "There is 1 more image with the same issue",
        "There are {value} more images with the same issue",
        "There are {value} more images with the same issue",
        "There are {value} more images with the same issue"
      ],
      "core-depths-intersections": "The following images have depth intersection between cores: {filenames}."
    }
  },
  "ru": {
    "messages": {
      "depths-intersections": "Следующие изображения имеют пересечения по глубинам: {filenames}.",
      "depths-intersections-more": [
        "Есть ещё 1 изображение с такой же проблемой",
        "Есть ещё {value} изображение с такой же проблемой",
        "Есть ещё {value} изображения с такой же проблемой",
        "Есть ещё {value} изображений с такой же проблемой"
      ],
      "core-depths-intersections": "Следующие изображения имеют пересечения по глубинам между столбцами керна: {filenames}."
    }
  }
}
</i18n>
