import axios from 'axios'

/**
 * @group Services
 * describe the Services (it will not be shown in vuese yet)
 */
export default {
    extendSubscription(message) {
        const encoded = message.replace(/[\u00A0-\u9999<>]/g, function (i) {
            return '&#' + i.charCodeAt(0) + ';'
        })
        const data = {
            body: encoded
        }
        return axios.post('/api/v1/subscription/extend', data)
    }
}
