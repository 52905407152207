import axios from 'axios'

/**
 * @group Services
 * describe the Services (it will not be shown in vuese yet)
 */
export default {
    addLithologyType(projectId, id, name, color, pattern) {
        const data = {id, name, color, pattern}
        return axios.post(`/api/v1/lithology-type/${projectId}/add`, data)
    },
    swapLithologyType(id, name, color, pattern) {
        const data = {id, name, color, pattern}
        return axios.put(`/api/v1/lithology-type/swap`, data)
    },
    deleteLithologyType(typeId) {
        return axios.delete(`/api/v1/lithology-type/${typeId}/delete`)
    }
}
