/**
 * @group Views-mixins
 * This is a description of the component it includes DpButton component
 */
export default {
    data() {
        return {
            /**
             * @vuese
             * the data
             */
            cursors: {
                default: {
                    grab: 'grab',
                    // eslint-disable-next-line
                    zoomIn: `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.9312 12.1592C6.9312 11.5686 7.40994 11.0899 8.0005 11.0899H11.0899V8.0005C11.0899 7.40994 11.5687 6.9312 12.1592 6.9312H12.7931C13.3837 6.9312 13.8624 7.40994 13.8624 8.0005V11.0899H16.9518C17.5424 11.0899 18.0211 11.5686 18.0211 12.1592V12.7931C18.0211 13.3836 17.5424 13.8624 16.9518 13.8624H13.8624V16.9518C13.8624 17.5424 13.3837 18.0211 12.7931 18.0211H12.1592C11.5687 18.0211 11.0899 17.5424 11.0899 16.9518V13.8624H8.0005C7.40994 13.8624 6.9312 13.3836 6.9312 12.7931V12.1592Z' fill='%23242424'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.1534 23.5887C16.4504 24.4605 14.5207 24.9523 12.4762 24.9523C5.58577 24.9523 0 19.3665 0 12.4762C0 5.58577 5.58577 0 12.4762 0C19.3665 0 24.9523 5.58577 24.9523 12.4762C24.9523 14.5219 24.4599 16.4526 23.5872 18.1563L30.8725 25.4417C32.3729 26.942 32.3729 29.3745 30.8725 30.8748C29.3722 32.3751 26.9398 32.3751 25.4394 30.8748L18.1534 23.5887ZM22.8137 12.4762C22.8137 18.1854 18.1854 22.8137 12.4762 22.8137C6.76688 22.8137 2.1386 18.1854 2.1386 12.4762C2.1386 6.76688 6.76688 2.1386 12.4762 2.1386C18.1854 2.1386 22.8137 6.76688 22.8137 12.4762ZM20.0104 22.4213L26.9517 29.3625C27.6168 30.0277 28.6952 30.0277 29.3603 29.3625C30.0255 28.6974 30.0255 27.619 29.3603 26.9539L22.4194 20.013C21.7299 20.9212 20.9188 21.7321 20.0104 22.4213Z' fill='%23242424'/%3E%3C/svg%3E") 12 12, zoom-in`,
                    // eslint-disable-next-line
                    zoomOut: `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.00108 11.0907C7.41048 11.0907 6.9317 11.5695 6.9317 12.1601V12.794C6.9317 13.3846 7.41048 13.8634 8.00108 13.8634H16.953C17.5436 13.8634 18.0224 13.3846 18.0224 12.794V12.1601C18.0224 11.5695 17.5436 11.0907 16.953 11.0907H8.00108Z' fill='%23242424'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.477 24.9541C14.5218 24.9541 16.4516 24.4622 18.1547 23.5904L25.4412 30.8769C26.9416 32.3773 29.3743 32.3773 30.8747 30.8769C32.3751 29.3765 32.3751 26.9438 30.8747 25.4434L23.5889 18.1576C24.4617 16.4538 24.9541 14.5229 24.9541 12.477C24.9541 5.58617 19.3679 0 12.477 0C5.58617 0 0 5.58617 0 12.477C0 19.3679 5.58617 24.9541 12.477 24.9541ZM12.477 22.8153C18.1867 22.8153 22.8153 18.1867 22.8153 12.477C22.8153 6.76735 18.1867 2.13876 12.477 2.13876C6.76735 2.13876 2.13876 6.76735 2.13876 12.477C2.13876 18.1867 6.76735 22.8153 12.477 22.8153ZM26.9535 29.3645L20.0119 22.4229C20.9204 21.7336 21.7315 20.9227 22.421 20.0144L29.3624 26.9557C30.0276 27.6209 30.0276 28.6993 29.3624 29.3645C28.6972 30.0297 27.6187 30.0297 26.9535 29.3645Z' fill='%23242424'/%3E%3C/svg%3E") 12 12, zoom-out`
                },
                ie: {
                    grab: `url("${process.env.BASE_URL}cursors/grab.cur"), default`,
                    zoomIn: `url("${process.env.BASE_URL}cursors/zoom-in.cur"), default`,
                    zoomOut: `url("${process.env.BASE_URL}cursors/zoom-out.cur"), default`
                }
            }
        }
    },
    methods: {
        /**
         * @vuese
         * description
         */
        getGrabCursor() {
            if (this.isIE()) {
                return this.cursors.ie.grab
            } else {
                return this.cursors.default.grab
            }
        },
        /**
         * @vuese
         * description
         */
        getZoomInCursor() {
            if (this.isIEBrowser()) {
                return this.cursors.ie.zoomIn
            } else {
                return this.cursors.default.zoomIn
            }
        },
        /**
         * @vuese
         * description
         */
        getZoomOutCursor() {
            if (this.isIEBrowser()) {
                return this.cursors.ie.zoomOut
            } else {
                return this.cursors.default.zoomOut
            }
        }
    }
}
