import ImageHistoryMixin from '@/views/mixins/image-history-mixin'
import ImagePreviewMixin from '@/views/mixins/image-preview-mixin'
import CursorMixin from '@/views/mixins/cursor-mixin'

/**
 * @group Views-mixins
 * This is a description of the component it includes DpButton component
 */
export default {
    mixins: [ImageHistoryMixin, ImagePreviewMixin, CursorMixin],
    data() {
        return {
            /**
             * @vuese
             * data
             */
            canvasConfig: {
                width: 0,
                height: 0
            }
        }
    },
    methods: {
        /**
         * @vuese
         * method
         */
        isIEBrowser() {
            return /msie\s|trident\//i.test(navigator.userAgent)
        },
        /**
         * @vuese
         * method
         */
        getCanvasConfig() {
            return this.canvasConfig
        },
        /**
         * @vuese
         * method
         */
        getCanvasStage() {
            return this.$refs.canvasStage ? this.$refs.canvasStage.getNode() : null
        },
        /**
         * @vuese
         * method
         */
        getCanvasImage() {
            return this.$refs.canvasImage ? this.$refs.canvasImage.getNode() : null
        },
        /**
         * @vuese
         * method
         */
        getImageScale() {
            this.$log.warn('Method not implemented')
            return null
        },
        /**
         * @vuese
         * do nothing
         * @arg scaleX \\
         * @arg scaleY
         */
        setImageScale(scaleX, scaleY) {
            this.$log.warn('Method not implemented')
        },
        /**
         * @vuese
         * do nothing
         */
        getHistoryImageData() {
            this.$log.warn('Method not implemented')
        },
        /**
         * @vuese
         * do nothing
         * @arg imageData
         */
        loadHistoryImageData(imageData) {
            this.$log.warn('Method not implemented')
        },
        /**
         * @vuese
         * do nothing
         */
        onDragMoveImage() {
            // you can implement this method
        },
        /**
         * @vuese
         * do nothing
         */
        onImageScrolling() {
            // you can implement this method
        },
        /**
         * @vuese
         * method
         * @arg htmlImageElement
         */
        calculateInitialScale(htmlImageElement) {
            return Math.min(this.canvasConfig.width / htmlImageElement.width, this.canvasConfig.height / htmlImageElement.height)
        },
        /**
         * @vuese
         * method
         * @arg scale
         */
        normalizeScale(scale) {
            let result = scale > 1 ? 1 : scale
            const rest = (result * 100) % 5
            if (rest > 0) {
                result = (result * 100 - rest) / 100
            }
            if (result === 0) {
                result = 0.01
            }
            return result
        },
        /**
         * @vuese
         * method
         * @arg htmlImageElement
         */
        createImageConfig(htmlImageElement) {
            let scale = this.calculateInitialScale(htmlImageElement)
            scale = this.normalizeScale(scale)
            return {
                image: htmlImageElement,
                x: this.canvasConfig.width / 2,
                y: this.canvasConfig.height / 2,
                offset: {
                    x: htmlImageElement.width / 2,
                    y: htmlImageElement.height / 2
                },
                width: htmlImageElement.width,
                height: htmlImageElement.height,
                scaleX: scale,
                scaleY: scale,
                rotation: 0,
                opacity: 1,
                listening: true
            }
        },
        /**
         * @vuese
         * method
         * @arg withScroll - bool, default true
         */
        handImage(withScroll = false) {
            if (!this.resetCanvasImage()) return

            const canvasImage = this.getCanvasImage()
            canvasImage.draggable(true)
            this.setCursorStyle(canvasImage, this.getGrabCursor())

            canvasImage.on('dragmove', () => {
                this.onDragMoveImage()
                this.reloadPreviewViewport()
            })

            if (withScroll) {
                canvasImage.on('wheel', (e) => {
                    e.evt.preventDefault()
                    const canvasImage = this.getCanvasImage()
                    const clientRect = canvasImage.getClientRect()

                    const multiplier = this.getWheelMultiplier()
                    const newX = canvasImage.x() - e.evt.deltaX * multiplier
                    const newY = canvasImage.y() - e.evt.deltaY * multiplier

                    const visibleDelta = 50
                    const canChangeX = (newX + clientRect.width / 2 > visibleDelta) && (newX < this.canvasConfig.width - visibleDelta + clientRect.width / 2)
                    const canChangeY = (newY + clientRect.height / 2 > visibleDelta) && (newY < this.canvasConfig.height - visibleDelta + clientRect.height / 2)

                    if (canChangeX || canChangeY) {
                        this.onImageScrolling()
                        if (canChangeX) {
                            canvasImage.x(newX)
                        }
                        if (canChangeY) {
                            canvasImage.y(newY)
                        }
                        this.$refs.canvasLayer.getNode().batchDraw()
                        this.reloadPreviewViewport()
                    }
                })
            }
        },
        /**
         * @vuese
         * method
         */
        undoImage() {
            this.loadHistoryImageData(this.undoHistory()).then(() => {
                this.reloadPreview()
            })
        },
        /**
         * @vuese
         * method
         */
        redoImage() {
            this.loadHistoryImageData(this.redoHistory()).then(() => {
                this.reloadPreview()
            })
        },
        /**
         * @vuese
         * method
         */
        saveImage() {
            this.saveImagesFromTool().then(() => {
                this.reloadPreview()
            })
        },
        /**
         * @vuese
         * method
         */
        zoomImageIn() {
            this.zoomImage('in')
        },
        /**
         * @vuese
         * method
         */
        zoomImageOut() {
            this.zoomImage('out')
        },
        /**
         * @vuese
         * method
         * @arg type
         */
        zoomImage(type) {
            if (!this.resetCanvasImage()) return

            const canvasImage = this.getCanvasImage()
            this.setCursorStyle(canvasImage, type === 'in' ? this.getZoomInCursor() : this.getZoomOutCursor())

            canvasImage.on('click tap', () => {
                this.applyZoom(type, 0.05)
            })
            canvasImage.on('wheel', (e) => {
                e.evt.preventDefault()
                this.applyZoom(e.evt.deltaY > 0 ? 'out' : 'in', 0.05)
            })
        },
        /**
         * @vuese
         * method
         * @arg type \\
         * @arg ratio
         */
        applyZoom(type, ratio) {
            const imageScale = this.getImageScale()
            const scaleX = imageScale && imageScale.x ? imageScale.x : 1
            const scaleY = imageScale && imageScale.y ? imageScale.y : 1

            if (Math.abs(scaleX) === 0.01) {
                if (type === 'out') {
                    return
                }
                ratio = ratio === 0.05 ? 0.04 : ratio
            }

            const xSign = scaleX / Math.abs(scaleX)
            const ySign = scaleY / Math.abs(scaleY)
            const typeSign = type === 'out' ? -1 : 1

            let resultScaleX = scaleX + typeSign * xSign * ratio
            resultScaleX = Math.round(resultScaleX * 100) === 0 ? 0.01 * xSign : resultScaleX
            let resultScaleY = scaleY + typeSign * ySign * ratio
            resultScaleY = Math.round(resultScaleY * 100) === 0 ? 0.01 * xSign : resultScaleY

            this.setImageScale(resultScaleX, resultScaleY)
            this.saveHistory(this.getHistoryImageData(), false)
            this.reloadPreview(false)
        },
        /**
         * @vuese
         * method
         * @arg canvasObject \\
         * @arg cursor
         */
        setCursorStyle(canvasObject, cursor) {
            canvasObject.off('mouseenter.cursor mouseleave.cursor')
            canvasObject.on('mouseenter.cursor', () => {
                this.setStageCursor(cursor)
            })
            canvasObject.on('mouseleave.cursor', () => {
                this.setStageCursor('default')
            })
        },
        /**
         * @vuese
         * method
         * @arg cursor
         */
        setStageCursor(cursor) {
            this.getCanvasStage().container().style.cursor = cursor
        },
        /**
         * @vuese
         * method
         */
        resetCanvasImage() {
            const canvasImage = this.getCanvasImage()
            if (!canvasImage) {
                return false
            }
            canvasImage.draggable(false)
            canvasImage.off()
            return true
        },
        /**
         * @vuese
         * method
         * @arg coClientRect1 \\
         * @arg coClientRect2
         */
        haveIntersection(coClientRect1, coClientRect2) {
            return !(
                coClientRect2.x >= coClientRect1.x + coClientRect1.width ||
                coClientRect2.x + coClientRect2.width <= coClientRect1.x ||
                coClientRect2.y >= coClientRect1.y + coClientRect1.height ||
                coClientRect2.y + coClientRect2.height <= coClientRect1.y
            )
        },
        /**
         * @vuese
         * method
         * @arg object
         */
        clone(object) {
            return JSON.parse(JSON.stringify(object))
        },
        /**
         * @vuese
         * method
         * @arg imageConfig
         */
        cloneImageConfig(imageConfig) {
            const clonedConfig = this.clone(imageConfig)
            clonedConfig.image = imageConfig.image
            return clonedConfig
        },
        /**
         * @vuese
         * method
         */
        getDragBoundFunc() {
            return (newPosition) => {
                const canvasImage = this.getCanvasImage()
                const currentPosition = canvasImage.absolutePosition()
                const clientRect = canvasImage.getClientRect()

                const visibleDelta = 50
                let newX = currentPosition.x
                let newY = currentPosition.y

                if ((newPosition.x + clientRect.width / 2 > visibleDelta) && (newPosition.x < this.canvasConfig.width - visibleDelta + clientRect.width / 2)) {
                    newX = newPosition.x
                }
                if ((newPosition.y + clientRect.height / 2 > visibleDelta) && (newPosition.y < this.canvasConfig.height - visibleDelta + clientRect.height / 2)) {
                    newY = newPosition.y
                }
                return {
                    x: newX,
                    y: newY
                }
            }
        },
        /**
         * @vuese
         * method
         */
        getWheelMultiplier() {
            let multiplier
            if (this.isMacOSFirefox()) {
                multiplier = 1
            } else if (this.isFirefox()) {
                multiplier = 15
            } else if (this.isSafari()) {
                multiplier = 1
            } else {
                multiplier = 1
            }
            return multiplier
        }
    }
}
