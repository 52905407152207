<template>
  <component :is="component" v-bind="$props"></component>
</template>

<script>
/**
 * @group Components-icon
 * Standard components
 */
export default {
  name: 'dp-icon',
  props: {
    name: {
      type: String,
      default: ''
    },
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    fill: {
      type: String,
      default: '#242424'
    },
    sensitive: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      component: null
    }
  },
  mounted() {
    const asyncComponent = () => ({
      component: import('@/components/icon/svg/dp-' + this.name + '-icon')
    })
    asyncComponent().component.then((result) => {
      this.component = result.default
    })
  },
  beforeUpdate() {
    const asyncComponent = () => ({
      component: import('@/components/icon/svg/dp-' + this.name + '-icon')
    })
    asyncComponent().component.then((result) => {
      this.component = result.default
    })
  }
}
</script>
