<template>
  <b-modal
      v-model="display"
      :title="$t('title')"
      centered
      dialog-class="project-update"
      no-close-on-backdrop
      title-class="windows-title"
      @ok.prevent="upload"
  >
    <template v-slot:modal-header-close>
      <dp-icon name="close"></dp-icon>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <dp-button :disabled="actionsDisabled" ghost @click="cancel">{{
          $t("cancel")
        }}
      </dp-button>
      <dp-button :disabled="actionsDisabled || editFilesMode" @click="ok">{{
          $t("create")
        }}
      </dp-button>
    </template>
    <template v-slot:default>
      <b-form @submit.prevent="upload">
        <validation-provider
            ref="project-name-validator"
            v-slot="validationContext"
            name="message"
            rules="required|max:250"
        >
          <b-form-group
              id="project-name-group"
              :invalid-feedback="validationContext.errors[0]"
              :label="$t('project-name-label')"
              label-for="project-name"
          >
            <b-form-input
                id="project-name"
                :disabled="true"
                :state="getValidationState(validationContext)"
                :value="projectName"
            >
            </b-form-input>
          </b-form-group>
        </validation-provider>

        <b-form-file
            id="project-files"
            ref="project-files"
            v-model="uploadedFiles"
            :disabled="actionsDisabled || editFilesMode"
            :state="validation.files.state"
            accept="image/jpeg, image/png, image/tiff"
            multiple
        >
          <template v-slot:placeholder>
            <dp-icon
                :sensitive="false"
                class="file-upload-icon"
                name="upload"
            ></dp-icon>
            <span v-html="$t('files-placeholder')"></span>
          </template>
          <template v-slot:drop-placeholder>
            <span v-html="$t('files-drop-placeholder')"></span>
          </template>
          <template v-slot:file-name>
            <dp-icon
                :sensitive="false"
                class="file-upload-icon"
                name="upload"
            ></dp-icon>
            <span v-html="$t('files-placeholder')"></span>
          </template>
        </b-form-file>
        <div
            v-if="!validation.files.state"
            class="invalid-feedback"
            tabindex="-1"
        >
          {{ $t("no-files-feedback") }}
        </div>

        <b-container
            v-if="project.files && project.files.length > 0"
            class="files"
        >
          <b-row class="align-items-center mb-3">
            <div v-if="!editFilesMode" class="button-text">
              {{ uploadedDesc }}
            </div>
            <div v-if="editFilesMode" class="button-text">
              {{ selectedDesc }}
            </div>
            <dp-button
                v-if="!editFilesMode"
                :disabled="actionsDisabled"
                class="ml-auto"
                outline
                @click.prevent.stop="editFilesMode = true"
            >
              {{ $t("edit-files") }}
            </dp-button>
            <dp-button
                v-if="editFilesMode"
                :disabled="actionsDisabled"
                class="ml-auto"
                ghost
                @click.prevent.stop="editFilesMode = false"
            >
              {{ $t("cancel") }}
            </dp-button>
            <dp-button
                v-if="editFilesMode"
                :disabled="selectedFiles.length === 0 || actionsDisabled"
                class="ml-3"
                outline
                @click.prevent.stop="deleteSelectedFiles"
            >
              {{ $t("delete-files") }}
            </dp-button>
          </b-row>
          <vue-custom-scrollbar
              :settings="scrollYSettings"
              class="row row-cols-1 row-cols-sm-2 row-cols-lg-4 scrollable-container"
              tagname="div"
          >
            <b-col v-for="file in project.files" :key="file.fileId">
              <div
                  :class="editFilesMode ? 'clickable' : null"
                  class="d-flex flex-column h-100"
                  @click="selectFile(file)"
              >
                <b-overlay :opacity="0.3" :show="!getFilePreviewConfig(file)">
                  <v-stage
                      :config="canvasConfig"
                      @contextmenu="
                      (e) => {
                        e.evt.preventDefault();
                      }
                    "
                  >
                    <v-layer :config="{ listening: false }">
                      <v-image
                          v-if="getFilePreviewConfig(file)"
                          :config="getFilePreviewConfig(file)"
                      >
                      </v-image>
                    </v-layer>
                  </v-stage>

                  <div
                      v-if="editFilesMode && file.selected"
                      class="selected-icon"
                  >
                    <dp-icon
                        :sensitive="false"
                        height="24"
                        name="check-filled"
                        width="24"
                    >
                    </dp-icon>
                  </div>
                </b-overlay>

                <div
                    :title="file.fileName"
                    class="mt-auto pt-3 menu-default filename"
                >
                  {{ file.fileName }}
                </div>
              </div>
            </b-col>
          </vue-custom-scrollbar>
        </b-container>
      </b-form>
      <b-overlay :opacity="0.3" :show="actionsDisabled" no-wrap></b-overlay>
    </template>
  </b-modal>
</template>

<script>
import VueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import {v4 as uuidv4} from "uuid";
import DpIcon from "@/components/icon/dp-icon";
import DpButton from "@/components/button/dp-button";
import ValidationMixin from "@/mixins/validation-mixin";
import MessageMixin from "@/mixins/message-mixin";
import ProjectService from "@/services/project-service";
import FileService from "@/services/file-service";
import ScrollMixin from "@/mixins/scroll-mixin";

/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: "project-update",
  components: {
    DpIcon,
    DpButton,
    VueCustomScrollbar,
  },
  mixins: [ValidationMixin, MessageMixin, ScrollMixin],
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    projectId: {
      required: true,
    },
    projectName: {
      required: true,
    },
    projectAll: {
      required: true,
    },
  },
  data() {
    return {
      actionsDisabled: false,
      project: {
        name: null,
        files: [],
        fileNames: {},
        previews: {},
      },
      allImg: [],
      uploadedFiles: [],
      validation: {
        files: {
          state: null,
        },
      },
      editFilesMode: false,
      canvasConfig: {
        width: 130,
        height: 130,
      },
    };
  },
  computed: {
    display: {
      get() {
        return this.active;
      },
      set(newValue) {
        this.$emit("update:active", newValue);
      },
    },
    selectedFiles() {
      return this.project.files.filter((f) => f.selected);
    },
    uploadedDesc() {
      const value = this.project.files.length;
      return this.valueMessage(value, this.$t("uploaded-files")).replace(
          "{value}",
          value
      );
    },
    selectedDesc() {
      const value = this.selectedFiles.length;
      return this.valueMessage(value, this.$t("selected-files")).replace(
          "{value}",
          value
      );
    },
  },
  watch: {
    uploadedFiles() {
      this.allImg = [];
      Object.keys(this.projectAll).forEach((key) => {
        this.allImg.push(this.projectAll[key].file_name);
      });
      if (this.uploadedFiles && this.uploadedFiles.length > 0) {
        this.uploadedFiles.forEach((file) => {
          if (Object.keys(this.project.fileNames).includes(file.name)) {
            this.showWMessage(
                this.$t("exists-file-message", {name: file.name})
            );
            return;
          }
          if (this.allImg.includes(file.name)) {
            this.showWMessage(
                this.$t("exists-file-message", {name: file.name})
            );
            return;
          }

          const fileId = uuidv4();
          this.project.files.push({
            fileId: fileId,
            fileName: file.name,
            content: file,
            selected: false,
          });
          this.project.fileNames[file.name] = true;

          if (file.type === "image/tiff") {
            FileService.imageToJpeg(
                this.canvasConfig.width,
                this.canvasConfig.height,
                file
            ).then((result) => {
              const image = new Image();
              image.src = URL.createObjectURL(result.data);
              image.onload = () => {
                this.$set(this.project.previews, fileId, {
                  image: image,
                  url: image.src,
                });
              };
            });
          } else {
            const image = new Image();
            image.src = URL.createObjectURL(file);
            image.onload = () => {
              this.$set(this.project.previews, fileId, {
                image: image,
                url: image.src,
              });
            };
          }
        });
        this.$refs["project-files"].reset();
      }
      this.validation.files.state = null;
    },
    active(value) {
      if (value) {
        this.validation.files.state = null;
        this.project = {
          name: null,
          files: [],
          fileNames: {},
          previews: {},
        };
        this.editFilesMode = false;
        this.uploadedFiles = null;
      }
    },
  },
  methods: {
    selectFile(file) {
      if (this.editFilesMode) {
        file.selected = !file.selected;
      }
    },
    getFilePreviewConfig(file) {
      if (!this.project.previews[file.fileId]) {
        return null;
      }
      const image = this.project.previews[file.fileId].image;
      const scale = Math.max(
          this.canvasConfig.width / image.width,
          this.canvasConfig.height / image.height
      );
      return {
        x: this.canvasConfig.width / 2 - (image.width * scale) / 2,
        y: this.canvasConfig.height / 2 - (image.height * scale) / 2,
        image: image,
        scaleX: scale,
        scaleY: scale,
      };
    },
    deleteSelectedFiles() {
      const fileIds = this.selectedFiles.map((f) => f.fileId);

      const refreshed = [];
      this.project.files.forEach((f) => {
        if (!fileIds.includes(f.fileId)) {
          refreshed.push(f);
        } else {
          this.$delete(this.project.fileNames, f.fileName);
        }
      });
      this.project.files = refreshed;

      fileIds.forEach((fileId) => {
        const url = this.project.previews[fileId].url;
        if (url) {
          URL.revokeObjectURL(url);
        }
        this.$delete(this.project.previews, fileId);
      });
      this.editFilesMode = false;
    },
    upload() {
      this.actionsDisabled = true;
      this.projectFiles = this.project.files;
      ProjectService.upload(this.projectId, this.projectFiles)
          .then(() => {
            this.$emit("updateFiles");
            this.$emit("update:active", false);
            Object.keys(this.project.previews).forEach((k) => {
              const url = this.project.previews[k].url;
              if (url) {
                URL.revokeObjectURL(url);
              }
            });
          })
          .finally(() => {
            this.actionsDisabled = false;
          });
    },
  },
};
</script>

<style lang="scss">
.project-update {
  &.modal-dialog {
    max-width: 670px;
  }

  .form-group {
    margin-bottom: 24px;
  }

  .b-form-file {
    height: 80px;

    .custom-file-label {
      height: 80px;
      border: 2px dashed $--color-base-02;
      box-sizing: border-box;
      border-radius: 8px;
      font-weight: $--font-weight-bold;
      font-size: $--font-size-medium-base;
      line-height: 24px;
      cursor: pointer;
      padding-left: 24px;

      span {
        margin-top: 20px;
      }

      &:hover,
      &:active,
      &:focus {
        outline: none;
      }
    }

    .custom-file-input:focus ~ .custom-file-label {
      border-color: $--color-base-02;
      box-shadow: none;
    }
  }

  .b-form-file > label:after {
    display: none;
  }

  .file-upload-link {
    color: $--color-secondary !important;
  }

  .file-upload-icon {
    fill: $--color-secondary;
  }

  .file-upload-link {
    margin-left: 16px;
  }

  .files {
    margin-top: 32px;
    padding: 24px;
    background-color: $--color-base-05;
    border-radius: 8px;
    max-height: 300px;

    .row {
      margin: 0;
    }

    .row.scrollable-container {
      max-height: 186px;
      margin: 0 -16px -16px 0;
    }

    .col {
      margin-bottom: 16px;
      padding-right: 16px;
      padding-left: 0;
    }

    .filename {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .selected-icon {
      position: absolute;
      bottom: 8px;
      right: 10px;

      svg {
        fill: $--color-secondary;
      }
    }
  }

  .konvajs-content {
    background-color: $--color-base-01;

    canvas {
      border-radius: 8px;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Add new files",
    "create": "Upload files",
    "cancel": "Cancel",
    "edit-files": "Edit files",
    "delete-files": "Delete selected",
    "project-name-label": "Project name",
    "uploaded-files": [
      "Uploaded 1 file",
      "Uploaded {value} files",
      "Uploaded {value} files",
      "Uploaded {value} files"
    ],
    "selected-files": [
      "Selected 1 file",
      "Selected {value} files",
      "Selected {value} files",
      "Selected {value} files"
    ],
    "no-files-feedback": "Files are required",
    "files-placeholder": "<span class='file-upload-link'>Choose files</span> or drag it here",
    "files-drop-placeholder": "Drop files here. Please upload a JPEG, PNG or TIFF image",
    "exists-file-message": "File {name} was already loaded"
  },
  "ru": {
    "title": "Добавить новые файлы",
    "create": "Загрузить файлы",
    "cancel": "Отмена",
    "edit-files": "Изменить файлы",
    "delete-files": "Удалить выбранные",
    "project-name-label": "Наименование проекта",
    "uploaded-files": [
      "Загружен 1 файл",
      "Загружен {value} файл",
      "Загружено {value} файла",
      "Загружено {value} файлов"
    ],
    "selected-files": [
      "Выбран 1 файл",
      "Выбран {value} файл",
      "Выбрано {value} файла",
      "Выбрано {value} файлов"
    ],
    "no-files-feedback": "Необходимо загрузить хотя бы один файл",
    "files-placeholder": "<span class='file-upload-link'>Выберите файлы</span> или перетащите сюда",
    "files-drop-placeholder": "Перетащите файлы сюда. Разрешенные форматы: JPEG, PNG, TIFF",
    "exists-file-message": "Файл {name} был загружен ранее"
  }
}
</i18n>
