<template>
  <b-modal v-model="display" :title="$t('title')" centered
           no-close-on-backdrop title-class="windows-title"
           @ok.prevent="extendSubscription">
    <template v-slot:modal-header-close>
      <dp-icon name="close"></dp-icon>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <dp-button ghost @click="cancel">{{ $t('cancel') }}</dp-button>
      <dp-button :disabled="actionsDisabled" @click="ok">{{ $t('send') }}</dp-button>
    </template>
    <template v-slot:default>
      <div class="subtitle">{{ $t('subtitle') }}</div>
      <b-form @submit.prevent="extendSubscription">
        <validation-provider ref="message-validator"
                             v-slot="validationContext"
                             name="message"
                             rules="required">
          <b-form-group id="message-group"
                        :invalid-feedback="validationContext.errors[0]"
                        :label="$t('message-label')"
                        label-for="message">
            <b-form-textarea id="message"
                             v-model="message"
                             :disabled="actionsDisabled"
                             :placeholder="$t('message-placeholder')"
                             :state="getValidationState(validationContext)"
                             rows="6">
            </b-form-textarea>
          </b-form-group>
        </validation-provider>
      </b-form>
    </template>
  </b-modal>
</template>

<script>
import DpIcon from '@/components/icon/dp-icon'
import DpButton from '@/components/button/dp-button'
import ValidationMixin from '@/mixins/validation-mixin'
import SubscriptionService from '@/services/subscription-service'

/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: 'subscription-extension',
  components: {
    DpIcon,
    DpButton
  },
  mixins: [ValidationMixin],
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      actionsDisabled: false,
      message: null
    }
  },
  computed: {
    display: {
      get() {
        return this.active
      },
      set(newValue) {
        this.$emit('update:active', newValue)
      }
    }
  },
  watch: {
    active(value) {
      if (value) {
        this.message = null
      }
    }
  },
  methods: {
    extendSubscription() {
      this.actionsDisabled = true

      this.$refs['message-validator'].validate().then((result) => {
        if (result.valid) {
          SubscriptionService.extendSubscription(this.message).then(() => {
            this.$emit('update:active', false)
          }).finally(() => {
            this.actionsDisabled = false
          })
        } else {
          this.actionsDisabled = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.subtitle {
  margin-bottom: 24px;
  font-weight: $--font-weight-medium;
  font-size: $--font-size-base;
  line-height: 24px;
}
</style>

<i18n>
{
  "en": {
    "title": "Extend subscription",
    "subtitle": "Please, write below message with how much time you want to extend your subscription in free form. We will contact you soon",
    "message-label": "Your message",
    "message-placeholder": "Your text",
    "send": "Send request",
    "cancel": "Cancel"
  },
  "ru": {
    "title": "Продлить подписку",
    "subtitle": "В свободной форме напишите, пожалуйста, срок, на который вы хотите продлить подписку. Мы свяжемся с вами в ближайшее время",
    "message-label": "Сообщение",
    "message-placeholder": "Введите текст сообщения",
    "send": "Отправить запрос",
    "cancel": "Отмена"
  }
}
</i18n>
