/**
 * @group Store-modules
 * describe the store (it will not be shown in vuese yet)
 */
const state = {
    notification: {
        message: null,
        variant: null
    }
}

const getters = {
    getNotification: (state) => state.notification
}

const mutations = {
    setNotification(state, notification) {
        state.notification = notification
    }
}

const actions = {
    setNotification(context, notification) {
        context.commit('setNotification', notification)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
