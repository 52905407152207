/**
 * @group Mixins
 * This is a description of the mixin
 */
export default {
    data() {
        return {
            /**
             * @vuese
             * data
             */
            scrollYSettings: {
                suppressScrollX: true,
                suppressScrollY: false,
                wheelPropagation: false
            }
        }
    }
}
