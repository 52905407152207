<template>
  <div v-if="scale" class="image-scale text-center">{{ `${Math.abs(Math.round(scale * 100))}%` }}</div>
</template>

<script>
/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: 'image-scale',
  props: {
    scale: {
      type: Number,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.image-scale {
  width: 60px;
  padding: 5px;
  background: $--color-base-01;
  box-shadow: 5px 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: $--font-weight-regular !important;
  font-size: $--font-size-small !important;
  line-height: 18px;
}
</style>
