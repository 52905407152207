/**
 * @group Store-modules
 * describe the store (it will not be shown in vuese yet)
 */
const state = {
    tokens: {
        accessToken: null,
        refreshToken: null
    },
    authorizationCode: null,
    redirectedFrom: null,
    refreshMode: false
}

const getters = {
    getAccessToken: (state) => state.tokens.accessToken,
    isAuthorized: (state) => !!state.tokens.accessToken,
    getRefreshToken: (state) => state.tokens.refreshToken,
    getAuthorizationCode: (state) => state.authorizationCode,
    getRedirectedFrom: (state) => state.redirectedFrom,
    isRefreshMode: (state) => state.refreshMode
}

const mutations = {
    setTokens(state, tokens) {
        state.tokens = tokens
    },
    setAuthorizationCode(state, code) {
        state.authorizationCode = code
    },
    setRedirectedFrom(state, route) {
        state.redirectedFrom = route
    },
    setRefreshMode(state, refreshMode) {
        state.refreshMode = refreshMode
    }
}

const actions = {
    setTokens(context, tokens) {
        context.commit('setTokens', tokens)
    },
    setAuthorizationCode(context, code) {
        context.commit('setAuthorizationCode', code)
    },
    clearAuth(context) {
        context.commit('setTokens', {
            accessToken: null,
            refreshToken: null
        })
        context.commit('setAuthorizationCode', null)
    },
    setRedirectedFrom(context, route) {
        context.commit('setRedirectedFrom', route)
    },
    setRefreshMode(context, refreshMode) {
        context.commit('setRefreshMode', refreshMode)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
