/**
 * @group Store-modules
 * describe the store (it will not be shown in vuese yet)
 */
const state = {
    security: {
        csrfToken: null
    }
}

const getters = {
    getCsrfToken: (state) => state.security.csrfToken
}

const mutations = {
    setCsrfToken(state, token) {
        state.security.csrfToken = token
    }
}

const actions = {
    setCsrfToken(context, token) {
        context.commit('setCsrfToken', token)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
