<template>
  <div :class="disabled ? 'disabled' : 'clickable'" class="dp-checkbox" @click="!disabled && click()">
    <div v-if="value" class="checked"></div>
  </div>
</template>

<script>
/**
 * @group Components
 * Standard components
 */
export default {
  name: 'dp-checkbox',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    click() {
      this.$emit('update:value', !this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.dp-checkbox {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $--color-secondary;
  box-sizing: border-box;
  border-radius: 4px;

  .checked {
    width: 10px;
    height: 10px;
    background-color: $--color-secondary;
    border-radius: 2px;
  }

  &.disabled {
    border-color: #77CDEB;
    cursor: not-allowed;

    .checked {
      background-color: #77CDEB;
    }
  }
}
</style>
