<template>
  <div :class="[scrollable ? 'scrollable' : null, lithologyEnabled ? 'lithology' : null]" class="core-preview">
    <span class="windows-title">{{ $t('title') }}</span>
    <vue-custom-scrollbar ref="preview-container"
                          :settings="scrollYSettings"
                          class="preview-container scrollable-container"
                          tagname="div"
                          @ps-scroll-y="scrollContainer">
      <div v-if="imagesLoaded" :style="{height: `${containerHeight}px`}">
        <v-stage ref="preview-canvas-stage" :config="{width: canvasWidth, height: canvasHeight}"
                 class="position-fixed"
                 @contextmenu="(e) => {e.evt.preventDefault()}">
          <v-layer>
            <v-group ref="preview-canvas-group">
              <v-group :config="previewGroupConfig">
                <template v-for="(config, index) in previewImageConfigs">
                  <v-image :key="index" :config="config"/>
                </template>
              </v-group>

              <template v-for="(config, index) in depthPointConfigs">
                <v-text :key="`preview-depth-text-${index}`" :config="config"/>
              </template>

              <v-group v-if="probabilities.length > 0" :config="probabilityGroupConfig">
                <template v-for="(config, index) in probabilityConfigs">
                  <v-line v-if="lithologyEnabled"
                          :key="`preview-probability-line-${index}`"
                          :config="config"/>
                </template>
              </v-group>

              <v-rect ref="preview-canvas-viewport-rect"
                      :config="viewportConfig"
                      @mouseenter="setCursorStyle(getGrabCursor())"
                      @mouseleave="setCursorStyle('default')"/>
            </v-group>
          </v-layer>
        </v-stage>
      </div>
      <b-overlay v-else :opacity="0.3" :show="!imagesLoaded" class="empty-image">
        <b-img :blank="true" height="140" width="140"></b-img>
      </b-overlay>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import VueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import ScrollMixin from '@/mixins/scroll-mixin'
import CursorMixin from '@/views/mixins/cursor-mixin'

/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: 'core-preview',
  components: {
    VueCustomScrollbar
  },
  mixins: [ScrollMixin, CursorMixin],
  props: {
    /**
     * @vuese
     * ..
     */
    images: {
      type: Array,
      default: () => {
        return []
      }
    },
    /**
     * @vuese
     * ..
     */
    depths: {
      type: Array,
      default: () => {
        return []
      }
    },
    /**
     * @vuese
     * ..
     */
    viewport: {
      // {x: 0, y: 0, width: 20, height: 20, feedback: false}
      type: Object,
      default: () => {
        return null
      }
    },
    /**
     * @vuese
     * ..
     */
    lithologyEnabled: {
      type: Boolean,
      default: false
    },
    /**
     * @vuese
     * ..
     */
    probabilities: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      /**
       * @vuese
       * ..
       */
      dimensions: {
        width: 156,
        height: 140,
        visibleHeight: 140
      },
      /**
       * @vuese
       * ..
       */
      preview: {
        x: 6,
        y: 2,
        width: 64,
        height: 140,
        scale: 1
      },
      /**
       * @vuese
       * ..
       */
      previewImageConfigs: [],
      /**
       * @vuese
       * ..
       */
      depthPointConfigs: [],
      /**
       * @vuese
       * ..
       */
      probabilityConfigs: [],
      /**
       * @vuese
       * ..
       */
      viewportConfig: {},
      /**
       * @vuese
       * ..
       */
      scrollable: false,
      /**
       * @vuese
       * ..
       */
      scroll: {
        skip: false,
        top: 0
      },
      /**
       * @vuese
       * ..
       */
      previewGroupConfig: {
        listening: false,
        clipFunc: (ctx) => {
          const container = this.$refs['preview-container'].$el
          const x = this.preview.x
          const y = container.scrollTop < this.preview.y ? this.preview.y : container.scrollTop
          const width = this.preview.width * this.preview.scale
          const height = container.clientHeight - 2
          const radius = 8

          ctx.beginPath()
          ctx.moveTo(x + radius, y)
          ctx.lineTo(x + width - radius, y)
          ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
          ctx.lineTo(x + width, y + height - radius)
          ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
          ctx.lineTo(x + radius, y + height)
          ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
          ctx.lineTo(x, y + radius)
          ctx.quadraticCurveTo(x, y, x + radius, y)
          ctx.closePath()
        }
      },
      /**
       * @vuese
       * ..
       */
      probabilityGroupConfig: {
        listening: false,
        clipFunc: (ctx) => {
          const container = this.$refs['preview-container'].$el
          const x = this.preview.x + this.preview.width * this.preview.scale + 8
          const y = container.scrollTop < this.preview.y ? this.preview.y : container.scrollTop
          const width = 4
          const height = container.clientHeight - 2
          const radius = 2

          ctx.beginPath()
          ctx.moveTo(x + radius, y)
          ctx.lineTo(x + width - radius, y)
          ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
          ctx.lineTo(x + width, y + height - radius)
          ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
          ctx.lineTo(x + radius, y + height)
          ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
          ctx.lineTo(x, y + radius)
          ctx.quadraticCurveTo(x, y, x + radius, y)
          ctx.closePath()
        }
      }
    }
  },
  computed: {
    /**
     * @vuese
     * ..
     */
    canvasWidth() {
      return this.dimensions.width
    },
    /**
     * @vuese
     * ..
     */
    canvasHeight() {
      return this.dimensions.visibleHeight
    },
    /**
     * @vuese
     * ..
     */
    containerHeight() {
      return this.dimensions.height
    },
    /**
     * @vuese
     * ..
     */
    imagesLoaded() {
      return this.previewImageConfigs && this.previewImageConfigs.length > 0
    }
  },
  watch: {
    /**
     * @vuese
     * ..
     */
    images(value) {
      const previousScale = this.preview.scale
      this.reloadPreviewImages(value, this.lithologyEnabled)
      if (previousScale !== this.preview.scale) {
        this.reloadDepthPoints(this.depths)
        this.reloadProbabilities(this.probabilities)
        this.reloadViewport(this.viewport)
      }
    },
    /**
     * @vuese
     * ..
     */
    depths(value) {
      this.reloadDepthPoints(value)
    },
    /**
     * @vuese
     * ..
     */
    viewport(value) {
      if (value && !value.feedback) {
        const viewportStart = value.y * this.preview.scale
        const viewportEnd = (value.y + value.height) * this.preview.scale
        const container = this.$refs['preview-container'].$el
        if ((container.scrollTop + container.clientHeight) > viewportEnd || viewportStart > (container.scrollTop + container.clientHeight)) {
          container.scrollTop = viewportStart - container.clientHeight / 2 + (value.height / 2) * this.preview.scale
          this.scroll.skip = true
        }
      }
      this.reloadViewport(value)
    },
    /**
     * @vuese
     * ..
     */
    lithologyEnabled(value) {
      const previousScale = this.preview.scale
      this.reloadPreviewImages(this.images, value)
      if (previousScale !== this.preview.scale) {
        this.reloadDepthPoints(this.depths)
        this.reloadProbabilities(this.probabilities)
        this.reloadViewport(this.viewport)
      }
    },
    /**
     * @vuese
     * ..
     */
    probabilities(value) {
      this.reloadProbabilities(value)
    }
  },
  /**
   * @vuese
   * ..
   */
  mounted() {
    window.addEventListener('resize', this.detectScrollable)
  },
  /**
   * @vuese
   * ..
   */
  destroyed() {
    this.previewImageConfigs = null
    this.depthPointConfigs = null
    this.probabilityConfigs = null
    this.viewportConfig = null
    this.previewGroupConfig = null
    this.probabilityGroupConfig = null

    window.removeEventListener('resize', this.detectScrollable)
  },
  methods: {
    /**
     * @vuese
     * ..
     */
    reloadPreviewImages(images, lithologyEnabled) {
      if (images && images.length > 0) {
        const lithologyDelta = 64
        if (lithologyEnabled) {
          this.preview.width = images[0].width
          this.preview.scale = (2 * lithologyDelta + 14) / this.preview.width
          this.dimensions.width = 156 + lithologyDelta + 14
        } else {
          this.preview.width = images[0].width
          this.preview.scale = lithologyDelta / this.preview.width
          this.dimensions.width = 156
        }

        let height = 0
        const configs = []
        this.images.forEach((image) => {
          configs.push({
            x: this.preview.x,
            y: height * this.preview.scale + this.preview.y,
            image: image,
            width: image.width,
            height: image.height,
            scaleX: this.preview.scale,
            scaleY: this.preview.scale,
            listening: false
          })
          height += image.height
        })

        this.preview.height = height
        this.dimensions.height = height * this.preview.scale + 2
        this.$set(this, 'previewImageConfigs', configs)

        this.$nextTick(() => {
          this.detectScrollable()
        })
      } else {
        this.dimensions.width = 156
        this.dimensions.height = 140
        this.dimensions.visibleHeight = 140
        this.$set(this, 'previewImageConfigs', [])
      }
    },
    /**
     * @vuese
     * ..
     */
    reloadViewport(viewport) {
      let config = {}
      if (viewport && viewport.width > 0 && viewport.height > 0) {
        let y = Math.max(viewport.y * this.preview.scale + 2, 2)
        let height = Math.max(viewport.height * this.preview.scale - 2, 2)

        const container = this.$refs['preview-container'].$el
        if (y > container.scrollTop + container.clientHeight) {
          y = container.scrollTop + container.clientHeight - 2
        } else if (y + height < container.scrollTop) {
          height = container.scrollTop + 2 - y
        }

        config = {
          x: 2,
          y: y,
          width: this.preview.width * this.preview.scale + 8,
          height: height,
          fill: 'rgba(255, 255, 255, 0.3)',
          stroke: '#42B1E5',
          strokeWidth: 3,
          cornerRadius: 10,
          draggable: true,
          dragBoundFunc: (pos) => {
            const absolutePosition = this.$refs['preview-canvas-viewport-rect'].getNode().absolutePosition()
            const container = this.$refs['preview-container'].$el

            const newY = container.scrollTop === 0 && pos.y < 2 ? 2 : pos.y
            if (newY > container.clientHeight - 40 || (newY + height) < 40) {
              return {
                x: absolutePosition.x,
                y: absolutePosition.y
              }
            }

            const deltaY = newY - absolutePosition.y
            if (deltaY) {
              this.$emit('move-viewport', deltaY / this.preview.scale, {
                needChangeDimensions: true,
                needChangePosition: true
              })
            }
            // don't move viewport when drag, viewport will be moved with feedback from parent component
            return {
              x: absolutePosition.x,
              y: absolutePosition.y
            }
          }
        }
      }
      if (this.viewportConfig.width > 0 && this.viewportConfig.height > 0) {
        if (!viewport.feedbackAttributes || viewport.feedbackAttributes.needChangePosition) {
          this.viewportConfig.y = config.y
        }
        if (!viewport.feedbackAttributes || viewport.feedbackAttributes.needChangeDimensions) {
          this.viewportConfig.width = config.width
          this.viewportConfig.height = config.height
        }
      } else {
        this.$set(this, 'viewportConfig', config)
      }
    },
    /**
     * @vuese
     * ..
     */
    reloadDepthPoints(depths) {
      const configs = []
      if (depths && depths.length > 0) {
        let prevPoint = null
        depths.slice(0).sort((d1, d2) => d1.value - d2.value).forEach((depthPoint, index) => {
          if (!prevPoint) {
            prevPoint = depthPoint
          } else {
            if ((depthPoint.y - prevPoint.y) * this.preview.scale > 50 && index !== depths.length - 1) {
              prevPoint = depthPoint
              configs.push(this.getDepthTextConfig(depthPoint))
            }
          }
        })
      }
      this.$set(this, 'depthPointConfigs', configs)
    },
    /**
     * @vuese
     * ..
     */
    reloadProbabilities(probabilities) {
      const configs = []
      if (probabilities && probabilities.length > 0) {
        probabilities.forEach((probability) => {
          configs.push(this.getProbabilityLineConfig(probability))
        })
      }
      this.$set(this, 'probabilityConfigs', configs)
    },
    /**
     * @vuese
     * ..
     */
    getDepthTextConfig(depthPoint) {
      return {
        x: this.preview.x + this.preview.width * this.preview.scale + (this.lithologyEnabled ? (14 + 10) : 16),
        y: this.preview.y + depthPoint.y * this.preview.scale,
        height: 18,
        offsetY: 9,
        text: depthPoint.value.toFixed(2),
        fontFamily: 'Montserrat',
        fontSize: 14,
        fill: '#242424',
        verticalAlign: 'middle',
        listening: false
      }
    },
    /**
     * @vuese
     * ..
     */
    getProbabilityLineConfig(probability) {
      const points = []
      const x = this.preview.x + this.preview.width * this.preview.scale + 10
      points.push(x)
      points.push(this.preview.y + probability.from_y * this.preview.scale)
      points.push(x)
      points.push(this.preview.y + probability.to_y * this.preview.scale)

      let color = '#FFEFAB'
      if (probability.value >= 0.8) {
        color = '#4EBC50'
      } else if (probability.value < 0.5) {
        color = '#E4312C'
      }
      return {
        points: points,
        stroke: color,
        strokeWidth: 4,
        listening: false
      }
    },
    /**
     * @vuese
     * ..
     */
    scrollContainer() {
      const scrollTop = this.$refs['preview-container'].$el.scrollTop
      if (!this.scroll.skip) {
        const deltaY = scrollTop - this.scroll.top
        this.$emit('move-viewport', deltaY / this.preview.scale, {needChangeDimensions: true, needChangePosition: true})
      }
      this.scroll.skip = false
      this.scroll.top = scrollTop

      this.$refs['preview-canvas-group'].getNode().y(-scrollTop)
    },
    /**
     * @vuese
     * ..
     */
    detectScrollable() {
      const container = this.$refs['preview-container'].$el
      this.scrollable = container.scrollHeight > container.clientHeight
      this.dimensions.visibleHeight = container.clientHeight
    },
    /**
     * @vuese
     * ..
     */
    setCursorStyle(cursor) {
      this.$refs['preview-canvas-stage'].getNode().container().style.cursor = cursor
    }
  }
}
</script>

<style lang="scss">
.core-preview {
  padding: 16px 0;
  height: calc(100vh - 170px);
  background: $--color-base-01;
  box-shadow: 5px 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  &:not(.lithology) {
    &:not(.scrollable) .preview-container {
      width: 180px;
    }

    &.scrollable .preview-container {
      width: 188px;
    }
  }

  &.lithology {
    &:not(.scrollable) .preview-container {
      width: calc(180px + 64px + 14px);
    }

    &.scrollable .preview-container {
      width: calc(188px + 64px + 14px);
    }
  }

  &:not(.scrollable) {
    .windows-title {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &.scrollable {
    padding-right: 8px;

    .windows-title {
      padding-left: 16px;
      padding-right: 8px;
    }
  }

  .preview-container {
    padding: 0 0 0 16px;
    margin-top: 12px;
    height: calc(100% - 42px);

    .empty-image {
      width: 140px;
      height: 140px;
    }

    .konvajs-content {
      canvas {
        margin-left: -6px !important;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Core View"
  },
  "ru": {
    "title": "Обзор керна"
  }
}
</i18n>
