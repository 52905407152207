import ProjectService from '@/services/project-service'

/**
 * @group Views-mixins
 * This is a description of the component it includes DpButton component
 */
export default {
    data() {
        return {
            /**
             * @vuese
             * the data
             */
            fillPatternImages: {},
            fillPatternImagePromises: {}
        }
    },
    /**
     * @vuese
     * description
     */
    destroyed() {
        this.fillPatternImages = null
        this.fillPatternImagePromises = null
    },
    methods: {
        /**
         * @vuese
         * description
         * @arg projectId \\
         * @arg type
         */
        getLithologyFillPattern(projectId, type) {
            const key = this.getLithologyFillPatternKey(type)
            const image = this.fillPatternImages[key]
            if (image) {
                return Promise.resolve(image)
            } else {
                return this.getLithologyFillPatternRemotely(projectId, type).then((result) => {
                    const htmlImage = new Image()
                    htmlImage.src = URL.createObjectURL(result)
                    return new Promise((resolve) => {
                        htmlImage.onload = () => {
                            this.fillPatternImages[key] = htmlImage
                            this.$delete(this.fillPatternImagePromises, key)
                            URL.revokeObjectURL(htmlImage.src)
                            resolve(htmlImage)
                        }
                    })
                })
            }
        },
        /**
         * @vuese
         * description
         * @arg projectId \\
         * @arg type
         */
        getLithologyFillPatternRemotely(projectId, type) {
            const key = this.getLithologyFillPatternKey(type)
            let promise = this.fillPatternImagePromises[key]
            if (!promise) {
                promise = ProjectService.lithologyFillPattern(projectId, type.color, type.pattern).then((result) => {
                    return result.data
                })
                this.fillPatternImagePromises[key] = promise
            }
            return promise
        },
        /**
         * @vuese
         * description
         * @arg type
         */
        getLithologyFillPatternKey(type) {
            return `${type.color}-${type.pattern}`
        }
    }
}
