/**
 * @group Mixins
 * This is a description of the mixin
 */
export default {
    methods: {
        /**
         * @vuese
         * method
         * @arg items \\
         * @arg key
         */
        arrayToMap(items, key) {
            return items.reduce((map, item) => {
                map[item[key]] = item
                return map
            }, {})
        },
        /**
         * @vuese
         * method
         * @arg items \\
         * @arg key
         */
        arrayToMapOfArrays(items, key) {
            return items.reduce((map, item) => {
                if (Object.prototype.hasOwnProperty.call(map, item[key])) {
                    map[item[key]].push(item)
                } else {
                    map[item[key]] = [item]
                }
                return map
            }, {})
        }
    }
}
