<template>
  <div v-if="image" class="image-preview d-flex flex-column align-items-center">
    <div class="image">
      <v-stage :config="{width: canvasWidth, height: canvasHeight}"
               @contextmenu="(e) => {e.evt.preventDefault()}">
        <v-layer :config="{listening: false}">
          <v-image :config="previewConfig"></v-image>
          <v-rect :config="viewportConfig"></v-rect>
        </v-layer>
      </v-stage>
    </div>
    <image-scale :scale="scale" class="mt-2"></image-scale>
  </div>
</template>

<script>
import ImageScale from '@/views/components/image-scale'

/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: 'image-preview',
  components: {
    /**
     * @vuese
     * ..
     */
    ImageScale
  },
  props: {
    /**
     * @vuese
     * ..
     */
    image: {
      required: true
    },
    /**
     * @vuese
     * ..
     */
    viewport: {
      // {x: 0, y: 0, width: 20, height: 20}
      type: Object,
      default: () => {
        return null
      }
    },
    /**
     * @vuese
     * ..
     */
    scale: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      /**
       * @vuese
       * ..
       */
      dimensions: {
        width: 100,
        height: 100
      },
      /**
       * @vuese
       * ..
       */
      previewScale: null
    }
  },
  computed: {
    /**
     * @vuese
     * ..
     */
    canvasWidth() {
      return this.dimensions.width
    },
    /**
     * @vuese
     * ..
     */
    canvasHeight() {
      return this.dimensions.height
    },
    /**
     * @vuese
     * ..
     */
    previewConfig() {
      return {
        x: 0,
        y: 0,
        image: this.image,
        scaleX: this.previewScale,
        scaleY: this.previewScale
      }
    },
    /**
     * @vuese
     * ..
     */
    viewportConfig() {
      if (this.viewport && !!this.previewScale && this.viewport.width > 0 && this.viewport.height > 0) {
        return {
          x: this.viewport.x * this.previewScale + 1,
          y: this.viewport.y * this.previewScale + 1,
          width: this.viewport.width * this.previewScale - 2,
          height: this.viewport.height * this.previewScale - 2,
          stroke: '#E4312C',
          strokeWidth: 2,
          cornerRadius: 4
        }
      } else {
        return {}
      }
    }
  },
  watch: {
    /**
     * @vuese
     * ..
     */
    image(value) {
      if (value) {
        const min = Math.min(value.width, value.height)
        const max = Math.max(value.width, value.height)

        this.previewScale = Math.min(100 / min, 160 / max)
        this.dimensions.width = value.width * this.previewScale
        this.dimensions.height = value.height * this.previewScale
      }
    }
  }
}
</script>

<style lang="scss">
.image-preview {
  .image {
    padding: 4px;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 5px 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .konvajs-content {
    canvas {
      border-radius: 4px;
    }
  }
}
</style>
