<template>
  <div class="lithology-types">
    <div class="windows-title">{{ $t('title') }}</div>

    <vue-custom-scrollbar :settings="scrollYSettings" class="lithology-types-container scrollable-container"
                          tagname="div">
      <div class="model-types">
        <div :title="modelName" class="windows-subtitle">{{ $t('model-prefix') }} {{ modelName }}</div>

        <vue-custom-scrollbar :settings="scrollYSettings"
                              :style="{'height': containerHeight.model}"
                              class="model-types-container scrollable-container" tagname="div">
          <div v-for="type in modelTypes" :key="type.id" :class="isActiveType(type) ? 'active' : null"
               class="lithology-type"
               @click="activateType(type)"
               @mouseleave="unHoverType(type)"
               @mouseover="hoverType(type)">
            <lithology-type-preview :color="type.color" :height="24" :pattern="type.pattern" :width="24"/>
            <div :title="getTypeName(type)" class="title">{{ getTypeName(type) }}</div>
            <div v-if="isActiveType(type) || isHoveredType(type)" class="ml-auto">
              <dp-icon name="edit" @click.native.stop="updateType(type)"/>
            </div>
          </div>
        </vue-custom-scrollbar>
      </div>

      <div class="custom-types">
        <div class="windows-subtitle">{{ $t('custom-types.title') }}</div>

        <div class="lithology-type new" @click="createType">
          <dp-icon :sensitive="false" name="new-item"/>
          <div class="title">{{ $t('custom-types.new') }}</div>
        </div>

        <vue-custom-scrollbar :settings="scrollYSettings"
                              :style="{'height': containerHeight.custom}"
                              class="custom-types-container scrollable-container" tagname="div">
          <div v-for="type in customTypes" :key="type.id" :class="isActiveType(type) ? 'active' : null"
               class="lithology-type"
               @click="activateType(type)"
               @mouseleave="unHoverType(type)"
               @mouseover="hoverType(type)">
            <lithology-type-preview :color="type.color" :height="24" :pattern="type.pattern" :width="24"/>
            <div :title="getTypeName(type)" class="title">{{ getTypeName(type) }}</div>
            <div v-if="isActiveType(type) || isHoveredType(type)" class="ml-auto d-flex actions">
              <dp-icon name="trash-bin" @click.native.stop="deleteType(type)"/>
              <dp-icon name="edit" @click.native.stop="updateType(type)"/>
            </div>
          </div>
        </vue-custom-scrollbar>
      </div>
    </vue-custom-scrollbar>

    <lithology-type :active.sync="typeDialog.display"
                    :disabled-settings="usedTypeSettings"
                    :model-name="modelName"
                    :type="typeDialog.type"
                    @save-type="saveType"/>

    <dp-decision-dialog :active.sync="decisionDialog.active"
                        :question="decisionDialog.question"
                        @agree="decisionDialog.agree"/>
  </div>
</template>

<script>
import VueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import LithologyType from '@/views/components/lithology/lithology-type'
import LithologyTypePreview from '@/views/components/lithology/lithology-type-preview'
import DpIcon from '@/components/icon/dp-icon'
import DpDecisionDialog from '@/components/dp-decision-dialog'
import ScrollMixin from '@/mixins/scroll-mixin'
import {v4 as uuidv4} from 'uuid'

/**
 * @group Views-components-lithology
 * This is a description of the component
 */
export default {
  name: 'lithology-types',
  components: {
    DpDecisionDialog,
    LithologyType,
    LithologyTypePreview,
    DpIcon,
    VueCustomScrollbar
  },
  mixins: [ScrollMixin],
  props: {
    projectId: {
      type: String,
      required: true
    },
    modelName: {
      type: String,
      required: true
    },
    types: {
      type: Array,
      default: () => {
        return []
      }
    },
    activeType: {
      type: Object,
      default: () => {
        return null
      }
    },
    usedTypeIds: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      containerHeight: {
        model: '240px',
        custom: '80px'
      },
      typeDialog: {
        display: false,
        type: null
      },
      hoveredType: null,
      decisionDialog: {
        active: false,
        question: null,
        agree: () => {
        },
        type: null
      }
    }
  },
  computed: {
    modelTypes() {
      return this.types.filter((t) => t.source_type === 'MODEL')
    },
    customTypes() {
      return this.types.filter((t) => t.source_type === 'CUSTOM')
    },
    usedTypeSettings() {
      return this.types.map((t) => {
        return {id: t.id, color: t.color, pattern: t.pattern}
      })
    }
  },
  watch: {
    types() {
      this.recalculateHeight()
    },
    'typeDialog.display'(value) {
      this.$store.dispatch('setCloseOnClickOutside', !value)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.recalculateHeight()
      window.addEventListener('resize', this.recalculateHeight)
    })
  },
  destroyed() {
    this.hoveredType = null
    window.removeEventListener('resize', this.recalculateHeight)
  },
  methods: {
    getTypeName(type) {
      if (type.custom_name) {
        return type.custom_name
      }
      return type.names[this.$i18n.locale.toUpperCase()]
    },
    isActiveType(type) {
      return this.activeType && this.activeType.id === type.id
    },
    activateType(type) {
      this.$emit('update:activeType', type)
    },
    isHoveredType(type) {
      return this.hoveredType && this.hoveredType.id === type.id
    },
    hoverType(type) {
      this.hoveredType = type
    },
    unHoverType(type) {
      if (type.id === this.hoveredType.id) {
        this.hoveredType = null
      }
    },
    createType() {
      this.typeDialog.type = null
      this.typeDialog.display = true
    },
    updateType(type) {
      this.typeDialog.type = type
      this.typeDialog.display = true
    },
    saveType(type) {
      if (type.id) {
        const updatedType = this.types.find((t) => t.id === type.id)
        const name = this.getTypeName(updatedType) === type.name && updatedType.source_type === 'MODEL' ? null : type.name
        const result = {
          id: type.id,
          custom_name: name,
          color: type.color,
          pattern: type.pattern,
          names: updatedType.names,
          source_type: updatedType.source_type
        }
        this.$emit('swap-type', result)
        if (this.isActiveType(type)) {
          this.activateType(type)
        }
      } else {
        const result = {
          id: uuidv4(),
          custom_name: type.name,
          color: type.color,
          pattern: type.pattern,
          names: null,
          source_type: 'CUSTOM'
        }
        this.$emit('add-type', result)
      }
    },
    deleteType(type) {
      if (this.usedTypeIds.includes(type.id)) {
        this.decisionDialog.question = this.$t('questions.delete-type')
        this.decisionDialog.agree = this.agreeDeleteType
        this.decisionDialog.type = type
        this.decisionDialog.active = true
      } else {
        this.agreeDeleteType(type)
      }
    },
    agreeDeleteType(type = null) {
      const deleted = !type ? this.decisionDialog.type : type
      this.$emit('delete-type', deleted)
      if (this.isActiveType(deleted)) {
        this.activateType(null)
      }
    },
    recalculateHeight() {
      const modelTypesLength = this.modelTypes.length
      const customTypesLength = this.customTypes.length

      let maxVisibleTypes = 1
      let maxVisibleCustomTypes = 1
      if (this.$el.clientHeight >= 451) {
        maxVisibleTypes = 6
        maxVisibleCustomTypes = 2
      } else if (this.$el.clientHeight >= 411) {
        maxVisibleTypes = 5
        maxVisibleCustomTypes = 2
      } else if (this.$el.clientHeight >= 371) {
        maxVisibleTypes = 4
      } else if (this.$el.clientHeight >= 331) {
        maxVisibleTypes = 3
      } else if (this.$el.clientHeight >= 291) {
        maxVisibleTypes = 2
      }

      const custom = Math.min(maxVisibleCustomTypes, customTypesLength)
      const calcModelHeight = Math.min(modelTypesLength, Math.max(maxVisibleTypes - custom, 1)) * 40
      const calcCustomHeight = custom * 40
      this.containerHeight.model = `${calcModelHeight}px`
      this.containerHeight.custom = `${calcCustomHeight}px`
    }
  }
}
</script>

<style lang="scss">
.lithology-types {
  width: 304px;
  max-height: calc(100vh - 170px - 251px);
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: $--color-base-01;
  box-shadow: -5px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .windows-title {
    margin-bottom: 16px;
  }

  .windows-subtitle {
    margin-bottom: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .lithology-type {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    height: 40px;
    padding: 8px 16px;
    box-sizing: border-box;
    cursor: pointer;

    &.active, &:active {
      width: calc(100% - 4px);
      background-color: $--color-base-01;
      border: 2px solid $--color-secondary;
      border-radius: 8px;
      color: $--color-secondary;
      padding-left: 14px;
      padding-right: 14px;

      .title {
        color: $--color-secondary;
      }
    }

    &:hover {
      background-color: $--color-base-02;
      border: 2px solid $--color-base-02;
      box-sizing: border-box;
      border-radius: 8px;
    }

    .title {
      margin-left: 8px;
      color: $--color-primary;
      font-size: $--font-size-base;
      font-weight: $--font-weight-medium;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 18px;
    }

    @supports (-webkit-line-clamp: 2) {
      .title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: unset;
      }
    }
  }

  .lithology-types-container {
    margin-right: -8px;
    padding-right: 8px;
    height: 100%;
  }

  .model-types-container, .custom-types-container {
    padding-right: 4px;
  }

  .custom-types {
    margin-top: 16px;

    .new {
      svg {
        fill: $--color-secondary;
      }
    }

    .lithology-type {
      .actions {
        min-width: 48px;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Classes",
    "model-prefix": "From",
    "custom-types": {
      "title": "My classes",
      "new": "New class"
    },
    "questions": {
      "delete-type": "Interpretation contains selected element that will be completely removed. Would you like to delete it?"
    }
  },
  "ru": {
    "title": "Классы",
    "model-prefix": "Из",
    "custom-types": {
      "title": "Мои классы",
      "new": "Новый класс"
    },
    "questions": {
      "delete-type": "Выбранный элемент присутствует в интерпретации и будет полностью из неё удален. Удалить его?"
    }
  }
}
</i18n>
