/**
 * @group Mixins
 * This is a description of the mixin
 */
export default {
    methods: {
        /**
         * @vuese
         * the method
         */
        isIE() {
            return /msie\s|trident\//i.test(navigator.userAgent)
        },
        /**
         * @vuese
         * the method
         */
        isFirefox() {
            return navigator.userAgent.indexOf('Firefox') > -1
        },
        /**
         * @vuese
         * the method
         */
        isMacOSFirefox() {
            return navigator.appVersion.indexOf('Mac') > -1 && navigator.userAgent.indexOf('Firefox') > -1
        },
        /**
         * @vuese
         * the method
         */
        isSafari() {
            return navigator.vendor.indexOf('Apple') > -1
        }
    }
}
