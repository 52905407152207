<template>
  <b-modal v-model="display" :title="modelTitle" centered
           modal-class="classification-model-types-modal"
           no-close-on-backdrop title-class="windows-title"
           @ok.prevent="applyModel"
           @cancel.prevent="cancelModel"
           @close.prevent="cancelModel">
    <template v-slot:modal-header-close>
      <dp-icon name="close"></dp-icon>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <dp-button ghost @click="cancel">{{ $t('actions.cancel') }}</dp-button>
      <dp-button :disabled="!applyModelEnabled" secondary @click="ok">
        {{ $t('actions.apply') }}
      </dp-button>
    </template>
    <template v-slot:default>
      <vue-custom-scrollbar ref="model-types-container"
                            :settings="scrollYSettings"
                            :style="{'max-height': modelType ? '540px' : '240px'}"
                            class="model-types-container scrollable-container" tagname="div">
        <div v-for="type in modelTypes" :key="type.id" class="model-type">
          <div class="model-type-info w-100"
               @mouseleave="unHoverType(type)"
               @mouseover="hoverType(type)">
            <dp-checkbox :value.sync="type.selected"></dp-checkbox>
            <div class="d-flex clickable align-items-center flex-grow-1"
                 @click="type.selected = !type.selected">
              <lithology-type-preview :color="type.color" :pattern="type.pattern"/>
              <div :title="getTypeName(type)" class="title">{{ getTypeName(type) }}</div>
              <div v-if="isHoveredType(type)" class="ml-auto">
                <dp-icon name="edit" @click.native.stop="switchEditModelType(type)"/>
              </div>
            </div>
          </div>
          <div v-if="modelType && modelType.id === type.id" class="model-type-edit">
            <validation-observer v-slot="{ handleSubmit }">
              <b-form @submit.prevent="handleSubmit(saveModelType)">
                <validation-provider ref="name-validator"
                                     v-slot="validationContext"
                                     name="name"
                                     rules="required|max:250">
                  <b-form-group v-if="modelType"
                                id="name-group"
                                :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="name"
                                  v-model="modelType.name"
                                  :state="getValidationState(validationContext)">
                    </b-form-input>
                  </b-form-group>
                </validation-provider>
                <lithology-type-settings v-if="modelType"
                                         :disabled-settings="usedTypeSettings"
                                         :settings.sync="modelTypeSettings"/>
                <div class="actions">
                  <dp-button ghost @click.prevent="cancelModelType">
                    {{ $t('actions.cancel') }}
                  </dp-button>
                  <dp-button class="ml-3" outline type="submit">
                    {{ $t('actions.save') }}
                  </dp-button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </vue-custom-scrollbar>
    </template>
  </b-modal>
</template>

<script>

import VueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import DpButton from '@/components/button/dp-button'
import DpIcon from '@/components/icon/dp-icon'
import LithologyTypePreview from '@/views/components/lithology/lithology-type-preview'
import LithologyTypeSettings from '@/views/components/lithology/lithology-type-settings'
import ValidationMixin from '@/mixins/validation-mixin'
import DpCheckbox from '@/components/dp-checkbox'
import ScrollMixin from '@/mixins/scroll-mixin'

/**
 * @group Views-components-lithology
 * This is a description of the component
 */
export default {
  name: 'classification-model-types',
  components: {DpCheckbox, LithologyTypePreview, LithologyTypeSettings, DpIcon, DpButton, VueCustomScrollbar},
  mixins: [ValidationMixin, ScrollMixin],
  props: {
    /**
     * indicates if the type is active?
     */
    active: {
      type: Boolean,
      deft: false
      // The default value is: false
    },
    /**
     * the model
     */
    model: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  /**
   * @vuese
   * the data
   */
  data() {
    return {
      /**
       * @vuese
       * the data
       */
      modelTypes: [],
      /**
       * @vuese
       * the data
       */
      modelType: null,
      /**
       * @vuese
       * the data
       */
      hoveredType: null
    }
  },
  computed: {

    /**
     * @vuese
     * should this be displayed
     * @arg The argument is a boolean value representing xxx
     */
    display: {

      get() {
        return this.active
      },
      set(newValue) {
        this.$emit('update:active', newValue)
      }
    },
    /**
     * @vuese
     * title
     * @arg The argument is a boolean value representing xxx
     */
    modelTitle() {
      return this.model.names[this.$i18n.locale.toUpperCase()]
    },
    /**
     * @vuese
     * title
     * @arg The argument is a boolean value representing xxx
     */
    selectedTypes() {
      return this.modelTypes.filter((t) => t.selected)
    },
    /**
     * @vuese
     * title
     * @arg The argument is a boolean value representing xxx
     */
    isEditMode() {
      return !!this.modelType
    },
    /**
     * @vuese
     * title
     * @arg The argument is a boolean value representing xxx
     */
    applyModelEnabled() {
      return this.selectedTypes.length > 0 && !this.isEditMode
    },
    /**
     * @vuese
     * modeltype
     */
    modelTypeSettings: {
      get() {
        return {
          id: this.modelType.id,
          color: this.modelType.color,
          pattern: this.modelType.pattern
        }
      },
      /**
       * @vuese
       * title
       */
      set(newValue) {
        this.modelType.color = newValue.color
        this.modelType.pattern = newValue.pattern
      }
    },
    /**
     * @vuese
     * title
     */
    usedTypeSettings() {
      return this.modelTypes.map((t) => {
        return {id: t.id, color: t.color, pattern: t.pattern}
      })
    }
  },

  /**
   * @vuese
   * title
   */
  mounted() {
    this.loadModelTypes()
  },

  /**
   * @vuese
   * title
   */
  destroyed() {
    this.modelTypes = null
    this.modelType = null
    this.hoveredType = null
  },
  methods: {
    /**
     * @vuese
     * Used to apply model
     *
     */
    applyModel() {
      const selected = []
      const edited = []
      this.modelTypes.forEach((t) => {
        if (t.selected) {
          selected.push(t.id)
        }
        if (t.edited) {
          edited.push(t)
        }
      })
      this.$emit('apply-model', this.model.id, selected, edited)
      this.display = false
    },

    /**
     * @vuese
     * title
     */
    cancelModel() {
      this.$emit('cancel-model')
      this.display = false
    },

    /**
     * @vuese
     * title
     */
    loadModelTypes() {
      if (this.model) {
        const modelTypes = this.model.classes.map((cl) => {
          return {
            ...cl,
            edited: false,
            selected: true
          }
        })
        this.$set(this, 'modelTypes', modelTypes)
      } else {
        this.$set(this, 'modelTypes', [])
      }
      this.modelType = null
    },

    /**
     * @vuese
     * title
     */
    switchEditModelType(type) {
      if (this.modelType && this.modelType.id === type.id) {
        this.cancelModelType()
      } else {
        this.editModelType(type)
      }
    },

    /**
     * @vuese
     * title
     */
    editModelType(type) {
      this.modelType = {
        id: type.id,
        name: this.getTypeName(type),
        color: type.color,
        pattern: type.pattern
      }
    },
    /**
     * @vuese
     * title
     */
    cancelModelType() {
      this.modelType = null
    },
    /**
     * @vuese
     * title
     */
    saveModelType() {
      const index = this.modelTypes.findIndex((t) => t.id === this.modelType.id)
      const editableType = this.modelTypes[index]

      const nameChanged = this.getTypeName(editableType) !== this.modelType.name
      const colorChanged = editableType.color !== this.modelType.color
      const patternChanged = editableType.pattern !== this.modelType.pattern
      if (nameChanged || colorChanged || patternChanged) {
        this.$set(this.modelTypes, index, {
          ...editableType,
          ...this.modelType,
          name: nameChanged ? this.modelType.name : null,
          edited: true
        })
      }
      this.modelType = null
    },
    /**
     * @vuese
     * title
     */
    getTypeName(type) {
      if (type.name) {
        return type.name
      }
      return type.names[this.$i18n.locale.toUpperCase()]
    },
    /**
     * @vuese
     * title
     */
    isHoveredType(type) {
      return this.hoveredType && this.hoveredType.id === type.id
    },
    /**
     * @vuese
     * title
     */
    hoverType(type) {
      this.hoveredType = type
    },
    /**
     * @vuese
     * title
     */
    unHoverType(type) {
      if (type.id === this.hoveredType.id) {
        this.hoveredType = null
      }
    }
  }
}
</script>

<style lang="scss">
.classification-model-types-modal {
  .modal-dialog {
    max-width: 676px;

    .modal-body {
      padding: 16px 0 24px !important;
    }
  }

  .model-types-container {
    .model-type {
      width: 100%;
      display: flex;
      flex-direction: column;

      .model-type-info {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 8px 4px 8px 20px;

        .title {
          max-width: 508px;
          margin-left: 8px;
          color: $--color-primary;
          font-size: $--font-size-base;
          font-weight: $--font-weight-medium;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          line-height: 18px;
        }

        @supports (-webkit-line-clamp: 2) {
          .title {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: unset;
          }
        }

        .dp-checkbox {
          margin-right: 20px;
        }

        svg {
          cursor: pointer;
        }
      }

      .model-type-edit {
        background-color: $--color-base-05;
        border: 2px solid $--color-secondary;
        box-sizing: border-box;
        border-radius: 8px;
        margin: 4px 0 16px;
        padding: 16px 24px 24px;

        .actions {
          width: 100%;
          display: flex;
          margin-top: 24px;
          justify-content: flex-end;
        }

        .lithology-type-settings {
          margin-top: 24px;

          .color-container, .pattern-container {
            max-height: 64px;
          }
        }
      }
    }

    &.scrollable-container.ps--active-y {
      .model-type-edit {
        margin-right: 8px;
        margin-left: 8px;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "color-title": "Choose color",
    "pattern-title": "Choose pattern",
    "actions": {
      "apply": "Apply",
      "save": "Save",
      "cancel": "Cancel"
    }
  },
  "ru": {
    "color-title": "Выберите цвет",
    "pattern-title": "Выберите паттерн",
    "actions": {
      "apply": "Применить",
      "save": "Сохранить",
      "cancel": "Отмена"
    }
  }
}
</i18n>
