<template>
  <b-modal
      v-model="display"
      :title="$t('title')"
      centered
      dialog-class="project-notice"
      no-close-on-backdrop
      title-class="windows-title"
      @ok.prevent="showCreationDialog"
  >
    <template v-slot:modal-header-close>
      <dp-icon name="close"></dp-icon>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <dp-button ghost @click="cancel">{{ $t("cancel") }}</dp-button>
      <dp-button @click="ok">{{ $t("create") }}</dp-button>
    </template>
    <template v-slot:default>
      <div>
        {{ $t("notice") }}
      </div>
      <b-overlay :opacity="0.3" :show="actionsDisabled" no-wrap></b-overlay>
      <!-- <project-update
        :active.sync="modal.creation"
        :project-name.sync="projectName"
        :project-id="projectId"
        v-on:updateFiles="updateFiles"
      >
      </project-update> -->
    </template>
  </b-modal>
</template>

<script>
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import DpIcon from "@/components/icon/dp-icon";
import DpButton from "@/components/button/dp-button";

/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: "project-update",
  components: {
    DpIcon,
    DpButton,
  },
  props: {
    modalNotice: {
      type: Boolean,
      default: false,
    },
    projectId: {
      required: true,
    },
    projectName: {
      required: true,
    },
  },
  data() {
    return {
      actionsDisabled: false,
    };
  },
  computed: {
    display: {
      get() {
        return this.modalNotice;
      },
      set(newValue) {
        this.$emit("update:modalNotice", newValue);
      },
    },
  },
  watch: {},
  methods: {
    showCreationDialog() {
      this.$emit("deleteFiles");
      this.$emit("update:modalNotice", false);
      this.$emit("showCreationDialog");
      this.$emit("toggle-button");
    },
    updateFiles() {
      this.$emit("loadImages");
    },
    upload() {
    },
  },
};
</script>

<style lang="scss">
.project-update {
  &.modal-dialog {
    max-width: 670px;
  }

  .form-group {
    margin-bottom: 24px;
  }

  .b-form-file {
    height: 80px;

    .custom-file-label {
      height: 80px;
      border: 2px dashed $--color-base-02;
      box-sizing: border-box;
      border-radius: 8px;
      font-weight: $--font-weight-bold;
      font-size: $--font-size-medium-base;
      line-height: 24px;
      cursor: pointer;
      padding-left: 24px;

      span {
        margin-top: 20px;
      }

      &:hover,
      &:active,
      &:focus {
        outline: none;
      }
    }

    .custom-file-input:focus ~ .custom-file-label {
      border-color: $--color-base-02;
      box-shadow: none;
    }
  }

  .b-form-file > label:after {
    display: none;
  }

  .file-upload-link {
    color: $--color-secondary !important;
  }

  .file-upload-icon {
    fill: $--color-secondary;
  }

  .file-upload-link {
    margin-left: 16px;
  }

  .files {
    margin-top: 32px;
    padding: 24px;
    background-color: $--color-base-05;
    border-radius: 8px;
    max-height: 300px;

    .row {
      margin: 0;
    }

    .row.scrollable-container {
      max-height: 186px;
      margin: 0 -16px -16px 0;
    }

    .col {
      margin-bottom: 16px;
      padding-right: 16px;
      padding-left: 0;
    }

    .filename {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .selected-icon {
      position: absolute;
      bottom: 8px;
      right: 10px;

      svg {
        fill: $--color-secondary;
      }
    }
  }

  .konvajs-content {
    background-color: $--color-base-01;

    canvas {
      border-radius: 8px;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Notice",
    "create": "Yes",
    "cancel": "No",
    "notice": "If you delete all the files, you will need to upload new images to continue your work. Delete all the files?"
  },
  "ru": {
    "title": "Внимание",
    "create": "Да",
    "cancel": "Нет",
    "notice": "Если Вы удалите все файлы, Вам нужно будет загрузить новые изображения для продолжения работы. Удалить все файлы?"
  }
}
</i18n>
