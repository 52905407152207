/**
 * @group Store-modules
 * describe the store (it will not be shown in vuese yet)
 */
const state = {
    additionalTools: {
        closeOnClickOutside: true
    }
}

const getters = {
    needCloseOnClickOutside: (state) => state.additionalTools.closeOnClickOutside
}

const mutations = {
    setCloseOnClickOutside(state, value) {
        state.additionalTools.closeOnClickOutside = value
    }
}

const actions = {
    setCloseOnClickOutside(context, value) {
        context.commit('setCloseOnClickOutside', value)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
