var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-previews",class:_vm.scrollable ? 'scrollable' : null},[_c('span',{staticClass:"windows-title"},[_vm._v(_vm._s(_vm.$t("title")))]),(_vm.step === 'preview')?_c('div',{staticClass:"d-flex align-items-center pl-3 mt-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedAllImg),expression:"selectedAllImg"}],staticClass:"mycheckbox",attrs:{"id":"all","type":"checkbox"},domProps:{"value":_vm.preparedImages,"checked":Array.isArray(_vm.selectedAllImg)?_vm._i(_vm.selectedAllImg,_vm.preparedImages)>-1:(_vm.selectedAllImg)},on:{"click":function($event){return _vm.selectAll()},"change":function($event){var $$a=_vm.selectedAllImg,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.preparedImages,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedAllImg=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedAllImg=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedAllImg=$$c}}}}),_c('label',{staticClass:"mb-0 ml-2 step-text-default",attrs:{"for":"all"}},[_vm._v(_vm._s(_vm.$t("select")))])]):_vm._e(),_c('div',{staticClass:"d-flex flex-column justify-content-between height_between"},[_c('vue-custom-scrollbar',{ref:"previews-container",staticClass:"previews-container scrollable-container",class:[
        Object.keys(_vm.images).length === 2 ? 'two-files' : null,
        Object.keys(_vm.images).length === 1 ? 'one-file' : null,
      ],attrs:{"settings":_vm.scrollYSettings,"tagname":"div"}},[_c('div',{staticClass:"previews-row d-flex flex-wrap",class:{ isActiveCheckbox: _vm.isActiveCheckbox }},_vm._l((_vm.preparedImages),function({ fileId, image }){return _c('div',{key:fileId,staticClass:"preview",class:[
            _vm.isActiveImage(fileId) ? 'active' : null,
            _vm.isPreviewDisabled(fileId) ? 'disabled' : 'clickable',
          ],on:{"click":function($event){return _vm.chooseActiveImage(fileId)}}},[_c('div',{staticClass:"d-flex flex-column h-100"},[_c('b-overlay',{attrs:{"opacity":0.3,"show":_vm.isPreviewDisabled(fileId)}},[(_vm.step === 'preview')?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedFile),expression:"selectedFile"}],staticClass:"previews-radio position-absolute select-checkbox",attrs:{"id":fileId,"type":"checkbox"},domProps:{"value":fileId,"checked":Array.isArray(_vm.selectedFile)?_vm._i(_vm.selectedFile,fileId)>-1:(_vm.selectedFile)},on:{"click":function($event){return _vm.selectOne(fileId)},"change":function($event){var $$a=_vm.selectedFile,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=fileId,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedFile=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedFile=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedFile=$$c}}}}),_c('label',{staticClass:"mb-0 ml-2 step-text-default position-absolute select-checkbox-right",attrs:{"for":fileId}})]):_vm._e(),(
                  (_vm.hasWarningStatus(fileId) || _vm.hasErrorStatus(fileId)) &&
                  _vm.previews[fileId] &&
                  _vm.previews[fileId].src
                )?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.auto",value:({
                  boundary: 'viewport',
                  content: _vm.getStatusMessage(fileId),
                }),expression:"{\n                  boundary: 'viewport',\n                  content: getStatusMessage(fileId),\n                }",modifiers:{"hover":true,"auto":true}}],staticClass:"status",on:{"click":function($event){$event.stopPropagation();}}},[(_vm.hasWarningStatus(fileId))?_c('dp-icon',{attrs:{"sensitive":false,"name":"question"}}):_vm._e(),(_vm.hasErrorStatus(fileId))?_c('dp-icon',{attrs:{"sensitive":false,"name":"alert"}}):_vm._e(),_c('div',{staticClass:"circle",class:[
                    _vm.hasWarningStatus(fileId) ? 'warning' : null,
                    _vm.hasErrorStatus(fileId) ? 'error' : null,
                  ]})],1):_vm._e(),(_vm.previews[fileId] && _vm.previews[fileId].src)?_c('b-img',{attrs:{"src":_vm.previews[fileId].src,"fluid":"","height":"72","width":"72"}}):_c('b-img',{attrs:{"blank":true,"height":"72","width":"72"}})],1),_c('div',{staticClass:"mt-auto pt-1 filename",attrs:{"title":image.file_name}},[_vm._v(" "+_vm._s(image.file_name)+" ")])],1)])}),0)]),(_vm.step === 'preview')?_c('div',{staticClass:"d-flex justify-content-around pr-3"},[_c('div',{on:{"click":_vm.showCreationDialog}},[_c('dp-icon',{attrs:{"height":"35px","name":"new-item","width":"35px"}})],1),_c('div',{on:{"click":function($event){return _vm.deleteFilesIf()}}},[_c('dp-icon',{class:{ disabledBin: _vm.isDisabled },attrs:{"height":"35px","name":"trash-bin","width":"33px"}})],1)]):_vm._e(),_c('project-update',{attrs:{"active":_vm.modal.creation,"project-all":_vm.images,"project-id":_vm.project.project_id,"project-name":_vm.project.project_name},on:{"update:active":function($event){return _vm.$set(_vm.modal, "creation", $event)},"update:projectName":function($event){return _vm.$set(_vm.project, "project_name", $event)},"update:project-name":function($event){return _vm.$set(_vm.project, "project_name", $event)},"updateFiles":_vm.updateFiles}}),_c('image-notice',{attrs:{"modalNotice":_vm.modal.notice,"project-id":_vm.project.project_id,"project-name":_vm.project.project_name},on:{"update:modalNotice":function($event){return _vm.$set(_vm.modal, "notice", $event)},"update:modal-notice":function($event){return _vm.$set(_vm.modal, "notice", $event)},"update:projectName":function($event){return _vm.$set(_vm.project, "project_name", $event)},"update:project-name":function($event){return _vm.$set(_vm.project, "project_name", $event)},"deleteFiles":_vm.deleteFiles,"showCreationDialog":_vm.showCreationDialog,"toggle-button":_vm.changeActiveRadioAfterDelete}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }