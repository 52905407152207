<template>
  <b-button-group class="dp-button-group">
    <button :class="[buttonClassByType, {'dp-disabled': disabled}]" :disabled="disabled" :style="buttonDimensions"
            class="dp-button" @click.stop="click">
      <div v-if="!!$slots.icon && leftIcon" class="dp-button-icon left">
        <slot name="icon"></slot>
      </div>
      <slot></slot>
      <div v-if="!!$slots.icon && rightIcon && !leftIcon" class="dp-button-icon right">
        <slot name="icon"></slot>
      </div>
    </button>
  </b-button-group>
</template>

<script>
/**
 * @group Components-button
 * Standard components
 */
export default {
  name: 'dp-button',
  props: {
    primary: {
      type: Boolean,
      default: true
    },
    secondary: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    ghost: {
      type: Boolean,
      default: false
    },
    rightIcon: {
      type: Boolean,
      default: true
    },
    leftIcon: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: null
    },
    minWidth: {
      type: Number,
      default: null
    },
    maxHeight: {
      type: Number,
      default: null
    },
    minHeight: {
      type: Number,
      default: null
    }
  },
  computed: {
    buttonClassByType() {
      if (this.secondary) {
        return 'dp-secondary'
      } else if (this.outline) {
        return 'dp-outline'
      } else if (this.ghost) {
        return 'dp-ghost'
      }
      return 'dp-primary'
    },
    buttonDimensions() {
      return {
        maxWidth: this.maxWidth ? this.maxWidth + 'px' : '',
        minHeight: this.minHeight ? this.minHeight + 'px' : '',
        maxHeight: this.maxHeight ? this.maxHeight + 'px' : '',
        minWidth: this.minWidth ? this.minWidth + 'px' : ''
      }
    }
  },
  methods: {
    click(e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.dp-button-group {
  -webkit-appearance: none;

  .dp-button {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    border: 2px solid;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    max-height: 40px;
    box-sizing: border-box;
    justify-content: center;
    font-weight: $--font-weight-semi-bold;

    &:focus {
      outline: none;
    }

    &-icon {
      &.right {
        margin-left: 13px;
      }

      &.left {
        margin-right: 13px;
      }
    }

    &.dp-primary {
      background: $--color-primary;
      border-color: $--color-primary;
      color: $--color-base-01;

      &:not(.dp-disabled):hover {
        background: $--color-base-01;
        color: $--color-primary;
      }

      &:not(.dp-disabled):active {
        background: $--color-base-01;
        border-color: $--color-base-01;
        color: $--color-primary;
      }

      &.dp-disabled {
        background: $--color-base-02 !important;
        color: $--color-base-06 !important;
        border-color: $--color-base-02;
      }
    }

    &.dp-ghost {
      background: $--color-base-01;
      border: none;
      color: $--color-primary;

      &:not(.dp-disabled):hover {
        background: $--color-base-02;
      }

      &:not(.dp-disabled):active {
        background: $--color-base-01;
        border: 2px solid $--color-secondary;
        color: $--color-secondary;
      }

      &.dp-disabled {
        color: $--color-base-06 !important;
        border: none;
      }
    }

    &.dp-outline {
      background: $--color-base-01;
      color: $--color-primary;

      &:not(.dp-disabled):hover {
        background: $--color-primary;
        border-color: $--color-primary;
        color: $--color-base-01;
      }

      &:not(.dp-disabled):active {
        background: $--color-base-02;
        border-color: $--color-primary;
        color: $--color-primary;
      }

      &.dp-disabled {
        background: $--color-base-01 !important;
        color: $--color-base-06 !important;
        border-color: $--color-base-06;
      }
    }

    &.dp-secondary {
      background: $--color-secondary;
      border-color: $--color-secondary;
      color: $--color-base-01;

      &:not(.dp-disabled):hover {
        background: $--color-base-01;
        color: $--color-secondary;
        border-color: $--color-secondary;
      }

      &:not(.dp-disabled):active {
        background: $--color-base-01;
        border: none;
        color: $--color-secondary;
      }

      &.dp-disabled {
        background: $--color-base-07;
        color: $--color-base-01 !important;
        border-color: $--color-base-07;
      }
    }

    &.dp-disabled {
      cursor: not-allowed;
    }
  }
}
</style>

<style lang="scss">
.dp-button {
  &.dp-primary {
    .dp-button-icon > svg {
      fill: $--color-base-01 !important;
    }

    &:not(.dp-disabled):hover > .dp-button-icon > svg {
      fill: $--color-primary !important;
    }

    &:not(.dp-disabled):active > .dp-button-icon > svg {
      fill: $--color-primary !important;
    }

    &.dp-disabled > .dp-button-icon > svg {
      fill: $--color-base-06 !important;
    }
  }

  &.dp-outline {
    .dp-button-icon > svg {
      fill: $--color-primary !important;
    }

    &:not(.dp-disabled):hover > .dp-button-icon > svg {
      fill: $--color-base-01 !important;
    }

    &:not(.dp-disabled):active > .dp-button-icon > svg {
      fill: $--color-primary !important;
    }

    &.dp-disabled > .dp-button-icon > svg {
      fill: $--color-base-06 !important;
    }
  }

  &.dp-ghost {
    .dp-button-icon > svg {
      fill: $--color-primary !important;
    }

    &:not(.dp-disabled):hover > .dp-button-icon > svg {
      fill: $--color-primary !important;
    }

    &:not(.dp-disabled):active > .dp-button-icon > svg {
      fill: $--color-secondary !important;
    }

    &.dp-disabled > .dp-button-icon > svg {
      fill: $--color-base-06 !important;
    }
  }

  &.dp-secondary {
    .dp-button-icon > svg {
      fill: $--color-base-01 !important;
    }

    &:not(.dp-disabled):hover > .dp-button-icon > svg {
      fill: $--color-secondary !important;
    }

    &:not(.dp-disabled):active > .dp-button-icon > svg {
      fill: $--color-secondary !important;
    }

    &.dp-disabled > .dp-button-icon > svg {
      fill: $--color-base-01 !important;
    }
  }
}
</style>
