import Vue from 'vue'
import {BIcon, BIconBriefcase, BIconEnvelope, BIconFileEarmarkZip, BootstrapVue} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

/**
 * @vuese
 * describe the plugins (it will not be shown in vuese yet)
 */
Vue.use(BootstrapVue)
Vue.component('BIcon', BIcon)
Vue.component('BIconEnvelope', BIconEnvelope)
Vue.component('BIconBriefcase', BIconBriefcase)
Vue.component('BIconFileEarmarkZip', BIconFileEarmarkZip)
