<template>
  <div class="image-toolbar d-flex flex-column">
    <div
        v-click-outside="onClickOutsideTools"
        class="additional-tools d-flex flex-column"
    >
      <div v-if="display.editingTools" class="editing-tools tool-card">
        <div class="windows-title">{{ $t("editing-tools-title") }}</div>
        <template v-for="tool in allEditingTools">
          <div
              v-if="editingTools.includes(tool.name)"
              :key="tool.name"
              :class="active[tool.name] ? 'active' : null"
              class="tool d-flex mt-1 clickable"
              @click.stop="
              tool.instant
                ? emit(tool.name)
                : activateAndEmitEditingTool(tool.name)
            "
          >
            <dp-icon
                :name="toolIconName(tool)"
                :sensitive="!active[tool.name]"
                class="mx-2"
                height="32"
                width="32"
            >
            </dp-icon>
            <div v-html="$t(`editing-tools-labels.${tool.name}`)"></div>
          </div>
        </template>
      </div>

      <slot v-if="display.editingTools" name="external-editing-tools"></slot>

      <div v-if="cropToolsEnabled" class="crop-confirmation-tool tool-card">
        <div class="windows-title">
          {{ $t("crop-confirmation-tool-title") }}
        </div>
        <dp-button outline @click="cancelCrop">{{
            $t("actions.cancel")
          }}
        </dp-button>
        <dp-button class="ml-3" secondary @click="applyCrop">{{
            $t("actions.apply")
          }}
        </dp-button>
      </div>

      <div v-if="cropToolsEnabled" class="crop-straightening-tool tool-card">
        <div class="windows-title">
          {{ $t("crop-straightening-tool-title") }}
        </div>
        <div class="d-flex flex-row align-items-center">
          <div>
            <vue-slider
                v-model="cropRotation"
                :max="90"
                :min="-90"
                :process="false"
                :width="206"
                tooltip="none"
                @change="emitCropStraightening"
            >
            </vue-slider>
          </div>
          <div>
            <b-input v-model="cropRotation" :disabled="true"></b-input>
          </div>
        </div>
      </div>

      <div v-if="drawingToolsEnabled" class="drawing-stroke-tool tool-card">
        <div class="windows-title">
          {{ $t(`editing-tools-labels.${activeTool}`) }}
        </div>
        <div class="d-flex flex-row align-items-center">
          <div>
            <vue-slider
                v-model="drawingStrokeWidth"
                :max="500"
                :min="1"
                :width="200"
                tooltip="none"
                @change="emitDrawingStrokeWidth"
            >
            </vue-slider>
          </div>
          <div>
            <b-input
                v-model="drawingStrokeWidth"
                v-mask="'###'"
                @input="changeDrawingStrokeWidth"
            >
            </b-input>
          </div>
        </div>
      </div>

      <div
          v-if="display.editingTools && depthsEnabled"
          class="depths tool-card"
      >
        <div class="windows-title mb-3">{{ $t(`depths-title`) }}</div>
        <validation-observer ref="depths-vo" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(applyDepths)">
            <validation-provider
                v-slot="validationContext"
                name="top-depth"
                rules="required|max_value:@bottom-depth|is_not:@bottom-depth"
                vid="top-depth"
            >
              <b-form-group
                  id="top-depth-group"
                  :label="$t('depths-labels.top-depth')"
                  label-cols="6"
                  label-for="top-depth"
              >
                <b-form-input
                    id="top-depth"
                    v-model="depths.top"
                    v-mask="depths.mask"
                    :state="
                    validationContext.touched ? validationContext.valid : null
                  "
                    @change="changeTopDepth"
                >
                </b-form-input>
              </b-form-group>
            </validation-provider>
            <validation-provider
                v-slot="validationContext"
                name="bottom-depth"
                rules="required|min_value:@top-depth|is_not:@top-depth"
                vid="bottom-depth"
            >
              <b-form-group
                  id="bottom-depth-group"
                  :label="$t('depths-labels.bottom-depth')"
                  label-cols="6"
                  label-for="bottom-depth"
              >
                <b-form-input
                    id="bottom-depth"
                    v-model="depths.bottom"
                    v-mask="depths.mask"
                    :state="
                    validationContext.touched ? validationContext.valid : null
                  "
                    @change="changeBottomDepth"
                >
                </b-form-input>
              </b-form-group>
            </validation-provider>
            <dp-button class="mt-3" secondary type="submit">{{
                $t("actions.apply")
              }}
            </dp-button>
          </b-form>
        </validation-observer>
      </div>

      <div
          v-if="display.projectOptions"
          :class="[$i18n.locale.toLowerCase()]"
          class="project-options core-column-height tool-card"
      >
        <div class="windows-title">
          {{ $t(`project-options.column-height-title`) }}
        </div>
        <validation-observer
            ref="core-column-height-vo"
            v-slot="{ handleSubmit }"
        >
          <b-form inline @submit.prevent="handleSubmit(applyCoreColumnHeight)">
            <validation-provider
                v-slot="validationContext"
                :rules="`required|is_not:0.00 ${$t('metrics.m')}`"
                name="core-column-height"
            >
              <b-form-group id="core-column-height-group">
                <b-form-input
                    id="core-column-height"
                    v-model="projectOptions.coreColumnHeight"
                    v-mask="projectOptions.coreColumnHeightMask"
                    :state="
                    validationContext.touched ? validationContext.valid : null
                  "
                    @change="changeCoreColumnHeight"
                >
                </b-form-input>
              </b-form-group>
            </validation-provider>
            <dp-button class="ml-auto" secondary type="submit">{{
                $t("actions.apply")
              }}
            </dp-button>
          </b-form>
        </validation-observer>
      </div>

      <div
          v-if="display.projectOptions"
          :class="[$i18n.locale.toLowerCase()]"
          class="project-options coring-direction tool-card"
      >
        <div class="windows-title">
          {{ $t(`project-options.direction-title`) }}
        </div>
        <template v-for="cd in coringDirections">
          <div
              :key="cd.code"
              :class="
              projectOptions.coringDirection === cd.code ? 'active' : null
            "
              class="tool d-flex mt-1 clickable"
              @click.stop="applyCoringDirection(cd.code)"
          >
            <dp-icon
                :name="cd.icon"
                :sensitive="projectOptions.coringDirection !== cd.code"
                class="mx-2"
                height="32"
                width="32"
            >
            </dp-icon>
            <div
                v-html="$t(`project-options.direction-labels.${cd.code}`)"
            ></div>
          </div>
        </template>
      </div>
    </div>

    <div
        :class="toolbarEnabled ? 'clickable' : 'disabled'"
        class="tool"
        @click.stop="toolbarEnabled && activateAndEmit('hand')"
    >
      <dp-icon
          :active="!!active.hand"
          :height="tool.height"
          :sensitive="toolbarEnabled"
          :width="tool.width"
          name="hand"
      >
      </dp-icon>
    </div>
    <div
        :class="toolbarEnabled ? 'clickable' : 'disabled'"
        class="tool mt-3"
        @click.stop="toolbarEnabled && activateAndEmit('zoom-in')"
    >
      <dp-icon
          :active="!!active['zoom-in']"
          :height="tool.height"
          :sensitive="toolbarEnabled"
          :width="tool.width"
          name="zoom-in"
      >
      </dp-icon>
    </div>
    <div
        :class="toolbarEnabled ? 'clickable' : 'disabled'"
        class="tool mt-3"
        @click.stop="toolbarEnabled && activateAndEmit('zoom-out')"
    >
      <dp-icon
          :active="!!active['zoom-out']"
          :height="tool.height"
          :sensitive="toolbarEnabled"
          :width="tool.width"
          name="zoom-out"
      >
      </dp-icon>
    </div>
    <div
        :class="[
        display.editingTools ? 'active' : null,
        toolbarEnabled && editingTools.length > 0 ? 'clickable' : 'disabled',
      ]"
        class="tool mt-3"
        @click.stop="
        toolbarEnabled && editingTools.length > 0 && switchEditingTools()
      "
    >
      <dp-icon
          :active="!!active.edit"
          :height="tool.height"
          :sensitive="
          toolbarEnabled && editingTools.length > 0 && !display.editingTools
        "
          :width="tool.width"
          name="edit"
      >
      </dp-icon>
    </div>
    <div
        v-if="modelsEnabled"
        :class="[
        display.models ? 'active' : null,
        toolbarEnabled ? 'clickable' : 'disabled',
      ]"
        class="tool mt-3"
        @click.stop="toolbarEnabled && switchModels()"
    >
      <dp-icon
          :height="tool.height"
          :sensitive="toolbarEnabled && !display.models"
          :width="tool.width"
          class="mt-0"
          name="models"
      >
      </dp-icon>
    </div>
    <div
        v-b-popover.hover.auto="{
        boundary: 'viewport',
        content: $t('project-options.disabled-message'),
        disabled: projectOptionsEnabled,
      }"
        :class="[
        display.projectOptions ? 'active' : null,
        toolbarEnabled && projectOptionsEnabled
          ? 'clickable'
          : 'disabled-settings',
      ]"
        class="tool mt-3 mb-3"
        @click.stop="
        toolbarEnabled && projectOptionsEnabled && switchProjectOptions()
      "
    >
      <dp-icon
          :height="tool.height"
          :sensitive="
          toolbarEnabled && !display.projectOptions && projectOptionsEnabled
        "
          :width="tool.width"
          name="settings"
      >
      </dp-icon>
    </div>
    <div
        v-if="modelsEnabled"
        :class="toolbarEnabled && undoEnabled ? 'clickable' : 'disabled'"
        class="tool mt-auto"
        @click.stop="toolbarEnabled && undoEnabled && emit('save')"
    >
      <dp-icon
          :height="tool.height"
          :sensitive="toolbarEnabled && undoEnabled"
          :width="tool.width"
          name="save-tool"
      ></dp-icon>
    </div>
    <div
        v-if="modelsEnabled"
        :class="toolbarEnabled && undoEnabled ? 'clickable' : 'disabled'"
        class="tool mt-3"
        @click.stop="toolbarEnabled && undoEnabled && emit('undo')"
    >
      <dp-icon
          :height="tool.height"
          :sensitive="toolbarEnabled && undoEnabled"
          :width="tool.width"
          name="undo"
      ></dp-icon>
    </div>
    <div
        v-if="!modelsEnabled"
        :class="toolbarEnabled && undoEnabled ? 'clickable' : 'disabled'"
        class="tool mt-auto"
        @click.stop="toolbarEnabled && undoEnabled && emit('undo')"
    >
      <dp-icon
          :height="tool.height"
          :sensitive="toolbarEnabled && undoEnabled"
          :width="tool.width"
          name="undo"
      ></dp-icon>
    </div>
    <div
        :class="toolbarEnabled && redoEnabled ? 'clickable' : 'disabled'"
        class="tool mt-3"
        @click.stop="toolbarEnabled && redoEnabled && emit('redo')"
    >
      <dp-icon
          :height="tool.height"
          :sensitive="toolbarEnabled && redoEnabled"
          :width="tool.width"
          name="redo"
      ></dp-icon>
    </div>
  </div>
</template>

<script>
import DpButton from "@/components/button/dp-button";
import DpIcon from "@/components/icon/dp-icon";
import ClickOutside from "vue-click-outside";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import i18n from "@/plugins/i18n";

/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: "image-toolbar",
  components: {
    DpButton,
    DpIcon,
    VueSlider,
  },
  directives: {
    ClickOutside,
  },
  props: {
    editingTools: {
      type: Array,
      default: () => [],
    },
    undoEnabled: {
      type: Boolean,
      default: true,
    },
    redoEnabled: {
      type: Boolean,
      default: true,
    },
    coreColumnHeight: {
      type: Number,
    },
    coringDirection: {
      type: String,
    },
    topDepth: {
      type: Number,
      default: 0,
    },
    bottomDepth: {
      type: Number,
      default: 0,
    },
    depthsEnabled: {
      type: Boolean,
      default: false,
    },
    modelsEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: {},
      display: {
        editingTools: false,
        cropTools: false,
        projectOptions: false,
        models: false,
      },
      toolbarEnabled: false,
      allEditingTools: [
        {name: "rotate-right", instant: true},
        {name: "rotate-left", instant: true},
        {name: "reflect-v", instant: true},
        {name: "reflect-h", instant: true},
        {name: "crop", instant: false},
        {name: "pointer-depth", instant: false},
        {name: "pointer-border", instant: false},
        {name: "brush", instant: false},
        {name: "eraser", instant: false},
        {name: "split", instant: false},
        {name: "brush-lithology", instant: false},
        {name: "interval-tool", instant: false},
        {name: "eraser-lithology", instant: false},
        {name: "trash-bin", instant: false},
      ],
      cropRotation: 0,
      drawingStrokeWidth: 0,
      projectOptions: {
        coreColumnHeightMask: createNumberMask({
          prefix: "",
          suffix: ` ${i18n.t("metrics.m")}`,
          allowDecimal: true,
          requireDecimal: true,
          includeThousandsSeparator: false,
          allowNegative: false,
        }),
        coreColumnHeight: null,
        coringDirection: null,
      },
      coringDirections: [
        {code: "LR", icon: "arrow-right"},
        {code: "RL", icon: "arrow-left"},
      ],
      depths: {
        mask: createNumberMask({
          prefix: "",
          suffix: "",
          allowDecimal: true,
          requireDecimal: true,
          includeThousandsSeparator: false,
          allowNegative: false,
        }),
        top: null,
        bottom: null,
      },
      tool: {
        width: 48,
        height: 48,
      },
    };
  },
  computed: {
    cropToolsEnabled() {
      return this.active.crop;
    },
    drawingToolsEnabled() {
      return this.active.brush || this.active.eraser;
    },
    activeTool() {
      const activeTools = Object.keys(this.active);
      return activeTools ? activeTools[0] : null;
    },
    projectOptionsEnabled() {
      return !!(this.coreColumnHeight || this.coringDirection);
    },
  },
  watch: {
    coreColumnHeight(value) {
      this.projectOptions.coreColumnHeight = value ? value.toFixed(2) : null;
    },
    coringDirection(value) {
      this.projectOptions.coringDirection = value;
    },
    topDepth(value) {
      this.depths.top = value ? value.toFixed(2) : null;
    },
    bottomDepth(value) {
      this.depths.bottom = value ? value.toFixed(2) : null;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.resize();
      window.addEventListener("resize", this.resize);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      const elHeight = this.$el.clientHeight;
      if (elHeight >= 500) {
        this.tool.width = 48;
        this.tool.height = 48;
      } else {
        let size = (0.8 * 48 * elHeight) / 500;
        size = size < 24 ? 24 : size;
        this.tool.width = size;
        this.tool.height = size;
      }
    },
    enable() {
      this.toolbarEnabled = true;
    },
    disable() {
      this.toolbarEnabled = false;
    },
    setup() {
      this.display.editingTools = false;
      this.enable();
      this.activateAndEmit("hand");
    },
    toolIconName(tool) {
      if (tool.name.startsWith("pointer-")) {
        return "pointer";
      } else if (tool.name.startsWith("brush-")) {
        return "brush";
      } else if (tool.name.startsWith("eraser-")) {
        return "eraser";
      } else if (tool.name.startsWith("trash-bin-")) {
        return "trash-bin";
      } else {
        return tool.name;
      }
    },
    onClickOutsideTools() {
      if (this.$store.getters.needCloseOnClickOutside) {
        this.display.editingTools = false;
      }
    },
    switchEditingTools() {
      this.displayEditingTools(!this.display.editingTools);
      this.$emit("switch-editing-tools", this.display.editingTools);
    },
    displayEditingTools(value = true) {
      this.display.editingTools = value;
      if (value) {
        this.display.projectOptions = false;
        this.initModels(false);
      }
    },
    activateTool(tool) {
      this.active = {};
      this.active[tool] = true;
      if (this.allEditingTools.map((t) => t.name).includes(tool)) {
        this.active.edit = true;
      }
    },
    activateAndEmitEditingTool(tool) {
      this.activateTool(tool);

      if (tool === "crop") {
        this.initCropTools();
      } else if (["brush", "eraser"].includes(tool)) {
        this.initDrawingTools();
      }

      this.emit(tool);
    },
    activateAndEmit(tool) {
      this.activateTool(tool);
      this.emit(tool);
    },
    emit(event) {
      this.$emit(event);
    },
    initCropTools() {
      this.cropRotation = 0;
      this.disable();
      this.display.editingTools = false;
      this.display.projectOptions = false;
      this.initModels(false);
      this.emitCropStraightening();
    },
    applyCrop() {
      this.enable();
      this.$emit("crop-apply");
      this.setup();
    },
    cancelCrop() {
      this.enable();
      this.$emit("crop-cancel");
      this.setup();
    },
    emitCropStraightening() {
      this.$emit("crop-rotation", this.cropRotation);
    },
    initDrawingTools() {
      this.drawingStrokeWidth = 100;
      this.display.editingTools = false;
      this.emitDrawingStrokeWidth();
    },
    emitDrawingStrokeWidth() {
      this.$emit(`${this.activeTool}-stroke-width`, this.drawingStrokeWidth);
    },
    changeDrawingStrokeWidth(value) {
      if (Number(value) > 500) {
        this.drawingStrokeWidth = 500;
      } else if (Number(value) <= 0 || !Number(value)) {
        this.drawingStrokeWidth = 100;
      }
      this.emitDrawingStrokeWidth();
    },
    switchProjectOptions() {
      this.display.projectOptions = !this.display.projectOptions;
      if (this.display.projectOptions) {
        this.display.editingTools = false;
        this.initModels(false);
        this.initProjectOptions();
      }
      this.$emit("switch-project-options", this.display.projectOptions);
    },
    initProjectOptions() {
      if (this.projectOptionsEnabled) {
        this.display.projectOptions = true;
        this.projectOptions.coreColumnHeight = this.coreColumnHeight.toFixed(2);
        this.projectOptions.coringDirection = this.coringDirection;
      }
    },
    changeCoreColumnHeight(value) {
      if (value) {
        this.projectOptions.coreColumnHeight = parseFloat(value).toFixed(2);
        this.$emit("update:coreColumnHeight", parseFloat(value));
      } else {
        this.projectOptions.coreColumnHeight = null;
        this.$emit("update:coreColumnHeight", null);
      }
    },
    applyCoreColumnHeight() {
      this.$emit(
          "core-column-height-apply",
          parseFloat(this.projectOptions.coreColumnHeight)
      );
      this.$refs["core-column-height-vo"].reset();
    },
    applyCoringDirection(coringDirection) {
      this.projectOptions.coringDirection = coringDirection;
      this.$emit("coring-direction-apply", this.projectOptions.coringDirection);
    },
    changeTopDepth(value) {
      if (value) {
        this.depths.top = parseFloat(value).toFixed(2);
      } else {
        this.depths.top = null;
      }
    },
    changeBottomDepth(value) {
      if (value) {
        this.depths.bottom = parseFloat(value).toFixed(2);
      } else {
        this.depths.bottom = null;
      }
    },
    applyDepths() {
      if (
          this.depths.top === this.topDepth &&
          this.depths.bottom === this.bottomDepth
      ) {
        this.$refs["depths-vo"].reset();
        return;
      }
      this.$emit(
          "depths-apply",
          parseFloat(this.depths.top),
          parseFloat(this.depths.bottom)
      );
      this.$refs["depths-vo"].reset();
    },
    switchModels() {
      this.display.models = !this.display.models;
      this.initModels(this.display.models);
    },
    initModels(display = true) {
      this.display.models = display && this.modelsEnabled;
      if (this.display.models) {
        this.display.editingTools = false;
        this.display.projectOptions = false;
      }
      this.$emit("display-models", this.display.models);
    },
  },
};
</script>

<style lang="scss">
.image-toolbar {
  height: calc(100vh - 170px);
  padding: 16px 8px;
  width: 64px;
  background: $--color-base-01;
  box-shadow: -5px 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .tool {
    margin-left: auto;
    margin-right: auto;

    &.active {
      background: $--color-secondary;
      border-radius: 8px;

      svg {
        fill: $--color-base-01 !important;
      }
    }

    &.disabled,
    &.disabled-settings {
      cursor: not-allowed;

      svg {
        fill: $--color-base-07 !important;
      }

      svg[active="true"] {
        fill: $--color-secondary !important;
      }
    }
  }

  .additional-tools {
    position: absolute;
    right: 80px;
    margin-top: -16px;
    width: 304px;

    .tool-card {
      background: $--color-base-01;
      box-shadow: -5px 0 20px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 8px;

      .windows-title {
        margin-bottom: 12px;
      }
    }

    .crop-straightening-tool,
    .drawing-stroke-tool {
      height: 115px;

      .vue-slider-rail {
        border-radius: 10px;
        background-color: $--color-base-02;
      }

      .vue-slider-dot-handle {
        width: 12px;
        height: 12px;
        background-color: $--color-secondary;
        box-shadow: none;
      }

      .vue-slider-process {
        background-color: $--color-secondary;
      }

      input.form-control {
        margin-left: 12px;
        height: 40px;
        padding: 8px 12px;
        border: 2px solid $--color-base-02;
        box-sizing: border-box;
        border-radius: 4px;
        color: $--color-primary;
        font-size: $--font-size-base;
        font-weight: $--font-weight-medium;
        line-height: 20px !important;

        &:focus {
          box-shadow: none;
          border: 2px solid $--color-secondary;
        }

        &:disabled {
          background-color: $--color-base-03;
          color: $--color-base-06;
        }
      }
    }

    .crop-straightening-tool {
      input.form-control {
        width: 54px;
      }
    }

    .drawing-stroke-tool {
      input.form-control {
        width: 60px;
      }
    }

    .project-options.core-column-height {
      input.form-control {
        width: 178px;
      }
    }

    .project-options.core-column-height.ru {
      input.form-control {
        width: 127px;
      }
    }

    input::-ms-clear {
      display: none;
    }

    .tool {
      font-weight: $--font-weight-medium;
      font-size: $--font-size-base;
      line-height: 18px;
      height: 40px;
      align-items: center;

      &.active,
      &:active {
        background-color: $--color-base-01;
        border: 2px solid $--color-secondary;
        box-sizing: border-box;
        border-radius: 8px;
        color: $--color-secondary;

        svg {
          fill: $--color-secondary !important;
        }
      }

      &:hover {
        background-color: $--color-base-02;
        border: 2px solid $--color-base-02;
        box-sizing: border-box;
        border-radius: 8px;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "editing-tools-title": "Editing tools",
    "editing-tools-labels": {
      "rotate-right": "Rotate clockwise",
      "rotate-left": "Rotate counter-clockwise",
      "reflect-v": "Reflect vertically",
      "reflect-h": "Reflect horizontally",
      "crop": "Crop and straighten",
      "pointer-depth": "Edit depth",
      "pointer-border": "Select or edit border",
      "brush": "Brush",
      "eraser": "Eraser",
      "split": "Split",
      "brush-lithology": "Brush",
      "eraser-lithology": "Eraser",
      "interval-tool": "Interval tool",
      "trash-bin": "Delete"
    },
    "crop-confirmation-tool-title": "Crop",
    "crop-straightening-tool-title": "Straightening",
    "project-options": {
      "column-height-title": "Core length",
      "direction-title": "Core layout direction",
      "direction-labels": {
        "LR": "Left to right",
        "RL": "Right to left"
      },
      "disabled-message": "It is impossible to edit the project settings in this step. Please, go back to the \"Preview\" step"
    },
    "depths-title": "Depths",
    "depths-labels": {
      "top-depth": "Top depth",
      "bottom-depth": "Bottom depth"
    },
    "actions": {
      "cancel": "Cancel",
      "apply": "Apply"
    }
  },
  "ru": {
    "editing-tools-title": "Инструменты",
    "editing-tools-labels": {
      "rotate-right": "Повернуть по часовой<br>стрелке",
      "rotate-left": "Повернуть против<br>часовой стрелки",
      "reflect-v": "Отразить вертикально",
      "reflect-h": "Отразить горизонтально",
      "crop": "Обрезать и выровнять",
      "pointer-depth": "Изменить глубину",
      "pointer-border": "Выбрать или<br>изменить границы",
      "brush": "Кисть",
      "eraser": "Ластик",
      "split": "Разделить",
      "brush-lithology": "Кисть",
      "eraser-lithology": "Ластик",
      "interval-tool": "Закрасить интервал",
      "trash-bin": "Удалить"
    },
    "crop-confirmation-tool-title": "Обрезка",
    "crop-straightening-tool-title": "Выравнивание",
    "project-options": {
      "column-height-title": "Длина столбца керна",
      "direction-title": "Направление отбора керна",
      "direction-labels": {
        "LR": "Слева направо",
        "RL": "Справа налево"
      },
      "disabled-message": "Невозможно отредактировать настройки проекта на этом шаге. Пожалуйста, вернитесь на шаг \"Просмотр\""
    },
    "depths-title": "Глубины",
    "depths-labels": {
      "top-depth": "Верхняя глуб.",
      "bottom-depth": "Нижняя глуб."
    },
    "actions": {
      "cancel": "Отмена",
      "apply": "Применить"
    }
  }
}
</i18n>
