<template>
  <div :class="scrollable ? 'scrollable' : null" class="image-previews">
    <span class="windows-title">{{ $t("title") }}</span>
    <div v-if="step === 'preview'" class="d-flex align-items-center pl-3 mt-1">
      <input
          id="all"
          v-model="selectedAllImg"
          :value="preparedImages"
          class="mycheckbox"
          type="checkbox"
          @click="selectAll()"
      />
      <label class="mb-0 ml-2 step-text-default" for="all">{{
          $t("select")
        }}</label>
    </div>

    <!-- <div
      v-for="option in Object.keys(selectOptions)"
      :key="option"
      class="d-flex align-items-center pl-3"
    >
      <dp-radio :value.sync="selectOptions[option].checked" class=""></dp-radio>
      <div
        class="option-title clickable pl-2 step-text-default"
        @click="selectOptions[option].checked = !selectOptions[option].checked"
      >
        Select all
      </div>
    </div> -->
    <div class="d-flex flex-column justify-content-between height_between">
      <vue-custom-scrollbar
          ref="previews-container"
          :class="[
          Object.keys(images).length === 2 ? 'two-files' : null,
          Object.keys(images).length === 1 ? 'one-file' : null,
        ]"
          :settings="scrollYSettings"
          class="previews-container scrollable-container"
          tagname="div"
      >
        <div
            class="previews-row d-flex flex-wrap"
            v-bind:class="{ isActiveCheckbox: isActiveCheckbox }"
        >
          <div
              v-for="{ fileId, image } in preparedImages"
              :key="fileId"
              :class="[
              isActiveImage(fileId) ? 'active' : null,
              isPreviewDisabled(fileId) ? 'disabled' : 'clickable',
            ]"
              class="preview"
              @click="chooseActiveImage(fileId)"
          >
            <div class="d-flex flex-column h-100">
              <b-overlay :opacity="0.3" :show="isPreviewDisabled(fileId)">
                <div v-if="step === 'preview'">
                  <input
                      :id="fileId"
                      v-model="selectedFile"
                      :value="fileId"
                      class="previews-radio position-absolute select-checkbox"
                      type="checkbox"
                      @click="selectOne(fileId)"
                  />
                  <label
                      :for="fileId"
                      class="mb-0 ml-2 step-text-default position-absolute select-checkbox-right"
                  ></label>
                </div>
                <!-- <div
                  v-for="option in Object.keys(selectOptions)"
                  :key="option"
                  class="position-absolute all_radio"
                > -->
                <!-- <dp-radio
                  :value.sync="selectOptions.checked"
                  class="previews-radio position-absolute all_radio"
                ></dp-radio> -->
                <!-- </div> -->
                <div
                    v-if="
                    (hasWarningStatus(fileId) || hasErrorStatus(fileId)) &&
                    previews[fileId] &&
                    previews[fileId].src
                  "
                    v-b-popover.hover.auto="{
                    boundary: 'viewport',
                    content: getStatusMessage(fileId),
                  }"
                    class="status"
                    @click.stop=""
                >
                  <dp-icon
                      v-if="hasWarningStatus(fileId)"
                      :sensitive="false"
                      name="question"
                  ></dp-icon>
                  <dp-icon
                      v-if="hasErrorStatus(fileId)"
                      :sensitive="false"
                      name="alert"
                  ></dp-icon>
                  <div
                      :class="[
                      hasWarningStatus(fileId) ? 'warning' : null,
                      hasErrorStatus(fileId) ? 'error' : null,
                    ]"
                      class="circle"
                  ></div>
                </div>
                <b-img
                    v-if="previews[fileId] && previews[fileId].src"
                    :src="previews[fileId].src"
                    fluid
                    height="72"
                    width="72"
                >
                </b-img>
                <b-img v-else :blank="true" height="72" width="72"></b-img>
              </b-overlay>
              <div :title="image.file_name" class="mt-auto pt-1 filename">
                {{ image.file_name }}
              </div>
            </div>
          </div>
        </div>
      </vue-custom-scrollbar>
      <div v-if="step === 'preview'" class="d-flex justify-content-around pr-3">
        <div @click="showCreationDialog">
          <dp-icon height="35px" name="new-item" width="35px"></dp-icon>
        </div>
        <div @click="deleteFilesIf()">
          <dp-icon
              height="35px"
              name="trash-bin"
              v-bind:class="{ disabledBin: isDisabled }"
              width="33px"
          ></dp-icon>
        </div>
      </div>
      <project-update
          :active.sync="modal.creation"
          :project-all="images"
          :project-id="project.project_id"
          :project-name.sync="project.project_name"
          v-on:updateFiles="updateFiles"
      >
      </project-update>
      <image-notice
          :modalNotice.sync="modal.notice"
          :project-id="project.project_id"
          :project-name.sync="project.project_name"
          v-on:deleteFiles="deleteFiles"
          v-on:showCreationDialog="showCreationDialog"
          @toggle-button="changeActiveRadioAfterDelete"
      >
      </image-notice>
    </div>
  </div>
</template>

<script>
import VueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import FileService from "@/services/file-service";
import ProjectService from "@/services/project-service.js";
import DpIcon from "@/components/icon/dp-icon";
import ScrollMixin from "@/mixins/scroll-mixin";
import ProjectUpdate from "@/views/components/project-update";
import ImageNotice from "@/views/components/image-notice";

/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: "image-previews",
  components: {DpIcon, VueCustomScrollbar, ProjectUpdate, ImageNotice},
  mixins: [ScrollMixin],
  props: {

    /**
     * @vuese
     * ..
     */
    images: {
      type: Object,
      default: () => {
        return {};
      },
    },
    /**
     * @vuese
     * ..
     */
    activeImage: {
      type: Object,
      default: () => {
        return {};
      },
    },
    /**
     * @vuese
     * ..
     */
    sortByDepth: {
      type: Boolean,
      default: false,
    },
    /**
     * @vuese
     * ..
     */
    steps: {
      type: String,
    },
  },
  data() {
    return {
      /**
       * @vuese
       * ..
       */
      previews: {},
      /**
       * @vuese
       * ..
       */
      scrollable: false,
      /**
       * @vuese
       * ..
       */
      selectOptions: {
        cores: {
          checked: false,
        },
      },
      /**
       * @vuese
       * ..
       */
      modal: {
        creation: false,
        notice: false,
      },
      /**
       * @vuese
       * ..
       */
      project: {},
      /**
       * @vuese
       * ..
       */
      selectedFile: [],
      /**
       * @vuese
       * ..
       */
      arraySelectedFiles: [],
      /**
       * @vuese
       * ..
       */
      selectedAllImg: [],
      /**
       * @vuese
       * ..
       */
      arrayAll: [],
      /**
       * @vuese
       * ..
       */
      flag: false,
      /**
       * @vuese
       * ..
       */
      test: false,
      /**
       * @vuese
       * ..
       */
      isDisabled: true,
      /**
       * @vuese
       * ..
       */
      isActiveCheckbox: false,
      /**
       * @vuese
       * ..
       */
      count: 0,
      /**
       * @vuese
       * ..
       */
      step: this.steps,
    };
  },
  computed: {
    /**
     * @vuese
     * ..
     */
    imagesLength() {
      return Object.keys(this.images).length;
    },
    /**
     * @vuese
     * ..
     */
    preparedImages() {
      const images = Object.keys(this.images).map((fileId) => {
        return {
          fileId: fileId,
          image: this.images[fileId],
        };
      });
      if (this.sortByDepth) {
        return images.sort((i1, i2) => i1.image.top_depth - i2.image.top_depth);
      } else {
        return images;
      }
    },
  },
  watch: {
    /**
     * @vuese
     * ..
     */
    images(newValue, oldValue) {
      const removedFileIds = Object.keys(oldValue).filter(
          (fileId) => !Object.keys(newValue).includes(fileId)
      );
      const addedFileIds = Object.keys(newValue).filter(
          (fileId) => !Object.keys(oldValue).includes(fileId)
      );

      this.destroyPreviews(removedFileIds);
      this.loadPreviews(addedFileIds);
      this.activateFirstImage();
      this.$nextTick(() => {
        this.detectScrollable();
      });
    },
    deep: true,
  },
  /**
   * @vuese
   * ..
   */
  mounted() {
    this.loadProject();
    window.addEventListener("resize", this.detectScrollable);
  },
  /**
   * @vuese
   * ..
   */
  destroyed() {
    this.destroyPreviews();
    window.removeEventListener("resize", this.detectScrollable);
  },
  methods: {
    /**
     * @vuese
     * ..
     */
    loadProject() {
      ProjectService.project(this.$route.params.projectId).then((result) => {
        this.project = result.data;
      });
    },
    /**
     * @vuese
     * ..
     */
    loadPreviews(fileIds = null) {
      Object.keys(this.images).forEach((fileId) => {
        if (!fileIds || fileIds.includes(fileId)) {
          this.loadPreview(fileId);
        }
      });
    },
    /**
     * @vuese
     * ..
     */
    loadPreview(fileId) {
      this.$set(this.previews, fileId, {src: null, loading: true});
      if (this.images[fileId].wait_file) {
        return Promise.resolve(true);
      } else {
        return FileService.filePreview(fileId).then((preview) => {
          this.$set(
              this.previews[fileId],
              "src",
              URL.createObjectURL(preview.data)
          );
          this.$set(this.previews[fileId], "loading", false);
          return true;
        });
      }
    },
    /**
     * @vuese
     * ..
     */
    startPreviewLoading(fileId) {
      this.previews[fileId].loading = true;
    },
    /**
     * @vuese
     * ..
     */
    endPreviewLoading(fileId) {
      this.previews[fileId].loading = false;
    },
    /**
     * @vuese
     * ..
     */
    destroyPreviews(fileIds = null) {
      Object.keys(this.previews).forEach((fileId) => {
        if (
            (!fileIds || fileIds.includes(fileId)) &&
            this.previews[fileId].src
        ) {
          URL.revokeObjectURL(this.previews[fileId].src);
        }
      });
    },
    /**
     * @vuese
     * ..
     */
    activateFirstImage() {
      if (this.activeImage && this.activeImage.file_id) {
        return;
      }
      const fileId = Object.keys(this.images).find(
          (fileId) => !this.images[fileId].wait_file
      );
      if (fileId) {
        this.emitActiveImage(this.images[fileId]);
      }
    },
    /**
     * @vuese
     * ..
     */
    isActiveImage(fileId) {
      return fileId === this.activeImage.file_id;
    },
    /**
     * @vuese
     * ..
     */
    isPreviewDisabled(fileId) {
      return !this.previews[fileId] || this.previews[fileId].loading;
    },
    /**
     * @vuese
     * ..
     */
    chooseActiveImage(fileId) {
      if (this.previews[fileId].loading) {
        return;
      }
      this.emitActiveImage(this.images[fileId]);
    },
    /**
     * @vuese
     * ..
     */
    emitActiveImage(image) {
      this.$emit("update:activeImage", image);
    },
    /**
     * @vuese
     * ..
     */
    detectScrollable() {
      const container = this.$refs["previews-container"].$el;
      this.scrollable = container.scrollHeight > container.clientHeight;
    },
    /**
     * @vuese
     * ..
     */
    hasSuccessStatus(fileId) {
      return this.images[fileId].bounding_box_status === 1;
    },
    /**
     * @vuese
     * ..
     */
    hasWarningStatus(fileId) {
      return this.images[fileId].bounding_box_status === 0;
    },
    /**
     * @vuese
     * ..
     */
    hasErrorStatus(fileId) {
      return this.images[fileId].bounding_box_status === -1;
    },
    /**
     * @vuese
     * ..
     */
    getStatusMessage(fileId) {
      if (this.hasErrorStatus(fileId)) {
        return this.$t("messages.error");
      } else if (this.hasWarningStatus(fileId)) {
        return this.$t("messages.warning");
      } else {
        return "";
      }
    },
    /**
     * @vuese
     * ..
     */
    showCreationDialog() {
      this.modal.creation = true;
    },
    /**
     * @vuese
     * ..
     */
    selectAll() {
      let countAll = Object.keys(this.previews).length;
      this.selectedAllImg = !this.flag;
      if (this.arraySelectedFiles.length === 0) {
        if (this.selectedAllImg === true) {
          this.preparedImages.forEach((fileId) => {
            this.arraySelectedFiles.push(fileId.fileId);
          });
          this.selectedFile = this.arraySelectedFiles;
          this.flag = false;
          this.isDisabled = false;
        } else {
          this.arraySelectedFiles = [];
          this.selectedFile = [];
          this.flag = false;
          this.isDisabled = true;
        }
        if (this.test === true) {
          this.arraySelectedFiles = [];
          this.preparedImages.forEach((fileId) => {
            this.arraySelectedFiles.push(fileId.fileId);
          });
          this.selectedFile = this.arraySelectedFiles;
          this.flag = false;
          this.test = false;
          this.isDisabled = false;
        }
      } else {
        if (this.selectedAllImg === false) {
          if (this.arraySelectedFiles.length === countAll) {
            this.arraySelectedFiles = [];
            this.selectedFile = [];
            this.flag = false;
            this.isDisabled = true;
            this.isActiveCheckbox = false;
          } else {
            this.arraySelectedFiles = [];
            this.preparedImages.forEach((fileId) => {
              this.arraySelectedFiles.push(fileId.fileId);
            });
            this.selectedFile = this.arraySelectedFiles;
            this.flag = false;
            this.test = false;
            this.isDisabled = false;
          }
        } else {
          if (this.test === true) {
            this.arraySelectedFiles = [];
            this.preparedImages.forEach((fileId) => {
              this.arraySelectedFiles.push(fileId.fileId);
            });
            this.selectedFile = this.arraySelectedFiles;
            this.flag = false;
            this.test = false;
            this.isDisabled = false;
          } else {
            this.arraySelectedFiles = [];
            this.selectedFile = [];
            this.flag = false;
            this.isDisabled = true;
            this.isActiveCheckbox = false;
          }
        }
      }
    },
    /**
     * @vuese
     * ..
     */
    changeActiveRadioAfterDelete() {
      this.selectedAllImg = !this.selectedAllImg;
      // this.flag = !this.flag;
      this.arraySelectedFiles.length = 0;
      this.previews = [];
    },
    /**
     * @vuese
     * ..
     */
    selectOne(fileId) {
      let countAll = Object.keys(this.previews).length;
      if (fileId) {
        if (this.arraySelectedFiles.includes(String(fileId))) {
          let i;
          i = this.arraySelectedFiles.indexOf(String(fileId));
          if (i >= 0) {
            this.arraySelectedFiles.splice(i, 1);
          }
          if (this.arraySelectedFiles.length === 0) {
            this.isDisabled = true;
            this.isActiveCheckbox = false;
          } else {
            this.isDisabled = false;
          }
          this.selectedAllImg = false;
          this.test = true;
        } else {
          this.arraySelectedFiles.push(String(fileId));
          this.flag = true;
          this.isDisabled = false;
          this.isActiveCheckbox = true;
          if (this.arraySelectedFiles.length === countAll) {
            this.selectedAllImg = true;
          }
        }
      }
    },
    /**
     * @vuese
     * ..
     */
    updateFiles() {
      this.$emit("loadImages");
      this.selectedAllImg = false;
      this.test = true;
      this.isActiveCheckbox = true;
      if (this.arraySelectedFiles.length === 0) {
        this.isDisabled = true;
        this.isActiveCheckbox = false;
      }
    },
    /**
     * @vuese
     * ..
     */
    deleteFiles() {
      // let fileId = "";
      let projectId = this.project.project_id;
      return ProjectService.deleteFile(projectId, this.arraySelectedFiles).then(
          (result) => {
            this.updateFiles();
            this.activeImage.file_id = "";
            return result.data;
          }
      );
    },
    /**
     * @vuese
     * ..
     */
    deleteFilesIf() {
      let countAll = Object.keys(this.previews).length;
      if (
          countAll === this.arraySelectedFiles.length ||
          this.count === this.arraySelectedFiles.length
      ) {
        this.modal.notice = true;
        countAll = 0;
      } else {
        this.deleteFiles();
      }
    },
  },
};
</script>

<style lang="scss">
.previews-radio .checked {
  width: 13px !important;
  height: 13px !important;
  background-color: white !important;
  background-image: url("../../assets/img/selected.svg");
  background-repeat: no-repeat;
  background-position: center;
}
</style>

<style lang="scss" scoped>
@include media-breakpoint-only(xs) {
  .image-previews {
    max-width: 167px;

    &:not(.scrollable) {
      .previews-container {
        width: 104px;
      }
    }

    &.scrollable {
      .previews-container {
        min-width: 108px;
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .image-previews {
    max-width: 200px;

    &:not(.scrollable) {
      .previews-container {
        width: 188px;

        &.one-file {
          width: 104px;
        }
      }
    }

    &.scrollable {
      .previews-container {
        width: 192px;

        &.one-file {
          min-width: 108px;
          width: 100%;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .image-previews {
    max-width: 284px;

    &:not(.scrollable) {
      .previews-container {
        width: 272px;

        &.one-file {
          width: 104px;
        }

        &.two-files {
          width: 188px;
        }
      }
    }

    &.scrollable {
      .previews-container {
        width: 276px;

        &.one-file {
          min-width: 108px;
          width: 100%;
        }

        &.two-files {
          width: 192px;
        }
      }
    }
  }
}

.all_radio {
  z-index: 1;
  right: 7px;
  top: 7px;
}

.previews-radio {
  background: white;
  border: 1px solid #000000;

  &:hover {
    border: 1px solid $--color-secondary;
  }
}

.height_between {
  height: calc(100% - 53px);
}

.image-previews {
  padding: 16px 0;
  height: calc(100vh - 170px);
  background: $--color-base-01;
  box-shadow: 5px 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  &:not(.scrollable) {
    .windows-title {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &.scrollable {
    padding-right: 8px;

    .windows-title {
      padding-left: 16px;
      padding-right: 8px;
    }
  }

  .previews-container {
    padding: 0 0 0 16px;
    margin-top: 12px;
    height: calc(100% - 60px);

    .previews-row {
      margin: 0 0 0 -6px;
    }

    .preview {
      cursor: pointer;
      padding: 0 6px 12px;
      width: 84px;

      .status {
        position: absolute;
        right: 0;
        margin: 2px;
        width: 24px;
        height: 24px;

        svg {
          position: absolute;
          fill: $--color-base-01;
          z-index: 1;
          margin-top: 0;
        }

        .circle {
          position: absolute;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          margin: 2px;
          z-index: 0;

          &.error {
            background-color: $--color-red-300;
          }

          &.warning {
            background-color: $--color-yellow-300;
          }
        }
      }

      img {
        width: min-content;
      }

      .filename {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: $--font-size-extra-small !important;
        line-height: 16px;
        letter-spacing: -0.5px;
        max-width: 72px;
      }
    }

    .preview:not(.active) {
      .filename {
        color: $--color-primary;
        font-weight: $--font-weight-regular !important;
      }
    }

    .preview.active {
      .filename {
        color: $--color-secondary;
        font-weight: $--font-weight-semi-bold !important;
      }
    }

    .preview.disabled {
      cursor: not-allowed;
    }

    .preview:hover {
      .select-checkbox-right {
        visibility: visible;
      }
    }
  }

  .mycheckbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .mycheckbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    margin-left: 0px !important;
  }

  .mycheckbox + label::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 8px !important;
    // background-size: 1px !important;
    border: 1px solid $--color-secondary !important;
    box-sizing: border-box;
    border-radius: 50% !important;
    margin-right: 8px;
  }

  .mycheckbox:checked + label::before {
    background-image: url("../../assets/img/forCheckbox.svg");
  }

  .mycheckbox:not(:disabled):not(:checked) + label:hover::before {
    cursor: pointer;
  }

  .mycheckbox:not(:disabled):checked + label:hover::before {
    cursor: pointer;
  }

  .select-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .select-checkbox + label::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 8px !important;
    background-color: #ffffff;
    // background-size: 1px !important;
    border: 1px solid #000000 !important;
    box-sizing: border-box;
    border-radius: 50% !important;
    margin-right: 7px;
    margin-top: 6px;
  }

  .select-checkbox:checked + label::before {
    background-image: url("../../assets/img/selected.svg");
    visibility: visible;
  }

  .select-checkbox:not(:disabled):not(:checked) + label:hover::before {
    cursor: pointer;
    border: 1px solid $--color-secondary !important;
  }

  .select-checkbox:not(:disabled):checked + label:hover::before {
    cursor: pointer;
    border: 1px solid $--color-secondary !important;
  }

  .select-checkbox-right {
    visibility: collapse;
    right: 0;
  }

  .disabledBin {
    fill: $--color-base-04;
  }

  .isActiveCheckbox .select-checkbox + label {
    visibility: visible;
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Images",
    "messages": {
      "warning": "Attention, these results may contain errors",
      "error": "The core was not found on an image or it is oriented horizontally. Please, check the results and the image"
    },
    "select": "Select all"
  },
  "ru": {
    "title": "Изображения",
    "messages": {
      "warning": "Внимание, в результатах анализа изображения могут быть ошибки",
      "error": "Керн на изображении не найден или расположен горизонтально, проверьте изображение и результат"
    },
    "select": "Выбрать все"
  }
}
</i18n>
