<template>
  <b-modal
      v-model="display"
      :hide-header-close="actionsDisabled"
      :title="$t('title')"
      centered
      modal-class="lithology-download-modal"
      no-close-on-backdrop
      title-class="windows-title"
      @ok.prevent="download"
  >
    <template v-slot:modal-header-close>
      <dp-icon name="close"></dp-icon>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <dp-button :disabled="actionsDisabled" ghost @click="cancel">{{
          $t("cancel")
        }}
      </dp-button>
      <dp-button
          :disabled="actionsDisabled || selectedOptions.length === 0"
          secondary
          @click="ok"
      >
        {{ $t("download") }}
      </dp-button>
    </template>
    <template v-slot:default>
      <div class="d-flex justify-content-between">
        <div>
          <div class="subtitle">{{ $t("subtitle") }}</div>
          <div class="options">
            <div
                v-for="option in Object.keys(downloadOptions)"
                :key="option"
                class="option"
            >
              <dp-checkbox
                  :value.sync="downloadOptions[option]"
                  class="option-checkbox"
              >
              </dp-checkbox>
              <div
                  class="option-title clickable"
                  @click="downloadOptions[option] = !downloadOptions[option]"
              >
                {{ $t(`options.${option}`) }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="subtitle">{{ $t(`template`) }}</div>
          <b-dropdown
              class="model-dropdown"
              no-caret
              @shown="updateModelItemsScrollbar"
          >
            <template v-slot:button-content>
              <div class="d-flex flex-row pr-3 pl-3">
                <div :title="selectedFormat" class="model-name text-truncate">
                  {{ selectedFormat.join() }}
                </div>
                <div class="ml-auto mt-0">
                  <dp-icon
                      :sensitive="false"
                      height="24"
                      name="chevron-down"
                      width="24"
                  >
                  </dp-icon>
                </div>
              </div>
            </template>
            <vue-custom-scrollbar
                ref="model-items-scrollbar"
                :settings="scrollYSettings"
                class="dropdown-model-items scrollable-container"
                tagname="div"
            >
              <b-form-group class="mb-0">
                <template v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                      id="templateOptions"
                      v-model="selected"
                      :aria-describedby="ariaDescribedby"
                      :options="newTemplateOptions"
                      aria-label="Individual templateOptions"
                      class="mb-0 dp-checkbox-form"
                      name="templateOptions"
                      stacked
                      text-field="captions"
                      value-field="id"
                  ></b-form-checkbox-group>
                </template>
              </b-form-group>
            </vue-custom-scrollbar>
          </b-dropdown>
          <!-- <div
                  class="ml-1 mt-0"
                  v-b-popover.hover.bottom="{
                    boundary: 'viewport',
                    content: getModelDescription(selectedModel),
                  }"
                >
                  <dp-icon
                    name="info"
                    width="24"
                    height="24"
                    @click.native.stop=""
                  >
                  </dp-icon>
                </div> -->
        </div>
      </div>
      <b-overlay :opacity="0.3" :show="actionsDisabled" no-wrap></b-overlay>
    </template>
  </b-modal>
</template>

<script>
import VueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import DpButton from "@/components/button/dp-button";
import DpIcon from "@/components/icon/dp-icon";
import DpCheckbox from "@/components/dp-checkbox";
import ScrollMixin from "@/mixins/scroll-mixin";
import ProjectService from "@/services/project-service";

/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: "lithology-download",
  components: {DpCheckbox, DpButton, DpIcon, VueCustomScrollbar},
  mixins: [ScrollMixin],
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actionsDisabled: false,
      downloadOptions: {
        generation: true,
        registry: true,
        legend: true,
        column: false,
      },
      templateOptions: [{id: "", captions: ""}],
      newTemplateOptions: [],
      selected: [],
      allSelected: false,
      indeterminate: false,
      selectedFormat: [],
      len: 1,
    };
  },
  computed: {
    display: {
      get() {
        return this.active;
      },
      set(newValue) {
        this.$emit("update:active", newValue);
      },
    },
    selectedOptions() {
      return Object.keys(this.downloadOptions).filter(
          (o) => this.downloadOptions[o]
      );
    },
  },
  watch: {
    "$i18n.locale": {
      handler() {
        this.selected = [];
        this.selectedFormat = [];
        this.templateOptions = [];
        this.newTemplateOptions = [];
        ProjectService.getClassificationTemplates().then((result) => {
          this.templateOptions = result.data.classification_report_templates;
          let obj;
          this.templateOptions.filter((i) => {
            obj = {
              id: i.id,
              captions: i.captions[this.$i18n.locale.toUpperCase()],
            };
            this.newTemplateOptions.push(obj);
            if (i.default === true) {
              this.selected.push(i.id),
                  this.selectedFormat.push(
                      i.captions[this.$i18n.locale.toUpperCase()]
                  );
            }
          });
        });
      },
    },
    selected() {
      //console.log("len", this.selected.length);
      if (this.selected.length > this.len) {
        this.len = this.selected.length;
        this.selected.filter((a) => {
          //console.log("a", a);
          this.templateOptions.filter((b) => {
            //console.log("b", b);
            if (a === b.id) {
              //console.log("selectedFormat", this.selectedFormat);
              if (
                  this.selectedFormat.includes(
                      b.captions[this.$i18n.locale.toUpperCase()]
                  )
              ) {
                //console.log("this.selectedFormat))", this.selectedFormat);
              } else {
                this.selectedFormat.push(
                    b.captions[this.$i18n.locale.toUpperCase()]
                );
              }
            }
          });
          //console.log("this.selectedFormat", this.selectedFormat);
        });
      }
      if (this.selected.length < this.len) {
        (this.selectedFormat = []), (this.len = this.selected.length);
        this.selected.filter((a) => {
          //console.log("a", a);
          this.templateOptions.filter((b) => {
            //console.log("b", b);
            if (a === b.id) {
              if (
                  this.selectedFormat.includes(
                      b.captions[this.$i18n.locale.toUpperCase()]
                  )
              ) {
                //console.log("this.selectedFormat))", this.selectedFormat);
              } else {
                this.selectedFormat.push(
                    b.captions[this.$i18n.locale.toUpperCase()]
                );
              }
            }
          });
          //console.log("this.selectedFormat", this.selectedFormat);
        });
      }
    },
    active(value) {
      this.actionsDisabled = false;
      if (value) {
        this.downloadOptions.generation = true;
        this.downloadOptions.legend = true;
        this.downloadOptions.registry = true;
        this.downloadOptions.column = false;
      }
    },
    // this one should be implemented so when user choose one
    // of the generation or column option - another will be disabled
    // it is ok, when both are disabled
    // "downloadOptions.column.checked"(value) {
    //   if (!value) {
    //     this.downloadOptions.generation = !value;
    //   }
    // },
    // "downloadOptions.generation.checked"(value) {
    //   if (!value) {
    //     this.downloadOptions.column = !value;
    //   }
    // },
  },
  methods: {
    download() {
      this.actionsDisabled = true;
      const column = this.downloadOptions.column;
      const legend = this.downloadOptions.legend;
      const registry = this.downloadOptions.registry;
      const generation = this.downloadOptions.generation;
      const templates = this.selected;
      // added timeout to show loader immediate, $nextTick does not work, may be because of heavy image saving
      setTimeout(() => {
        this.$emit("download-lithology", column, legend, registry, templates, generation);
      }, 500);
    },
    updateModelItemsScrollbar() {
      this.$nextTick(() => {
        this.$refs["model-items-scrollbar"].update();
      });
    },
  },
  mounted() {
    ProjectService.getClassificationTemplates().then((result) => {
      this.templateOptions = result.data.classification_report_templates;
      let obj;
      this.templateOptions.filter((i) => {
        obj = {
          id: i.id,
          captions: i.captions[this.$i18n.locale.toUpperCase()],
        };
        this.newTemplateOptions.push(obj);
        if (i.default === true) {
          this.selected.push(i.id),
              this.selectedFormat.push(
                  i.captions[this.$i18n.locale.toUpperCase()]
              );
        }
      });
    });
  },
};
</script>

<style lang="scss">
.lithology-download-modal {
  .modal-dialog {
    max-width: 598px;
    max-height: 370px;

    .modal-body {
      padding: 20px 0 85px !important;
    }
  }

  .subtitle {
    font-weight: $--font-weight-semi-bold;
    font-size: $--font-size-base;
    line-height: 24px;
  }

  .options {
    margin-top: 16px;

    .option {
      display: flex;
      align-items: center;
      margin-top: 0;
      margin-bottom: 8px;
      height: 24px;

      .option-title {
        font-weight: $--font-weight-medium;
        font-size: $--font-size-small !important;
        line-height: 24px;
      }

      .option-checkbox {
        margin-left: 4px;
        margin-right: 8px;
      }
    }
  }
}

.model-dropdown {
  height: 40px;

  .model-name {
    //width: 88px;
  }

  .dropdown-toggle {
    width: 230px;
    margin: 0;
    padding: 7px 16px;
    background-color: $--color-base-01 !important;
    border: 2px solid $--color-base-02 !important;
    border-radius: 8px;
    color: $--color-primary !important;
    font-weight: $--font-weight-medium;
    font-size: $--font-size-base;
  }

  .dropdown-toggle[aria-expanded="true"] {
    border-color: $--color-secondary !important;
  }

  .dropdown-menu {
    background-color: $--color-base-01;
    border: 2px solid $--color-base-02;
    border-radius: 4px;
    box-shadow: none;
    padding: 8px;
    width: 230px;

    .dropdown-model-items {
      max-height: 125px;
      padding: 8px;
    }
  }

  .dropdown-item {
    padding: 0px;
    border-radius: 4px;
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    color: $--color-primary;
    //background-color: $--color-base-05;
    background-color: #fff !important;
    border: none;
    border-radius: 4px;
  }
}

.custom-checkbox {
  padding-bottom: 20px;
}

.dp-button-group {
  margin-left: 12px;
}

.custom-control-label::after {
  border: 1px solid #42b1e5;
  border-radius: 4px;
  background-image: none !important;
  //background-color: #fff !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  height: 13px;
  width: 13px;
  border-color: white !important;
  top: 25%;
  margin-left: 1.5px;
  background-color: $--color-secondary !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}
</style>

<i18n>
{
  "en": {
    "title": "Download results",
    "subtitle": "Choose data",
    "download": "Download",
    "cancel": "Cancel",
    "options": {
      "generation": "Graphical report (new, .png)",
      "legend": "Legend (.xslx)",
      "registry": "Table report (.xslx)",
      "column": "Graphical report (deprecated, .png)"
    },
    "template": "Templates"
  },
  "ru": {
    "title": "Экспорт результатов",
    "subtitle": "Выберите данные",
    "download": "Скачать",
    "cancel": "Отмена",
    "options": {
      "generation": "Графический отчет (нов., .png)",
      "legend": "Условные обозначения (.xslx)",
      "registry": "Отчет в виде таблицы (.xslx)",
      "column": "Графический отчет (устар., .png)"
    },
    "template": "Шаблоны"
  }
}
</i18n>
