/**
 * @group Mixins
 * This is a description of the mixin
 */
export default {
    methods: {
        /**
         * @vuese
         * method to decrease number of the same messages
         * @arg value \\
         * @arg messages
         */
        valueMessage(value, messages) {
            const twoLast = Math.abs(value) % 100
            const last = twoLast % 10
            if (twoLast > 10 && twoLast < 20) return messages[3]
            if (last > 1 && last < 5) return messages[2]
            if (last === 1 && value > 1) return messages[1]
            if (last === 1 && value === 1) return messages[0]
            return messages[3]
        }
    }
}
