<template>
  <b-container class="project">
    <b-row class="project-navbar">
      <div class="d-flex align-items-center">
        <div class="d-lg-none d-inline-flex clickable" @click="closeProject">
          <dp-icon height="32" name="close" width="32"></dp-icon>
        </div>
        <dp-button
            class="d-none d-lg-inline-flex"
            outline
            @click="closeProject"
        >
          {{ $t("actions.cancel") }}
        </dp-button>
      </div>

      <div class="steps mx-auto d-flex align-items-center">
        <template v-for="(step, index) in steps">
          <div
              v-if="index !== 0"
              :key="`divider-${index}`"
              class="divider"
          ></div>
          <div
              :key="`step-${index}`"
              :class="
              step.code === activeStep.code
                ? ['menu-active', 'active']
                : ['menu-default']
            "
              class="step d-flex align-items-center"
          >
            <dp-icon
                v-if="step.finished"
                :sensitive="false"
                class="order finished mr-2"
                height="32"
                name="check-filled"
                width="32"
            >
            </dp-icon>
            <div v-else class="order mr-2">{{ step.order }}</div>
            <div class="d-none d-lg-block">{{ $t(`step.${step.code}`) }}</div>
          </div>
        </template>
      </div>

      <div class="d-flex align-items-center">
        <div
            :class="activeStep.first ? 'disabled' : 'clickable'"
            class="previous-step-icon d-xl-none d-inline-flex"
            @click="goToPreviousStep"
        >
          <dp-icon name="arrow-left"></dp-icon>
        </div>
        <dp-button
            :disabled="activeStep.first"
            :left-icon="true"
            class="previous-step-btn d-none d-xl-inline-flex"
            ghost
            @click="goToPreviousStep"
        >
          <template v-slot:icon>
            <dp-icon :sensitive="false" name="arrow-left"></dp-icon>
          </template>
          <template v-slot:default>
            {{ $t("actions.previous") }}
          </template>
        </dp-button>
        <div
            :class="activeStep.last ? 'disabled' : 'clickable'"
            class="next-step-icon d-xl-none d-inline-flex"
            @click="goToNextStep"
        >
          <dp-icon name="arrow-right"></dp-icon>
        </div>
        <dp-button
            :disabled="activeStep.last"
            :right-icon="true"
            class="next-step-btn d-none d-xl-inline-flex"
            @click="goToNextStep"
        >
          <template v-slot:icon>
            <dp-icon :sensitive="false" name="arrow-right"></dp-icon>
          </template>
          <template v-slot:default>
            {{ $t("actions.next") }}
          </template>
        </dp-button>
        <div
            :class="downloadEnabled ? 'clickable' : 'disabled'"
            class="download-icon d-xl-none d-inline-flex"
            @click="download"
        >
          <b-icon font-scale="1.5" icon="file-earmark-zip"></b-icon>
        </div>
        <dp-button
            :disabled="!downloadEnabled"
            class="download-btn d-none d-xl-inline-flex"
            secondary
            @click="download"
        >
          {{ $t("actions.download") }}
        </dp-button>
      </div>
    </b-row>

    <b-row class="project-content">
      <project-preview
          v-if="isPreviewStep"
          ref="preview"
          :project.sync="project"
          :steps="activeStep.code"
          @enable-download="enableDownload"
      >
      </project-preview>
      <project-core-selection
          v-if="isCoreSelectionStep"
          ref="core_selection"
          :project.sync="project"
          :steps="activeStep.code"
          @enable-download="enableDownload"
      >
      </project-core-selection>
      <project-depth-referencing
          v-if="isDepthReferencingStep"
          ref="depth_referencing"
          :project.sync="project"
          @enable-download="enableDownload"
      >
      </project-depth-referencing>
      <project-classification
          v-if="isClassificationStep"
          ref="classification"
          :project.sync="project"
          @enable-download="enableDownload"
      >
      </project-classification>
      <b-overlay :opacity="0.3" :show="actionsDisabled" no-wrap></b-overlay>
    </b-row>

    <dp-decision-dialog
        :active.sync="decisionDialog.active"
        :question="decisionDialog.question"
        @agree="decisionDialog.agree"
    >
    </dp-decision-dialog>
  </b-container>
</template>

<script>
import DpButton from "@/components/button/dp-button";
import DpIcon from "@/components/icon/dp-icon";
import ProjectService from "@/services/project-service";
import ProjectPreview from "@/views/components/project-preview";
import ProjectCoreSelection from "@/views/components/project-core-selection";
import ProjectDepthReferencing from "@/views/components/project-depth-referencing";
import ProjectClassification from "@/views/components/project-classification";
import DpDecisionDialog from "@/components/dp-decision-dialog";

/**
 * @group Views
 * Root view
 */
export default {
  name: "project",
  components: {
    DpDecisionDialog,
    DpButton,
    DpIcon,
    ProjectClassification,
    ProjectDepthReferencing,
    ProjectCoreSelection,
    ProjectPreview,
  },
  data() {
    return {
      /**
       * @vuese
       * description
       */
      project: {},
      /**
       * @vuese
       * description
       */
      steps: [
        {order: 1, code: "preview", finished: false, first: true},
        {order: 2, code: "core_selection", finished: false},
        {order: 3, code: "depth_referencing", finished: false},
        {order: 4, code: "classification", finished: false, last: true},
      ],
      /**
       * @vuese
       * description
       */
      activeStep: {},
      /**
       * @vuese
       * description
       */
      downloadEnabled: false,
      /**
       * @vuese
       * description
       */
      actionsDisabled: false,
      /**
       * @vuese
       * description
       */
      decisionDialog: {
        active: false,
        question: null,
        agree: () => {
        },
      },
      /**
       * @vuese
       * description
       */
      ieWarningEnable: true,
    };
  },
  computed: {
    /**
     * @vuese
     * description
     */
    isPreviewStep() {
      return this.activeStep.code === "preview";
    },
    /**
     * @vuese
     * description
     */
    isCoreSelectionStep() {
      return this.activeStep.code === "core_selection";
    },
    /**
     * @vuese
     * description
     */
    isDepthReferencingStep() {
      return this.activeStep.code === "depth_referencing";
    },
    /**
     * @vuese
     * description
     */
    isClassificationStep() {
      return this.activeStep.code === "classification";
    },
  },
  /**
   * @vuese
   * description
   */
  mounted() {
    this.loadProject();

    if (this.isIE() && this.ieWarningEnable) {
      this.ieWarningEnable = false;
      this.showWMessage(this.$t("warnings.ie"));
    }
  },
  /**
   * @vuese
   * description
   */
  beforeRouteLeave(to, from, next) {
    this.executeInProjectComponent("closeProject", () => {
      next();
    });
  },
  methods: {
    /**
     * @vuese
     * description
     */
    loadProject() {
      ProjectService.project(this.$route.params.projectId).then((result) => {
        this.$set(this, "project", result.data);
        document.title = `${process.env.VUE_APP_NAME} - ${this.project.project_name}`;
        this.loadActiveStep();
      });
    },
    /**
     * @vuese
     * description
     */
    closeProject() {
      this.$router.replace({name: "projects"});
    },
    /**
     * @vuese
     * description
     */
    loadActiveStep() {
      const step = this.steps.find(
          (s) => s.code === this.project.project_step.toLowerCase()
      );

      this.steps.forEach((s) => {
        s.finished = s.order < step.order;
      });
      this.$set(this, "activeStep", step);
      this.enableDownload(false);
    },
    /**
     * @vuese
     * description
     */
    goToNextStep() {
      if (this.activeStep.last) {
        return;
      }

      this.executeInProjectComponent("goToNextStep", () => {
        return ProjectService.nextStep(this.project.project_id).then(
            (result) => {
              this.$set(this, "project", result.data);
              this.loadActiveStep();
              this.$nextTick(() => {
                this.executeInProjectComponent(
                    "afterGoToNextStep",
                    () => {
                    },
                    false
                );
              });
            }
        );
      });
    },
    /**
     * @vuese
     * description
     */
    goToPreviousStep() {
      if (this.activeStep.first) {
        return;
      }

      this.decisionDialog.question = this.$t("questions.previous-step");
      this.decisionDialog.agree = this.applyGoToPreviousStep;
      this.decisionDialog.active = true;
    },
    /**
     * @vuese
     * description
     */
    applyGoToPreviousStep() {
      this.executeInProjectComponent("goToPreviousStep", () => {
        return ProjectService.previousStep(this.project.project_id).then(
            (result) => {
              this.$set(this, "project", result.data);
              this.loadActiveStep();
            }
        );
      });
    },
    /**
     * @vuese
     * description
     * @arg value
     */
    enableDownload(value) {
      this.downloadEnabled = !!value;
    },
    /**
     * @vuese
     * description
     */
    download() {
      if (!this.downloadEnabled) {
        return;
      }
      this.executeInProjectComponent("download", () => {
      }, false);
    },
    /**
     * @vuese
     * description
     * @arg funcName \\
     * @arg callback \\
     * @arg disableActions default = true
     */
    executeInProjectComponent(funcName, callback, disableActions = true) {
      if (disableActions) {
        this.actionsDisabled = true;
      }
      const projectComponent = this.$refs[this.activeStep.code];
      if (projectComponent && projectComponent[funcName]) {
        projectComponent[funcName]()
            .then(() => {
              return callback();
            })
            .finally(() => {
              if (disableActions) {
                this.actionsDisabled = false;
              }
            });
      } else {
        const result = callback();
        if (disableActions) {
          if (result instanceof Promise) {
            result.finally(() => {
              this.actionsDisabled = false;
            });
          } else {
            this.actionsDisabled = false;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.project {
  &.container {
    max-width: none !important;
    margin: 0;
  }

  .project-navbar {
    height: 64px;
    background-color: $--color-base-01;
    padding: 12px 24px;

    .next-step-btn {
      margin-left: 4px;
    }

    .download-btn,
    .download-icon {
      margin-left: 12px;
    }

    .previous-step-icon,
    .next-step-icon,
    .download-icon {
      &.disabled {
        cursor: not-allowed;

        svg {
          fill: $--color-base-04 !important;
        }
      }
    }

    .steps {
      .divider {
        border-bottom: 1px solid $--color-primary;
        margin: 12px;
        width: 10px;
        height: 0;
      }

      .step {
        .order:not(.finished) {
          border: 2px solid $--color-base-02;
          border-radius: 50%;
          height: 32px;
          width: 32px;
          text-align: center;
          padding-top: 2px;
        }

        &.active .order {
          border-color: $--color-secondary;
        }

        svg.order.finished {
          fill: $--color-secondary !important;
        }
      }
    }
  }

  .project-content {
    padding: 8px;
    height: calc(100vh - 154px);
  }
}
</style>

<i18n>
{
  "en": {
    "actions": {
      "cancel": "Exit",
      "download": "Download",
      "previous": "Previous step",
      "next": "Next step"
    },
    "step": {
      "preview": "Preview",
      "core_selection": "Core selection",
      "depth_referencing": "Depth referencing",
      "classification": "Classification"
    },
    "questions": {
      "previous-step": "All your changes on current step will be lost. Would you like to return to the previous step?"
    },
    "warnings": {
      "ie": "Action \"Undo history\" is limited in browser Internet Explorer"
    }
  },
  "ru": {
    "actions": {
      "cancel": "Выход",
      "download": "Экспорт",
      "previous": "Назад",
      "next": "Вперед"
    },
    "step": {
      "preview": "Просмотр",
      "core_selection": "Извлечение керна",
      "depth_referencing": "Привязка глубин",
      "classification": "Классификация"
    },
    "questions": {
      "previous-step": "Все внесенные вами изменения на текущем шаге будут утеряны. Перейти на предыдущий шаг?"
    },
    "warnings": {
      "ie": "Действие возврата по истории изменений ограничено в браузере Internet Explorer"
    }
  }
}
</i18n>
