<template>
  <div class="project-preview d-flex w-100">
    <image-previews
        ref="imagePreviews"
        :active-image.sync="image"
        :images="images"
        :steps="step"
        v-on:loadImages="loadImages"
    ></image-previews>

    <div ref="imageContainer" class="image-container flex-grow-1">
      <image-preview
          :image="imagePreview.image"
          :scale="image.config.scaleX"
          :viewport="imagePreview.viewport"
          class="preview"
      >
      </image-preview>
      <image-filename
          :filename="image.file_name"
          class="filename"
      ></image-filename>

      <v-stage
          ref="canvasStage"
          :config="canvasConfig"
          class="image-canvas"
          @contextmenu="
          (e) => {
            e.evt.preventDefault();
          }
        "
      >
        <v-layer ref="canvasLayer">
          <v-image
              v-if="imageLoaded"
              ref="canvasImage"
              :config="image.config"
          />

          <v-group
              v-if="image.crop.enabled"
              ref="canvasCropGroup"
              :config="image.crop.groupConfig"
          >
            <v-image :config="image.crop.imageConfig"/>
          </v-group>

          <v-rect
              v-if="image.crop.enabled"
              ref="canvasCropRect"
              :config="image.crop.rectConfig"
          />

          <v-transformer
              v-if="image.crop.enabled"
              ref="canvasTransformer"
              :config="image.crop.transformerConfig"
          />
        </v-layer>
      </v-stage>
    </div>

    <image-toolbar
        ref="imageToolbar"
        :bottom-depth="image.bottom_depth"
        :core-column-height.sync="projectOptions.coreColumnHeight"
        :coring-direction.sync="projectOptions.coringDirection"
        :depths-enabled="true"
        :editing-tools="[
        'rotate-right',
        'rotate-left',
        'reflect-v',
        'reflect-h',
        'crop',
      ]"
        :redo-enabled="redoEnabled"
        :top-depth="image.top_depth"
        :undo-enabled="undoEnabled"
        @crop="startCropImage"
        @hand="handImage(true)"
        @redo="redoImage"
        @undo="undoImage"
        @rotate-right="rotateImageRight"
        @rotate-left="rotateImageLeft"
        @reflect-v="reflectImageVertically"
        @reflect-h="reflectImageHorizontally"
        @crop-apply="applyCropImage"
        @crop-cancel="cancelCropImage"
        @crop-rotation="changeCropImageRotation"
        @zoom-in="zoomImageIn"
        @zoom-out="zoomImageOut"
        @core-column-height-apply="saveCoreColumnHeight"
        @coring-direction-apply="saveCoringDirection"
        @depths-apply="saveDepths"
        @switch-editing-tools="projectOptions.accepted = true"
        @switch-project-options="projectOptions.accepted = true"
    >
    </image-toolbar>
  </div>
</template>

<script>
import ProjectService from "@/services/project-service";
import FileService from "@/services/file-service";
import ImageToolbar from "@/views/components/image-toolbar";
import ImagePreviews from "@/views/components/image-previews";
import ImagePreview from "@/views/components/image-preview";
import ImageFilename from "@/views/components/image-filename";
import CanvasImageMixin from "@/views/mixins/canvas-image-mixin";
import CropAnchorsMixin from "@/views/mixins/crop-anchors-mixin";

/**
 * @group Views-components
 * This is a description of the component
 */
export default {
  name: "project-preview",
  components: {
    ImageFilename,
    ImagePreviews,
    ImagePreview,
    ImageToolbar,
  },
  mixins: [CanvasImageMixin, CropAnchorsMixin],
  props: {
    project: {
      type: Object,
      required: true,
    },
    steps: {
      type: String,
    },
  },

  data() {
    return {
      /**
       * @vuese
       * images
       */
      images: {},
      /**
       * @vuese
       * files
       */
      files: {},
      /**
       * @vuese
       * image properties
       */
      image: {
        file_id: null,
        file_name: null,
        top_depth: null,
        bottom_depth: null,
        config: {},
        crop: {
          enabled: false,
          initialRotation: null,
          groupConfig: {},
          imageConfig: {},
          rectConfig: {},
          transformerConfig: {},
        },
      },
      /**
       * @vuese
       * project options
       */
      projectOptions: {
        accepted: false,
        coreColumnHeight: null,
        coringDirection: null,
      },
      /**
       * @vuese
       * current step
       */
      step: this.steps,
    };
  },
  computed: {
    /**
     * @vuese
     * image loading status
     */
    imageLoaded() {
      return Object.keys(this.image.config).length > 0;
    },
  },
  watch: {
    /**
     * @vuese
     * image loading status
     */
    image(newImage, oldImage) {
      if (newImage.file_id !== oldImage.file_id) {
        this.saveActiveImage(oldImage);
        this.reloadActiveImage(newImage);
      }
    },
  },
  /**
   * @vuese
   * project preview doing things when mounted
   */
  mounted() {
    this.$nextTick(() => {
      this.resizeCanvas();
      window.addEventListener("resize", this.resizeCanvas);

      this.loadOptions();
      this.loadImages().then(() => this.$nextTick(() => this.resizeCanvas()));
    });
    // console.log("step", this.step);
  },
  /**
   * @vuese
   * project preview doing things when destroyed
   */
  destroyed() {
    Object.keys(this.files).forEach((fileId) => this.deleteFile(fileId));

    this.images = null;
    this.files = null;
    this.image = null;

    window.removeEventListener("resize", this.resizeCanvas);
  },
  methods: {
    /**
     * @vuese
     * close project
     */
    closeProject() {
      return this.saveActiveImage(this.image, false);
    },
    /**
     * @vuese
     * goToNextStep
     */
    goToNextStep() {
      return this.saveActiveImage(this.image, false);
    },
    /**
     * @vuese
     * loadOptions
     */
    loadOptions() {
      this.projectOptions.coreColumnHeight = this.project.core_column_height;
      this.projectOptions.coringDirection = this.project.coring_direction;
    },
    /**
     * @vuese
     * loadImages
     */
    loadImages() {
      return ProjectService.images(this.project.project_id).then((result) => {
        this.$set(
            this,
            "images",
            result.data
                ? result.data.reduce((images, image) => {
                  images[image.file_id] = image;
                  return images;
                }, {})
                : {}
        );
        return true;
      });
    },
    /**
     * @vuese
     * saveCoreColumnHeight
     */
    saveCoreColumnHeight(coreColumnHeight) {
      ProjectService.saveCoreColumnHeight(
          this.project.project_id,
          coreColumnHeight
      ).then((result) => {
        this.projectOptions.coreColumnHeight = result.data.core_column_height;
      });
    },
    /**
     * @vuese
     * saveCoringDirection
     */
    saveCoringDirection(coringDirection) {
      ProjectService.saveCoringDirection(
          this.project.project_id,
          coringDirection
      ).then((result) => {
        this.projectOptions.coringDirection = result.data.coring_direction;
      });
    },
    /**
     * @vuese
     * saveDepths
     */
    saveDepths(topDepth, bottomDepth) {
      FileService.changeDepths(this.image.file_id, topDepth, bottomDepth).then(
          (result) => {
            this.$set(this.image, "top_depth", result.data.top_depth);
            this.$set(this.image, "bottom_depth", result.data.bottom_depth);
          }
      );
    },
    /**
     * @vuese
     * saveActiveImage
     */
    saveActiveImage(image, refreshPreview = true) {
      if (!this.needSaveImage()) {
        return Promise.resolve(false);
      }

      const fileId = image.file_id;

      this.$refs.imagePreviews.startPreviewLoading(fileId);
      this.deleteFile(fileId);

      const url = this.$refs.canvasImage.getNode().toDataURL({
        mimeType: "image/jpeg",
        quality: 1,
        pixelRatio: 1 / Math.abs(image.config.scaleX),
      });
      return FileService.swapDataURL(fileId, image.file_name, "image/jpeg", url)
          .then((result) => {
            this.$set(this.images, fileId, result.data);
            if (refreshPreview) {
              return this.$refs.imagePreviews.loadPreview(fileId);
            } else {
              this.$refs.imagePreviews.endPreviewLoading(fileId);
              return true;
            }
          })
          .catch(() => {
            this.$refs.imagePreviews.endPreviewLoading(fileId);
            return false;
          });
    },
    /**
     * @vuese
     * setupImage
     */
    setupImage(image) {
      this.$set(image, "config", {});
      this.$set(image, "crop", {
        enabled: false,
        initialRotation: null,
        groupConfig: {},
        imageConfig: {},
        rectConfig: {},
        transformerConfig: {},
      });
    },
    /**
     * @vuese
     * reloadActiveImage
     */
    reloadActiveImage(image) {
      this.clearHistory();
      this.clearPreview();
      this.setupImage(image);

      this.$refs.imageToolbar.disable();

      const fileId = image.file_id;
      const file = this.files[fileId];
      if (file) {
        this.reloadActiveImageFromFile(image, file);
      } else {
        FileService.file(fileId).then((result) => {
          const file = URL.createObjectURL(result.data);
          this.$set(this.files, fileId, file);
          this.reloadActiveImageFromFile(image, file);
        });
      }
    },
    /**
     * @vuese
     * reloadActiveImageFromFile
     */
    reloadActiveImageFromFile(image, file) {
      const htmlImage = new Image();
      htmlImage.src = file;
      htmlImage.onload = () => {
        this.$set(image, "config", this.createImageConfig(htmlImage));
        this.$nextTick(() => {
          this.getCanvasImage().dragBoundFunc(this.getDragBoundFunc());
          this.$refs.imageToolbar.setup();
          if (!this.projectOptions.accepted) {
            this.$refs.imageToolbar.initProjectOptions();
          }
          this.setupHistory(this.getHistoryImageData());
          this.reloadPreview();
        });
      };
    },
    /**
     * @vuese
     * resizeCanvas
     */
    resizeCanvas() {
      const imageContainer = this.$refs.imageContainer;

      this.canvasConfig.width = imageContainer ? imageContainer.clientWidth : 0;
      this.canvasConfig.height = imageContainer
          ? imageContainer.clientHeight
          : 0;

      this.image.config = this.image.config ? this.image.config : {};
      this.image.config.x = this.canvasConfig.width / 2;
      this.image.config.y = this.canvasConfig.height / 2;
      this.reloadPreview(false);

      if (this.image.crop.enabled) {
        const xDelta = this.image.config.x - this.image.crop.imageConfig.x;
        const yDelta = this.image.config.y - this.image.crop.imageConfig.y;

        this.image.crop.imageConfig.x = this.image.config.x;
        this.image.crop.imageConfig.y = this.image.config.y;
        this.image.crop.rectConfig.x = this.image.crop.rectConfig.x + xDelta;
        this.image.crop.rectConfig.y = this.image.crop.rectConfig.y + yDelta;
      }
    },
    /**
     * @vuese
     * ..
     */
    getImageScale() {
      return {x: this.image.config.scaleX, y: this.image.config.scaleY};
    },
    /**
     * @vuese
     * ..
     */
    setImageScale(scaleX, scaleY) {
      const canvasImageRect = this.getCanvasImage().getClientRect();
      const currentX = canvasImageRect.x;
      const currentY = canvasImageRect.y;
      let currentOffsetX = this.image.config.offset.x;
      let currentOffsetY = this.image.config.offset.y;
      if ([90, -90, 270, -270].includes(this.image.config.rotation)) {
        currentOffsetX = this.image.config.offset.y;
        currentOffsetY = this.image.config.offset.x;
      }
      const currentImageX =
          currentX + currentOffsetX * Math.abs(this.image.config.scaleX);
      const currentImageY =
          currentY + currentOffsetY * Math.abs(this.image.config.scaleY);

      const pointerPosition = this.getCanvasStage().getPointerPosition();
      const positionDeltaX =
          (pointerPosition.x - currentX) / Math.abs(this.image.config.scaleX);
      const positionDeltaY =
          (pointerPosition.y - currentY) / Math.abs(this.image.config.scaleY);

      this.image.config.scaleX = scaleX;
      this.image.config.scaleY = scaleY;

      const nextTopLeftX = currentImageX - currentOffsetX * Math.abs(scaleX);
      const nextTopLeftY = currentImageY - currentOffsetY * Math.abs(scaleY);
      const nextBottomRightX =
          nextTopLeftX + this.image.config.width * Math.abs(scaleX);
      const nextBottomRightY =
          nextTopLeftY + this.image.config.height * Math.abs(scaleY);
      if (
          nextTopLeftX < 0 ||
          nextTopLeftY < 0 ||
          nextBottomRightX > this.canvasConfig.width ||
          nextBottomRightY > this.canvasConfig.height
      ) {
        this.image.config.x =
            pointerPosition.x -
            positionDeltaX * Math.abs(scaleX) +
            currentOffsetX * Math.abs(scaleX);
        this.image.config.y =
            pointerPosition.y -
            positionDeltaY * Math.abs(scaleY) +
            currentOffsetY * Math.abs(scaleY);
      } else {
        this.image.config.x = this.canvasConfig.width / 2;
        this.image.config.y = this.canvasConfig.height / 2;
      }
    },
    /**
     * @vuese
     * ..
     */
    getHistoryImageData() {
      return this.cloneImageConfig(this.image.config);
    },
    /**
     * @vuese
     * ..
     */
    loadHistoryImageData(imageData) {
      this.$set(this.image, "config", this.cloneImageConfig(imageData));
      return Promise.resolve(true);
    },
    /**
     * @vuese
     * ..
     */
    rotateImageRight() {
      this.rotateImage(90);
    },
    /**
     * @vuese
     * ..
     */
    rotateImageLeft() {
      this.rotateImage(-90);
    },
    /**
     * @vuese
     * ..
     */
    rotateImage(degrees) {
      const rotation = this.image.config.rotation + degrees;
      this.image.config.rotation = Math.abs(rotation) === 360 ? 0 : rotation;
      this.saveHistory(this.getHistoryImageData());
      this.reloadPreview();
    },
    /**
     * @vuese
     * ..
     */
    reflectImageVertically() {
      const scaleField = [90, 270].includes(
          Math.abs(this.image.config.rotation)
      )
          ? "scaleY"
          : "scaleX";
      this.reflectImage(scaleField);
    },
    /**
     * @vuese
     * ..
     */
    reflectImageHorizontally() {
      const scaleField = [90, 270].includes(
          Math.abs(this.image.config.rotation)
      )
          ? "scaleX"
          : "scaleY";
      this.reflectImage(scaleField);
    },
    /**
     * @vuese
     * ..
     */
    reflectImage(scaleField) {
      this.image.config[scaleField] *= -1;
      this.saveHistory(this.getHistoryImageData());
      this.reloadPreview();
    },
    /**
     * @vuese
     * ..
     */
    fitImageToCanvas() {
      const canvasImage = this.$refs.canvasImage.getNode();
      const imageRect = canvasImage.getClientRect();
      const scaleX = this.image.config.scaleX;
      const scaleY = this.image.config.scaleY;
      const scaleXSign = scaleX / Math.abs(scaleX);
      const scaleYSign = scaleY / Math.abs(scaleY);
      let scale = Math.min(
          (this.canvasConfig.width / imageRect.width) * Math.abs(scaleX),
          (this.canvasConfig.height / imageRect.height) * Math.abs(scaleY)
      );
      scale = this.normalizeScale(scale);

      this.image.config.x = this.canvasConfig.width / 2;
      this.image.config.y = this.canvasConfig.height / 2;
      this.image.config.scaleX = scale * scaleXSign;
      this.image.config.scaleY = scale * scaleYSign;
      this.image.config.opacity = 0.1;

      canvasImage.draggable(false);
      canvasImage.x(this.image.config.x);
      canvasImage.y(this.image.config.y);
      canvasImage.off();
      this.reloadPreview(false);
    },
    /**
     * @vuese
     * ..
     */
    startCropImage() {
      this.fitImageToCanvas();

      this.image.crop.enabled = true;
      this.image.crop.initialRotation = this.image.config.rotation;

      this.$nextTick(() => {
        this.image.crop.groupConfig = {
          clipFunc: (ctx) => {
            const cropRect = this.$refs.canvasCropRect.getNode();
            ctx.save();
            ctx.translate(cropRect.x(), cropRect.y());
            // eslint-disable-next-line
            ctx.rotate(Konva.getAngle(cropRect.rotation()));
            ctx.rect(
                0,
                0,
                cropRect.width() * cropRect.scaleX(),
                cropRect.height() * cropRect.scaleY()
            );
            ctx.restore();
          },
        };

        this.image.crop.imageConfig = {
          x: this.image.config.x,
          y: this.image.config.y,
          offset: this.image.config.offset,
          scaleX: this.image.config.scaleX,
          scaleY: this.image.config.scaleY,
          rotation: this.image.config.rotation,
          image: this.image.config.image,
        };

        const imageRect = this.$refs.canvasImage.getNode().getClientRect();
        this.image.crop.rectConfig = {
          x: imageRect.x,
          y: imageRect.y,
          width: imageRect.width,
          height: imageRect.height,
          fill: "#000000",
          opacity: 0,
          draggable: true,
          dragBoundFunc: (pos) => {
            const image = this.$refs.canvasImage.getNode().getClientRect();
            const rectNode = this.$refs.canvasCropRect.getNode();
            const rect = rectNode.getClientRect();

            let x;
            if (rectNode.rotation() === -180) {
              x = pos.x - rect.width < image.x ? image.x + rect.width : pos.x;
              x = pos.x > image.x + image.width ? image.x + image.width : x;
            } else {
              x = pos.x < image.x ? image.x : pos.x;
              x =
                  pos.x + rect.width > image.x + image.width
                      ? image.x + image.width - rect.width
                      : x;
            }
            let y;
            if (rectNode.rotation() === 0 && rectNode.scaleY() < 0) {
              y = pos.y - rect.height < image.y ? image.y + rect.height : pos.y;
              y = pos.y > image.y + image.height ? image.y + image.height : y;
            } else {
              y = pos.y < image.y ? image.y : pos.y;
              y =
                  pos.y + rect.height > image.y + image.height
                      ? image.y + image.height - rect.height
                      : y;
            }

            return {x, y};
          },
        };

        const secondaryColor = "#42B1E5";
        const transparentColor = "transparent";
        this.image.crop.transformerConfig = {
          borderStrokeWidth: 4,
          borderStroke: secondaryColor,
          anchorSize: 16,
          anchorStroke: transparentColor,
          anchorFill: transparentColor,
          rotateEnabled: false,
          keepRatio: false,
          boundBoxFunc: (oldBoundBox, newBoundBox) => {
            const image = this.$refs.canvasImage.getNode().getClientRect();

            let box = newBoundBox;
            if (
                newBoundBox.width > imageRect.width ||
                newBoundBox.height > imageRect.height
            ) {
              box = oldBoundBox;
            } else if (
                image.x - newBoundBox.x > 2 ||
                image.y - newBoundBox.y > 2
            ) {
              box = oldBoundBox;
            } else if (
                newBoundBox.x + newBoundBox.width - (image.x + image.width) >
                2
            ) {
              box = oldBoundBox;
            } else if (newBoundBox.x - (image.x + image.width) > 2) {
              box = oldBoundBox;
            } else if (
                newBoundBox.y + newBoundBox.height - (image.y + image.height) >
                2
            ) {
              box = oldBoundBox;
            } else if (newBoundBox.y - (image.y + image.height) > 2) {
              box = oldBoundBox;
            }

            return box;
          },
        };

        this.setCursorStyle(
            this.$refs.canvasCropRect.getNode(),
            this.getGrabCursor()
        );
        this.$refs.canvasTransformer
            .getNode()
            .nodes([this.$refs.canvasCropRect.getNode()]);

        this.$refs.canvasCropRect.getNode().on("transform", () => {
          const transformer = this.$refs.canvasTransformer.getNode();
          transformer.update();
          this.$nextTick(() => {
            this.moveCustomCropAnchors(transformer);
          });
        });

        this.$nextTick(() => {
          this.addCustomCropAnchors(this.$refs.canvasTransformer.getNode());
        });
      });
    },
    /**
     * @vuese
     * ..
     */
    restartCropImage() {
      this.cancelCropImage();
      this.$nextTick(() => {
        this.$refs.imageToolbar.activateAndEmitEditingTool("crop");
      });
    },
    /**
     * @vuese
     * ..
     */
    applyCropImage() {
      const cropRect = this.$refs.canvasCropRect.getNode().getClientRect();
      const cropGroup = this.$refs.canvasCropGroup.getNode().getClientRect();

      if (!this.haveIntersection(cropRect, cropGroup)) {
        this.showWMessage(this.$t("messages.crop-intersection-error"));
        this.restartCropImage();
      } else {
        const canvas = this.$refs.canvasCropGroup.getNode().toCanvas({
          pixelRatio: 1,
          x: cropRect.x,
          y: cropRect.y,
          width: cropRect.width,
          height: cropRect.height,
        });
        const context = canvas.getContext("2d",{ willReadFrequently: true });
        let correctImage = false;
        for (let i = 0; i < cropRect.height; i += 10) {
          for (let j = 0; j < cropRect.width; j += 10) {
            const pixel = context.getImageData(j, i, 1, 1).data;
            if (
                pixel[0] !== 0 ||
                pixel[1] !== 0 ||
                pixel[2] !== 0 ||
                pixel[3] !== 0
            ) {
              correctImage = true;
              break;
            }
          }
          if (correctImage) {
            break;
          }
        }

        if (!correctImage) {
          this.showWMessage(this.$t("messages.crop-intersection-error"));
          this.restartCropImage();
        } else {
          this.$refs.canvasCropGroup.getNode().toImage({
            mimeType: "image/jpeg",
            quality: 1,
            pixelRatio: 1 / Math.abs(this.image.config.scaleX),
            x: cropRect.x,
            y: cropRect.y,
            width: cropRect.width,
            height: cropRect.height,
            callback: (img) => {
              this.image.crop.enabled = false;
              this.image.crop.initialRotation = null;
              this.image.config.image = img;
              this.image.config.opacity = 1;
              this.image.config.rotation = 0;
              this.image.config.width = img.width;
              this.image.config.height = img.height;
              this.image.config.offset = {
                x: img.width / 2,
                y: img.height / 2,
              };
              this.image.config.scaleX = Math.abs(this.image.config.scaleX);
              this.image.config.scaleY = Math.abs(this.image.config.scaleY);
              this.saveHistory(this.getHistoryImageData());
              this.reloadPreview();
            },
          });
        }
      }
    },
    /**
     * @vuese
     * ..
     */
    cancelCropImage() {
      this.image.crop.enabled = false;
      this.image.config.opacity = 1;
      this.image.config.rotation = this.image.crop.initialRotation;
      this.image.crop.initialRotation = null;
    },
    /**
     * @vuese
     * ..
     */
    changeCropImageRotation(value) {
      if (this.image.crop.initialRotation === null) {
        this.image.crop.initialRotation = this.image.config.rotation;
      }
      this.image.config.rotation = this.image.crop.initialRotation + value;
      this.image.crop.imageConfig.rotation =
          this.image.crop.initialRotation + value;
    },
    /**
     * @vuese
     * ..
     */
    deleteFile(fileId) {
      const file = this.files[fileId];
      if (file) {
        URL.revokeObjectURL(file);
      }
      this.$delete(this.files, fileId);
    },
  },
};
</script>

<style lang="scss">
.project-preview {
  .image-container {
    position: relative;

    .preview {
      position: absolute;
      margin-left: 8px;
      top: 0;
      z-index: 1;
    }

    .filename {
      position: absolute;
      margin-left: 8px;
      bottom: 0;
      z-index: 1;
    }

    .image-canvas {
      position: absolute;
      z-index: 0;
    }
  }

  .image-toolbar {
    z-index: 1;
  }
}
</style>

<i18n>
{
  "en": {
    "messages": {
      "crop-intersection-error": "Cropping selection area must intersect with source image"
    }
  },
  "ru": {
    "messages": {
      "crop-intersection-error": "Вырезаемая область должна содержать исходное изображение"
    }
  }
}
</i18n>
