import Vue from 'vue'
import VueLogger from 'vuejs-logger'

/**
 * @vuese
 * describe the plugins (it will not be shown in vuese yet)
 */
const isProduction = process.env.NODE_ENV === 'production'
const options = {
    isEnabled: true,
    logLevel: isProduction ? 'info' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true
}

Vue.use(VueLogger, options)
