<template>
  <b-container id="app" :fluid="true" class="app-container h-100">
    <b-row class="header">
      <dp-header @extend-subscription="modal.subscription = true"></dp-header>
    </b-row>
    <b-row class="content">
      <router-view/>
    </b-row>
    <b-row :style="footerColor ? `background-color: ${footerColor}` : null" class="footer pt-1">
      <dp-footer></dp-footer>
    </b-row>

    <subscription-extension :active.sync="modal.subscription"></subscription-extension>
  </b-container>
</template>

<script>
import i18n from '@/plugins/i18n'
import {localize} from 'vee-validate'
import DpHeader from '@/components/dp-header'
import DpFooter from '@/components/dp-footer'
import AuthService from '@/services/auth-service'
import UserService from '@/services/user-service'
import SubscriptionExtension from '@/views/components/subscription-extension'

/**
 * @group App
 * The main app
 */
export default {
  name: 'App',
  components: {
    SubscriptionExtension,
    DpHeader,
    DpFooter
  },
  data() {
    return {
      /**
       * @vuese
       * description
       */
      modal: {
        subscription: false
      }
    }
  },
  computed: {
    /**
     * @vuese
     * description
     */
    isAuthorized() {
      return this.$store.getters.isAuthorized && this.$store.getters.isUserGranted && !this.$store.getters.isSubscriptionUnpaid
    },
    /**
     * @vuese
     * description
     */
    footerColor() {
      return this.$route.meta.footerColor
    }
  },
  watch: {
    /**
     * @vuese
     * description
     * @arg value
     */
    isAuthorized(value) {
      if (value && this.$route.name === 'home') {
        let route = this.$store.getters.getRedirectedFrom
        route = route !== null ? route : {name: 'projects'}
        this.$store.dispatch('setRedirectedFrom', null)
        this.$router.replace(route)
      } else if (!value && this.$route.name !== 'home') {
        this.$router.replace({name: 'home'})
      }
    }
  },
  /**
   * @vuese
   * description
   */
  beforeCreate() {
    const locale = navigator.language.split('-')[0]

    // activate i18n locale
    i18n.locale = locale
    // activate vee-validate locale
    localize(locale)

    this.$log.info('Current browser locale: ' + locale)
  },
  /**
   * @vuese
   * description
   */
  mounted() {
    this.loadAuthCode()

    const authorizationCode = this.$store.getters.getAuthorizationCode
    if (authorizationCode) {
      this.checkAuth(authorizationCode)
    } else {
      this.loadUser()
    }

    this.$store.watch((state) => state.notification, (value) => {
      if (value.notification && value.notification.message && value.notification.variant) {
        this.showMessage(value.notification.message, value.notification.variant)
      }
    }, {
      deep: true
    })
  },
  methods: {
    /**
     * @vuese
     * description
     */
    loadAuthCode() {
      const query = this.$route.query
      if (Object.keys(query).length > 0 && query.authorization_code) {
        this.$store.dispatch('setAuthorizationCode', query.authorization_code)
        this.$router.replace({
          ...this.$route,
          query: {}
        })
      }
    },
    /**
     * @vuese
     * description
     * @arg authorizationCode \\
     */
    checkAuth(authorizationCode) {
      this.$store.dispatch('clearAuth')
      this.$store.dispatch('setUser', {})
      AuthService.authCheck(authorizationCode).then((result) => {
        this.$store.dispatch('setTokens', {
          accessToken: result.data.access_token,
          refreshToken: result.data.refresh_token
        })
        this.loadUser()
      }).catch((error) => {
        this.$log.error(error)
      })
    },
    /**
     * @vuese
     * description
     */
    loadUser() {
      return UserService.profile().then((result) => {
        i18n.locale = result.data.locale.toLowerCase()
        localize(result.data.locale.toLowerCase())
        return this.$store.dispatch('setUser', result.data)
      })
    }
  }
}
</script>

<style lang="scss">
.app-container {
  display: flex;
  flex-direction: column;

  .header {
    flex-shrink: 0;
    min-height: 65px;
  }

  .content {
    flex: 1 0 auto;
  }

  .footer {
    flex-shrink: 0;
  }
}
</style>
