import axios from 'axios'
import qs from 'qs'
import router from '@/router'
import store from '@/store'
import AuthService from '@/services/auth-service'
import ErrorService from '@/services/error-service'

/**
 * @vuese
 * describe the plugins (it will not be shown in vuese yet)
 */
axios.interceptors.request.use((request) => {
    request.headers['X-CSRF-Token'] = store.getters.getCsrfToken
    const accessToken = store.getters.getAccessToken
    if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`
    }
    return request
})

axios.interceptors.response.use((response) => {
        store.dispatch('setCsrfToken', response.headers['x-csrf-token'])
        return response
    },
    (error) => {
        const errorResponse = error.response ? error.response : error
        console.log(errorResponse)

        if (errorResponse.headers) {
            store.dispatch('setCsrfToken', errorResponse.headers['x-csrf-token'])
        }

        if (errorResponse.status === 402) {
            store.dispatch('setUnpaid', true)
        }
        if (errorResponse.status === 406 || errorResponse.status === 403) {
            store.dispatch('setGranted', false)
        }

        if (errorResponse.status === 392) {
            const processResult = processAuthError(error.config, errorResponse)
            if (processResult instanceof Promise) {
                return processResult
            }
        }
        if (errorResponse.status === 401) {
            processAuthRedirect()
        }

        const errorMessage = ErrorService.getErrorMessage(error)
        if (errorMessage) {
            store.dispatch('setNotification', {
                message: errorMessage,
                variant: 'danger'
            })
        }

        return Promise.reject(error)
    })

function processAuthError(request, response) {
    if (store.getters.getAuthorizationCode) {
        console.log('Redirect skip because of authorization_code')
        return
    }

    const location = response.headers['x-redirect-location']
    if (!store.getters.getRefreshToken || request.url.includes('/token/refresh') || request._retry) {
        processAuthRedirect(location)
        return
    }

    if (!store.getters.isRefreshMode) {
        store.dispatch('setRefreshMode', true)
        return AuthService.refreshToken().then((result) => {
            store.dispatch('setTokens', {
                accessToken: result.data.access_token,
                refreshToken: result.data.refresh_token
            })

            // retry original request after token refresh
            request._retry = true
            request.headers['X-CSRF-Token'] = store.getters.getCsrfToken
            request.headers.Authorization = `Bearer ${result.data.access_token}`
            return axios(request)
        }).finally(() => {
            store.dispatch('setRefreshMode', false)
        })
    } else {
        return new Promise((resolve) => {
            setTimeout(() => {
                request._retry = true
                request.headers['X-CSRF-Token'] = store.getters.getCsrfToken
                request.headers.Authorization = `Bearer ${store.getters.getAccessToken}`
                resolve(axios(request))
            }, 2000)
        })
    }
}

function processAuthRedirect(location = null) {
    if (location) {
        store.dispatch('clearAuth')
        AuthService.redirect(location, router.currentRoute)
    } else {
        AuthService.authLocation().then((result) => {
            store.dispatch('clearAuth')
            AuthService.redirect(result.data.location, router.currentRoute)
        })
    }
}

const appHost = process.env.VUE_APP_HOST
const axiosHost = appHost && appHost !== '' ? appHost : window.location.host
axios.defaults.baseURL = process.env.VUE_APP_PROTOCOL + '://' + axiosHost + process.env.VUE_APP_CONTEXT
axios.defaults.withCredentials = true
axios.defaults.hostURL = process.env.VUE_APP_PROTOCOL + '://' + axiosHost
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.paramsSerializer = (params) => {
    return qs.stringify(params)
}

export default axios
