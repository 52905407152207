import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import auth from '@/store/modules/auth'
import security from '@/store/modules/security'
import user from '@/store/modules/user'
import notification from '@/store/modules/notification'
import toolbar from '@/store/modules/toolbar'
import lithology from '@/store/modules/lithology'
/**
 * @group Store
 * describe the store (it will not be shown in vuese yet)
 */

Vue.use(Vuex)

const vuexSessionStorage = new VuexPersist({
    key: 'dp-dc',
    storage: window.sessionStorage,
    reducer: (state) => ({
        user: {
            ...state.user
        },
        lithology: {
            ...state.lithology
        }
    })
})

const vuexLocalStorage = new VuexPersist({
    key: 'dp-dc',
    storage: window.localStorage,
    reducer: (state) => ({
        auth: {
            ...state.auth
        }
    })
})

export default new Vuex.Store({
    plugins: [vuexSessionStorage.plugin, vuexLocalStorage.plugin],
    modules: {
        auth,
        security,
        user,
        notification,
        toolbar,
        lithology
    },
    state: {},
    mutations: {},
    actions: {},
    strict: true
})
