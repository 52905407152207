<template>
  <b-container class="dp-footer">
    <b-row align-v="center" class="mx-0 h-100">
      <b-col class="text-center">
        <div class="copyright">
          Copyright © {{vue_app_year}}
          <a href="https://petroleum.digital/" target="_blank"
          >Digital Petroleum</a
          >. {{ $t("rights") }}.
          <a :href="$t('terms-and-conditions-url')" target="_blank">{{
              $t("terms-and-conditions")
            }}</a>
          {{ $t("divider") }}
          <a :href="$t('privacy-policy-url')" target="_blank">{{
              $t("privacy-policy")
            }}</a>.
          {{ $t("version") }}:
          {{vue_app_version}}
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/**
 * @group Components
 * Standard components
 */
export default {
  name: "dp-footer",
  data() {
    return {
      vue_app_year: process.env.VUE_APP_YEAR,
      vue_app_version: process.env.VUE_APP_UI_VERSION
    };
  }
};
</script>

<style lang="scss">
.dp-footer {
  height: 21px;
  max-width: none !important;
}
</style>

<i18n>
{
  "en": {
    "rights": "All rights reserved",
    "terms-and-conditions": "Terms and Conditions",
    "terms-and-conditions-url": "https://petroleum.digital/offer/en.html",
    "privacy-policy": "Privacy Policy",
    "privacy-policy-url": "https://petroleum.digital/privacy-policy/en.html",
    "divider": "and",
    "version": "UI version"
  },
  "ru": {
    "rights": "Все права защищены",
    "terms-and-conditions": "Условия использования",
    "terms-and-conditions-url": "https://petroleum.digital/offer/ru.html",
    "privacy-policy": "Конфиденциальность",
    "privacy-policy-url": "https://petroleum.digital/privacy-policy/ru.html",
    "divider": "и",
    "version": "Версия UI"
  }
}
</i18n>
