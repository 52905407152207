import CanvasImageMixin from '@/views/mixins/canvas-image-mixin'
import CollectionMixin from '@/mixins/collection-mixin'
import {v4 as uuidv4} from 'uuid'

/**
 * @group Views-mixins
 * This is a description of the component it includes DpButton component
 */
export default {
    mixins: [CanvasImageMixin, CollectionMixin],
    props: {
        /**
         * desc
         */
        project: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            /**
             * @vuese
             * data
             */
            coreColumns: [],
            /**
             * @vuese
             * data
             */
            coreColumnSplits: {},
            ignoredSplitsNum: 0,
            /**
             * @vuese
             * data
             */
            images: [],
            /**
             * @vuese
             * data
             */
            scale: {
                x: 1,
                y: 1
            },
            /**
             * @vuese
             * data
             */
            splitHeight: 40,
            /**
             * @vuese
             * data
             */
            depthCharWidth: 13,
            /**
             * @vuese
             * Contains info about points (y-pixels coordinates) and the physical depths
             */
            depths: {
                editable: false,
                points: {},
                current: {
                    active: false,
                    value: 0,
                    rules: null,
                    position: null
                }
            },
            /**
             * @vuese
             * data
             */
            cursorPosition: {
                boundConfig: {},
                pointerConfig: {},
                depthConfig: {}
            }
        }
    },
    computed: {
        /**
         * @vuese
         * description
         */
        imageDataLoaded() {
            const coreColumnsNum = Object.keys(this.coreColumnSplits).flatMap((fileId) => this.coreColumnSplits[fileId]).length
            const estimatedImagesNum = this.coreColumns.length + coreColumnsNum - this.ignoredSplitsNum
            return this.images && this.images.length > 0 && this.images.length === estimatedImagesNum &&
                this.images[this.images.length - 1].config && Object.keys(this.images[this.images.length - 1].config).length > 0
        },
        /**
         * @vuese
         * description
         */
        imagePreviewDepths() {
            const depths = []
            if (this.imagePreview.images && this.imagePreview.images.length > 0 && this.imagePreview.ratioY) {
                Object.keys(this.depths.points).forEach((imageId) => {
                    const splitsNum = this.getSplitsNumBeforeImage(imageId)
                    this.depths.points[imageId].forEach((point) => {
                        depths.push({
                            x: 0,
                            y: (point.y - splitsNum * this.splitHeight / this.scale.y) * this.scale.y * this.imagePreview.ratioY +
                                splitsNum * this.splitHeight * this.getPixelRatio() -
                                this.splitHeight * this.imagePreview.ratioY,
                            value: point.value
                        })
                    })
                })
            }
            return depths
        }
    },
    /**
     * @vuese
     * description
     */
    destroyed() {
        this.images = null
        this.depths = null
        this.coreColumns = null
        this.coreColumnSplits = null
    },
    methods: {
        /**
         * @vuese
         * description
         */
        getPreviewGaugeCanvasImage() {
            if (this.images.length === 0) {
                return null
            }
            const key = `canvas-image-part-${this.images[0].image_id}`
            return this.$refs[key] ? this.$refs[key][0].getNode() : null
        },
        /**
         * @vuese
         * description
         * @arg ratio
         */
        reloadPreviewImages(ratio) {
            const canvasImage = this.getPreviewCanvasImage()
            const canvasImageClientRect = canvasImage.getClientRect()
            const canvasImageY = canvasImageClientRect.y
            const canvasImageHeight = canvasImageClientRect.height

            const promises = []

            let y = canvasImageY
            let imageHeight = canvasImageHeight
            const heightPart = 5000
            for (let i = 1; i < canvasImageHeight / heightPart; i++) {
                const promise = new Promise((resolve) => {
                    canvasImage.toImage({
                        y: y,
                        height: heightPart,
                        pixelRatio: ratio,
                        callback: (image) => {
                            resolve(image)
                        }
                    })
                })
                promises.push(promise)
                y += heightPart
                imageHeight -= heightPart
            }
            if (imageHeight > 0) {
                const promise = new Promise((resolve) => {
                    canvasImage.toImage({
                        y: y,
                        height: imageHeight,
                        pixelRatio: ratio,
                        callback: (image) => {
                            resolve(image)
                        }
                    })
                })
                promises.push(promise)
            }

            return Promise.all(promises)
        },
        /**
         * @vuese
         * description
         */
        setupImages() {
            this.$set(this, 'scale', {x: 1, y: 1})
            this.$set(this, 'images', [])
            this.$set(this, 'depths', {
                editable: false,
                points: {},
                current: {
                    active: false,
                    value: 0,
                    rules: null,
                    position: null
                }
            })
            this.$set(this, 'ignoredSplitsNum', 0)
        },
        /**
         * @vuese
         * description
         */
        beforeImagesLoading() {
            this.clearHistory()
            this.clearPreview()
            this.setupImages()
            this.$refs.imageToolbar.disable()
        },
        /**
         * @vuese
         * description
         */
        afterImagesLoading() {
            this.$nextTick(() => {
                this.getCanvasImage().dragBoundFunc((newPosition) => {
                    const canvasImage = this.getCanvasImage()
                    const currentPosition = canvasImage.absolutePosition()
                    const clientRect = canvasImage.getClientRect()

                    let newY = currentPosition.y
                    if ((newPosition.y + clientRect.height >= (this.canvasConfig.height - this.splitHeight)) && (newPosition.y <= 0)) {
                        newY = newPosition.y
                    }
                    return {
                        x: currentPosition.x,
                        y: newY
                    }
                })
                this.$refs.imageToolbar.setup()
                this.setupHistory(this.getHistoryImageData())
                this.reloadPreview()
            })
        },
        // promisedImages - array of objects: {fileId, image, cores}
        /**
         * @vuese
         * description
         * @arg promisedImages
         */
        loadImagesWithCores(promisedImages) {
            return Promise.all(promisedImages).then((results) => {
                let imageIndex = 0
                let currentY = 0
                let currentOffset = 0
                results.forEach((result, index) => {
                    const isLastSrcImage = index === results.length - 1
                    const imageConfig = this.createImageConfig(result.image)

                    if (imageIndex > 0 && (this.scale.x !== imageConfig.scaleX || this.scale.y !== imageConfig.scaleY)) {
                        this.showEMessage('Unexpected error: Images must be with same width')
                        return
                    }
                    this.$set(this, 'scale', {x: imageConfig.scaleX, y: imageConfig.scaleY})

                    if (imageIndex === 0) {
                        // start offset for images - need for correct displaying first top depth
                        currentY = this.splitHeight
                        currentOffset = this.splitHeight / imageConfig.scaleY
                    }

                    const imageHeight = imageConfig.height
                    const initialY = imageConfig.y

                    const coreColumnSplits = this.coreColumnSplits[result.fileId]
                    const splits = coreColumnSplits ? coreColumnSplits.sort((s1, s2) => s1.anchor_y - s2.anchor_y) : []
                    const needUserSplit = splits && splits.length > 0
                    const needDepthSplit = !isLastSrcImage && results[index + 1].coreColumn.top_depth !== result.coreColumn.bottom_depth
                    const cropHeight = needUserSplit ? splits[0].anchor_y : imageConfig.height

                    imageConfig.cropX = 0
                    imageConfig.cropY = 0
                    imageConfig.cropWidth = imageConfig.width
                    imageConfig.cropHeight = cropHeight
                    imageConfig.height = cropHeight
                    imageConfig.offset.y = (this.canvasConfig.height / 2) / imageConfig.scaleY
                    imageConfig.y += currentY

                    const cores = this.arrayToMap(result.cores.filter((core) => core.anchor_y < cropHeight), 'core_id')
                    const image = {
                        image_id: uuidv4(),
                        file_id: result.fileId,
                        offset: currentOffset,
                        config: imageConfig,
                        cores: cores,
                        with_split: needUserSplit || needDepthSplit,
                        first_part: imageIndex === 0 ? true : this.images[imageIndex - 1].with_split,
                        last_part: needUserSplit || needDepthSplit || isLastSrcImage
                    }
                    if (image.with_split) {
                        image.splitLineConfig = this.createSplitLineConfig(imageConfig, currentY)
                        image.splitRectConfig = this.createSplitRectConfig(imageConfig, currentY)
                    }

                    const points = this.coresToDepthPoints(image)
                    image.top_depth = points[0].value
                    image.bottom_depth = points[points.length - 1].value

                    this.$set(this.images, imageIndex, image)
                    this.$set(this.depths.points, image.image_id, points)

                    currentY = this.nextImageY(imageConfig, currentY, image.with_split)
                    currentOffset = this.nextImageOffset(imageConfig, currentOffset, image.with_split)
                    imageIndex++

                    splits.forEach((split, splitIndex) => {
                        const isLastSplit = splitIndex === splits.length - 1
                        const splitImageCropHeight = (isLastSplit ? imageHeight : splits[splitIndex + 1].anchor_y) - split.anchor_y
                        if (splitImageCropHeight === 0) {
                            this.ignoredSplitsNum++
                            return
                        }

                        const splitImageConfig = this.cloneImageConfig(imageConfig)
                        splitImageConfig.cropY = split.anchor_y
                        splitImageConfig.cropHeight = splitImageCropHeight
                        splitImageConfig.height = splitImageConfig.cropHeight
                        splitImageConfig.y = initialY + currentY

                        const cores = this.arrayToMap(result.cores.filter((core) => {
                            return core.anchor_y >= split.anchor_y && (isLastSplit || core.anchor_y < splits[splitIndex + 1].anchor_y)
                        }), 'core_id')
                        const splitImage = {
                            image_id: uuidv4(),
                            file_id: result.fileId,
                            offset: currentOffset,
                            config: splitImageConfig,
                            cores: cores,
                            with_split: !isLastSplit || needDepthSplit,
                            first_part: this.images[imageIndex - 1].with_split,
                            last_part: !isLastSplit || isLastSrcImage || needDepthSplit
                        }
                        if (splitImage.with_split) {
                            splitImage.splitLineConfig = this.createSplitLineConfig(splitImageConfig, currentY)
                            splitImage.splitRectConfig = this.createSplitRectConfig(splitImageConfig, currentY)
                        }

                        const points = this.coresToDepthPoints(splitImage)
                        splitImage.top_depth = points[0].value
                        splitImage.bottom_depth = points[points.length - 1].value

                        this.$set(this.images, imageIndex, splitImage)
                        this.$set(this.depths.points, splitImage.image_id, points)

                        currentY = this.nextImageY(splitImageConfig, currentY, splitImage.with_split)
                        currentOffset = this.nextImageOffset(splitImageConfig, currentOffset, splitImage.with_split)
                        imageIndex++
                    })
                })
                return true
            })
        },
        /**
         * @vuese
         * description
         * @arg currentImageConfig \\
         * @arg currentY \\
         * @arg withSplit default = true
         */
        nextImageY(currentImageConfig, currentY, withSplit = true) {
            return currentY + currentImageConfig.height * currentImageConfig.scaleY + (withSplit ? this.splitHeight : 0)
        },
        /**
         * @vuese
         * description
         * @arg currentImageConfig \\
         * @arg currentOffset \\
         * @arg withSplit default = true
         */
        nextImageOffset(currentImageConfig, currentOffset, withSplit = true) {
            return currentOffset + currentImageConfig.height + (withSplit ? this.splitHeight / currentImageConfig.scaleY : 0)
        },
        /**
         * @vuese
         * description
         * @arg imageIndex
         */
        getJointImages(imageIndex) {
            const jointImages = []
            const startImage = this.images[imageIndex]
            jointImages.push(startImage)

            let previousImage = null
            let nextImage = null

            let index = imageIndex - 1
            if (!startImage.first_part) {
                while (this.images[index] && !this.images[index].first_part) {
                    jointImages.splice(0, 0, this.images[index--])
                }
                jointImages.splice(0, 0, this.images[index--]) // first part
            }
            previousImage = this.images[index]

            index = imageIndex + 1
            if (!startImage.last_part) {
                while (this.images[index] && !this.images[index].last_part) {
                    jointImages.push(this.images[index++])
                }
                jointImages.push(this.images[index++]) // last part
            }
            nextImage = this.images[index]

            return {jointImages, previousImage, nextImage}
        },
        getSplitsNumBeforeImage(imageId) {
            let splitsNum = 0
            for (let i = 0; i < this.images.length; i++) {
                const image = this.images[i]
                if (image.image_id === imageId) {
                    break
                } else if (image.with_split) {
                    splitsNum++
                }
            }
            return splitsNum
        },
        /**
         * @vuese
         * description
         * @arg imageConfig \\
         * @arg currentY \\
         * @arg width default = imageConfig.width * imageConfig.scaleX
         */
        createSplitLineConfig(imageConfig, currentY, width = imageConfig.width * imageConfig.scaleX) {
            const nextCurrentY = currentY + imageConfig.height * imageConfig.scaleY + this.splitHeight
            let x = imageConfig.x - imageConfig.offset.x * imageConfig.scaleX

            const peaks = Math.max(Math.round(width / 25), 2)
            const splitPoints = []
            for (let i = 1; i <= peaks; i++) {
                splitPoints.push(x, nextCurrentY - 10)
                splitPoints.push(x + width / (peaks * 2), nextCurrentY - this.splitHeight + 10)
                x += width / peaks
            }
            splitPoints.push(x, nextCurrentY - 10)

            return {
                points: splitPoints,
                stroke: '#242424',
                strokeWidth: 2,
                listening: false
            }
        },
        /**
         * @vuese
         * description
         * @arg imageConfig \\
         * @arg currentY \\
         * @arg width default = imageConfig.width * imageConfig.scaleX
         */
        createSplitRectConfig(imageConfig, currentY, width = imageConfig.width * imageConfig.scaleX) {
            const x = imageConfig.x - imageConfig.offset.x * imageConfig.scaleX
            return {
                x: x,
                y: currentY + imageConfig.height * imageConfig.scaleY,
                width: width,
                height: this.splitHeight,
                fill: '#F5F5F5'
            }
        },
        /**
         * @vuese
         * Generates depth points for cores inside core image
         * @arg image \\
         * @arg extraWidth default = 0
         */
        coresToDepthPoints(image, extraWidth = 0) {
            const imageX = image.config.x - image.config.offset.x * this.scale.x
            const imageWidth = image.config.width * this.scale.x + extraWidth

            let prevTopY = 0
            const points = []
            const cores = image.cores
            const splitOffset = image.config.cropY ? image.config.cropY : 0
            const size = Object.keys(cores).length
            Object.keys(cores).sort((id1, id2) => cores[id1].top_depth - cores[id2].top_depth).forEach((coreId, index) => {
                const core = cores[coreId]
                const topY = core.anchor_y + image.offset - splitOffset
                const point = {
                    fileId: image.file_id,
                    coreId: core.core_id,
                    y: topY,
                    type: 'top',
                    value: core.top_depth,
                    editable: index === 0 && image.first_part,
                    visible: index === 0 || (topY - prevTopY) * this.scale.y > 36
                }
                if (point.visible) {
                    prevTopY = topY
                }
                points.push(this.completeDepthPoint(point, imageX, imageWidth))

                const minDistance = 36 / this.scale.y
                const pixelPerDepthUnit = core.height / (core.bottom_depth - core.top_depth)
                let depthDelta = Math.max(0.1 * pixelPerDepthUnit, minDistance) / pixelPerDepthUnit
                depthDelta = Math.round(depthDelta * 10) / 10
                if (depthDelta > 0.1 && depthDelta <= 1) {
                    depthDelta = 1
                } else if (depthDelta > 1) {
                    depthDelta = Math.round(depthDelta)
                    depthDelta = Math.round(depthDelta / (10 * String(depthDelta).length)) * (10 * String(depthDelta).length)
                }
                const pixelDelta = depthDelta * pixelPerDepthUnit

                if (pixelDelta > 0 && depthDelta > 0) {
                    let depth
                    if (depthDelta === 0.1) {
                        const fixedValue = core.top_depth.toFixed(2)
                        const lastChar = fixedValue.charAt(fixedValue.length - 1)
                        if (lastChar !== '0') {
                            depth = core.top_depth - lastChar * 0.01 + depthDelta
                        } else {
                            depth = core.top_depth + depthDelta
                        }
                    } else if (depthDelta < 10) {
                        depth = Math.round(core.top_depth)
                        depth = depth < core.top_depth ? depth + 1 : depth
                    } else {
                        depth = Math.round(core.top_depth / (10 * String(depthDelta).length)) * (10 * String(depthDelta).length)
                    }

                    let anchorY = topY + (depth - core.top_depth) * pixelPerDepthUnit
                    while (core.bottom_depth - depth >= depthDelta / 2) {
                        if ((anchorY - topY) >= minDistance / 2) {
                            const point = {
                                fileId: image.file_id,
                                coreId: core.core_id,
                                y: anchorY,
                                type: 'middle',
                                value: depth,
                                editable: false,
                                visible: true
                            }
                            points.push(this.completeDepthPoint(point, imageX, imageWidth))
                        }
                        anchorY += pixelDelta
                        depth += depthDelta
                    }
                }

                if (index === size - 1) {
                    const point = {
                        fileId: image.file_id,
                        coreId: core.core_id,
                        y: topY + core.height,
                        type: 'bottom',
                        value: core.bottom_depth,
                        editable: true,
                        visible: core.height * this.scale.y >= 72 && (image.with_split || image.last_part)
                    }
                    points.push(this.completeDepthPoint(point, imageX, imageWidth))
                }
            })
            return points
        },
        /**
         * @vuese
         * description
         * @arg points \\
         * @arg imageConfig \\
         * @arg extraWidth default = 0
         */
        recalculateDepthPointConfigs(points, imageConfig, extraWidth = 0) {
            const imageX = imageConfig.x - imageConfig.offset.x * this.scale.x
            const imageWidth = imageConfig.width * this.scale.x + extraWidth
            return points.map((point) => this.completeDepthPoint(point, imageX, imageWidth))
        },
        /**
         * @vuese
         * description
         * @arg point \\
         * @arg imageX \\
         * @arg imageWidth
         */
        completeDepthPoint(point, imageX, imageWidth) {
            point.boundConfig = this.getDepthBoundConfig(point, imageX, imageWidth)
            point.pointerConfig = this.getDepthPointerConfig(point, imageX)
            point.textConfig = this.getDepthTextConfig(point, imageX, false)
            if (point.visible && point.editable && this.needEditableDepthPoint()) {
                point.editableRectConfig = this.getDepthRectConfig(point, imageX, true)
                point.editableTextConfig = this.getDepthTextConfig(point, imageX, true)
            }
            return point
        },
        /**
         * @vuese
         * description
         */
        needEditableDepthPoint() {
            return true
        },
        /**
         * @vuese
         * depth text tick configurations
         * @arg point \\
         * @arg imageX \\
         * @arg editable
         */
        getDepthTextConfig(point, imageX, editable) {
            const value = point.value.toFixed(2)
            const width = value.length * this.depthCharWidth
            const height = 36
            const fill = editable && point.editable ? '#FFFFFF' : '#242424'
            return {
                id: `depth${editable ? '-edit' : ''}-text-${point.coreId}`,
                fileId: point.fileId,
                coreId: point.coreId,
                depthType: point.type,
                depthValue: point.value,
                depthEditable: point.editable,
                x: imageX - width - 32,
                y: point.y * this.scale.y - (height / 2),
                width: width,
                height: height,
                text: value,
                fontSize: 18,
                fontFamily: 'Montserrat',
                fill: fill,
                align: 'center',
                verticalAlign: 'middle',
                listening: editable && point.editable && this.needEditableDepthPoint()
            }
        },
        /**
         * @vuese
         * description
         * @arg point \\
         * @arg imageX \\
         * @arg editable
         */
        getDepthRectConfig(point, imageX, editable) {
            const textConfig = this.getDepthTextConfig(point, imageX, editable)
            return {
                fill: '#42B1E5',
                stroke: '#FFFFFF',
                strokeWidth: 2,
                cornerRadius: 10,
                x: textConfig.x,
                y: textConfig.y,
                height: textConfig.height,
                width: textConfig.width,
                listening: false
            }
        },
        /**
         * @vuese
         * description
         * @arg point \\
         * @arg imageX \\
         * @arg imageWidth
         */
        getDepthBoundConfig(point, imageX, imageWidth) {
            const y = point.y * this.scale.y
            return {
                points: [imageX, y, imageX + imageWidth, y],
                stroke: 'rgba(255, 255, 255, 0.5)',
                strokeWidth: 2,
                listening: false
            }
        },
        /**
         * @vuese
         * how the tick and depth line will look like
         * @arg point \\
         * @arg imageX
         */
        getDepthPointerConfig(point, imageX) {
            const width = point.type === 'middle' ? 8 : 16
            const x = imageX - width
            const y = point.y * this.scale.y
            return {
                points: [x, y, x + width, y],
                stroke: '#242424',
                strokeWidth: 2,
                listening: false
            }
        },
        /**
         * @vuese
         * description
         */
        resizeCanvas() {
            const imageContainer = this.$refs.imageContainer
            this.$set(this.canvasConfig, 'width', imageContainer ? imageContainer.clientWidth : 0)
            this.$set(this.canvasConfig, 'height', imageContainer ? imageContainer.clientHeight : 0)
            this.$set(this.depths.current, 'active', false)
            this.repositioningImages()
            this.repositioningExtraLayers()
            this.reloadPreview(false)
        },
        /**
         * @vuese
         * description
         */
        calculateInitialScale(htmlImageElement) {
            return (this.canvasConfig.width / 5) / htmlImageElement.width
        },
        /**
         * @vuese
         * description
         */
        getCanvasImage() {
            return this.$refs.canvasImageGroup ? this.$refs.canvasImageGroup.getNode() : null
        },
        /**
         * @vuese
         * description
         */
        getImageScale() {
            return {x: this.scale.x, y: this.scale.y}
        },
        /**
         * @vuese
         * @arg scaleX \\
         * @arg scaleY
         */
        setImageScale(scaleX, scaleY) {
            const pointerPosition = this.getCanvasStage().getPointerPosition()
            const imageClientRect = this.getCanvasImage().getClientRect()
            const height = imageClientRect.height / this.scale.y
            const deltaY = (pointerPosition.y - imageClientRect.y) / this.scale.y

            this.$set(this, 'scale', {x: scaleX, y: scaleY})
            this.$set(this.depths.current, 'active', false)
            this.repositioningImages()
            this.repositioningExtraLayers()

            let newY = pointerPosition.y - deltaY * scaleY - 22 // it is depth point text offset
            if (newY > 0) {
                newY = 0
            }
            if (newY + height * scaleY < this.canvasConfig.height - this.splitHeight) {
                newY = this.canvasConfig.height - this.splitHeight - height * scaleY
            }
            this.getCanvasImage().y(newY)
        },
        /**
         * @vuese
         * description
         */
        repositioningImages() {
            let currentY = this.splitHeight
            let currentOffset = this.splitHeight / this.scale.y
            this.images.forEach((image) => {
                if (image.config) {
                    image.config.scaleX = this.scale.x
                    image.config.scaleY = this.scale.y

                    image.config.x = this.canvasConfig.width / 2
                    image.config.y = this.canvasConfig.height / 2 + currentY
                    // need set offset.y and offsetY for correct depth positioning - may be bug in konva
                    image.config.offset.y = (this.canvasConfig.height / 2) / image.config.scaleY
                    image.config.offsetY = image.config.offset.y
                    image.offset = currentOffset

                    if (image.with_split) {
                        image.splitLineConfig = this.createSplitLineConfig(image.config, currentY)
                        image.splitRectConfig = this.createSplitRectConfig(image.config, currentY)
                    }

                    this.$set(this.depths.points, image.image_id, this.coresToDepthPoints(image))

                    currentY = this.nextImageY(image.config, currentY, image.with_split)
                    currentOffset = this.nextImageOffset(image.config, currentOffset, image.with_split)
                }
            })
        },
        /**
         * @vuese
         * description
         * @arg images
         */
        cloneImages(images) {
            const cloned = this.clone(images)
            cloned.forEach((image, index) => {
                image.config.image = images[index].config.image
            })
            return cloned
        },
        /**
         * @vuese
         * description
         */
        repositioningExtraLayers() {
            this.repositioningCursorGroup()
        },
        /**
         * @vuese
         * description
         */
        repositioningCursorGroup() {
            this.$nextTick(() => {
                if (this.getCanvasImage()) {
                    this.$refs.canvasCursorGroup.getNode().y(this.getCanvasImage().y())
                    this.$refs.canvasCursorLayer.getNode().batchDraw()
                }
            })
        },
        /**
         * @vuese
         * description
         */
        turnOnImageScrolling() {
            this.getCanvasImage().on('wheel', (e) => this.scrollImage(e))
        },
        /**
         * @vuese
         * description
         * @arg e
         */
        scrollImage(e) {
            e.evt.preventDefault()
            const canvasImage = this.getCanvasImage()
            const clientRect = canvasImage.getClientRect()

            const newY = canvasImage.y() - e.evt.deltaY * this.getWheelMultiplier()
            if ((newY + clientRect.height >= (this.canvasConfig.height - this.splitHeight)) && (newY <= 0)) {
                this.onImageScrolling()
                canvasImage.y(newY)
                this.$refs.canvasLayer.getNode().batchDraw()

                this.repositioningExtraLayers()
                this.reloadPreviewViewport()
            }
        },
        /**
         * @vuese
         * description
         */
        scrollImageToTop() {
            const canvasImage = this.getCanvasImage()
            canvasImage.y(0)
            this.$refs.canvasLayer.getNode().batchDraw()

            this.repositioningExtraLayers()
            this.reloadPreviewViewport()
        },
        /**
         * @vuese
         * description
         * @arg deltaY \\
         * @arg feedbackAttributes
         */
        scrollImageFromPreview(deltaY, feedbackAttributes) {
            this.onImageScrolling()
            const canvasImage = this.getCanvasImage()
            const clientRect = canvasImage.getClientRect()

            let newY = canvasImage.y() - deltaY / this.imagePreview.ratioY
            if (newY + clientRect.height < (this.canvasConfig.height - this.splitHeight)) {
                newY = (this.canvasConfig.height - this.splitHeight) - clientRect.height
            }
            if (newY > 0) {
                newY = 0
            }

            canvasImage.y(newY)
            this.$refs.canvasLayer.getNode().batchDraw()

            this.repositioningExtraLayers()
            this.reloadPreviewViewport(true, feedbackAttributes)
        },
        /**
         * @vuese
         * does nothing
         */
        onImageScrolling() {
            // you can implement this method
        },
        /**
         * @vuese
         * description
         */
        onDragMoveImage() {
            this.repositioningExtraLayers()
        },
        /**
         * @vuese
         * description
         * @arg color - \\
         * @arg dashed - \\
         * @arg needBound - bool, default = true \\
         * @arg needPointer - bool, default = true \\
         * @arg needDepth - bool, default = true
         */
        turnOnCursorLine(color, dashed, needBound = true, needPointer = true, needDepth = true) {
            this.repositioningCursorGroup()

            this.$refs.canvasImage.getNode().on('touchend mouseleave', () => {
                this.resetCursorPosition()
                this.$refs.canvasCursorLayer.getNode().batchDraw()
            })

            this.$refs.canvasImage.getNode().on('touchmove mousemove', () => {
                const position = this.$refs.canvasStage.getNode().getPointerPosition()
                const imageRect = this.$refs.canvasImage.getNode().getClientRect()
                const y = position.y - imageRect.y + this.splitHeight

                if (needBound) {
                    const points = [imageRect.x, y, imageRect.x + imageRect.width, y]
                    if (this.cursorPosition.boundConfig.points) {
                        this.cursorPosition.boundConfig.points = points
                    } else {
                        this.$set(this.cursorPosition, 'boundConfig', {
                            points: points,
                            stroke: color,
                            strokeWidth: 2,
                            dash: [10, 10],
                            dashEnabled: dashed,
                            listening: false
                        })
                    }
                }

                if (needPointer) {
                    const points = [imageRect.x - 16, y, imageRect.x, y]
                    if (this.cursorPosition.pointerConfig.points) {
                        this.cursorPosition.pointerConfig.points = points
                    } else {
                        this.$set(this.cursorPosition, 'pointerConfig', {
                            points: points,
                            stroke: '#9E9E9E',
                            strokeWidth: 2,
                            listening: false
                        })
                    }
                }

                if (needDepth) {
                    const cursorImageY = y / this.scale.y

                    const image = this.images.find((image) => image.config.height + image.offset >= cursorImageY && cursorImageY >= image.offset)
                    if (!image) {
                        this.resetCursorPosition()
                        this.$refs.canvasCursorLayer.getNode().batchDraw()
                        return
                    }

                    const imageCropY = image.config.cropY
                    const coreId = Object.keys(image.cores).find((coreId) => {
                        return image.cores[coreId].anchor_y - imageCropY + image.offset <= cursorImageY &&
                            image.cores[coreId].anchor_y - imageCropY + image.offset + image.cores[coreId].height >= cursorImageY
                    })
                    if (!coreId || !image.cores[coreId]) {
                        this.resetCursorPosition()
                        this.$refs.canvasCursorLayer.getNode().batchDraw()
                        return
                    }

                    const core = image.cores[coreId]
                    const depthPerPixel = (core.bottom_depth - core.top_depth) / core.height

                    let depthValue = (cursorImageY - image.offset - core.anchor_y + imageCropY) * depthPerPixel + core.top_depth
                    depthValue = depthValue.toFixed(3)

                    const width = depthValue.length * this.depthCharWidth

                    if (this.cursorPosition.depthConfig.y) {
                        this.cursorPosition.depthConfig.x = imageRect.x - width - 32
                        this.cursorPosition.depthConfig.y = y - 18
                        this.cursorPosition.depthConfig.width = width
                        this.cursorPosition.depthConfig.text = depthValue
                    } else {
                        this.$set(this.cursorPosition, 'depthConfig', {
                            x: imageRect.x - width - 32,
                            y: y - 18,
                            width: width,
                            height: 36,
                            text: depthValue,
                            fontSize: 18,
                            fontFamily: 'Montserrat',
                            fill: '#9E9E9E',
                            align: 'center',
                            verticalAlign: 'middle',
                            listening: false
                        })
                    }
                }

                this.$refs.canvasCursorLayer.getNode().batchDraw()
            })
        },
        /**
         * @vuese
         * description
         */
        turnOffCursorLine() {
            this.$refs.canvasImage.getNode().off()
            this.resetCursorPosition()
            this.$refs.canvasCursorLayer.getNode().batchDraw()
        },
        /**
         * @vuese
         * description
         */
        resetCursorPosition() {
            this.$set(this, 'cursorPosition', {
                boundConfig: {},
                pointerConfig: {},
                depthConfig: {}
            })
        }
    }
}
