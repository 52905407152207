<template>
  <div
      v-show="display"
      :class="componentClasses"
      :style="componentStyles"
      class="lithology-classes"
  >
    <div v-if="pointerPosition" :class="arrowClasses"></div>
    <vue-custom-scrollbar
        :settings="scrollYSettings"
        class="lithology-classes-container scrollable-container"
        tagname="div"
    >
      <div
          v-for="cl in classes"
          :key="cl.class_id"
          :class="activeClass.class_id === cl.class_id ? 'active' : null"
          class="lithology-class"
          @click="changeActiveClass(cl)"
      >
        <lithology-type-preview
            :color="getTypeColor(cl.type_id)"
            :height="24"
            :pattern="getTypePattern(cl.type_id)"
            :width="24"
        />
        <div :title="getTypeName(cl.type_id)" class="title">
          {{ getTypeName(cl.type_id) }}
        </div>
        <div v-if="cl.lithology_class_id" class="probability">
          {{ getProbability(cl.probability) }}
        </div>
      </div>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import VueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import LithologyTypePreview from "@/views/components/lithology/lithology-type-preview";
import ScrollMixin from "@/mixins/scroll-mixin";

/**
 * @group Views-components-lithology
 * This is a description of the component
 */
export default {
  name: "lithology-classes",
  components: {
    LithologyTypePreview,
    VueCustomScrollbar,
  },
  mixins: [ScrollMixin],
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    pointerPosition: {
      type: Object,
      default: () => {
        return null;
      },
    },
    columnId: {
      type: String,
      default: null,
    },
    rectId: {
      type: String,
      default: null,
    },
    classes: {
      type: Array,
      default: () => {
        return [];
      },
    },
    types: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      arrowPosition: "right",
    };
  },
  computed: {
    display: {
      get() {
        return this.active;
      },
      set(newValue) {
        this.$emit("update:active", newValue);
      },
    },
    activeClass() {
      return this.classes.find((cl) => cl.selected);
    },
    componentClasses() {
      const classes = [];
      if (this.pointerPosition) {
        classes.push("position-absolute");
      }
      return classes;
    },
    componentStyles() {
      const centerX = this.pointerPosition ? this.pointerPosition.x : 0;
      const centerY = this.pointerPosition ? this.pointerPosition.y : 0;
      const offsetX = this.pointerPosition ? this.pointerPosition.offsetX : 0;
      const offsetY = this.pointerPosition ? this.pointerPosition.offsetY : 0;

      const elWidth = 230;
      const elHeight = Math.min(this.classes.length * 44 + 12, 276);
      const x = centerX - elWidth / 2;
      const y = centerY + offsetY + 24 + 12;
      const parentWidth =
          this.$el && this.$el.parentNode ? this.$el.parentNode.clientWidth : 0;
      const paddingWidth = 48;
      const parentHeight =
          this.$el && this.$el.parentNode ? this.$el.parentNode.clientHeight : 0;
      const position = {
        left: "",
        top: "",
      };
      if (
          elWidth < centerX &&
          elHeight / 2 < centerY &&
          elHeight / 2 < parentHeight - centerY
      ) {
        // component is on the left side
        this.setArrowPosition("right");
        position.left = this.pointerPosition
            ? `${centerX - elWidth - offsetX - 24 - 12}px`
            : 0;
        position.top = centerY - elHeight / 2 + "px";
      } else if (centerY - elHeight - offsetY > 0) {
        // component is on the top side
        this.setArrowPosition("bottom");
        position.left = this.pointerPosition ? `${x}px` : 0;
        position.top = this.pointerPosition
            ? `${centerY - elHeight - offsetY - 24 - 12}px`
            : 0;
      } else if (
          elHeight / 2 < centerY &&
          elWidth + centerX + paddingWidth < parentWidth
      ) {
        // component is on the right side
        this.setArrowPosition("left");
        position.left = this.pointerPosition
            ? `${centerX + offsetX + 24 + 12}px`
            : 0;
        position.top = centerY - elHeight / 2 + "px";
      } else if (elHeight + centerY < parentHeight) {
        // component is on the bottom side
        this.setArrowPosition("top");
        position.left = this.pointerPosition ? `${x}px` : 0;
        position.top = this.pointerPosition ? `${y}px` : 0;
      }
      return position;
    },
    arrowClasses() {
      return ["arrow", this.arrowPosition];
    },
  },
  methods: {
    setArrowPosition(value) {
      this.arrowPosition = value;
    },
    getType(id) {
      return this.types[id];
    },
    getTypePattern(id) {
      return this.getType(id).pattern;
    },
    getTypeColor(id) {
      return this.getType(id).color;
    },
    getTypeName(id) {
      const type = this.getType(id);
      if (type.custom_name) {
        return type.custom_name;
      }
      return type.names[this.$i18n.locale.toUpperCase()];
    },
    getProbability(probability) {
      const prob = Math.round(probability * 100);
      if (prob === -1) {
        return "NaN";
      } else {
        return prob + "%";
      }
    },
    changeActiveClass(cl) {
      this.classes.forEach((cls) => {
        if (cls.class_id === cl.class_id) {
          cls.selected = true;
          cls.custom_probability = 1;
        } else {
          cls.selected = false;
          cls.custom_probability = 0;
        }
      });
      this.$emit(
          "update:classes",
          this.classes,
          cl.type_id,
          this.columnId,
          this.rectId
      );
    },
  },
};
</script>

<style lang="scss">
.lithology-classes {
  width: 230px;
  max-height: 280px;
  background-color: $--color-base-01;
  box-shadow: 5px 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px;
  z-index: 1;

  .lithology-classes-container.scrollable-container:not(.ps--active-y) {
    .lithology-class {
      width: 214px;
    }
  }

  .lithology-classes-container.scrollable-container.ps--active-y {
    .lithology-class {
      width: 206px;
    }
  }

  .lithology-classes-container {
    max-height: 264px;

    .lithology-class:not(:first-child) {
      margin-top: 4px;
    }

    .lithology-class {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      height: 40px;
      padding: 8px;
      box-sizing: border-box;
      cursor: pointer;

      &.active,
      &:active {
        background-color: $--color-base-01;
        border: 2px solid $--color-secondary;
        border-radius: 8px;
        color: $--color-secondary;

        .title,
        .probability {
          color: $--color-secondary;
        }
      }

      &:hover {
        background-color: $--color-base-02;
        border: 2px solid $--color-base-02;
        box-sizing: border-box;
        border-radius: 8px;
      }

      .title,
      .probability {
        color: $--color-primary;
        font-size: $--font-size-base;
        font-weight: $--font-weight-medium;
      }

      .title {
        margin-left: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 18px;
      }

      @supports (-webkit-line-clamp: 2) {
        .title {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: unset;
        }
      }

      .probability {
        margin-left: auto;
        padding-left: 4px;
      }
    }
  }

  .arrow {
    position: absolute;
    width: 0;
    height: 0;

    &.right {
      right: -18px;
      top: calc(50% - 18px);
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
      border-left: 18px solid $--color-base-01;
    }

    &.left {
      left: -18px;
      top: calc(50% - 18px);
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
      border-right: 18px solid $--color-base-01;
    }

    &.top {
      top: -18px;
      left: calc(50% - 18px);
      border-right: 18px solid transparent;
      border-left: 18px solid transparent;
      border-bottom: 18px solid $--color-base-01;
    }

    &.bottom {
      bottom: -18px;
      left: calc(50% - 18px);
      border-right: 18px solid transparent;
      border-left: 18px solid transparent;
      border-top: 18px solid $--color-base-01;
    }
  }
}
</style>
