import Vue from 'vue'
import {extend, localize, ValidationObserver, ValidationProvider} from 'vee-validate'
// eslint-disable-next-line
import {is_not, max, max_value, min, min_value, required} from 'vee-validate/dist/rules'
import en from '@/locales/en.json'
import ru from '@/locales/ru.json'

/**
 * @vuese
 * describe the plugins (it will not be shown in vuese yet)
 */
extend('is_not', is_not)
extend('max_value', max_value)
extend('min_value', min_value)
extend('min', min)
extend('max', max)
extend('required', required)

localize({
    en: {
        messages: en.validations.messages
    },
    ru: {
        messages: ru.validations.messages
    }
})

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
