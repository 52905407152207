/**
 * @vuese
 * @group Views-mixins
 * This is a description of the component it includes DpButton component
 */
export default {
    data() {
        return {
            /**
             * @vuese
             * the data
             */
            cropShapeSize: {
                default: null,
                transform: null
            }
        }
    },
    methods: {
        /**
         * @vuese
         * description
         * @arg transformer
         */
        addCustomCropAnchors(transformer) {
            const widthShape = transformer.children[0].attrs.width
            const heightShape = transformer.children[0].attrs.height

            this.cropShapeSize.default = {
                width: widthShape,
                height: heightShape
            }
            this.cropShapeSize.transform = 100

            const minimumShapeSize = 0.5

            /* eslint-disable */
            const pathCenter = new Konva.Path({
                data: 'M6.79902 1.50122C7.55811 0.55236 8.70736 0 9.9225 0H70.0775C71.2926 0 72.4419 0.552359 73.201 1.50122L80 10H0L6.79902 1.50122Z',
                fill: '#42B1E5'
            })
            const pathLeftRight = new Konva.Path({
                data: 'M1.50122 73.201C0.552359 72.4419 -3.80611e-07 71.2926 -4.33726e-07 70.0775L-3.06319e-06 9.9225C-3.1163e-06 8.70737 0.552356 7.55811 1.50122 6.79903L10 -4.37114e-07L10 80L1.50122 73.201Z',
                fill: '#42B1E5'
            })
            const pathAngle = new Konva.Path({
                data: 'M1.50122 43.201C0.552359 42.4419 -3.80611e-07 41.2926 -4.33726e-07 40.0775L-2.01072e-06 4C-2.10729e-06 1.79086 1.79086 -7.8281e-08 4 -1.74846e-07L10 -4.37114e-07L10 50L1.50122 43.201Z M1.74845e-07 4C7.8281e-08 1.79086 1.79086 1.53904e-06 4 1.44248e-06L35.0775 8.40352e-08C36.2926 3.09199e-08 37.4419 0.552359 38.201 1.50122L45 10H4.37114e-07L1.74845e-07 4Z',
                fill: '#42B1E5'
            })
            /* eslint-enable */

            const tcAnchor = transformer.findOne('.top-center')
            tcAnchor.size({width: 80 * (widthShape / this.cropShapeSize.default.width), height: 10})
            tcAnchor.x(tcAnchor.x() - tcAnchor.width() / 2)
            tcAnchor.y(tcAnchor.y() - tcAnchor.height() / 2)
            tcAnchor.offsetX(0)

            const tcPath = pathCenter.clone({
                x: tcAnchor.x(),
                y: tcAnchor.y() - tcAnchor.height() / 2 - minimumShapeSize,
                id: 'top-center-custom'
            })
            tcPath.scaleX(widthShape / this.cropShapeSize.default.width)
            transformer.add(tcPath)
            tcAnchor.moveToTop()

            const bcAnchor = transformer.findOne('.bottom-center')
            bcAnchor.size({width: 80 * (widthShape / this.cropShapeSize.default.width), height: 10})
            bcAnchor.x(bcAnchor.x() - bcAnchor.width() / 2)
            bcAnchor.y(bcAnchor.y() + bcAnchor.height() / 2)
            bcAnchor.offsetX(0)

            const bcPath = pathCenter.clone({
                x: bcAnchor.x(),
                y: bcAnchor.y() + bcAnchor.height() / 2 + minimumShapeSize,
                scaleY: -1,
                id: 'bottom-center-custom'
            })
            bcPath.scaleX(widthShape / this.cropShapeSize.default.width)
            transformer.add(bcPath)
            bcAnchor.moveToTop()

            // middle-left
            const mlAnchor = transformer.findOne('.middle-left')
            mlAnchor.size({
                width: widthShape / this.cropShapeSize.default.width > minimumShapeSize ? 10 * (widthShape / this.cropShapeSize.default.width) : 10 * minimumShapeSize,
                height: heightShape / this.cropShapeSize.default.height > minimumShapeSize ? 80 * (heightShape / this.cropShapeSize.default.height) : 80 * minimumShapeSize
            })
            mlAnchor.x(mlAnchor.x() - 2)
            mlAnchor.y(mlAnchor.y() - mlAnchor.height() / 2)

            const lcPath = pathLeftRight.clone({
                x: mlAnchor.x() - mlAnchor.width() + 2 - minimumShapeSize,
                y: mlAnchor.y(),
                id: 'middle-left-custom'
            })
            transformer.add(lcPath)
            mlAnchor.moveToTop()

            // middle-right
            const mrAnchor = transformer.findOne('.middle-right')
            mrAnchor.size({
                width: widthShape / this.cropShapeSize.default.width > minimumShapeSize ? 10 * (widthShape / this.cropShapeSize.default.width) : 10 * minimumShapeSize,
                height: heightShape / this.cropShapeSize.default.height > minimumShapeSize ? 80 * (heightShape / this.cropShapeSize.default.height) : 80 * minimumShapeSize
            })
            mrAnchor.x(mrAnchor.x() + mrAnchor.width() - 2)
            mrAnchor.y(mrAnchor.y() - mrAnchor.height() / 2)

            const mrPath = pathLeftRight.clone({
                x: mrAnchor.x() + 2 + minimumShapeSize,
                y: mrAnchor.y() - 2,
                scaleX: -(heightShape / this.cropShapeSize.default.height),
                id: 'middle-right-custom'
            })
            transformer.add(mrPath)
            mrAnchor.moveToTop()

            // top-left
            const tlAnchor = transformer.findOne('.top-left')
            tlAnchor.size({
                width: widthShape / this.cropShapeSize.default.width > 0.5 ? 16 * (widthShape / this.cropShapeSize.default.width) : 8,
                height: heightShape / this.cropShapeSize.default.height > 0.5 ? 16 * (heightShape / this.cropShapeSize.default.height) : 8
            })
            tlAnchor.x(tlAnchor.x() * (widthShape / this.cropShapeSize.default.width))
            tlAnchor.y(tlAnchor.y())

            const tlPath = pathAngle.clone({
                id: 'top-left-custom',
                x: tlAnchor.x() - tlAnchor.height() + 5,
                y: tlAnchor.y() - tlAnchor.width() + 5
            })
            transformer.add(tlPath)
            tlAnchor.moveToTop()

            // top-right
            const trAnchor = transformer.findOne('.top-right')
            trAnchor.x(trAnchor.x())
            trAnchor.y(trAnchor.y())

            const trPath = pathAngle.clone({
                id: 'top-right-custom',
                x: trAnchor.x() + trAnchor.height() - 5,
                y: trAnchor.y() - trAnchor.width() + 5,
                scaleX: -1
            })
            transformer.add(trPath)
            trAnchor.moveToTop()

            // bottom-left
            const blAnchor = transformer.findOne('.bottom-left')
            blAnchor.x(blAnchor.x())
            blAnchor.y(blAnchor.y())

            const blPath = pathAngle.clone({
                id: 'bottom-left-custom',
                x: blAnchor.x() - blAnchor.height() + 5,
                y: blAnchor.y() + blAnchor.width() - 5,
                scaleY: -1
            })
            transformer.add(blPath)
            blAnchor.moveToTop()

            // bottom-right
            const brAnchor = transformer.findOne('.bottom-right')
            brAnchor.x(brAnchor.x())
            brAnchor.y(brAnchor.y())

            const brPath = pathAngle.clone({
                id: 'bottom-right-custom',
                x: brAnchor.x() + brAnchor.height() - 5,
                y: brAnchor.y() + brAnchor.width() - 5,
                scaleX: -1,
                scaleY: -1
            })
            transformer.add(brPath)
            brAnchor.moveToTop()
        },
        /**
         * @vuese
         * description
         * @arg transformer
         */
        moveCustomCropAnchors(transformer) {
            const widthShape = transformer.children[0].attrs.width
            const heightShape = transformer.children[0].attrs.height
            const minimumSizeShape = 0.5
            const colorFill = '#42B1E5'
            const defaultSizeCrop = 12
            const sizeAnchor = {
                width: widthShape / this.cropShapeSize.default.width > minimumSizeShape ? 16 * (widthShape / this.cropShapeSize.default.width) : 16 * minimumSizeShape,
                height: heightShape / this.cropShapeSize.default.height > minimumSizeShape ? 16 * (heightShape / this.cropShapeSize.default.height) : 16 * minimumSizeShape
            }
            const positionPathX = (widthShape / this.cropShapeSize.default.width > minimumSizeShape ?
                16 * (widthShape / this.cropShapeSize.default.width) - 5 * (widthShape / this.cropShapeSize.default.width) :
                16 * minimumSizeShape - 2.5)
            const positionPathY = (heightShape / this.cropShapeSize.default.height > minimumSizeShape ?
                16 * (heightShape / this.cropShapeSize.default.height) - 5 * (heightShape / this.cropShapeSize.default.height) :
                16 * minimumSizeShape - 2.5)
            const scaleX = widthShape / this.cropShapeSize.default.width > minimumSizeShape ? widthShape / this.cropShapeSize.default.width : minimumSizeShape
            const scaleY = heightShape / this.cropShapeSize.default.height > minimumSizeShape ? heightShape / this.cropShapeSize.default.height : minimumSizeShape

            const sizeAnchorStraight = [
                widthShape / this.cropShapeSize.default.width > minimumSizeShape ? 80 * (widthShape / this.cropShapeSize.default.width) : 80 * minimumSizeShape,
                heightShape / this.cropShapeSize.default.height > minimumSizeShape ? 10 * (heightShape / this.cropShapeSize.default.height) : 10 * minimumSizeShape
            ]
            const anchorReplacementFlag = {
                height: heightShape / this.cropShapeSize.default.height <= 0.2,
                width: widthShape / this.cropShapeSize.default.width <= 0.3
            }

            const tcAnchor = transformer.findOne('.top-center')
            const tcPath = transformer.findOne('#top-center-custom')
            if (!anchorReplacementFlag.width) {
                tcAnchor.size({width: sizeAnchorStraight[0], height: sizeAnchorStraight[1]})
                tcAnchor.x(tcAnchor.x() - tcAnchor.width() / 2)
                tcAnchor.y(tcAnchor.y() - tcAnchor.height() / 2)
                tcAnchor.offsetX(0)
                tcPath.attrs.fill = colorFill
                tcAnchor.attrs.fill = 'transparent'
                tcPath.position({
                    x: tcAnchor.x(),
                    y: tcAnchor.y() - tcAnchor.height() / 2 - minimumSizeShape
                })
                tcPath.scaleX(scaleX)
                tcPath.scaleY(scaleY)
            } else {
                tcAnchor.size({width: 12, height: 12})
                tcAnchor.x(tcAnchor.x() - tcAnchor.width() / 2)
                tcAnchor.y(tcAnchor.y() + 2)
                tcAnchor.offsetX(0)
                tcPath.attrs.fill = 'transparent'
                tcAnchor.attrs.fill = colorFill
            }

            const bcAnchor = transformer.findOne('.bottom-center')
            const bcPath = transformer.findOne('#bottom-center-custom')
            if (!anchorReplacementFlag.width) {
                bcAnchor.size({width: sizeAnchorStraight[0], height: sizeAnchorStraight[1]})
                bcAnchor.x(bcAnchor.x() - bcAnchor.width() / 2)
                bcAnchor.y(bcAnchor.y() + bcAnchor.height() / 2)
                bcAnchor.offsetX(0)
                bcPath.attrs.fill = colorFill
                bcAnchor.attrs.fill = 'transparent'
                bcPath.position({
                    x: bcAnchor.x(),
                    y: bcAnchor.y() + bcAnchor.height() / 2 + minimumSizeShape
                })
                bcPath.scaleX(scaleX)
                bcPath.scaleY(-scaleY)
            } else {
                bcAnchor.size({width: defaultSizeCrop, height: defaultSizeCrop})
                bcAnchor.x(bcAnchor.x() - bcAnchor.width() / 2)
                bcAnchor.y(bcAnchor.y() + 2)
                bcAnchor.offsetX(0)
                bcPath.attrs.fill = 'transparent'
                bcAnchor.attrs.fill = colorFill
            }

            // middle-left
            const mlAnchor = transformer.findOne('.middle-left')
            const mlPath = transformer.findOne('#middle-left-custom')
            if (!anchorReplacementFlag.height) {
                mlAnchor.size({
                    width: widthShape / this.cropShapeSize.default.width > minimumSizeShape ? 10 * (widthShape / this.cropShapeSize.default.width) : 10 * minimumSizeShape,
                    height: heightShape / this.cropShapeSize.default.height > minimumSizeShape ? 80 * (heightShape / this.cropShapeSize.default.height) : 80 * minimumSizeShape
                })
                mlAnchor.x(mlAnchor.x() - mlAnchor.width())
                mlAnchor.y(mlAnchor.y() - mlAnchor.height() / 2)
                mlAnchor.offsetX(0)
                mlPath.attrs.fill = colorFill
                mlAnchor.attrs.fill = 'transparent'
                mlPath.position({
                    x: mlAnchor.x() - mlAnchor.width() + (10 * scaleX) - minimumSizeShape,
                    y: mlAnchor.y() - 2
                })
                mlPath.scaleY(scaleY)
                mlPath.scaleX(scaleX)
            } else {
                mlAnchor.size({width: defaultSizeCrop, height: defaultSizeCrop})
                mlAnchor.x(mlAnchor.x() - mlAnchor.width() / 2)
                mlAnchor.y(mlAnchor.y())
                mlAnchor.offsetX(0)
                mlPath.attrs.fill = 'transparent'
                mlAnchor.attrs.fill = colorFill
            }

            // middle-right
            const mrAnchor = transformer.findOne('.middle-right')
            const mrPath = transformer.findOne('#middle-right-custom')
            if (!anchorReplacementFlag.height) {
                mrAnchor.size({
                    width: widthShape / this.cropShapeSize.default.width > minimumSizeShape ? 10 * (widthShape / this.cropShapeSize.default.width) : 10 * minimumSizeShape,
                    height: heightShape / this.cropShapeSize.default.height > minimumSizeShape ? 80 * (heightShape / this.cropShapeSize.default.height) : 80 * minimumSizeShape
                })
                mrAnchor.x(mrAnchor.x() + minimumSizeShape)
                mrAnchor.y(mrAnchor.y() - mrAnchor.height() / 2)
                mrAnchor.offsetX(0)
                mrPath.attrs.fill = colorFill
                mrAnchor.attrs.fill = 'transparent'
                mrPath.position({
                    x: mrAnchor.x() + (10 * scaleX),
                    y: mrAnchor.y() - 2
                })
                mrPath.scaleY(scaleY)
                mrPath.scaleX(-scaleX)
            } else {
                mrAnchor.size({width: defaultSizeCrop, height: defaultSizeCrop})
                mrAnchor.x(mrAnchor.x() - mrAnchor.width() / 2)
                mrAnchor.y(mrAnchor.y())
                mrAnchor.offsetX(0)
                mrPath.attrs.fill = 'transparent'
                mrAnchor.attrs.fill = colorFill
            }

            // top-right
            const trAnchor = transformer.findOne('.top-right')
            const trPath = transformer.findOne('#top-right-custom')
            if (anchorReplacementFlag.height || anchorReplacementFlag.width) {
                trAnchor.size({width: defaultSizeCrop, height: defaultSizeCrop})
                trAnchor.position({
                    x: trAnchor.x() + 2,
                    y: trAnchor.y() + 2
                })
                trAnchor.attrs.fill = colorFill
                trPath.attrs.fill = 'transparent'
            } else {
                trAnchor.size(sizeAnchor)
                trAnchor.x(trAnchor.x())
                trAnchor.y(trAnchor.y())
                trAnchor.attrs.fill = 'transparent'
                trPath.attrs.fill = colorFill
                trPath.position({
                    x: trAnchor.x() + positionPathX,
                    y: trAnchor.y() - positionPathY
                })
                trPath.scaleX(-scaleX)
                trPath.scaleY(scaleY)
            }

            // top-left
            const tlAnchor = transformer.findOne('.top-left')
            const tlPath = transformer.findOne('#top-left-custom')
            if (anchorReplacementFlag.height || anchorReplacementFlag.width) {
                tlAnchor.size({width: 16, height: 16})
                tlAnchor.attrs.fill = colorFill
                tlPath.attrs.fill = 'transparent'
                tlAnchor.scaleX(0.75)
                tlAnchor.scaleY(0.75)
            } else {
                tlAnchor.size(sizeAnchor)
                tlAnchor.x(tlAnchor.x())
                tlAnchor.y(tlAnchor.y())
                tlPath.position({
                    x: tlAnchor.x() - positionPathX,
                    y: tlAnchor.y() - positionPathY
                })
                tlAnchor.attrs.fill = 'transparent'
                tlPath.attrs.fill = colorFill
                tlPath.scaleX(scaleX)
                tlPath.scaleY(scaleY)
            }

            // bottom-left
            const blAnchor = transformer.findOne('.bottom-left')
            const blPath = transformer.findOne('#bottom-left-custom')
            if (anchorReplacementFlag.height || anchorReplacementFlag.width) {
                blAnchor.size({width: 12, height: 12})
                blAnchor.position({
                    x: blAnchor.x() + 2,
                    y: blAnchor.y() + 2
                })
                blAnchor.attrs.fill = colorFill
                blPath.attrs.fill = 'transparent'
            } else {
                blAnchor.size(sizeAnchor)
                blAnchor.x(blAnchor.x())
                blAnchor.y(blAnchor.y())
                blPath.position({
                    x: blAnchor.x() - positionPathX,
                    y: blAnchor.y() + positionPathY
                })
                blAnchor.attrs.fill = 'transparent'
                blPath.attrs.fill = colorFill
                blPath.scaleX(scaleX)
                blPath.scaleY(-scaleY)
            }

            // bottom-right
            const brAnchor = transformer.findOne('.bottom-right')
            const brPath = transformer.findOne('#bottom-right-custom')
            if (anchorReplacementFlag.height || anchorReplacementFlag.width) {
                brAnchor.size({width: 16, height: 16})
                brAnchor.attrs.fill = colorFill
                brPath.attrs.fill = 'transparent'
                brAnchor.scaleX(0.75)
                brAnchor.scaleY(0.75)
            } else {
                brAnchor.size(sizeAnchor)
                brAnchor.x(brAnchor.x())
                brAnchor.y(brAnchor.y())

                brPath.position({
                    x: brAnchor.x() + positionPathX,
                    y: brAnchor.y() + positionPathY
                })
                brAnchor.attrs.fill = 'transparent'
                brPath.attrs.fill = colorFill
                brPath.scaleX(-scaleX)
                brPath.scaleY(-scaleY)
            }
        }
    }
}
