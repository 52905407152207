import axios from 'axios'

/**
 * @group Services
 * describe the Services (it will not be shown in vuese yet)
 */
export default {
    coreColumnOpacity() {
        return axios.get('/api/v1/image/core-column-opacity')
    },
    classificationModels() {
        return axios.get('/api/v1/models/classification')
    },
    patternNames() {
        return axios.get('/api/v1/image/icons')
    },
    patternIcon(name, color) {
        return axios.get('/api/v1/image/icon', {params: {name, color}})
    }
}
